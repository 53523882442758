import * as Sentry from "@sentry/vue";
const isSentryEnabled = import.meta.env.VITE_ENABLE_SENTRY === 'true'
const APP_VERSION = import.meta.env.VITE_APP_VERSION || 'front'
const initSenitry = (app, router) => {
    if(!isSentryEnabled) {
      return false
    }
    Sentry.init({
      app,
      environment: import.meta.env.VITE_SENTRY_ENV || 'unknown',
      dsn: import.meta.env.VITE_SENTRY_DSN,
      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      release: `kiwiread.${APP_VERSION}`,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
    
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      trackComponents: true,
    });
}
const reportLog = (message, data = {}, level = "info") => {
  if(!isSentryEnabled) {
    return false
  }
  Sentry.captureMessage(message, {
    level: level,
    extra: {
      object: data
    }
  });
}
export {
  initSenitry,
  reportLog
}