export function useFormData() {
  function createFormData(object, form, namespace) {
    const formData = form || new FormData();
    for (let property in object) {
      if (!object.hasOwnProperty(property)) {
        continue;
      }
  
      const formKey = namespace ? `${namespace}[${property}]` : property;
  
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (object[property] instanceof File) {
        formData.append(formKey, object[property]);
      } else if (object[property] instanceof Blob && object[property].type.startsWith('audio/')) {
        formData.append(formKey, object[property], 'audio.mp3');
      } else if (Array.isArray(object[property])) {
        if (object[property].length === 0) {
          formData.append(formKey + '[]', ''); // Handle empty array
        } else {
          object[property].forEach((value, index) => {
            const arrayKey = `${formKey}[${index}]`;
            if (typeof value === 'object' && value !== null) {
              createFormData(value, formData, arrayKey);
            } else {
              formData.append(arrayKey, value == null ? '' : value);
            }
          });
        }
      } else if (typeof object[property] === "object" && object[property] !== null) {
        createFormData(object[property], formData, formKey);
      } else {
        const value = object[property] == null ? '' : object[property];
        formData.append(formKey, value);
      }
    }
    return formData;
  }
  
  return { createFormData };
}
