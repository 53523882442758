<template>
    <article class="paragraphs">
        <span 
            v-if="content && content.category" 
            :class="[content.category.color, content.category.transform ? `text-${content.category.transform}` : 'text-uppercase']" 
            class="kiwi-card-category text-body-1 font-weight-semi-bold" 
            v-html="content.category.name" 
        />
        <div v-if="content" class="author-wrapper d-flex flex-wrap has-dot-seprator">
            <span v-if="content.author" v-html="content.author_name" />
            <v-icon class="mdi mdi-circle-small px-4"></v-icon>
            <span v-if="content.published_date || content.created_date" v-html="formatDate(content.published_date) || formatDate(content.created_date)" />
        </div>
        <h2 v-if="!isBlogContent" class="kiwi-fs-40 font-weight-bold" v-html="content.title" />
        <router-link v-else :to="{name: 'blogPost', params:{ slug: blog_slug }}" style="text-decoration: none;"><div class="font-weight-bold blog-title kiwi-text-black mt-2" :class="'kiwi-fs-20'" v-html="content.title" /></router-link>
        <p class="kiwi-text-body-1 mt-2" v-html="limitString(content.content, 200)"></p>
        <template v-if="content.tags && content.tags.length">
            <v-chip-group class="mt-2">
                <v-chip v-for="(tag, i) in content.tags" label>{{tag.title}}</v-chip>
            </v-chip-group>
        </template>
        <template v-if="mode === 'two-column'">
            <router-link :to="{name: 'blogPost', params:{ slug: blog_slug }}" style="text-decoration: none;">
                <v-btn 
                    variant="outlined" 
                    size="x-large" 
                    rounded="xl" 
                    min-width="126px" 
                    height="40px" 
                    :class="'kiwi-border-primary kiwi-text-primary text-capitalize text-body-1'"
                    class="mt-2"
                >Read More</v-btn>
            </router-link>
        </template>
    </article>
</template>

<script setup>
import { limitString } from '@/utils/utils';
import { computed } from 'vue';

    const props = defineProps({
        content: {
            type: Object,
            required: true,
        },
        isBlogContent: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'single',
        }
    })

    const blog_slug = computed(() => {
        return props.content.title_slug || '404'
    })
    const formatDate = (dateString) => {
        // Parse the date string into a Date object
        const date = new Date(dateString)

        // Extract the day and month
        const day = date.getUTCDate() // get the day of the month
        const month = date.toLocaleString('default', { month: 'long' }) // get the month name

        return `${getOrdinalSuffix(day)} ${month}`
    }

    // Format the day with ordinal suffix
    const getOrdinalSuffix = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    
   
</script>