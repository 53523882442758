<template>
  <Card :handleControl="microphoneControl" class="h-100 align-center microphone">
    <article class="kiwi-max-w-100">
      <div class="title kiwi-fs-20 font-weight-bold">
        {{ microphoneContent.title }}
      </div>
      <div class="title kiwi-fs-16 font-widht-medium mt-2">
        {{ microphoneContent.text }}
      </div>
    </article>
    <figure class="mt-4 position-relative w-100">
      <img
        src="/public/assets/microphone.png"
        class="max-w-100 w-100 position-relative z-1"
        alt="microphone img"
      />
      <figcaption
        class="position-absolute z-2 bottom-0 text-center w-100"
        style="left: 50%; transform: translateX(-50%)"
      >
        <span>Speak to the microphone. Does the Indicator move?</span>
        <ProgressBar ref="refBar" class="justify-center" :recording="recording" :playing="playing" />
        <v-sheet
          variant="flat"
          class="kiwi-radius-8 kiwi-bg-light-grey-3 pa-4 justify-center d-flex mt-4 flex-wrap"
        >
          <v-btn
            rounded="xl"
            variant="flat"
            :class="[
              'kiwi-bg-white',
              'text-center',
              'text-none',
              'font-weight-semi-bold',
              'kiwi-fs-16',
              'mr-4',
              { 'bg-red': recording },
            ]"
            style="flex: 1;"
            :disabled="playing"
            height="48"
            @click.native="toggleRecording()"
            >
              <template v-if="recording">
                <img class="mr-2" :src="'/assets/stop.svg'">
              </template>
              <template v-else>
                <svg width="16" height="16" class="mr-2" viewBox="0 0 16 16" :stroke="institute.primaryColor" :fill="institute.primaryColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.6665 8.00033C2.6665 5.81417 2.6665 4.7211 3.20905 3.94627C3.40977 3.6596 3.65911 3.41026 3.94578 3.20954C4.72061 2.66699 5.81368 2.66699 7.99984 2.66699C10.186 2.66699 11.279 2.66699 12.0539 3.20954C12.3406 3.41026 12.5899 3.6596 12.7906 3.94627C13.3332 4.7211 13.3332 5.81417 13.3332 8.00033C13.3332 10.1865 13.3332 11.2795 12.7906 12.0544C12.5899 12.3411 12.3406 12.5904 12.0539 12.7911C11.279 13.3337 10.186 13.3337 7.99984 13.3337C5.81368 13.3337 4.72061 13.3337 3.94578 12.7911C3.65911 12.5904 3.40977 12.3411 3.20905 12.0544C2.6665 11.2795 2.6665 10.1865 2.6665 8.00033Z" fill="white"/>
                </svg>
              </template>
              {{ recording ? "Stop" : "Record" }}</v-btn
          >
          <v-btn
            rounded="xl"
            variant="flat"
            :class="[
              'kiwi-bg-white',
              'text-center',
              'text-none',
              'font-weight-semi-bold',
              'kiwi-fs-16',
              { 'bg-red': playing },
            ]"
            height="48"
            style="flex: 1;"
            :disabled="recording"
            @click.native="togglePlayback"
            kiwi-fs-16
            >
            <template v-if="playing">
                <img class="mr-2" :src="'/assets/stop.svg'">
            </template>
            <template v-else>
              <svg width="21" height="20" viewBox="0 0 21 20" :stroke="institute.primaryColor"  xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2422 10.705C15.9477 11.8242 14.5557 12.615 11.7715 14.1968C9.08008 15.7258 7.73441 16.4903 6.64994 16.183C6.20158 16.0559 5.79307 15.8147 5.46361 15.4823C4.66675 14.6782 4.66675 13.1188 4.66675 10C4.66675 6.88117 4.66675 5.32175 5.46361 4.51777C5.79307 4.18538 6.20158 3.94407 6.64994 3.81702C7.73441 3.50971 9.08008 4.27423 11.7715 5.80328C14.5557 7.38498 15.9477 8.17583 16.2422 9.295C16.3638 9.757 16.3638 10.243 16.2422 10.705Z"  :fill="institute.primaryColor"/>
              </svg>
            </template>
            {{ playing ? "Stop" : "Play" }}</v-btn
          >
        </v-sheet>
      </figcaption>
    </figure>
  </Card>
</template>

<script setup>
import Card from "@/components/Dashboard/ContentSheet.vue";
import { ref } from "vue";
import { useMicrophone } from "@/composables/useMicrophone";
import ProgressBar from "@/components/ProgressBar/ProgressBar.vue";
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()
const {
  microphoneAccess,
  requestMicrophonePermission,
  toggleRecording,
  recording,
  togglePlayback,
  micSupported,
  playing,
} = useMicrophone();
const refBar = ref(null);

const microphoneControl = ref({
  bg: "white",
  textColor: "black",
  mode: "microphone",
});

const microphoneContent = ref({
  title: "Microphone Check",
  text: "Let’s make sure the microphone works correctly right before you take the test",
});
</script>

<style>
.bottom-0 {
  bottom: 0; 
}
.bg-red {
  background-color: red !important;
}
</style>
