<template>
        <v-dialog v-model="dialog" persistent max-width="1440">
                <v-sheet class="rounded">
                    <div class="border-b px-3 align-center d-flex justify-space-between">
                        <div class="pb-4 pt-4">
                            <p class="font-weight-bold kiwi-fs-20 kiwi-text-black-3">{{title}} : Question  {{index + 1}}</p>
                            <p class="kiwi-fs-14 kiwi-text-light-black-3">{{evaluation?.exam_details?.title}} - {{convertDate(evaluation?.exam_details?.tested_on)}}</p>
                        </div>
                        <div class="exit-btn  mdi mdi-close align-center d-flex justify-center " @click="close" style="cursor: pointer;">
                        </div>
                    </div>

                    <div class="mt-5 px-5">
                        <v-row>
                            <v-col cols="12" md="6" v-if="questionAnswerDetails.layout === 'english'">
                                <p class="no-select" v-html="questionAnswerDetails.question_text">  </p>

                            </v-col>
                            <v-col cols="12" md>
                                <div class="rectangle  d-flex  text-center align-center justify-center ">{{questionIndex + 1 }}</div>
                                <p  v-if="questionAnswerDetails?.layout !== 'english'" class="mt-2 no-select" v-html="questionAnswerDetails?.question_text" ></p>
                                <ol class="alphabetical mx-5" v-if="questionAnswerDetails?.layout !== 'math_without_options'">
                                    <li v-for="(answer, index) in questionAnswerDetails.options" :key="index" @click="selectAnswer(index)"
                                        :class="{ 'selected': selectedAnswer === index && !hide } "  style="cursor: pointer;" v-html="answer.option">
                                    </li>
                                </ol>

                            <div class="feedback pa-1 text-center" v-if="!hide && questionAnswerDetails?.layout !== 'math_without_options'" :class="{
                                'correct-answer': selectedAnswer === correctAnswer,
                                'incorrect-answer': (selectedAnswer !== correctAnswer)
                            }">
                                <p>
                                    {{ alphabets[selectedAnswer]  ? 'You selected answer ' + alphabets[selectedAnswer]+'.' : 'No Answer Selected.'}} The correct answer is
                                    {{ alphabets[correctAnswer] }}.
                                </p>
                            </div>
                            <div class="feedback py-1 px-3 text-center" v-if="!hide && questionAnswerDetails?.layout === 'math_without_options'" :class="{
                                'correct-answer': questionAnswerDetails?.is_user_answer_correct,
                                'incorrect-answer': !questionAnswerDetails?.is_user_answer_correct
                            }">
                                <p style="line-height: 1;">
                                    {{questionAnswerDetails?.user_answer ? 'You entered answer is ' + questionAnswerDetails?.user_answer  : 'No Answer Selected'}}. The correct answer is {{questionAnswerDetails?.answer_text}}
                                </p>
                            </div>
                                <div v-show="!hide">
                                    <div class="kiwi-fs-18 font-weight-bold kiwi-text-black-3 mt-4 mb-2">Explanation</div>
                                    <div  v-html="explanation" class="d-block explanation-text  no-select"></div>
                                </div>
                        </v-col>

                        </v-row>
                    </div>
                    <div class="d-flex text-center align-center px-5  justify-space-between">
                        <v-checkbox v-model="hide" color="#1CA972" class="kiwi-fs-14 kiwi-text-black-3 mt-5"
                            label="Hide correct answer and explanation"></v-checkbox>
                        <div>
                            <v-btn v-if="questionIndex !== 0" color="#E6E7E7" class="me-3 rounded-pill kiwi-text-light-black-3"
                                @click="back">Back</v-btn>
                            <v-btn color="#1CA972" class="rounded-pill" @click="next">Next</v-btn>
                        </div>
                    </div>
                </v-sheet>
        </v-dialog>
</template>

<script setup>
import { ref , nextTick, watch, computed } from 'vue';
import {useEvaluationStore} from "@/stores/Sat/evaluation-store";
import {storeToRefs} from "pinia";
const dialog = ref(true)
const props = defineProps({
    questionText: {
        required: true,
        default: "This is the question text of math ",
    },
    choiceAnswers: {
        type: Array,
        required: false,
        default: () => ["(x-4)(x+10)", "(x-5)(x+8)", "(x-8)(x+5)", "(x-10)(x+4)"],
    },
    questions:{
      type: Array,
      required: true
    },
    index:{
      type: Number,
      default: 0
    },
    questionId:{
      type: Number,
    }
});
const renderMathJax = () => {
  nextTick(() => {
    if (window.MathJax) {
      window.MathJax.typeset();
    }
  });
};

renderMathJax();
const questionIndex = computed(() => {
  return props.index;
})
const hide = ref(false);
const emit = defineEmits(["close-dialog","next","back"]);
const alphabets = ['A', 'B', 'C', 'D', 'E']
const localSelectedAnswer = ref(props.selectedAnswer);

const selectAnswer = (index) => {
    localSelectedAnswer.value = index;
};
const close =() =>{
  emit('close-dialog');
}
const next = () =>{
    renderMathJax();
 emit('next');
}
const back =()=>{
    renderMathJax();
  emit('back');
}
const questionAnswerDetails = computed(() => {
  return props.questions[props.index];
})

const selectedAnswer = computed(() => {
  return  questionAnswerDetails.value.options.findIndex(option => option.selected)
})
const correctAnswer = computed(() => {
 return  questionAnswerDetails.value.options.findIndex(option => option.is_correct_answer)

})
const explanation = computed(() => {
  return questionAnswerDetails.value.descriptive_hint;
})
watch(explanation, (newVal) => {
    if(newVal) {
        renderMathJax();
    }
}, {deep: true,})
watch(hide, (newVal) => {
    if(newVal) {
        renderMathJax();
    }
}, {deep: true,})
const title =  computed(() => {
  if(layout.value === 'english'){
    return "Reading And Writing"
  }
  return "Maths";

})
const layout = computed(() => {
  return props.questions[props.index].layout;
})
const evaluationStore = useEvaluationStore();
const {evaluation} = storeToRefs(evaluationStore);
const convertDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  return date.toLocaleDateString('en-US', options);
};


</script>

<style scoped>
.rectangle {
    height: 36px;
    width: 36px;
    background: #03120C;
    color: #FFFFFF;
}

.exit-btn {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    border: 1px solid #E2E6E4;
    font-size: 30px;
}

.feedback {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    border-radius: 4px;
    height: 36px;
    color: #FFFFFF;
}

.correct-answer {
    background-color: #039855;
}

.incorrect-answer {
    background-color: #D92D20;
}

ol.alphabetical {
    list-style-type: upper-alpha;
}

li {
    margin-top: 20px;
    line-height: 20px;
}

.selected {
    font-weight: bold;
}
.explanation-text * {
    font-size: 14px;
    line-height: 1.5;
    //word-break: break-all;
    word-wrap: break-word;
}
.no-select {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}
</style>
