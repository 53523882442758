import { defineStore } from 'pinia';
import {api} from "@/plugins/axios";
import {ref} from "vue";

export const useModuleStore = defineStore("moduleStore", () => {

  const moduleTime = ref();

  async function getEachModuleTime(id)
  {
    let url = `exam/sat/exam-instance/${id}/get-module-time/`;
    await  api.get(url).then(res => {
      moduleTime.value = res.data;
    })
  }

  return {moduleTime , getEachModuleTime}
})
