<template>
    <v-dialog
        v-model="dialog"
        style="box-shadow: 0px 8px 30px -4px rgba(16, 24, 40, 0.04) !important;"
        @update:model-value="emit('onClose')"
    >
        <v-sheet 
            class="kiwi-bg-white kiwi-text-black overflow-hidden modal-dialog-component" 
            variant="flat"
        >
            <slot />
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import {ref} from 'vue'
const emit = defineEmits('onClose')
const dialog = ref(true)
</script>