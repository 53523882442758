import { defineStore } from "pinia";
import { downloadResponse } from "@/utils/utils";
import {api, apiWithoutAuth} from '@/plugins/axios';
import { ref } from "vue";
export const useAgentVoucherStore = defineStore('agentVoucherStore' , () =>{
    const fetching = ref(false)
    const vouchers = ref([])
    const exporting = ref(false)
    const statusCount = ref({})
    const pagination = ref({
    })
    const packages = ref([])
    const filter = ref({
        package: [],
        search: null,
        page: 1,
        status: 'active',
        page_size: 10
    })

    const getAgentVouchers = async () => {
        try {
            fetching.value = true
            await api.get('/agent/voucher/', {params: {...filter.value, package: filter.value.package.join(',')}}).then(res => {
                const {results, ...payload} = res.data
                vouchers.value = results
                pagination.value = {...pagination.value, ...payload}
                fetching.value = false
            })
        }
        catch(error) {
            throw error
        }
    }

    async function getAgentPackages()
    {
        try {
            await api.get('/agent/package/', {params: {with_voucher_count: 0, with_student_count: 1}}).then(res => {
                packages.value = res.data.results || []
            })
        } catch (error) {
            throw error
        }
    }

    async function getAgentStatusCount()
    {
        try {
            await api.get('/agent/voucher/status_wise_count/').then(res => {
                statusCount.value = res.data || {}
            })
        } catch (error) {
            throw error
        }
    }

    async function exportData()
    {
        exporting.value = true
        try {
            const res = await api.get('/agent/voucher/export-csv', {
                responseType: 'blob',
                params: {...filter.value, page_size: null, page: null, package: filter.value.package.join(',')}
            }).then(res => {
                downloadResponse(res, 'voucher')
                exporting.value = false
            })
            return res;
        } catch (error) {
            exporting.value = false
            throw error
        }
    }

    async function applyVoucher(email, code) 
    {
        try {
            return await api.post('subscriptions/subscribe-package/', {
                voucher_code: code,
                apply_for_user_email: email
            }).then(res => {
                return res
            })
        } catch (error) {
            throw error
        }
    }

    return {
        fetching,
        vouchers,
        packages,
        pagination,
        filter,
        getAgentPackages,
        getAgentVouchers,
        getAgentStatusCount,
        statusCount,
        exportData,
        applyVoucher
    }
})