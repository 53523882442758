<template>
    <div :key="key">
        <audio  controls class="mt-2 mb-3" type="audio/mpeg">
            <source :src="source" :id="key">
            Your browser does not support the audio element.
        </audio>
    </div>
</template>
<script setup>
import { onMounted  , ref } from 'vue';

const props = defineProps({
    source: {
        required: false
    },
    autoPlay:{
        required: false,
        default:false,
        type:Boolean
    }
})

onMounted(() => {
    document.querySelectorAll('audio').forEach(audio => {
        audio.addEventListener('play', function() {
            document.querySelectorAll('audio').forEach(otherAudio => {
            if (otherAudio !== audio) {
                otherAudio.pause();
            }
            });
        });
    });
    if(props.autoPlay){
        document.querySelectorAll('audio').forEach(audio =>{
            audio.setAttribute("autoplay" ,'')
        })
    }
})
const generateRandomText = (length  = 10) =>
  Array.from({length}, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    .charAt(Math.floor(Math.random() * 62)))
    .join('');

const  key = ref(generateRandomText())


</script>
