<template>
    <section class="kiwi-coming-soon-page position-relative d-flex fill-height align-center justify-center">
        <img 
            src="/assets/coming-soon-watermark.svg" alt="background svg"
            class="position-absolute z-1 w-100 h-100"
            style="top: 0; left: 0; object-fit: cover"
        >
        <v-sheet class="outer-wrapper position-relative z-2 kiwi-bg-white pa-4 rounded-xl w-100 d-flex align-center justify-center overflow-hidden" style="height: 100%; max-height: 700px; max-width: 75%;">
            <article class="position-relative z-2 d-flex flex-column align-center justify-center kiwi-text-black-3 mx-auto" style="max-width: 800px;">
                <img src="/assets/kiwi-logo.svg" alt="Kiwi Logo" class="kiwi-logo">
                <img src="/assets/coming-soon-bar.svg" class="mt-3 bar-line" alt="bar">
                <h1 class="kiwi-fs-56 font-weight-bold">Coming Soon!</h1>
                <p class="kiwi-fs-18 mobile-w-75 mt-4 font-weight-medium kiwi-lh-1-5 kiwi-text-black-2 text-center">Master your PTE journey with <b class="kiwi-text-black-3">KiwiRead</b>, your ultimate companion for PTE success. Our advanced AI algorithm provides personalized solutions that mirror real testing environments, helping you achieve your PTE goals effortlessly.</p>
                <div 
                    class="social-bar w-100 text-center kiwi-bg-gradient-3 position-relative z-2 overflow-hidden kiwi-radius-8 mt-6 mt-md-10" 
                    style="padding: 1px;"
                >
                    <div class="w-100 h-100 kiwi-bg-white d-flex justify-center flex-wrap kiwi-fs-18 font-weight-medium kiwi-text-black-3 pa-4 kiwi-radius-8" style="grid-gap: 10px;">
                        <div class="d-flex flex-wrap flex-column flex-md-row align-center mobile-w-100" style="grid-gap: 10px;">
                            <div class="d-flex align-center order-1" style="grid-gap: 10px;">
                                <span class="kiwi-text-primary font-weight-semi-bold">Contact us</span>
                                <span style="color: #E2E6E4;" class="d-none d-md-block">|</span>
                            </div>
                            <div class="d-flex align-center flex-wrap order-3 order-md-2">
                                <img src="/assets/mail-01.svg" class="mr-2 mail-icon" alt="">
                                <a href="mailto:info@kiwiread.com" class="kiwi-text-black-3 text-decoration-none">info@kiwiread.com</a>
                            </div>
                            <div class="d-flex align-center flex-wrap order-2 order-md-3">
                                <img src="/assets/calling.svg" class="mr-2 tel-icon" alt="">
                                <a href="tel:+977-9801986550" class="kiwi-text-black-3 text-decoration-none">+977-9801986550</a>
                            </div>
                        </div>
                        <div class="d-flex align-center flex-wrap">
                            <img src="/assets/waveseprator.svg" class="mr-2 d-none d-md-block" alt="">
                            <span class="font-weight-semi-bold gradient-text-2">Follow us at</span>
                        </div>
                        <span style="color: #E2E6E4;">|</span>
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=61560503900717&mibextid=ZbWKwL">
                            <img src="/assets/logos_facebook.svg" class="social-icon" alt="fb">
                        </a>
                        <a target="_blank" href="https://www.instagram.com/kiwiread.ai/">
                            <img src="/assets/skill-icons_instagram.svg" class="social-icon" alt="insta">
                        </a>
                    </div>
                </div>
                <img src="/assets/paper_plane.svg" class="position-absolute z-1" style="bottom: -50px; right: -250px; max-height: 450px;" alt="">
            </article>
            
            <img 
                src="/assets/Ellipse 48.png" alt="background svg"
                class="position-absolute z-1"
                style="top: 50%; left: 50%; object-fit: contain; transform: translate(-50%, -50%);"
            >
            <img 
                src="/assets/_Compound Path_ copy 14.svg" alt="background svg"
                class="position-absolute z-1"
                style="bottom: 0; left: 0; object-fit: cover"
            >
            <img 
                src="/assets/_Compound Path_ copy 15.svg" alt="background svg"
                class="position-absolute z-1"
                style="bottom: 0; right: 0; object-fit: cover"
            >
        </v-sheet>
    </section>
</template>

<script setup>
</script>

<style>

</style>