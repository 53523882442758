<template>
    <section class="pa-4 tab-section">
        <v-tabs
            class=""
        >
            <v-tab 
                value="contact"
                class="kiwi-fs-16-overwrite font-weight-bold text-none"
                :class="$route.name === 'contactTab' ? 'kiwi-text-primary kiwi-bg-gradient-1' : ''"
                :to="{name: 'contactTab'}"
                v-if="institute.$hasFeature('contact-us-tab')"
            >Contact Us</v-tab>
            <v-tab 
                value="faq"
                class="kiwi-fs-16-overwrite font-weight-bold text-none"
                :class="$route.name === 'faqTab' ? 'kiwi-text-primary kiwi-bg-gradient-1' : ''"
                :to="{name: 'faqTab'}"
                v-if="institute.$hasFeature('help-and-faq-tab')"
                
            >FAQ(s) & Help</v-tab>
            <v-tab 
                value="term"
                class="kiwi-fs-18-overwrite font-weight-bold text-none"
                :class="$route.name === 'termsTab' ? 'kiwi-text-primary kiwi-bg-gradient-1' : ''"
                :to="{name: 'termsTab'}"
                v-if="institute.$hasFeature('terms-and-condition-tab')"
            >Terms & Conditions</v-tab>
            <v-tab 
                value="privacy"
                class="kiwi-fs-18-overwrite font-weight-bold text-none"
                :class="$route.name === 'privacyTab' ? 'kiwi-text-primary kiwi-bg-gradient-1' : ''"
                :to="{name: 'privacyTab'}"
                v-if="institute.$hasFeature('privacy-policy-tab')"
            >Privacy Policy</v-tab>
        </v-tabs>
        <v-divider />
        <router-view></router-view>
    </section>
</template>

<script setup>
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()
</script>

<style>

</style>