<template>
    <div ref="calculatorElement" class="desmos-calculator"></div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";

const calculatorControl = ref({
  bg: "black",
  textColor: "white",
  mode: "calculator",
});

const calculatorContent = ref({
  title: "Calculator",
});

const calculatorElement = ref(null)
let calculator = null
let calculatorOptions = {
  expressions: true,
  keypad: true,
  settingsMenu: true
};

const initDesmos = () => {
  if(calculatorElement.value){
    calculator = Desmos.GraphingCalculator(calculatorElement.value, calculatorOptions);
    console.log(calculator)

  }
}

onMounted(() => {
  initDesmos()
})

onBeforeUnmount(() => {
  if(calculator){
    calculator.destroy()
  }
})
</script>

<style>
.desmos-calculator {
  width: 100%;
  height: 400px;
}
</style>
