<template>
    <v-card color="#F1F2F3" class="rounded">
      <v-tabs
      class="bg-white"
        v-model="tab"
        align-tabs="center"
      >
        <div class="tab ml-0" color="#FFFFFF">
            <v-tab class="sub-tab  " :value="1" style="border: 1px solid transparent !important; border-radius: 8px 8px 0px 0px;" :class="tab === 1 ? 'active-tab' : 'inactive-tab'">Score Reports</v-tab>
        <v-tab class="sub-tab " :value="2" :class="tab === 2 ? 'active-tab' : 'inactive-tab'">All Answers</v-tab>
        </div>
      </v-tabs>
      <v-tabs-window   v-model="tab">
        <v-tabs-window-item class="px-5" :value="1">
          <ScoreCard @score-details="changeTab" :isQbt = isQbt />
        </v-tabs-window-item>
        <v-tabs-window-item class="px-3 mt-5" :value="2">
          <AnswerCard  :over-all="OverAll" :isQbt="isQbt" />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card>
  </template>

<script setup>
import { ref , computed} from 'vue'
import ScoreCard from './ScoreCard.vue';
import AnswerCard from './AnswerCard.vue';
import {useRoute} from 'vue-router';
import {useQuestionBasedSatStore} from "@/stores/Sat/questionBasedSat-store";

const questionBasedSatStore = useQuestionBasedSatStore();
const {getEvaluation} = questionBasedSatStore;

import {useEvaluationStore} from "@/stores/Sat/evaluation-store";
const evaluationStore = useEvaluationStore();


const route = useRoute();
const examId = parseInt(route.params.id);


const isQbt = computed(() => {
  const isQbt = route.query.isQbt === 'true';
  return isQbt;
});

const answertList = computed(() => {
  if(isQbt.value){
    return  questionBasedSatStore.evaluationForQbt?.questions;
  }
 return  evaluationStore.answerList;
})
const tab = ref(1);

const changeTab = ()=>{
  tab.value = 2;
}
if(isQbt.value){
  getEvaluation(examId);
}else{
  evaluationStore.getEvaluations(examId);
  evaluationStore.getResults(examId);
}

const OverAll = computed(() => {
  if(isQbt.value){
    return questionBasedSatStore.evaluationForQbt?.overall_answer_dict;
  }else{
   return  evaluationStore.overAll ;
  }
})
</script>


<style scoped>
.tab {
  max-height: 82px;
  background: #ffffff;
}
.sub-tab {
  max-height: 52px;
  border: 1px, 1px, 0px, 1px solid #DBE1E0;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px -1px 0px 0px #FFFFFF00 inset;
}

.active-tab {
    color: #081E20;
    font-weight: 700;
    font-size: 16px;
    background: #F1F2F3;
}

.inactive-tab {
    color: #4E5452;
    font-weight: 500;
    font-size: 16px;
}
</style>
