<template>
    <section class="kiwi-information-page kiwi-bg-white pa-4 kiwi-radius-8 fill-height">
        <Header :breadcrumbs="breadcrumbs" />
        <div class="px-4 pt-4">
            <v-divider />
        </div>
        <Article :title="detail.title" :content="detail.content"/>
    </section>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import { api } from '@/plugins/axios';
import Header from '@/components/Information/Header.vue'
import Article from '@/components/Information/Article.vue'
import { useRoute } from 'vue-router';

const breadcrumbs = ref([
    {
        title: '',
        disabled: true,
        href: '',
    },
    {
        title: 'Dashboard',
        disabled: false,
        href: '/dashboard',
    },
    {
        title: 'Knowledge Base',
        disabled: false,
        href: '/information',
    }
])


// const titles = ref({
//     PTE: 'Q1. What you need to know about PTE ?',
//     patterns: 'Q1.What are the Test Patterns in PTE?',
//     preparation: 'Q1. What to expect on test day?'
// })

const detail = ref({})
const route = useRoute();

const getKnowledgeBaseContentDetail = async () => {
    try {
        const res = await api.get(`/help-and-support/kb-content/${route.params.slug}`)
        detail.value = res.data
    } catch (error) {
        // console.log(error)
    }
}

onMounted(() => {
    getKnowledgeBaseContentDetail()
})
</script>

<style>

</style>