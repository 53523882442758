<template>
    <v-dialog v-model="dialog" max-width="450px" :persistent="false">
        <v-card class="position-relative" style="padding-top: 48px;">
            <v-tabs v-model="tab" style="background: #F5F6F7; min-height: 48px;" dark class="position-fixed top-0 left-0 w-100 z-1">
                <v-tab v-for="tab in tabs" :key="tab" class="text-none">{{ tab }}</v-tab>
                <v-icon class="mdi mdi-close position-absolute kiwi-text-error" @click="close" style="top: 10px; right: 10px;"></v-icon>
            </v-tabs>
            <v-card-text>
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="Pronunciation">
                        <div><strong>{{ ucfirst(returnData?.text) }}</strong></div>
                        <article class="mt-4">
                            <!-- <template v-for="(item, p) in returnData?.phonetics" :key="p">
                                <template v-for="(key, j) in Object.keys(item)" :key="j">
                                    <div class="kiwi-fs-14">
                                        <template v-if="key !== 'audio_file'">
                                            <strong style="text-transform: capitalize;">{{key}} :</strong> <span :class="key === 'lang' ? 'text-uppercase font-weight-bold' : ''">{{item[key] || 'N/A'}}</span>  
                                        </template>
                                        <template v-else>
                                            <strong style="text-transform: capitalize;">{{key}} :</strong> <v-icon v-if="item[key]" class="mdi mdi-headphones kiwi-text-primary kiwi-fs-20" @click="pronounceWord(returnData?.text, item[key] === 'us' ? 'en-us' : item[key] === 'uk' ? 'en-uk' : '')"></v-icon>
                                        </template>
                                    </div>
                                </template>
                            </template> -->
                            <div class="mt-2"><strong>[ Uk ]</strong>{{ returnData?.phonetics[0]?.notation }}<v-icon class="mdi mdi-headphones kiwi-text-primary" @click.native="pronounceWord(returnData?.text ,'en-GB')"/></div>
                            <div class="mt-2"><strong>[ USA ]</strong>{{ returnData?.phonetics[1]?.notation }}<v-icon class="mdi mdi-headphones kiwi-text-primary" @click.native="pronounceWord(returnData?.text ,'en-US')"/></div>
                            <!-- <p v-if="returnData?.phonetics.length === 0" class="fw-bold kiwi-fs-14">Data not available.</p> -->
                        </article>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="Vocabulary">
                        <div><strong>{{ ucfirst(returnData?.text) }}</strong></div>
                        <article class="mt-4">
                            <template v-for="(item, i) in meanings" :key="i">
                                <div class="kiwi-fs-14">
                                    <div class="font-weight-medium">{{item.part_of_speech}}</div>
                                    <ul class="my-2" style="padding-left: 30px;" v-if="item.definition">
                                        <li>{{item.definition}}</li>
                                    </ul>
                                </div>
                            </template>
                            <v-divider class="mt-3"></v-divider>
                            <template v-for="(item, i) in meanings" :key="i">
                                <div class="kiwi-fs-14 mt-2" v-if="item.example">
                                    <div class="font-weight-medium">Example:</div>
                                    <ul class="my-2" style="padding-left: 30px;">
                                        <li>{{item.example}}</li>
                                    </ul>
                                </div>
                            </template>
                            <p v-if="returnData?.meanings.length === 0" class="fw-bold kiwi-fs-14">Data not available.</p>
                        </article>
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script setup>
import {ref, onMounted, watch, computed} from 'vue';
const props = defineProps({
  word:{
    type: String,
    default:'hello'
  },
  returnData: {
    type: Object
  }
})

const dialog = ref(true)
const emit = defineEmits(['closeDialog'])

const tab = ref(0)
const tabs = ['Pronunciation', 'Vocabulary']

const close = () =>{
    emit('closeDialog');
}

const ucfirst = (str) => {
    str = str.replace(/\./g, '');
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const pronounceWord = (word , langCode) =>{
    getVoice(langCode).then(voice => {
        if (voice) {
            const utterance = new SpeechSynthesisUtterance(word);
            utterance.voice = voice;
            utterance.pitch = 1;
            utterance.rate = 1;
            window.speechSynthesis.speak(utterance);
        } else {
            console.log('Voice not found for language code:', langCode);
        }
    });
    function getVoice(langCode) {
        return new Promise((resolve) => {
            const voices = window.speechSynthesis.getVoices();
            const voice = voices.find(v => v.lang.startsWith(langCode));
            resolve(voice);
        });
    }
}

const meanings = computed(() => {
    const obj = {}
    props.returnData?.meanings.forEach(m => {
        if(!obj[m.part_of_speech]) {
            obj[m.part_of_speech] = m
        }
 
    });
    return Object.keys(obj).length ? Object.values(obj) : []
    
})

watch(dialog, (newVal, oldVal) => {
  if(!newVal && oldVal) {
    emit('closeDialog')
  }
})
</script>

<style>

</style>