<template>
    <v-radio-group v-model="selected">
        <v-radio 
            v-for="(txt, i) in options" 
            :key="i"
            class="v-radio-focus-white mb-3"
            :value="txt.id">
            <template v-slot:label>
                 <span class="d-flex flex-wrap text-black" style="font-weight: 400;" v-html="`${removeTags(txt.option)}`"></span>
            </template>
        </v-radio>
    </v-radio-group>
</template>
<script setup>
  import { defineProps, ref, watch} from "vue";
  import { removeTags } from '@/utils/utils';
  const emit = defineEmits(["selected"]);
  const props = defineProps({
        options: {
            type: Array,
            required: true,
        },
    })
   const selected = ref(null)
   emit("selected", selected.value);
   watch(selected, (newVal, oldVal) => {
        emit("selected", newVal);
   });
</script>
<style>
.v-radio-focus-white.v-selection-control--dirty {
    background-color: white !important;
    border-radius: 4px;
}
.v-radio-focus-white label {
    opacity: 1;
}
</style>
  