<script setup>
import {computed, ref, reactive, watch , onUnmounted, onMounted, nextTick} from "vue";
import Notes from "@/components/SAT/MockTest/Notes.vue";
import {useExamInstanceStore} from "@/stores/examInstance-store";
import {storeToRefs} from "pinia";
import {useNotesStore} from "@/stores/Sat/notes-store";
import {useBookMarkeStore} from "@/stores/Sat/bookmarks-stores";
import {removeHtmlWithNewlineHint} from '@/utils/utils';
import {useRoute} from "vue-router";
import {useQuestionBasedTestStore} from "@/stores/questionbased-test-store";
import { tr } from "vuetify/lib/locale/index.mjs";

const questionBasedTestStore = useQuestionBasedTestStore();
const bookMarkStore = useBookMarkeStore();
const { bookmarks } = bookMarkStore;
const notesStore = useNotesStore();
const examInstanceStore = useExamInstanceStore();
const {examDetail , highLightMode} = storeToRefs(examInstanceStore);
const {wordsArray} = storeToRefs(notesStore);
const {saveHighLightedWords , deleteHighlightedWord} = notesStore;
const route = useRoute();
const props = defineProps({
  options:{
    type: Array,
    required: true
  },
  layout:{
    type: String,
    default: 'english'
  },
  question:{
   required: true,
  },
  index:{
    required: true,
  }
})
const isBookmarked = ref(false);
const highlightWordText = ref(null);



const isCurrentQuestionBookMarked = () =>{
  const bookmark = bookmarks.find(b => b.questionId === props.question.id);
  isBookmarked.value = bookmark ? bookmark.bookmarked : false;
}

isCurrentQuestionBookMarked();

const examDetails = computed(() =>{
  if(route.name === 'satquestionbasedtest'){
    return questionBasedTestStore.testDetail;
  }
  return examDetail.value;
})

const isQbt = computed(() =>{
  if(route.name === 'satquestionbasedtest'){
    return true;
  }
  return false;
})

const question = computed(() => {
  return props.question;
})
const emit = defineEmits(["sync" , "markForReview"]);

const optionChoiceToggle = ref(false);

const removalChoice = ref([]);

const selectedOption = ref(null);
const answer  = ref(null);

const setSelectedChoice = (id) => {
  selectedOption.value = id
}
const setAnswerField = (value) =>{
  if(value !== null && value !== undefined) {
    answer.value = value;
  }
}
setSelectedChoice(parseInt(props.question.user_answer));
setAnswerField(props.question.user_answer);

const questionColControl = ref(false);
const optionColControl = ref(false);


const questionCol = computed(() => {
  if(questionColControl.value) {
    optionColControl.value = false;
    return 7;
  } else {
    return ''
  }
});

const highlightColControl = ref(false)
const highlightCol = computed(() => {
  if(highlightColControl.value) {
    optionColControl.value = false;
    questionColControl.value = false;
    return 4;
  } else {
    return ''
  }
})

const optionCol = computed(() => {
  if(optionColControl.value) {
    questionColControl.value = false;
    return 7;
  } else {
    return ''
  }
})

const removeOptionChoice = (index) => {
  togglePush(removalChoice.value, index);
}

const togglePush = (array, value) => {
  const index = array.indexOf(value);
  if (index === -1) {
    if (array.length < 4) {
      array.push(value);
    } else {
      alert('you can only removed two choices')
    }
  } else {
    array.splice(index, 1);
  }
}


const seprateWords = computed(() => {
  return removeHtmlWithNewlineHint(question.value.question_text)
})

const highlightedWord = ref('');
const highlightedWordIndex = ref(null);
const highlightColorName = ref('');
const highlightedWordModule = (word) => {
  highlightedWord.value = word;
}

const highlightColorOption = ref([
  {
    color: '#FFF8E0',
    active: false,
    name: 'yellow',
    border: '#DBE1E0'
  },
  {
    color: '#D2E7FF',
    active: false,
    name: 'skyBlue',
    border: '#DBE1E0'
  },
  {
    color: '#F5DAFF',
    active: false,
    name: 'lightPink',
    border: '#DBE1E0'
  },
])

const activeColor = ref(null);

const highlightedWordsCollection = ref([])
const wordInit = ref(false)
const compareArrayPush = (array, word) => {
  let index = array.some(ar => ar.word === word)
  if(!index) {
    array.push({
      word: word,
      colors: [...highlightColorOption.value],
      comment: null,
    })
  }
}

// To track the highlighted words and their respective colors
const activeColorArray = reactive([]);

// Reference for the highlight option box
const highlightOptionBox = ref(null);
const closeHighlightOptionFlag = ref(null);

const closeCommentCard = (index) => {
  closeHighlightOptionFlag.value = index
  activeColorWordIndex.value = null;
}


// Function to close the highlight option
const closeHighlightOption = () => {
  highlightedWordIndex.value = null; // Reset flag
};


// Function to check if click is outside and close highlight option
const handleClickOutside = (event) => {
  if (highlightOptionBox.value && !highlightOptionBox.value.contains(event.target)) {
    closeHighlightOption(); // Close if clicked outside
  }
};

const isHighlighted = (word, index) => {
  if(seprateWords.value.includes(word)) {
    highlightedWordIndex.value = index;
    highlightedWordModule(word)
  }
}
const activeColorWordIndex = ref(null);

const setActiveColor = (cI, word, index) => {
  const color = highlightColorOption.value[cI].color;
  highlightColorOption.value.forEach(opt => {
    opt.active = false;
  })
  highlightColorOption.value[cI].active = true;
  activeColor.value = color
  activeColorWordIndex.value = index;

   // Check if the word is already highlighted
  const existingWord = activeColorArray.find((w) => w.index === index);
  if (!existingWord) {
    // Add the word and color to the activeColorArray
    activeColorArray.push({ word, index, color });
  }
}


const openComentOption = ref(false);
const commentStatus = ref('expand');
const openCommentCard = async (word, i) => {
  highlightedWordIndex.value = null;
  const index = wordsArray.value.some(cm => cm.word === word);
  if(!activeColor.value) {
    return;
  }
  if (!index) {
    activeColor.value ? wordsArray.value.push({word: word, sub_text: '', color: activeColor.value}) : wordsArray.value.push({title: word, sub_text: '', color: ''});
  } else {
    wordsArray.value.splice(index, 1);
  }
  questionColControl.value = true;
  openComentOption.value = "expand";
  const payload = {
    word: word,
    sub_text: '',
    color: activeColor.value,
    question_id: props.question.id
  }
  if(!isQbt.value){
    await saveHighLightedWords(examDetails.value.id ,payload);
    notesStore.getHighlightedWords(examDetails.value.id, props.question?.id);
  }
  activeColor.value = null;
  closeHighlightOptionFlag.value = i;
  closeHighlightOption()
}

// Function to remove the highlighted word when close button is clicked
const removeHighlightedWord = (index) => {
  setTimeout(() => {
    highlightedWordIndex.value = null
    document.getElementById(`highlight_${index}`).classList.remove('showOption')
  }, 300)
  const wordIndex = activeColorArray.findIndex((w) => w.index === index);
  if (wordIndex > -1) {
    activeColorArray.splice(wordIndex, 1); // Remove from activeColorArray
  }
};
watch(selectedOption, (newVal, oldVal) => {
  emit("sync",'answer_id' , newVal);
});

watch(answer, (newVal, oldVal) => {
  emit("sync",'answer_text' , answer.value);
})
watch(highLightMode, (val) => {
  if((val === false) || (val === true && highlightedWordIndex.value === null)) {
    openComentOption.value = false

    if(wordsArray.length) {
      openComentOption.value = 'collapse'
    }
  }  else if(val === true && highlightedWordIndex.value !== null) {
    questionColControl.value = true;
  }
} , {immediate: true , deep:true})
onMounted(() => {
});
onUnmounted(()=>{
  highLightMode.value = false;
});

['english','math_with_options'].includes(props.layout)? emit("sync", 'answer_id' , answer.value) : emit("sync", 'answer_text' , answer.value);

const markForReview = () =>{
isBookmarked.value = !isBookmarked.value;
emit("markForReview");
}
const deleteNote = (word) =>{
  if(!isQbt.value){
    const payload = {
      question_id:props.question.id,
      word:word,
    }
    deleteHighlightedWord(examDetails.value.id ,payload);
  }else{
    const index = wordsArray.value.findIndex((w) => w.word === word);
    if(index > -1){
      wordsArray.value.splice(index, 1);
    }
  }
  deleteHighlightedWord(examDetail.value.id ,payload);
}

watch(openComentOption, (val) => {
  if(openComentOption.value === true || openComentOption.value === 'collapse' ) {
    questionColControl.value = true;
    optionColControl.value = false;
  }
})

const validateInput = () =>{
  let value = answer.value;

  if (value.includes('-') && value.indexOf('-') !== 0) {
    value = value.replace(/-/g, '');
  }
  if (value.startsWith('-') && value.indexOf('-', 1) !== -1) {
    value = '-' + value.slice(1).replace(/-/g, '');
  }
  const dotIndex = value.indexOf('.');
  if (dotIndex !== -1) {
    value = value.slice(0, dotIndex + 1) + value.slice(dotIndex + 1).replace(/\./g, '');
  }

  const slashIndex = value.indexOf('/');
  if (slashIndex !== -1) {
    value = value.slice(0, slashIndex + 1) + value.slice(slashIndex + 1).replace(/\//g, '');
  }
  answer.value = value.replace(/[^0-9\-./]/g, '');
  const lengthExcludingChars = value.replace(/-/g, '').length;
  if (lengthExcludingChars > 5) {
    answer.value = value.slice(0, answer.value.length - 1);
  }
}

const renderMathJax = () => {
  nextTick(() => {
    if (window.MathJax) {
      window.MathJax.typeset();
    }
  });
};

renderMathJax();
watch(wordsArray, (newVal , oldVal) => {
  if(!wordInit.value && newVal && newVal.length) {
    openComentOption.value = "expand"
    wordInit.value = true
  }
} , {immediate: true , deep:true})

const mathJaxAnswer = computed(() => {
  if(answer.value){
    renderMathJax();
    const formattedAnswer = answer.value.replace(/(\d+)\/(\d+)/g, '\\frac{$1}{$2}');
    return `\\(${formattedAnswer}\\)`;
  }
});
const sanitizeText = (html) =>{
  if(props.layout === 'english'){
    return html
      .replace(/<br\s*\/?>/gi, '')      // Remove <br /> or <br>
      .replace(/\r?\n|\r/g, '')         // Remove \r\n or \n (line breaks)
      .replace(/&nbsp;/g, ' ')          // Replace &nbsp; with a space
      .trim();
  }
  return html;
}
</script>

<template>
  <v-row class="ma-0 h-100 w-100">
  <v-col cols="12" :md="(layout === 'math_with_options') ? 12 : questionCol" class="pa-0 position-relative" v-if="layout !== 'math_with_options'">
    <v-btn
      variant="outlined"
      class="kiwi-bg-white kiwi-border-light-grey-3 position-absolute"
      icon
      v-if="layout !== 'math_with_options'"
      @click="questionColControl = !questionColControl"
      style="top: 10px; right: 10px; z-index: 1;"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M12.514 15.8936H14.4822C15.3106 15.8936 15.9822 15.222 15.9822 14.3936V3.56055C15.9822 2.73212 15.3106 2.06055 14.4822 2.06055H3.60956C2.78113 2.06055 2.10956 2.73212 2.10956 3.56055V5.51881" stroke="#1A1F1D" stroke-width="1.2" stroke-linecap="round"/>
        <rect width="6.71202" height="6.69288" rx="0.2" transform="matrix(-1 0 0 1 8.82178 9.20117)" stroke="#1A1F1D" stroke-width="1.2"/>
        <path d="M9 9.20117L12 6.20117" stroke="#1A1F1D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 6.20117H12V9.20117" stroke="#1A1F1D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </v-btn>
    <v-row style="margin: 0;" class="h-100" v-if="layout === 'english'">
      <v-col md>
        <article class="kiwi-text-black-3 px-4 py-10 mx-auto position-relative w-100 " style="max-width: 640px;">
          <span v-if="!highLightMode" class="kiwi-paragraphs-bundle highlight-bundle no-select" v-html="question.question_text"></span>
          <template v-else>
                <div class="position-absolute sat-highlight-overlay" ></div>
                <span
                  v-for="(word, index) in seprateWords" :key="index"
                  class="d-inline-block position-relative highlight-word no-select"
                  :class="[highlightedWordIndex === index ? 'showOption' : '', word === '' ? 'd-block new-line-highlight': '']"
                  @click="isHighlighted(word, index)"
                  style="font-size: 16px; cursor: crosshair; margin-right: 4.35px;"
                  :style="{
                    backgroundColor: activeColorArray.find((w) => w.index === index)?.color
                  }"
                  ref="highlightOptionBox"
                  :id="`highlight_${index}`"
                >
                  <span v-html="word"></span>

                  <div class="highlight_comment-option d-none pa-2 kiwi-radius-8 cursor-default z-3" v-if="highlightedWordIndex === index">
                    <button
                      type="button"
                      v-for="(color, cI) in highlightColorOption"
                      :key="cI"
                      class="color-btn"
                      :class="color.active ? 'active' : ''"
                      :style="[{backgroundColor: color.color, border: `1px solid ${color.border}`}]"
                      @click="setActiveColor(cI, word, index)"
                    />
                    <!-- <button
                      type="button"
                      class="color-btn d-flex align-center justify-center"
                      style="border: 1px solid #DBE1E0;"
                      @click="deleteNote(word)"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                      <path d="M11.375 3.70831L11.0135 9.55629C10.9211 11.0504 10.875 11.7975 10.5005 12.3346C10.3153 12.6001 10.0769 12.8242 9.80041 12.9926C9.24122 13.3333 8.49275 13.3333 6.99574 13.3333C5.49682 13.3333 4.74734 13.3333 4.18778 12.992C3.91113 12.8233 3.67267 12.5988 3.48756 12.3328C3.11318 11.7948 3.06801 11.0467 2.97769 9.55051L2.625 3.70831" stroke="#081E20" stroke-linecap="round"/>
                      <path d="M1.75 3.70835H12.25M9.36582 3.70835L8.96764 2.88686C8.7031 2.34117 8.5708 2.06832 8.34266 1.89816C8.29208 1.86041 8.23848 1.82684 8.18242 1.79776C7.92978 1.66669 7.62656 1.66669 7.02012 1.66669C6.39847 1.66669 6.08767 1.66669 5.83081 1.80326C5.77389 1.83353 5.71957 1.86846 5.66842 1.9077C5.43762 2.08476 5.3087 2.36759 5.05086 2.93326L4.69754 3.70835" stroke="#081E20" stroke-linecap="round"/>
                      </svg>
                    </button> -->
                    <button
                      type="button"
                      class="color-btn d-flex align-center justify-center"
                      style="border: 1px solid #DBE1E0;"
                      @click="openCommentCard(word, index)"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M2.0415 9.33333V5.25C2.0415 3.60008 2.0415 2.77513 2.55407 2.26256C3.06663 1.75 3.89159 1.75 5.5415 1.75H8.45817C10.1081 1.75 10.933 1.75 11.4456 2.26256C11.9582 2.77513 11.9582 3.60008 11.9582 5.25V7C11.9582 9.74983 11.9582 11.1248 11.1039 11.979C10.2496 12.8333 8.87467 12.8333 6.12484 12.8333H5.5415C3.89159 12.8333 3.06663 12.8333 2.55407 12.3208C2.0415 11.8082 2.0415 10.9832 2.0415 9.33333Z" stroke="#081E20"/>
                      <path d="M5.12939 6.5918H8.87028" stroke="#081E20" stroke-linecap="round"/>
                      <path d="M7 4.92749L7 8.25409" stroke="#081E20" stroke-linecap="round"/>
                      <path d="M11.9585 8.45837C11.9585 9.26378 11.3056 9.91671 10.5002 9.91671C10.2089 9.91671 9.8655 9.86567 9.58229 9.94156C9.33064 10.009 9.13411 10.2055 9.06668 10.4572C8.99079 10.7404 9.04183 11.0838 9.04183 11.375C9.04183 12.1804 8.3889 12.8334 7.5835 12.8334" stroke="#081E20" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="color-btn d-flex align-center justify-center"
                      style="border: 1px solid #DBE1E0;"
                      @click="removeHighlightedWord(index)"
                    >
                      <v-icon class="mdi mdi-close" />
                    </button>
                  </div>
                </span>
          </template>
        </article>
      </v-col>
      <v-col lg="4" v-if="(openComentOption)" class="pt-16 position-relative" style="background-color: #F0F1F2; border-left: 2px solid #D4D7D6; max-height: 100%;" :style="openComentOption === 'collapse' ? {maxWidth: `10px`, paddingLeft: `0px`, paddingRight: `0px`} : {overflowY: `auto` }">
          <article class="pa-4" v-if="(openComentOption && openComentOption !== 'collapse')">
            <Notes :exam-instance-id = "examDetails.id"  :question-id="question.id"  :is-qbt="isQbt"/>
          </article>
          <button class="position-absolute" style="left: 0; bottom: 50px;" @click="openComentOption = 'collapse';" v-if="openComentOption === 'expand'">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
            <path d="M0.5 1H14C20.3513 1 25.5 6.14873 25.5 12.5V14.5C25.5 20.8513 20.3513 26 14 26H0.5V1Z" fill="#8E9492"/>
            <path d="M0.5 1H14C20.3513 1 25.5 6.14873 25.5 12.5V14.5C25.5 20.8513 20.3513 26 14 26H0.5V1Z" stroke="#DBE1E0"/>
            <path d="M10.75 9C10.75 9 15.25 12.3142 15.25 13.5C15.25 14.6859 10.75 18 10.75 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button class="position-absolute" style="left: -25px; bottom: 50px; transform: rotate(180deg)" @click="openComentOption = 'expand';" v-else-if="openComentOption === 'collapse'">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
            <path d="M0.5 1H14C20.3513 1 25.5 6.14873 25.5 12.5V14.5C25.5 20.8513 20.3513 26 14 26H0.5V1Z" fill="#8E9492"/>
            <path d="M0.5 1H14C20.3513 1 25.5 6.14873 25.5 12.5V14.5C25.5 20.8513 20.3513 26 14 26H0.5V1Z" stroke="#DBE1E0"/>
            <path d="M10.75 9C10.75 9 15.25 12.3142 15.25 13.5C15.25 14.6859 10.75 18 10.75 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
      </v-col>
    </v-row>
    <article class="kiwi-paragraphs-bundle mt-16 mx-auto" v-else style="max-width: 700px;">
      <p class="kiwi-fs-18 kiwi-text-black-3 font-weight-bold">Student-produced response directions</p>
      <ul style="padding-left: 30px">
        <li>If you find <strong>more than one correct answer</strong>, enter only one answer.</li>
        <li>You can enter up to 5 characters for a <strong>positive</strong> answer and up to 6 characters (including the negative sign) for a <strong>negative</strong> answer.</li>
        <li>If your answer is a <strong>fraction</strong> that doesn't fit in the provided space, enter the decimal equivalent.</li>
        <li>If your answer is a <strong>decimal</strong> that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.</li>
        <li>If your answer is a <strong>mixed number</strong> (such as 3½), enter it as an improper fraction (7/2) or its decimal equivalent (3.5).</li>
        <li>Don't enter <strong>symbols</strong> such as a percent sign, comma, or dollar sign.</li>
      </ul>
      <p class="text-center">Examples</p>
      <v-table class="sat-math-table mx-auto" style="max-width: 442px;">
        <thead>
          <tr>
            <th>Answer</th>
            <th>Acceptable ways to enter answer</th>
            <th>Unacceptable: will NOT receive credit</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>3.5</td>
            <td>
              <p>3.5</p>
              <p>3.50</p>
              <p>7/2</p>
            </td>
            <td>
              <p>3 1/2</p>
              <p>3 1/2</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <div style="max-width: 24px; text-align: center;">
                  <span class="d-block" style="border-bottom: 1px solid var(--color-black-3);">2</span>
                  <span class="d-block">3</span>
                </div>
              </p>
            </td>
            <td>
              <p>2/3</p>
              <p>.6666</p>
              <p>.6667</p>
              <p>0.666</p>
              <p>0.667</p>
            </td>
            <td>
              <p>0.66</p>
              <p>.66</p>
              <p>0.67</p>
              <p>.67</p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="d-flex flex-row align-center">
                  -
                  <div style="max-width: 24px; text-align: center;">
                  <span class="d-block" style="border-bottom: 1px solid var(--color-black-3);">1</span>
                  <span class="d-block">3</span>
                  </div>
                </p>
            </td>
            <td>
              <p>-1/3</p>
              <p>-.3333</p>
              <p>-0.333</p>
            </td>
            <td>
              <p>-.33</p>
              <p>-0.33</p>
            </td>
          </tr>
        </tbody>
      </v-table>
    </article>
  </v-col>
  <v-col cols="12" :md="(layout === 'math_with_options') ? 12 :  optionCol" class="pa-0 kiwi-border-light-grey-3 position-relative" :style="layout === 'math_with_options' ? {} : {borderLeft: '2px solid'}">
    <v-btn
      variant="outlined"
      class="kiwi-bg-white kiwi-border-light-grey-3 position-absolute"
      icon
      @click="optionColControl = !optionColControl"
      v-if="layout !== 'math_with_options'"
      style="top: 10px; left: 10px; z-index: 1;"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" style="transform: rotate(-90deg);">
        <path d="M12.514 15.8936H14.4822C15.3106 15.8936 15.9822 15.222 15.9822 14.3936V3.56055C15.9822 2.73212 15.3106 2.06055 14.4822 2.06055H3.60956C2.78113 2.06055 2.10956 2.73212 2.10956 3.56055V5.51881" stroke="#1A1F1D" stroke-width="1.2" stroke-linecap="round"/>
        <rect width="6.71202" height="6.69288" rx="0.2" transform="matrix(-1 0 0 1 8.82178 9.20117)" stroke="#1A1F1D" stroke-width="1.2"/>
        <path d="M9 9.20117L12 6.20117" stroke="#1A1F1D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 6.20117H12V9.20117" stroke="#1A1F1D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </v-btn>
    <article class="kiwi-text-black-3 pa-4 py-10 pl-16 mx-auto" style="max-width: 700px;">
      <div class="kiwi-sat-option-choice-wrapper">
        <!-- Option Choice Header start -->
        <div class="option-header d-flex align-center kiwi-border-black-3" style="height: 36px; background-color: #F7F8F9; border-bottom: 1px dashed;">
          <div class="option-index-label kiwi-bg-black-3 mr-2 kiwi-text-white font-weight-semi-bold d-flex align-center justify-center h-100" style="width: 36px;">{{index}}</div>
          <v-icon :class="isBookmarked ? 'mdi mdi-bookmark kiwi-text-error' : 'mdi mdi-bookmark-outline' "  @click="markForReview"></v-icon>
          <span class="font-weight-semi-bold kiwi-text-black-3">Mark for Review</span>
          <v-spacer></v-spacer>
          <v-tooltip v-if="['math_with_options' , 'english'].includes(layout) " location="top" text="Check out the answer choices you think are wrong.">
             <template v-slot:activator="{ props }">
              <div
                class="option-index-label choice-cutter-toggle kiwi-border-black-3 mr-2 kiwi-text-black-3 border font-weight-semi-bold d-flex align-center justify-center px-1 kiwi-radius-4 cursor-pointer"
                :class="optionChoiceToggle ? 'active' : ''"
                style="height: calc(100% - 8px);"
                @click="optionChoiceToggle = !optionChoiceToggle"
                v-bind="props"
                v-if="layout !== 'math_without_options'"
              >ABC</div>

             </template>
          </v-tooltip>
        </div>

        <!-- Option Choice Header end -->
        <article class="kiwi-text-black-3 py-8 mx-auto position-relative w-100" style="max-width: 700px;" v-if="layout !== 'english'">
          <span v-if="!highLightMode" class="no-select" v-html="question.question_text"></span>
        </article>
        <p class="kiwi-fs-14 font-weight-medium kiwi-text-black-3 py-3 no-select" v-html="question.sub_question_text"></p>
        <div v-if="layout === 'math_with_options' || layout === 'english' " class="option-choice-box mt-2">
          <template
            v-for="(option, index) in question.options"
            :key="index"
          >
            <div class="d-flex align-center">
              <div
                class="d-flex py-2 px-3 kiwi-radius-4 position-relative cursor-pointer my-2 border mr-2"
                @click="setSelectedChoice(option.id)"
                :class="[
                    selectedOption === option.id && !(removalChoice.includes(option.id) && optionChoiceToggle) ? 'kiwi-border-primary active-shadow' : 'kiwi-border-light-grey-3',
                    (removalChoice.includes(option.id) && optionChoiceToggle) ? 'removed' : ''
                  ]"
                style="flex: 1;"
                :style="(removalChoice.includes(option.id) && optionChoiceToggle) ? {pointerEvents: 'none'} : {}"
              >
                <label :for="option.id" class="position-absolute w-100 h-100 z-2" style="top: 0; left: 0; visibility: hidden;">
                  <input type="radio" :value="option.id" name="option" :id="option.id">
                </label>
                <div
                  class="content position-relative d-flex align-center z-1" :class="selectedOption === option.id && !(removalChoice.includes(option.id) && optionChoiceToggle) ? 'kiwi-text-primary' : 'kiwi-text-black-3'"
                >
                    <span
                      class="circle-border-index mr-2 text-uppercase"
                      :class="selectedOption === option.id && !(removalChoice.includes(option.id) && optionChoiceToggle) ? 'kiwi-border-primary kiwi-bg-primary kiwi-text-white' : 'kiwi-border-black-3'"
                    >{{String.fromCharCode(97 + index)}}</span>
                    <span style="flex: 1;" class="no-select" v-html="sanitizeText(option.option)"></span>
                </div>
              </div>
              <template v-if="optionChoiceToggle">
                <v-btn
                  class="option-cutter-btn"
                  @click="removeOptionChoice(option.id)"
                  icon
                  style="border: 1px solid var(--color-black-3); box-shadow: none; width: 30px; height: 30px;"
                  v-if="!removalChoice.includes(option.id)"
                >
                  {{String.fromCharCode(97 + index)}}
                </v-btn>
                <button v-else-if="removalChoice.includes(option.id)" @click="removeOptionChoice(option.id)" >Undo</button>
              </template>

            </div>
          </template>
        </div>
        <div v-else>
          <div style="width: 126px; height: 56px; border: 1px solid var(--color-light-grey-3);" class="d-flex align-end justify-center px-4 py-2 kiwi-radius-8">
            <input type="text" v-model="answer" style="background-color: var(--color-white); border: none; border-bottom: 1px solid var(--color-black-3); outline: none; width: 100%;" @input="validateInput" />
          </div>
          <div class="mt-4 d-flex align-item-center">
            <span class="mt-1 mr-3">Answer Preview:</span>
            <p v-html="mathJaxAnswer"></p>
          </div>
        </div>
      </div>
    </article>
  </v-col>
  </v-row>
</template>

<style lang="scss">
.question-render-container {
  .recorder-component {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.circle-border-index {
  width: 24px;
  height: 24px;
  font-weight: 600;
  color: var(--color-black-3);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-black-3);
}

.choice-cutter-toggle {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 1px;
    background-color: var(--color-black-3);
    transform: rotate(-28deg);
  }

  &.active {
    background-color: var(--color-primary);
    color: var(--color-white) !important;

    &::before {
      background-color: var(--color-white);
    }
  }
}

.option-cutter-btn, .removed {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -5px;
    z-index: 1;
    transform: translateY(-50%);
    width: calc(100% + 10px);
    height: 1px;
    background-color: var(--color-black-3);
  }
}

.sat-highlight-overlay {
  @media screen and (min-width: 768px) {
    width: 100&;
    height: 100%;
    left: 0;
    right: 0;
  }
}
.highlight-word {
  &.showOption {
    background-color: #FFF8E0;
  }
  &.showOption > .highlight_comment-option {
    position: absolute;
    bottom: -40px;
    left: -10px;
    z-index: 1;
    display: flex !important;
    min-width: 240px;
    background-color: var(--color-white);
    box-shadow: 0px -2px 8px -4px #1018280A;
    box-shadow: 0px 0px 24px -4px #1018281A;

  }

  &.showOption, &:hover {
    background-color: #FFF8E0;
  }

  .color-btn {
    width: 28px;
    height: 28px;
    border-radius: 100%;

    &.active {
      border-color: var(--color-black-3) !important;
    }
  }

  button + button {
    margin-left: 10px;
  }
}
.active-shadow {
  box-shadow: 0px 0px 0px 5px #1CA9721F;
  p {
    color: var(--color-primary) !important;
  }
}
.sat-math-table {
  border: 1px solid var(--color-light-grey-3);

  td + td, th + th {
    border-left: 1px solid var(--color-light-grey-3);
  }

  td, th {
    padding: 8px !important;
  }
}
.kiwi-paragraphs-bundle.highlight-bundle {
  p + p {
    margin-top: 45px;
  }
}
.no-select {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}
</style>
