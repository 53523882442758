import { useRouter, useRoute } from "vue-router";
import {useAuthStore} from "@/stores/auth/authStore";
export function useTestAction(type = "mock-test") {
  const router = useRouter();
  const route = useRoute();
  const isQbt = type === "question-based";
  const isIQT = type === "individual-question";
  const store = useAuthStore();
  const isSatQbt = store.isSAT;
  const upgrade = () => {
    return router.push({ name: "subscription", query: {} });
  };
  const start = (id) => {
    const routeName = isQbt ? (isSatQbt ? "satquestionbasedtest" : "questionbasedtest") : (!store.isSAT ? "mocktest" : "satmocktest");
    const url = router.resolve({ name: routeName, params: { id: id } }).href;
    window.open(url, "_blank");
  };
  const startIndividual = (id, topic_id, index) => {
    const routeName = isQbt ? (isSatQbt ? "satquestionbasedtest" : "questionbasedtest") : !isIQT ? "mocktest" : "individualQuestion";
    router.push({
      name: routeName,
      params: {
        id: id,
        topic_id: topic_id,
        index: index
      },
      query: {
        ...route.query
      }
    });
  };
  const viewResult = (id) => {
    let routeName = "";
    let params = { id };
    let query = null;
    if (isQbt && !isSatQbt) {
      routeName = "QBTEvaluation";
      params = { test: id };
    } else if (isSatQbt && isQbt) {
      routeName = "SatEvaluation";
      query = { isQbt: true };
    } else if (store.isSAT) {
      routeName = "SatEvaluation";
    } else {
      routeName = "mockTestEvaluation";
      params = { exam: id };
    }
    return router.push({ name: routeName, params, query });

  };
  const mockTestAction = (test) => {
    if (test.is_completed) {
      viewResult(test.id);
    } else if (!test.is_accessible)  {
      upgrade();
    } else {
      start(test.id);
    }
  };
  const IndividualTestAction = (test, index) => {
    if (test.is_completed) {
      viewResult(test.id);
    } else if (!test.is_accessible && test.is_accessible === false)  {
      upgrade();
    } else {
      startIndividual(test?.id, test?.topic?.id, index);
    }
  };

  return {
    mockTestAction,
    IndividualTestAction,
    start,
    startIndividual,
    upgrade
  };
}
