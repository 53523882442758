<template>
    <prompt max-width="500" @on-close="closeDialog">
        <div class="pa-4 text-center">
            <h2 class="font-weight-bold kiwi-fs-24">Promocode</h2>
            <v-text-field 
                class="kiwi-form-input w-100 mt-4 text-left"
                variant="outlined"
                placeholder="Enter Promo Code"
                v-model="promoCode"
                @input="promoEvent"
                :error-messages="validation"
            />
        </div>
        <v-divider />
        <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px">
            <v-btn
                variant="outlined"
                class="flex-md-grow-1 text-center kiwi-bg-white kiwi-text-black text-none kiwi-fs-16"
                size="large"
                @click.prevent="closeDialog"
                style="border-color: #DBE1E0;"
            >Close</v-btn>
            <v-btn
                variant="flat"
                class="flex-md-grow-1 text-center text-none kiwi-fs-16"
                size="large"
                @click.prevent="applyPromo"
                :disabled="!enableApplyButton ? true : false"
                :class="enableApplyButton ? 'kiwi-bg-primary kiwi-text-white' : 'kiwi-bg-disabled kiwi-button-disable kiwi-text-black'"
            >Apply</v-btn>
        </div>
    </prompt>
</template>

<script setup>
import Prompt from '@/components/Prompt/ConfirmAlert.vue'
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import { usePackageStore } from '@/stores/package-store';
import { storeToRefs } from 'pinia';
import { useConfirmer } from '@/stores/confirmer';

const router = useRouter();
const emit = defineEmits(['closeModal'])
const enableApplyButton = ref(false);

const promoCode = ref(null)
const validation = ref(null)

const packageData = usePackageStore()
const {redeemPromocode} = packageData
const {transactionData} = storeToRefs(packageData)

const confirmer = useConfirmer()

const promoEvent = () => {
    if(promoCode.value) {
        enableApplyButton.value = true;
    }else{
        enableApplyButton.value = false;
    }
}

const closeDialog = () => {
    emit('closeModal')
}

const applyPromo = () => {
    redeemPromocode(transactionData.value.uuid, promoCode.value).then(res => {
        transactionData.value = res.data
        confirmer.openSnackBar({type: 'success', msg: res.message})
        closeDialog()
    }).catch(error => {
        if(error.type == 'validation'){
            validation.value = error.message
        }
    })
}
</script>

<style>

</style>