import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref, reactive } from "vue";

export const useQuestionBasedSatStore = defineStore("questionBasedSat-store", ()=>{
  const satTopics = ref({})
  const questions = ref([])

  const evaluationForQbt = ref([]);

  async function getTopics  (topic){
    let url = `/question/topic?fields=children`;
    const res = await api.get(url).then(res=>{
      satTopics.value = res.data.results;
    }).catch(error =>{})
    return res;
  }

  async function getQuestions (id){
    let url = `exam/scheduled-test?topic=${id}`;
    const res = await api.get(url).then(res=>{
      questions.value = res.data.results;
    }).catch(error =>{})
    return res;
  }

  async function getEvaluation (id) {
    let url = `/exam/scheduled-test/${id}/sat-final-evaluation/`;
    const res = await api.get(url).then(res=>{
      evaluationForQbt.value = res.data;
    })
  }

  return { getTopics , getQuestions  , satTopics , questions , evaluationForQbt  , getEvaluation }
})
