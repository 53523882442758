<template>
    <section class="kiwi-contact-page kiwi-bg-white pa-4 kiwi-radius-8">
        <Header />
        <Tab />
    </section>
</template>

<script setup>
import Header from '@/components/Contact/Header.vue'
import Intercom from '@intercom/messenger-js-sdk';
import Tab from '@/components/Contact/Tab.vue'
import {watch, computed} from 'vue'
import {useRoute} from 'vue-router'
import { useAuthStore } from '@/stores/auth/authStore'
import { useInstituteStore } from '@/stores/institute/institute-store'
import { storeToRefs } from 'pinia';
const authUser = useAuthStore()
const route = useRoute();
const store = useInstituteStore();
const {configData} = storeToRefs(store);

const {user} = storeToRefs(authUser);
const enabledFeatures = computed(() => {
  return configData.value.institute.enabled_features
})
</script>
<style>

</style>