<template>
    <div class="kiwi-fs-15 hiw" style="">
        <div ref="paragraph" @click="handleWordClick" style="word-break: break-word;">
            <span v-for="(word, index) in words" :key="index" @click="highlightWord(index)" class="word" :class="{'highlight': selectedWords.includes(index)}">{{ word }}</span>
        </div>
    </div>
</template>

<script setup>
import {ref, reactive, defineProps, computed, watch} from 'vue'
import { removeTags } from '@/utils/utils';
const props = defineProps({
    text: {
        type: String,
        required: true,
    },
})
const emit = defineEmits(["selected"]);
const words = computed (()=> {
    const paragraph = removeTags(props.text);
    return paragraph.split(' ')
}) 
const selectedWords = ref([]);
const highlightWord = (i) => {
  if(selectedWords.value.includes(i)) {
    selectedWords.value = selectedWords.value.filter(n => n !== i)
  }else {
    selectedWords.value = [...selectedWords.value, i]
  }
}
emit("selected", selectedWords.value);
watch(selectedWords, (newVal, oldVal) => {
    emit("selected", newVal);
});
</script>

<style lang="scss" scoped>
.word {
  cursor: pointer;
  padding: 0 1px;
  margin-right: 2px;
  display: inline-block;
  font-size: 16px;
}

.word.highlight {
  background-color: rgba(254, 240, 199, 1) !important;
  color: rgba(220, 104, 3, 1) !important;
}
</style>