<template>
    <div>
        <div class="kiwi-fs-15 fib" :class="props.answerFor === 'individualQuestion' ? 'IQT_content' : ''" style="">
            <span v-for="(part, index) in parts" :key="index">
                <template v-if="props.answerFor === 'individualQuestion'">
                    <!-- <span v-if="part.type === 'text'" v-html="part.value"></span> -->
                    <span 
                        class="iqt-fib-words kiwi-question-word cursor-pointer" 
                        v-for="(txt, ti) in part.words" 
                        :key="`${index}-${ti}`" 
                        @click="handleClick(txt, ti, index)" 
                        :class="{'kiwi-text-primary': (tword === txt && selectedIndex === `${index}-${ti}`)}"
                        style="display: inline-block;"
                    > 
                        {{txt}} 
                    </span>
                </template>
                <span v-if="part.type === 'text' && props.answerFor !== 'individualQuestion'" v-html="part.value"></span>
                <template v-else>
                    <template v-if="props.answerFor === 'individualQuestion'">
                        <span class="fill-text iqt-word fill-wrong cursor-pointer" v-if="!part.is_correct && part.selected_answer" @click="handleClick(part.selected_answer, null)">
                            <v-icon class="mdi mdi-close" size="14" />
                            {{ part.selected_answer }}
                        </span>
                        <span class="fill-text iqt-word fill-right cursor-pointer" v-if="!part.is_correct && props.answerFor && part.correct_answer" :class="!part.is_correct && props.answerFor && part.correct_answer ? 'd-inline-block' : 'd-none'" @click="handleClick(part.correct_answer, null)">
                            {{ part.correct_answer }}
                        </span>
                        <span class="fill-text iqt-word fill-right cursor-pointer" v-if="part.is_correct" @click="handleClick(part.selected_answer, null)">
                            <v-icon class="mdi mdi-check"  size="14" />
                            {{ part.selected_answer }}
                        </span>
                        <!-- <span class="text-ans-right cursor-pointer" v-if="slug === 'fib-r'  && !part.is_correct && part.correct_answer" @click="handleClick(part.correct_answer, null)"> ({{part.correct_answer}})</span> -->
                    </template>
                    <template v-else>
                        <span class="fill-text fill-wrong" v-if="!part.is_correct && part.selected_answer">
                            <v-icon class="mdi mdi-close" size="14" />
                            {{ part.selected_answer }}
                        </span>
                        <span class="fill-text fill-right" v-if="!part.is_correct && props.answerFor" :class="!part.is_correct && props.answerFor && part.correct_answer ? 'd-inline-block' : 'd-none'">
                            {{ part.correct_answer }}
                        </span>
                        <span class="fill-text fill-right" v-if="part.is_correct">
                            <v-icon class="mdi mdi-check"  size="14" />
                            {{ part.selected_answer }}
                        </span>
                        <span class="text-ans-right" v-if="slug === 'fib-r' && !part.is_correct && !props.answerFor"> ({{part.correct_answer}})</span>
                    </template>
                </template>
            </span>
        </div>
        <div v-if="!props.answerFor">
            <div v-if="slug === 'fib-r'">
                <AHeadline :text="`Options:`" />
                <template v-for="(item, k) in optionData" :key="k">
                    <span class="d-inline-block mr-2" :class="{
                        'fill-text fill-right': item.is_correct
                    }">
                        {{ item.option }}<template v-if="k !== (options.length - 1)">,</template>
                    </span>
                </template>
            </div>
            <div v-else-if="slug === 'fib-l'">
                <AHeadline :text="`Answer:`" />
                <ol class="blanks-option-ol">
                    <li v-for="(opt, i) in dynamicOptions" :key="i">
                        {{opt.correct_answer}}
                    </li>
                </ol>
            </div>
            <div v-else>
                <AHeadline :text="`Options:`" />
                <ol class="blanks-option-ol">
                    <li v-for="(opt, i) in dynamicOptions" :key="i">
                        <template v-for="(item, k) in opt.options" :key="`${i}-${k}`">
                            <span class="d-inline-block mr-2" :class="{
                                'fill-text fill-right': (opt.correct_answer || '').trim() === (item || '').trim()
                            }">
                                {{ item }}<template v-if="k !== (opt.options.length - 1)">,</template>
                            </span>

                        </template>
                    </li>
                </ol>
            </div>
        </div>
        <div v-else-if="props.answerFor && slug === 'fib-r'" class="mt-5  mb-5  ">
            <template v-for="(item, k) in props.options" :key="k">
            <span class="fill-text fill-default ml-3 mb-2">
            {{ item }}
            </span>
            </template>
        </div>
        <DictionaryDialog v-if="dialog" @close-dialog="closeDialogModal" :word="tword" :returnData="dictionaryStore?.dictonaryData" />
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import AHeadline from "@/components/Evaluation/MockTests/Answers/AHeadline.vue";
import {ref, computed, reactive, watch} from 'vue'
import { removeTags } from '@/utils/utils';
import { useIndividualQustionsDictonaryStore } from "@/stores/individual-question-dictionary";
import DictionaryDialog from "@/components/IndividualQuestion/DIctionaryDialog.vue";
const props = defineProps({
    text: {
        required: false,
    },
    options: {
        type: Array,
        required: false,
    },
    obtained: {
        type: Array,
        required: true,
    },
    slug: {
        required: false,
    },
    answerFor:{
      type: String,
      required: false,
      default:null
    },
})

const dialog = ref(false);
const tword = ref('');
const selectedWords = ref('');
const selectedIndex = ref('')
const dictionaryStore = useIndividualQustionsDictonaryStore();
const { getDictonaryData, dictonaryData } = storeToRefs(dictionaryStore)

const selected = ref({})
const parts = computed(() => {
      const regex = /\{([^}]*)\}/g;
      const textParts = removeTags(props.text || "").split(regex);
      const parts = [];
      let selectIndex = 0;

      for (let i = 0; i < textParts.length; i++) {
        if (i % 2 === 0) {
          if (textParts[i]) {
            parts.push({ type: 'text', value: textParts[i], words: (textParts[i] || []).split(/(?:\s|&nbsp;)+/) });
          }
        } else {
          const options = props.options.length && props.slug === 'fib-r' ? props.options : (textParts[i] || "").split('|').map(s => (s || '').trim());
          const ans = props.obtained[selectIndex] || {};
          parts.push({
            type: 'blank',
            options: options,
            selectIndex: selectIndex,
            selected_answer: ans.selected_answer,
            is_correct: ans.is_correct,
            correct_answer: ans.correct_answer,
          });
          selected[selectIndex] = selected[selectIndex] || ''; // Initialize with the first option
          selectIndex++;
        }
      }
      return parts;
});
const dynamicOptions = computed(() => {
    return parts.value.filter(p => p.type === 'blank');
})
const correctObtained = computed(() => {
    return props.obtained.filter(o => o.is_correct).map(o => o.correct_answer);
})
const optionData = computed(() => {
    if(props.slug !== 'fib-r') {
        return []
    }
    const correntList = props.obtained.map(o => o.correct_answer);
    return props.options.map(o => {
        return {
            option: o,
            is_correct: correntList.includes(o)
        }
    })
})


const handleClick = (word, index, i) => {
    let text = word;
    
    if(word.split('.')) {
        text = word.split(/[^a-zA-Z]+/)[0];
        // text = text.filter(word => word);
    } else {
        text = word;
    }

    selectedWords.value = word;
    selectedIndex.value = `${i}-${index}`;

    dictionaryStore.getDictonaryData(text).then(res => {
        dialog.value = true;
        tword.value = text;
    });
}

const closeDialogModal = () => {
    dialog.value = false
    tword.value = ''
}

</script>

<style lang="scss" scoped>
.fill-text {
    padding: 2px;
    line-height: 1.5;
    margin-left: 2px;
    margin-right: 2px;
}
.fill-right {
    background: rgba(209, 250, 223, 1);
    color: rgba(18, 183, 106, 1);
}
.fill-wrong {
    background: rgba(254, 228, 226, 1);
    color: rgba(217, 45, 32, 1);
}
.blanks-option-ol {
    padding-left: 15px;
}
.text-ans-right {
    color: rgba(3, 152, 85, 1);
}
.fill-default{
  background-color: var(--Neutral-100, rgba(230, 231, 231, 1));
  color: #081E20;
}
.iqt-fib-words {
    margin: 0 5px;
    // & + .iqt-fib-words {
    //     margin-left: 10px;
    // }
}
.IQT_content {
    span {
        display: inline;
    }
    .iqt-word {
        &:hover {
            color: rgba(18, 183, 106, 1);
        }
    }
}
</style>
