import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import {ref, reactive, createCommentVNode} from "vue";

export const useIndividualQustionsDictonaryStore = defineStore("individualQuestionDictonary", () => {
  const dictonaryData = ref([]);

  async function getDictonaryData(word) {
    let url = `dictionary/get-for-word/${word}/`;
   let res =  await api
    .get(url)
    .then((res) => {
      const response = res.data;
      dictonaryData.value = res.data;
      return response
    })
    .catch((error) => {});
    return res
  }

  return {
        getDictonaryData, dictonaryData
  };
});
