<template>
  <div class="bar-range-content">
    <input
      class="answer-audio-recorder-slider slider w-100"
      type="range"
      min="0"
      max="100"
      v-model="valueRange"
      :style="
        valueRange
          ? {
              background: `linear-gradient(to right, rgb(104, 113, 109) ${valueRange}%, rgb(212, 215, 214) ${valueRange}%)`,
            }
          : ''
      "
    />
  </div>
</template>
<script setup>
import { ref, watch, onBeforeUnmount } from "vue";
const valueRange = ref(0);
const props = defineProps({
  range: {
    type: Number,
    default: 0,
  },
  useWatch: {
    type: Boolean,
    default: false,
  },
});

let recordingInterval = null;

const updateProgressRange = () => {
  let incrementBy;
  if (props.range !== 0) {
    incrementBy = 100 / props.range;
    valueRange.value = incrementBy;
  } else {
    incrementBy = 1;
    valueRange.value = 0;
  }
  clearInterval(recordingInterval);
  recordingInterval = setInterval(() => {
    if (valueRange.value < 100) {
      valueRange.value += incrementBy;
    } else {
      clearInterval(recordingInterval);
    }
  }, 1000);
};

const clear = () => {
  clearInterval(recordingInterval);
};
watch(
  () => props.range,
  (newRange) => {
    if (newRange !== 0 && props.useWatch && newRange > 0) {
      updateProgressRange();
    } else {
      clear();
    }
  }
);

onBeforeUnmount(() => {
  try {
    clear();
  } catch (err) {}
});

defineExpose({
  updateProgressRange,
  clear,
});
</script>
<style lang="scss">
.bar-range-content {
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }
}
</style>
