<template>
    <v-sheet
    class="d-flex align-center kiwi-home-info-card justify-center flex-wrap text-center mx-auto px-4 kiwi-bg-primary pa-2"
    elevation="4"
    min-height="150"
    width="100%"
    rounded="xl"
  >
    <v-row class="w-100">
        <v-col cols="12" md="3" v-for="(cnt, i) in props.info" :key="i" :class="i > 0 ? 'kiwi-has-divder-left' : ''">
            <div class="d-flex flex-column flex-grow-1 kiwi-text-white justify-start" >
                <div class="font-weight-semi-bold kiwi-fs-38">{{cnt.counter}}</div>
                <div class="font-weight-semi-bold kiwi-fs-18">{{cnt.text}}</div>
            </div>
        </v-col>
    </v-row>
  </v-sheet>
</template>

<script setup>
    const props = defineProps({
        info: {
            type: Array,
            required: true
        }
    })
</script>
