<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-sheet class="w-100 kiwi-radius-4">
            <div class="w-100 pa-5">
                <div class="kiwi-fs-20 font-weight-semi-bold kiwi-text-black-3">You'll lose testing time during the break</div>
                <v-checkbox label="I need to take a break, and I understand that I'll lose the testing break."></v-checkbox>
                <p>If you are testing with  a laptop, don't close it.</p>
            </div>
            <v-divider />
            <div class="py-3 px-5 d-flex align-center flex-wrap">
                <v-btn class="kiwi-button kiwi-border-light-grey kiwi-black-3 text-none mr-2 flex-grow-1" variant="outlined" @click="closeDialog">Close</v-btn>
                <v-btn class="kiwi-button kiwi-bg-primary kiwi-text-white text-none flex-grow-1" variant="flat">Take Unscheduled Break</v-btn>
            </div>
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import {ref, computed, watch} from 'vue';

const dialog = ref(true);
const emits = defineEmits(['closeDialog'])
const props = defineProps(['dialog'])

const closeDialog = () => {
    dialog.value = false;
    emits('closeDialog', dialog.value)
}


watch(props.dialog, (val) => {
    if(val === false) {
        closeDialog();
    }
})
</script>

<style>

</style>