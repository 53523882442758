<template>
  <v-form class="w-100 mt-4">
    <textarea
        v-model="text"
        @input="updateWordCount"
        ref="textarea"
        rows="6"
        cols="30"
        @copy.prevent
        @paste.prevent
        @click.right.prevent
        class="kiwi-form-textarea kiwi-bg-white border kiwi-border-light-grey kiwi-radius-4 w-100 pa-2"
        autocorrect="off"
        spellcheck="false"
        :disabled="isSubmitted"
    ></textarea>

    <div class="d-flex align-center">
        <div class="ma-2">
            <p>Total Word Count: <strong>{{ wordCount }}</strong></p>
        </div>
        <div class="ma-2">
            <v-btn @click="cutText" variant="flat" class="mr-2 border text-none kiwi-fs-16 font-weight-semi-bold">Cut</v-btn>
            <v-btn @click="copyText" variant="flat" class="mr-2 border text-none kiwi-fs-16 font-weight-semi-bold">Copy</v-btn>
            <v-btn @click="pasteText" variant="flat" class="mr-2 border text-none kiwi-fs-16 font-weight-semi-bold">Paste</v-btn>
        </div>
    </div>
    <SnackBar />
  </v-form>
</template>

<script setup>
import { ref, onMounted, nextTick, watch} from 'vue';
import {storeToRefs} from "pinia";
import SnackBar from '@/components/SnackBar.vue'
import {useConfirmer} from '@/stores/confirmer'
import {useIndividualQustionsTestStore} from "@/stores/individual-question-test-store";
const individualQuestionsTestStore = useIndividualQustionsTestStore();
const {isSubmitted} = storeToRefs(individualQuestionsTestStore);
const confirmer = useConfirmer();

const text = ref('');
const copiedText = ref('')
const wordCount = ref(0);
const textarea = ref(null);
const emit = defineEmits(["text:updated"]);

const updateWordCount = () => {
  wordCount.value = text.value.trim().split(/\s+/).filter(Boolean).length;
};

let textareaElement = null;

onMounted(async () => {
  await nextTick();
  if (textarea.value) {
    textareaElement = textarea.value;
  }
});

const cutText = () => {
  if (textareaElement) {
    textareaElement.focus();
    const selectedText = document.getSelection().toString();
    if (selectedText) {
      copiedText.value = selectedText
      document.execCommand('cut');
    }
    updateWordCount();
  }
};

const copyText = () => {
  if (textareaElement) {
    textareaElement.focus();
    const selectedText = document.getSelection().toString();
    console.log('Selected text for copy:', selectedText); // Debug log
    if (selectedText) {
      copiedText.value = selectedText
      document.execCommand('copy');
    }
  }
};

const pasteText = async () => {
  if (textareaElement) {
    textareaElement.focus();
    try {
      const textFromClipboard = copiedText.value // await navigator.clipboard.readText();
      // if(copiedText.value !== textFromClipboard) {
      //   return false;
      // }
      const startPos = textareaElement.selectionStart;
      const endPos = textareaElement.selectionEnd;
      const currentValue = textareaElement.value;
      textareaElement.value = currentValue.slice(0, startPos) + textFromClipboard + currentValue.slice(endPos);
      text.value = textareaElement.value;
      updateWordCount();
    } catch (err) {
      confirmer.openSnackBar({type: 'error', msg: `Failed to read clipboard contents: ${err}`})
    }
  }
};
emit("text:updated", text.value);
watch(text, (newVal, oldVal) => {
    emit("text:updated", text.value);
});
</script>
