<template>
  <v-sheet class="bookmark-pagination-box">
    <div class="box-header text-center">
      <span class="font-weight-bold kiwi-text-black-3">{{ examTitle }}</span>
      <button
        class="position-absolute"
        style="top: 10px; right: 10px"
        @click="closeDialog"
      >
        <v-icon class="mdi mdi-close" />
      </button>
    </div>
    <v-divider />
    <Bookmarks
      @change-question="(val) => changeQuestion(val)"
      @go-to-review-page="goto"
    />
  </v-sheet>
</template>

<script setup>
import { useBookMarkeStore } from "@/stores/Sat/bookmarks-stores";
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import Bookmarks from "@/components/SAT/MockTest/Bookmarks.vue";

const examInstanceStore = useExamInstanceStore();
const bookmarkStore = useBookMarkeStore();
const { bookmarks } = storeToRefs(bookmarkStore);

const emits = defineEmits([
  "closeDialog",
  "change-question",
  "go-to-review-page",
]);

const closeDialog = () => {
  emits("closeDialog", "change-question");
};
const changeQuestion = (index) => {
  emits("change-question", index);
};

const examDetail = computed(() => {
  return examInstanceStore.examDetail?.exam_attempt_data?.exam_state
    ?.current_section_module;
});

const examTitle = computed(() => {
  const titles = {
    11: "Section 1, Module 1: English",
    12: "Section 1, Module 2: English",
    21: "Section 2, Module 1: Maths",
    22: "Section 2, Module 2: Maths",
  };

  return titles[examDetail.value] || "Unknown Exam";
});
const goto = () => {
  emits("go-to-review-page");
};
</script>

<style lang="scss">
.bookmark-pagination-box {
  position: absolute;
  top: calc(-100% - 308px);
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  max-width: 500px;
  min-height: 322px;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 60px -4px #10182814;
  padding: 0 20px;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -12px;
    right: 47%;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 12px solid var(--color-light-black-3);
    box-shadow: 0px 0px 60px -4px #10182814;
  }

  .box-header,
  .box-body,
  .box-index {
    padding: 20px 0;
  }

  .box-body {
    margin: -6px;
  }

  .num-box {
    position: relative;
    border: 1px dashed var(--color-black-3);
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-primary);
    margin: 10px 6px;
    .current-icon,
    .bookmark-icon {
      position: absolute;
    }
    .current-icon {
      top: -18px;
      left: 50%;
      transform: translateX(-50%);
    }
    .bookmark-icon {
      top: -6px;
      right: -6px;
    }
  }
}

</style>
