<template>
    <span>{{formattedTime}}</span>
</template>

<script setup>
import moment from 'moment';
import {ref, onMounted, computed} from 'vue'
const props = defineProps({
    time: {
        type: String,
        required: true
    }
})

const formattedTime = ref('')

const updateTime = () => {
    const duration = moment.duration(moment().diff(moment(props.time)));
    const seconds = duration.seconds();
    const minutes = duration.minutes();
    const hours = duration.hours();
    const days = duration.days();
    
    if (days > 0) {
        formattedTime.value = days === 1 ? 'Yesterday' : `${days} days ago`;
    } else if (hours > 0) {
        formattedTime.value = `${hours} hours ago`;
    } else if (minutes > 0) {
        formattedTime.value = `${minutes} minutes ago`;
    } else {
        formattedTime.value = `Just now`;
    }
}

onMounted(() => {
    updateTime()
})
</script>

<style>

</style>