<template>
  <div class="fill-height">
    <div class="d-flex flex-wrap kiwi-voucher-checkout-grid" v-if="hasOrders">
        <div class="kiwi-crate-voucher-form-wrapper">
            <v-sheet
                class="kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3 fill-height"
                variant="flat"
            >
                <v-sheet class="kiwi-mock-test-header py-3 px-4 d-flex flex-wrap" variant="flat">
                    <h1 class="kiwi-fs-20 font-weight-bold">Order History</h1>
                </v-sheet>
                <v-divider />
                <div class="pa-4">
                  <v-expansion-panels class="order-history" variant="accordion" bg-color="#F7F8F9" elevation="0" rounded="4">
                    <v-expansion-panel
                      class=""
                      :class="i > 0 ? 'mt-6' : ''"
                      style="border-radius: 4px !important; border: 1px solid #E2E6E4;"
                      v-for="(order, i) in orderHistory"
                      :key="i"
                      @click="fetchOrder(order, i)"
                    >
                      <v-expansion-panel-title>
                        <div><span class="order-history-accordion-title-dot mr-2" /><span class="font-weight-bold mr-2">Order: {{i + 1}}</span> <span class="font-weight-medium">Creation date: {{moment(order?.created).format("YYYY-MM-DD") || 'N/A'}}</span></div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <template v-if="order.cart_items.length">
                          <v-data-table-server
                            items-per-page="10"
                            class="inst-list-table order-history-table"
                            :headers="headers"
                            :items="order.cart_items"
                            :items-length="7"
                            loading-text=""
                            items-per-page-text=""
                            hide-default-footer
                          >
                            <template v-slot:item="{ item }">
                              <tr>
                                <td v-for="(key, i) in headers" :key="i">
                                    <template v-if="key.key === 'package_name'">
                                      <span>{{packages.filter(p => p.id === item.package)[0].name}}</span>
                                    </template>
                                    <template v-else-if="key.key === 'quantity'">
                                      <span>{{item.quantity}}</span>
                                    </template>
                                    <template v-else-if="key.key === 'amount'">
                                      <span>{{item.per_unit_price}}</span>
                                    </template>
                                </td>
                              </tr>
                            </template>
                            <template v-slot:no-data>
                              <div class="no-data-container">
                                <img
                                  src="/assets/no_data.svg"
                                  alt="No Vouchers yet"
                                  class="no-data-image"
                                />
                              </div>
                            </template>
                          </v-data-table-server>
                        </template>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
            </v-sheet>
        </div>
        <div class="kiwi-crate-voucher-summary">
            <v-sheet
                class="kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3"
            >
                <v-sheet class="kiwi-mock-test-header py-3 px-4 d-flex flex-wrap" variant="flat">
                    <h1 class="kiwi-fs-20 font-weight-bold">Order Summary<template v-if="orderSummary.index">: {{orderSummary.index}}</template> </h1>
                    <v-divider class="mt-4" />
                    <v-table density="comfortable" class="custom-table plain mt-4 checkout-table w-100">
                        <tbody>
                            <tr>
                                <td>Gross Amount</td>
                                <td>
                                    <span class="d-block text-right">
                                        NPR. {{orderSummary.sub_total || 0}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Discount Amount</td>
                                <td>
                                    <span class="d-block text-right">
                                        NPR. {{orderSummary.discount || 0}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Sub-total</td>
                                <td>
                                    <span class="d-block text-right">
                                        NPR. {{orderSummary.total || 0}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="pb-2" style="border-bottom: 1px dashed #E2E6E4 !important;">VAT(13%)</td>
                                <td class="pb-2" style="border-bottom: 1px dashed #E2E6E4 !important;">
                                    <span class="d-block text-right">
                                        NPR. {{orderSummary.tax || 0}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="pt-2"><span class="font-weight-bold">Total</span></td>
                                <td class="pt-2">
                                    <span class="d-block text-right font-weight-bold">
                                        <!-- {{ $route.query.promo === 'true' ? 'NPR. 63.00' : 'NPR. 105.00' }} -->
                                        NPR. <span class="kiwi-fs-20 font-weight-bold">{{orderSummary.grand_total || 0}}</span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
    <v-sheet
      class="kiwi-my-practice-page kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3 fill-height"
      variant="flat"
      v-else
    >
      <v-sheet class="kiwi-mock-test-header py-3 px-4 d-flex flex-wrap" variant="flat">
        <h1 class="kiwi-fs-20 font-weight-bold">Order History</h1>
      </v-sheet>
      <v-divider></v-divider>
      <div class="pa-4 h-100" style="max-height: calc(100% - 54px);">
        <v-sheet class="h-100 d-flex align-center justify-center pa-4 kiwi-radius-4" color="#F7F8F9">
            <div class="d-flex flex-column align-center justify-center">
                <img src="/assets/File_searching.svg" alt="">
                <div class="font-weight-bold kiwi-fs-20">No Vouchers yet</div>
                <span class="text-center kiwi-fs-14 kiwi-text-light-black-2 mt-1">Use the (+) button or the one below to start<br /> creating vouchers</span>
                <v-btn variant="flat" class="kiwi-bg-primary kiwi-text-white kiwi-radius-4 text-none align-center mt-3" :to="{name: 'ins_create-voucher'}">
                    <v-icon class="mdi mdi-plus-circle-outline mr-2" />Buy Voucher
                </v-btn>
            </div>
        </v-sheet>
      </div>  
    </v-sheet>
  </div>
  <PromptDialog v-if="prompt" v-bind="prompt" />
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useShopStore } from "@/stores/institute/institute-shop-store";
import { storeToRefs } from "pinia";
import moment from "moment";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import SnackBar from "@/components/SnackBar.vue";
import { useConfirmer } from "@/stores/confirmer";

const confirmer = useConfirmer();
const prompt = ref(null);
const shopStore = useShopStore();
const hasOrders = ref(true);
const {orderHistory, packages} = storeToRefs(shopStore)

const headers = ref([
    { title: 'Package Name', key: 'package_name',  sortable: false},
    { title: 'No of Vouchers', key: 'quantity',  sortable: false},
    { title: 'Amount (NRS)', key: 'amount', sortable: false},
])
const orderSummary = ref({})
const fetchOrder = (order, index) => {
  if(orderSummary.value && orderSummary.value.id === order.id) {
    orderSummary.value = {} 
  }else {
      orderSummary.value = {...order, index: index + 1}
  }
}

onMounted(() => {
  orderSummary.value = {}
  shopStore.getOrderHistory()
  shopStore.getPackages()
})

</script>
<style lang="scss">
.v-table.inst-list-table {
  thead {
    tr {
      th {
        white-space: nowrap;
        font-size: 1rem;
        background: rgba(242, 244, 246, 1);
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 1rem;
      }
    }
  }
}
.v-table.order-history-table {
  border-radius: 8px !important;
  border: 1px solid #E2E6E4;
  thead tr {
    th {
      background: var(--color-primary) !important;
      color: var(--color-white) !important;
    }
  }
}
.pkg-chip {
  font-weight: 500;
}
.pkg-chip.active-pkg {
  background: linear-gradient(127.21deg, #ffffff 12.55%, #faf3ce 111.14%);
}
.voucher-status.Redeemed {
  color: rgba(247, 144, 9, 1);
}
.voucher-status.Active {
  color: rgba(28, 169, 114, 1);
}
.voucher-status.Expired {
  color: rgba(240, 68, 56, 1);
}
.status-chip {
  font-weight: 500;
  &.active-status {
    color: #fff !important;
    .dot {
      background-color: #fff !important;
    }
  }
  .dot {
    width: 6px;
    height: 6px;
    margin-right: 4px;
    border-radius: 50%;
    display: inline-block;
  }
}

.kiwi-crate-voucher-form-wrapper {
  @media screen and (min-width: 1280px) {
    max-height: calc(100vh - 94px);
    height: 100%;
    overflow-y: auto;
  }
}
</style>
