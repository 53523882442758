<template>
  <v-app>
    <v-main class="pt-0" v-if="!fetchingAppConfig">
      <InstituteLayout v-if="layout === 'InstituteLayout'" />
      <MainLayout v-else-if="layout === 'MainLayout'" />
      <AnswerLayout v-else-if="layout === 'answerLayout'" />
      <router-view v-else />
    </v-main>
  </v-app>
</template>

<script setup>
import MainLayout from '@/layouts/MainLayout.vue'
import InstituteLayout from '@/layouts/InstituteLayout.vue'
import AnswerLayout from '@/layouts/AnswerLaout.vue'
import { computed, watch } from 'vue'
import {useRoute} from 'vue-router'
import { useInstituteStore } from './stores/institute/institute-store'
import { useAuthStore } from './stores/auth/authStore'
const instituteStore = useInstituteStore()
const user = useAuthStore()
instituteStore.init()
const route = useRoute()
const layout = computed(() => {
   const meta =  route.meta || {};
   if(meta.requiresAuth) {
     if(user.isInstitute || user.isAgent) {
        return "InstituteLayout"
     }else {
       return meta.layout || 'MainLayout'
     }
   }else {
      return null
   }
})
const fetchingAppConfig = computed(() => {
  return instituteStore.configData === null
})
</script>
