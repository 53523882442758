<template>
    <v-checkbox 
        v-for="(txt, i) in options"
        hide-details 
        v-model="selected"
        :value="txt.id"
        :key="i"
        :multiple="true"
        class="multiple-choice"
        >
            <template v-slot:label>
                 <span class="d-flex flex-wrap text-black" style="font-weight: 400;" v-html="`${removeTags(txt.option)}`"></span>
            </template>
        </v-checkbox>
</template>
<script setup>
  import { defineProps, ref, watch } from "vue";
  import { removeTags } from '@/utils/utils';
  const indexToLetter = (index) => {
        return String.fromCharCode(97 + index).toUpperCase();
  }
  const emit = defineEmits(["selected"]);
  const props = defineProps({
        options: {
            type: Array,
            required: true,
        },
    })
   const selected = ref([])
   emit("selected", selected.value);
   watch(selected, (newVal, oldVal) => {
        emit("selected", newVal);
   });
</script>

<style lang="scss">
.multiple-choice + * {
    margin-top: 8px;
}
.multiple-choice.v-checkbox .v-selection-control {
    min-height: auto;
}
.multiple-choice.v-checkbox .v-selection-control--dirty {
    background-color: white !important;
    border-radius: 4px;
    min-height: auto;
}
.v-checkbox label {
    opacity: 1;
}   
</style>
  