import { api } from "@/plugins/axios";
import axios from 'axios';
import { defineStore } from "pinia";
import { ref } from "vue";

export const useConfigStore = defineStore('configuration' , () =>{
    const configData = ref({})
    const faqContent = ref([])
    
    async function getConfigData(){
        try {
            const res = await api.get('/common/project-config/all')
            configData.value = res.data
        } catch (error) {
        }
    }

    async function getFaqContent(){
        try {
            const res = await api.get('common/faqs/')
            faqContent.value = res.data
        } catch (error) {
            // console.log("Error Message: ", error)
        }
    }

    return {configData, faqContent, getConfigData, getFaqContent}
})