<template>
  <div v-if="institute.$hasFeature('referral')">
    <Card :handleControl="referControl">
        <template v-slot:mediaBg>
            <img :src="referControl.media" class="kiwi-main-media position-absolute top-0 right-0 h-100 z-2" v-if="referControl.mode === 'media-bg'" alt="">
        </template>
        <article class="kiwi-max-w-60">
            <div 
                class="sub-title kiwi-ff-manrope kiwi-fs-10 font-weight-semi-bold"
            >{{referContent.subTitle}}</div>
            <div 
                class="title mt-2 kiwi-fs-18 font-weight-bold kiwi-lh-1-md"
            >{{ referContent.title }}</div>
            <v-btn 
                variant="flat" 
                class="kiwi-radius-4 kiwi-text-white text-none mt-4 kiwi-bg-primary-gradient-2 font-weight-semi-bold kiwi-fs-14" 
                :to="{name: 'referal'}"
            >{{referContent.action}}</v-btn>
        </article>
    </Card>
  </div>
</template>

<script setup>
import Card from "@/components/Dashboard/ContentSheet.vue";
import {ref, computed} from 'vue'
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()
const referControl = ref({
    bg: 'primary-gradient-1',
    textColor: 'white',
    mode: 'media-bg',
    media: '/assets/refer-a-friend.png',
    mediaWave: '/assets/admin_card_wave.svg',
    haseWave: true,
    hasAction: true,
    contentWidth: '60',
});

const referContent = computed(() => {
    return {
        subTitle: 'Learning Together',
        title: `Start learning on ${institute.name} with your Friends!`,
        action: 'Refer a Friend',
    }
});
</script>

<style>

</style>