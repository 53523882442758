<template>
    <article class="px-4 py-6 kiwi-paragraphs-bundle">
        <p class="kiwi-fs-16 kiwi-text-black-2">PTE is accepted by over 3,500 universities and colleges globally for study, by Australia and New Zealand for work and migration visas, and for professional registration. PTE Academic is a computer-based English test that assesses your academic-level speaking, writing, reading and listening skills. You take the test in a small, friendly test center environment in just two hours.</p>
        <p class="font-weight-semi-bold kiwi-text-black-3"><span class="kiwi-text-error">Note:</span> The PTE Test is approximately 2 hours long.</p>
        <v-table density="comfortable" class="custom-table border mt-6 answer-table kiwi-text-black-3 font-style-italic">
            <thead class="kiwi-bg-light-grey-5">
                <tr>
                    <th>Part</th>
                    <th>Content</th>
                    <th>Time Allowed</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Intro</td>
                    <td>Introduction</td>
                    <td>1 Minutes</td>
                </tr>
                <tr>
                    <td>Part1</td>
                    <td>Speaking and Writing</td>
                    <td>54-67 Minutes</td>
                </tr>
                <tr>
                    <td>Part2</td>
                    <td>Reading</td>
                    <td>29-30 Minutes</td>
                </tr>
                <tr>
                    <td>Part3</td>
                    <td>Listening</td>
                    <td>30-43 Minutes</td>
                </tr>
            </tbody>
        </v-table>
    </article>
</template>
<script setup>
</script>
