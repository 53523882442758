<template>
    <section class="kiwi-website kiwi-position-relative fill-height d-flex flex-column" :class="`kiwi-`+props.page">
        <div class="kiwi-innerlay-bg-layout kiwi-gradient-bg kiwi-top-bg kiwi-position-absolute" v-if="props.isGradientBgTop"></div>
        <MHeader class="d-md-none" />
        <Header class="d-none d-md-block" />
        <slot />
        <Footer style="margin-block-start: auto;" />
    </section>
</template>

<script setup>
    import Header from './Layout/Header.vue'
    import MHeader from './Layout/MobileHeader.vue'
    import Footer from './Layout/Footer.vue'
    const props = defineProps({
        page: String,
        isGradientBgTop: {
            type: Boolean,
            default: false,
            requred: true,
        }
    })
</script>