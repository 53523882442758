import Store from "@/pages/Institute/Store/Store.vue";
import BuyingVoucherForm from "@/pages/Institute/Store/BuyingVoucherForm.vue";
import AgentVoucher from "@/pages/Institute/Agent/Voucher.vue";
import Checkout from '@/pages/Student/Packages/Checkout.vue';
import AgentStudent from "@/pages/Institute/Agent/Student.vue";
import AgentReferal from "@/pages/Institute/Agent/Referal.vue";

const protected_routes = [
  {
    path: "/agent/students",
    component: AgentStudent,
    name: "agent_student",
  },
  {
    path: "/agent/order-history",
    component: Store,
    name: "agent_store",
  },
  {
    path: "/agent/voucher/create-voucher",
    component: BuyingVoucherForm,
    name: "agent_create-voucher",
  },
  {
    path: "/agent/voucher/create-voucher/checkout/:uuid",
    component: Checkout,
    name: "agent_checkout",
  },
  {
    path: "/agent/voucher",
    component: AgentVoucher,
    name: "agent_voucher",
  },
  {
    path: "/agent/referal",
    component: AgentReferal,
    name: "agent_referal",
  }
];
protected_routes.forEach((r) => {
  r.meta = {
    ...(r.meta || {}),
    layout: 'InstituteLayout',
    requiresAuth: true,
    user_type: 'agent'
  };
});
export default protected_routes;
