import { ref } from 'vue'

export function useValidation() {
  const isValid = ref(false)
  const fields = []
  const rules = {
    required: (name) => required(name),
    fullname: value => fullname(value),
    minLength: (val, number) => validateMinLength(val, number),
    email: (value) => validateEmail(value),
    password: (value) => validatePassword(value),
    confirm_password: (value, password) => validateCPassword(value, password),
    phone_number: (value, country) => validatePhoneNumber(value, country),
  }

  const required = (value) => {
    return validConditionCheck('required', !!value , `This field is required`);
  }

  const fullname = (value) => {
    const fullnamePattern = /\b\w+\b (\b\w+\b)/;
    const numbersPattern = /\d/;

    let error_msg = ''
    let result = true
    if (numbersPattern.test(value)) {
      error_msg = 'No numbers allowed';
      result = false;
    }else if(!fullnamePattern.test(value)){
      error_msg = 'Please enter full name'  
      result = false
    }else if(value && value.length >= 25){
        error_msg = 'Maximum word legth reached'
        result = false
    }else{
      result = true
    }
   
    return validConditionCheck('fullname', result, error_msg)
  }

  const validateMinLength = (value, rule = 6) => {
    return validConditionCheck('minLength', value.length >= rule, `At least ${rule} characters`)
  }

  const validateEmail = (value) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return validConditionCheck('email', emailPattern.test(value) ,'Invalid email address')
  }

  const validatePassword = (password) => {
    const strongPasswordRegex = /^.{6,}$/;
    return validConditionCheck('password', strongPasswordRegex.test(password) ,'Password is too short or invalid.')
  }

  const validateCPassword = (value, password) => {
    const strongPasswordRegex = /^.{6,}$/;
    const isValid = validConditionCheck('confirm_password', strongPasswordRegex.test(value) ,'Password is too short or invalid.')
    if(!isValid) {
      return isValid
    } else {
      return value === password ? true : `Re-type password didn't match.`
    }
  }

  const validatePhoneNumber = (obj) => {
  // const validatePhoneNumber = (phone_number, country = null) => {
    // const nepalPhoneNumberRegex = /^(?:\+977[- ]?)?(97[45]\d{7}|98\d{8}|[1-6]\d{7})$/;
    // return validConditionCheck('phone_number', nepalPhoneNumberRegex.test(phone_number. replace("-", "")), "Phone number is invalid")

    let condition =  (obj && obj.valid === true) ? true : false
    if(condition){
      if(obj.countryCode === 'NP'){
        condition = false
        let formatted_number = obj.formatted.replace('-', '')
        formatted_number = formatted_number.replace('+977', '')
        formatted_number = formatted_number.replace(/\s/g, '')
        if(formatted_number.length === 10){
          condition = true
        }
      }
    }
    return validConditionCheck('phone_number', condition, "Phone number is invalid")

    // let check = true
    // if (!phone_number.startsWith('9')) {
    //   console.log('start with 9', phone_number)
    //   check = false
    // }
    // if(phone_number.length === 1){
    //   console.log('length === 1', phone_number.length, phone_number)
    //   check = false
    // }
    // console.log('length', phone_number.length, phone_number)
    // if(country != null && country === "Nepal" && phone_number.length === 8 ){
    //   console.log('country', country)
    //   check = false
    // }
    // console.log('vlid phone', nepalPhoneNumberRegex.test(phone_number))
  }

  const validConditionCheck = (field_name, condition, error_message) => {
    let result = null
    if(condition){
      fields[field_name] = true;
      result = true
    }else{
      fields[field_name] = false;
      result = error_message
    }
    isValid.value = Object.values(fields).every((field) => field)
    return result
  }

  return {rules, isValid}
}
