<template>
<div>
     <!--Score Card start-->
     <v-card class="mt-5" :width="isQbt ? 528 : 450">
      <div class="d-flex mt-4 pb-2 px-3 justify-space-between align-center">
        <div v-if="!isQbt">
          <span class="font-weight-bold kiwi-fs-24 kiwi-text-black-3">Your SAT Score</span>
          <v-card-subtitle v-if="!isQbt" class="kiwi-text-light-black-3 px-1 " style="opacity: 1 !important;">
            ({{value}} -1600)
          </v-card-subtitle>
        </div>
        <template v-else>
          <span class="font-weight-bold kiwi-fs-24 kiwi-text-black-3">{{evaluationForQbt?.detail?.title}}</span>
          <span class="sat-difficulty-tag kiwi-text-black-3 px-3 py-1 d-inline-flex align-center justify-center kiwi-fs-14 font-weight-semi-bold" style="border-radius: 24px;">{{evaluationForQbt?.detail?.exam_difficulty}}</span>
        </template>
      </div>
      <v-divider></v-divider>
       <v-row v-if="isQbt" class="text-center mt-3">
        <v-col cols="12" md="6">
          <div class="half-cirlce-progress position-relative">
            <svg viewBox="0 0 100 50">
            <!-- The Gradient -->
            <defs>
              <linearGradient id="gradient2" gradientTransform="rotate(95.8)">
                <stop offset="0%" stop-color="#1CA972" />
                <stop offset="100%" stop-color="#2FE29C " />
              </linearGradient>
            </defs>

            <!-- Background path -->
            <path d="M 10,50 A 40,40 0 0,1 90,50" fill="none" stroke="#ECEEED" stroke-width="3"  style="border: darshed #bebebe;"/>

            <!-- Foreground path (progress) -->
            <path
              class="foreground-path"
              d="M 10,50 A 40,40 0 0,1 90,50"
              fill="none"
              stroke="url(#gradient2)"
              stroke-width="10"
              :stroke-dasharray="progressDashArray"
              :stroke-dashoffset="qbtProgressDashOffsetC"
            />
          </svg>
          <div class="score text-center kiwi-fs-36 kiwi-text-black-3 font-weight-bold">
            {{ correctAnswer }} <br>
            <p class="kiwi-text-light-black-3 kiwi-fs-14 ">Correct Answer</p>
          </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="half-cirlce-progress position-relative">
            <svg viewBox="0 0 100 50">
            <!-- The Gradient -->
            <defs>
              <linearGradient id="gradient3" gradientTransform="rotate(95.8)">
                <stop offset="0%" stop-color="#E84356" />
                <stop offset="100%" stop-color="#E9AF59 " />
              </linearGradient>
            </defs>

            <!-- Background path -->
            <path d="M 10,50 A 40,40 0 0,1 90,50" fill="none" stroke="#ECEEED" stroke-width="3"  style="border: darshed #bebebe;"/>

            <!-- Foreground path (progress) -->
            <path
              class="foreground-path"
              d="M 10,50 A 40,40 0 0,1 90,50"
              fill="none"
              stroke="url(#gradient3)"
              stroke-width="10"
              :stroke-dasharray="progressDashArray"
              :stroke-dashoffset="qbtProgressDashOffsetW"
            />
          </svg>
          <div class="score text-center kiwi-fs-36 kiwi-text-black-3 font-weight-bold">
            {{ wrongAnswer }} <br>
            <p class="kiwi-text-light-black-3 kiwi-fs-14 ">Wrong Answer</p>
          </div>
          </div>
        </v-col>
        </v-row>
       <div v-else  class="half-circle-progress mt-5">
         <svg viewBox="0 0 100 50">
           <!-- The Gradient -->
           <defs>
             <linearGradient id="gradient" gradientTransform="rotate(95.8)">
               <stop offset="0%" stop-color="#E84356" />
               <stop offset="100%" stop-color="#E9AF59 " />
             </linearGradient>
           </defs>

           <!-- Background path -->
           <path d="M 10,50 A 40,40 0 0,1 90,50" fill="none" stroke="#ECEEED" stroke-width="3"  style="border: darshed #bebebe;"/>

           <!-- Foreground path (progress) -->
           <path
             class="foreground-path"
             d="M 10,50 A 40,40 0 0,1 90,50"
             fill="none"
             stroke="url(#gradient)"
             stroke-width="10"
             :stroke-dasharray="progressDashArray"
             :stroke-dashoffset="progressDashOffset"
           />
         </svg>
         <div class="score text-center kiwi-fs-36 kiwi-text-black-3 font-weight-bold">
           {{ value }} <br>
           <p class="kiwi-text-light-black-3 kiwi-fs-14 ">Total Score</p>
         </div>
       </div>

      <v-divider class="mt-5"></v-divider>

      <v-card-text v-if="!isQbt">
        <v-card-item style="background: #F7F8F9; color:#081E20;" class="rounded">
          <v-card-text class="px-1">
            <v-card-title class="font-weight-bold kiwi-fs-18 kiwi-text-black-3">{{evaluation?.exam_details?.title}}</v-card-title>
            <v-text class="kiwi-text-light-black-3">Tested on:</v-text>
            <v-text class="kiwi-text-black-3 font-weight-bold ms-3">{{convertDate(evaluation?.exam_details?.tested_on)}}</v-text>
          </v-card-text>
          <hr>

          <v-card-text class="px-1">
            <v-card-subtitle class="kiwi-text-light-black-3 kiwi-fs-14"
                             style="opacity: 1 !important; font-weight: 500;">
              Your Reading and Writing Score
            </v-card-subtitle>
            <div>
              <v-text class="font-weight-bold kiwi-fs-18 kiwi-text-black-3">{{evaluation?.section_wise_score?.section_1}}</v-text> |
              <v-text >200-800</v-text>
            </div>
            <v-card-subtitle class="kiwi-text-light-black-3 mt-5" style="opacity: 1 !important;">
              Your Math Score
            </v-card-subtitle>
            <div>
              <v-text class="font-weight-bold kiwi-fs-18 kiwi-text-black-3 py-1">{{evaluation?.section_wise_score?.section_2}}</v-text> |
              <v-text>200-800</v-text>
            </div>
          </v-card-text>
        </v-card-item>
      </v-card-text >

      <v-card-text v-else>
        <v-card-item style="background: #F7F8F9; color:#081E20;" class="rounded">
          <v-card-text class="px-1">
            <div class="font-weight-bold kiwi-fs-14 kiwi-text-black-3">Advanced Math - <span class="font-weight-regular">{{evaluationForQbt?.detail?.total_number_of_question}} Questions</span></div>
            <div class="kiwi-bg-white kiwi-text-black-3 py-1 px-2 kiwi-radius-4 mt-2" style="border-left: 4px solid var(--color-primary);">Description: {{evaluationForQbt?.detail?.description}}</div>
          </v-card-text>
        </v-card-item>
      </v-card-text >

      <v-divider class="mb-3"></v-divider>
      <div class="px-4">
      <div class="rounded-pill mb-3 text-center kiwi-fs-14 font-weight-bold position-relative"
           style="background: #1CA972; color: #FFFFFF; height:48px; padding-top: 12px;" @click="scoreDetails">
        Score Details
      </div>

      </div>
    </v-card>
    <!--Score Card end-->


    <v-divider class="mt-5 mb-5"></v-divider>
    <p style="font-weight: 500; font-size: 16px; letter-spacing: -0.18px;">
      <strong>*Note:</strong> This score report is unofficial and cannot be used for university and college
      applications or visa purposes. It's based solely on your performance in the mock test provided by KiwiRead.
    </p>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, computed } from 'vue';
import {useEvaluationStore} from "@/stores/Sat/evaluation-store";
import {storeToRefs} from "pinia";
import {useQuestionBasedSatStore} from "@/stores/Sat/questionBasedSat-store"

const props = defineProps({
  isQbt:{
    type: Boolean,
    default: false
  }
})
const evaluationStore = useEvaluationStore();
const questionBasedSatStore = useQuestionBasedSatStore();
const {evaluationForQbt} = storeToRefs(questionBasedSatStore);
const {evaluation} = storeToRefs(evaluationStore);
const interval = ref(-1);
const emits = defineEmits(['score-details'])

const value = computed(() => {
  return evaluation.value.total_score;
})
const correctAnswer = computed(()=>{
  return evaluationForQbt.value?.overall_answer_dict?.right_answers;
})
const wrongAnswer = computed(()=>{
  return evaluationForQbt.value?.overall_answer_dict?.wrong_answers;
})
const maxValue = 1600;

const circumference = 126.92

const progress = computed(() => {
  return Math.round((value.value / maxValue) * 100)
})

const qbtCProgress = computed(() => {
  return Math.round((evaluationForQbt.value?.overall_answer_dict?.right_answers / evaluationForQbt.value?.overall_answer_dict?.total_questions) * 100)
  // return Math.round((evaluationForQbt.value?.overall_answer_dict?.right_answers / evaluationForQbt.value?.overall_answer_dict?.total_questions))
})
const qbtWProgress = computed(() => {
  return Math.round((wrongAnswer.value / evaluationForQbt.value?.overall_answer_dict?.total_questions) * 100)
})
const progressDashArray = computed(() => {
  return circumference
})

const progressDashOffset = computed(() => {
  return circumference - (circumference * progress.value) / 100
})

const qbtProgressDashOffsetC = computed(() => {
  return circumference - (circumference * qbtCProgress.value) / 100
})

const qbtProgressDashOffsetW = computed(() => {
  return circumference - (circumference * qbtWProgress.value) / 100
})

const scoreDetails = () =>{
emits('score-details');
}

onBeforeUnmount(() => {
  clearInterval(interval.value)
})
const convertDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  return date.toLocaleDateString('en-US', options);
};
</script>

<style scoped>
.half-circle-progress {
  position: relative;
  width: 200px;
  height: 100px;
  left: 25%;

}

svg {
  width: 100%;
  height: auto;
}

.foreground-path {
  stroke-linecap: round;
}

.score {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sat-difficulty-tag {
  background-color: #FFCB80;
}
</style>
