<template>
    <v-sheet
            variant="flat"
            class="kiwi-bg-white pa-4"
        >
        <Header :title="title ? title.toUpperCase() : ''" />
        <v-divider class="mb-3" />

        <div class="text-center pb-4 pt-10" v-if="isLoading">
            <Loading  />
        </div>
        <div v-for="(answer, a) in activeAnswers" :key="answer.id">
            <Answer :answer="answer" :index="a + 1" :n="((perPage*page) + a + 1) - perPage" />
        </div>
        <template v-if="!isLoading">
            <v-divider />
            <div class="pa-4 kiwi-bg-light-grey-7 rounded-lg kiwi-text-black-3 d-flex align-center justify-center">
                <v-pagination
                    v-model="page"
                    :length="Math.ceil(pageCount / perPage)"
                    :total-visible="perPage"
                    variant="text"
                ></v-pagination>
            </div>
        </template>
    </v-sheet>
</template>

<script setup>
import { useQuestionBasedEvaluation } from "@/stores/questionbased-evauation";
import { useQuestionBasedTestStore } from "@/stores/questionbased-test-store";
import {ref, computed} from 'vue'
import {useRoute} from 'vue-router'
import Header from '@/components/Evaluation/MockTests/Header.vue'
import Answer from '@/components/Evaluation/MockTests/Answers/Answer.vue'
const page = ref(1)
const perPage = ref(5)
const route = useRoute();
const testId = parseInt(route.params.test);
const questionBasedTestStore = useQuestionBasedTestStore();
const questionBasedEvaluation = useQuestionBasedEvaluation();
questionBasedTestStore.testDetail = null
questionBasedTestStore.getTestDetail(testId)
questionBasedEvaluation.getEvaluation(testId)
const answers = computed(() => {
    return questionBasedEvaluation.answers
})
const pageCount = computed(() => {
    return answers.value.length
})

const isLoading = computed(() => {
    return questionBasedEvaluation.fetching.answers && !answers.value.length
})
const activeAnswers = computed(() => {
    const start = (page.value - 1) * perPage.value;
    const end = start + perPage.value;
    return answers.value.slice(start, end);
}) 
const title = computed(() => {
    return questionBasedTestStore.testDetail ? questionBasedTestStore.testDetail.title : ''
}) 
</script>

<style>

</style>