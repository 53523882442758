<template>
  <v-sheet
    class=" kiwi-my-practice-page mx-auto kiwi-test-black-3 w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent d-flex flex-column align-center justify-center mt-5"
    variant="flat"
    v-if="contentView === 0"
  >
    <div class="note kiwi-fs-24 font-weight-bold text-center">
      <span>We're preparing your Practice Test</span>
    </div>
    <v-sheet class="kiwi-bg-light-grey-7 kiwi-radius-8 pa-10 text-center mx-auto d-flex flex-column align-center mt-5 justify-center" style="max-width: 500px; min-height: 200px;">
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
        <div class="mt-8 kiwi-text-light-3">This may take up a minute. Please don’t refresh the page or quit the app.</div>
    </v-sheet>
  </v-sheet>
  <div v-else-if="contentView === 1" class="d-flex fill-height w-100 flex-wrap flex-column align-center justify-center" style="max-height: calc(100vh - 200px);">
  <v-sheet
    class="kiwi-my-practice-page mx-auto kiwi-test-black-3 w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent d-flex flex-column align-center justify-center h-100"
    style="max-width: 500px; overflow-y: auto;"
    variant="flat"

  >
    <h1 class="kiwi-fs-24 font-weight-bold text-center">Mock Test</h1>
    <v-sheet class="kiwi-bg-light-grey-7 pa-4 kiwi-radius-8 kiwi-paragraphs-bundle mt-4" style="overflow-y: auto;">
        <div class="d-flex align-start">
            <figure class="icon-wrapper size-44 kiwi-bg-white mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M15.8337 1.66669V4.16669C15.8337 7.38835 13.222 10 10.0003 10M10.0003 10C6.77867 10 4.16699 7.38835 4.16699 4.16669V1.66669M10.0003 10C13.222 10 15.8337 12.6117 15.8337 15.8334V18.3334M10.0003 10C6.77867 10 4.16699 12.6117 4.16699 15.8334V18.3334" stroke="#1A1F1D" stroke-width="1.5"/>
                <path d="M3.33301 1.66669H16.6663M16.6663 18.3334H3.33301" stroke="#1A1F1D" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            </figure>
            <article class="kiwi-text-black-3">
                <div class="font-weight-bold">Timing</div>
                <p class="kiwi-text-light-black-3 kiwi-fs-14">Timing of Mock Tests are timed, but you can pause them.</p>
            </article>
        </div>
        <div class="d-flex align-start mt-5">
            <figure class="icon-wrapper size-44 kiwi-bg-white mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M9.16699 5H17.5003" stroke="#081E20" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M9.16699 10H17.5003" stroke="#081E20" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M9.16699 15H17.5003" stroke="#081E20" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M2.5 6.1607C2.5 6.1607 3.33333 6.70386 3.75 7.49998C3.75 7.49998 5 4.37498 6.66667 3.33331" stroke="#081E20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 15.3274C2.5 15.3274 3.33333 15.8706 3.75 16.6667C3.75 16.6667 5 13.5417 6.66667 12.5" stroke="#081E20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </figure>
            <article class="kiwi-text-black-3">
                <div class="font-weight-bold">Scores</div>
                <p class="kiwi-text-light-black-3 kiwi-fs-14">When you finish the mock test, click on view score to see your scores and detail analysis of the options.</p>
            </article>
        </div>
        <div class="d-flex align-start mt-5">
            <figure class="icon-wrapper size-44 kiwi-bg-white mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M8.33333 5.83331L7.90346 6.99502C7.33978 8.51831 7.05794 9.27998 6.50232 9.83565C5.94669 10.3912 5.18503 10.6731 3.66171 11.2368L2.5 11.6666L3.66171 12.0965C5.18503 12.6602 5.94669 12.9421 6.50232 13.4976C7.05794 14.0533 7.33978 14.815 7.90346 16.3382L8.33333 17.5L8.76317 16.3382C9.32692 14.815 9.60875 14.0533 10.1643 13.4976C10.72 12.9421 11.4817 12.6602 13.0049 12.0965L14.1667 11.6666L13.0049 11.2368C11.4817 10.6731 10.72 10.3912 10.1643 9.83565C9.60875 9.27998 9.32692 8.51831 8.76317 6.99502L8.33333 5.83331Z" stroke="#081E20" stroke-width="1.5" stroke-linejoin="round"/>
                <path d="M15 2.5L14.8158 2.99788C14.5742 3.65073 14.4534 3.97716 14.2152 4.21528C13.9772 4.4534 13.6507 4.57419 12.9979 4.81577L12.5 5L12.9979 5.18423C13.6507 5.42581 13.9772 5.5466 14.2152 5.78472C14.4534 6.02284 14.5742 6.34927 14.8158 7.00212L15 7.5L15.1842 7.00212C15.4258 6.34927 15.5466 6.02284 15.7847 5.78472C16.0228 5.5466 16.3493 5.42581 17.0021 5.18423L17.5 5L17.0021 4.81577C16.3493 4.57419 16.0228 4.4534 15.7847 4.21528C15.5466 3.97716 15.4258 3.65073 15.1842 2.99788L15 2.5Z" stroke="#081E20" stroke-width="1.5" stroke-linejoin="round"/>
                </svg>
            </figure>
            <article class="kiwi-text-black-3">
                <div class="font-weight-bold">Assistive Technology (AT)</div>
                <p class="kiwi-text-light-black-3 kiwi-fs-14">Mock Test includes the implementation of Assistive Technology which helps you to receive customized second module based on your performance in first module for both sections.</p>
            </article>
        </div>
        <div class="d-flex align-start mt-5">
            <figure class="icon-wrapper size-44 kiwi-bg-white mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 13.75V12.0833" stroke="#081E20" stroke-width="1.5" stroke-linecap="round"/>
                <path d="M3.55618 15.7039C3.74358 17.0958 4.89645 18.1862 6.29939 18.2507C7.4799 18.305 8.67909 18.3333 9.99967 18.3333C11.3203 18.3333 12.5194 18.305 13.6999 18.2507C15.1029 18.1862 16.2558 17.0958 16.4432 15.7039C16.5655 14.7956 16.6663 13.8647 16.6663 12.9167C16.6663 11.9687 16.5655 11.0378 16.4432 10.1294C16.2558 8.7375 15.1029 7.64707 13.6999 7.58257C12.5194 7.52831 11.3203 7.5 9.99967 7.5C8.67909 7.5 7.4799 7.52831 6.29939 7.58257C4.89645 7.64707 3.74358 8.7375 3.55618 10.1294C3.43388 11.0378 3.33301 11.9687 3.33301 12.9167C3.33301 13.8647 3.43388 14.7956 3.55618 15.7039Z" stroke="#081E20" stroke-width="1.5"/>
                <path d="M6.25 7.49999V5.41666C6.25 3.34559 7.92893 1.66666 10 1.66666C11.6328 1.66666 12.9167 2.91666 13.3333 4.16666" stroke="#081E20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </figure>
            <article class="kiwi-text-black-3">
                <div class="font-weight-bold">No Device Lock</div>
                <p class="kiwi-text-light-black-3 kiwi-fs-14">We don't lock your device during practice. On test day, you'll be blocked from using other programs or apps.</p>
            </article>
        </div>
        <div class="d-flex align-start mt-5">
            <figure class="icon-wrapper size-44 kiwi-bg-white mr-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="56" viewBox="0 0 52 56" fill="none">
                <g filter="url(#filter0_d_12621_1450)">
                <rect x="4" width="44" height="44" rx="22" fill="white"/>
                <path d="M25.9997 18.6667C29.6816 18.6667 32.6663 17.5474 32.6663 16.1667C32.6663 14.7859 29.6816 13.6667 25.9997 13.6667C22.3178 13.6667 19.333 14.7859 19.333 16.1667C19.333 17.5474 22.3178 18.6667 25.9997 18.6667Z" stroke="#081E20" stroke-width="1.25"/>
                <path d="M21.833 21.035C22.3343 21.1858 22.895 21.3098 23.4997 21.4017" stroke="#081E20" stroke-width="1.25" stroke-linecap="round"/>
                <path d="M32.6663 22C32.6663 23.3808 29.6816 24.5 25.9997 24.5C22.3178 24.5 19.333 23.3808 19.333 22" stroke="#081E20" stroke-width="1.25"/>
                <path d="M21.833 26.8683C22.3343 27.0191 22.895 27.1432 23.4997 27.235" stroke="#081E20" stroke-width="1.25" stroke-linecap="round"/>
                <path d="M32.6663 16.1667V27.8333C32.6663 29.2141 29.6816 30.3333 25.9997 30.3333C22.3178 30.3333 19.333 29.2141 19.333 27.8333V16.1667" stroke="#081E20" stroke-width="1.25"/>
                </g>
                <defs>
                <filter id="filter0_d_12621_1450" x="0" y="0" width="52" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_12621_1450"/>
                <feOffset dy="8"/>
                <feGaussianBlur stdDeviation="4"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12621_1450"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12621_1450" result="shape"/>
                </filter>
                </defs>
                </svg>
            </figure>
            <article class="kiwi-text-black-3">
                <div class="font-weight-bold">Acknowledging Our Sources</div>
                <p class="kiwi-text-light-black-3 kiwi-fs-14">We believe acknowledging sources from which the contents drawn from them. <span @click="openSource = true;" class="cursor-pointer text-decoration-underline" style="color: #1368CA;">(see sources)</span></p>
            </article>
        </div>
    </v-sheet>
    <SourceModal v-if="openSource" :dialog="openSource" @closeDialog="closeDialog" />
  </v-sheet>

  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import headsetCheck from "@/assets/headsetCheck.mp3";
import { useExamTimerInstanceStore } from "@/stores/examTimerInstance-store";
import SourceModal from '@/components/SAT/Prompt/sourcesDialog.vue'

const examTimerStore = useExamTimerInstanceStore();
const { updateIntroductionTimer } = examTimerStore;
const { contentView } = defineProps({
  contentView: {
    type: Number,
    default: 1,
  },
});
const openSource = ref(false);
const closeDialog = (status) => {
    openSource.value = status;
}
</script>
<style lang="scss">
.icon-wrapper {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 8px -4px #1018280A;

    & + article {
        flex: 1;
    }
}
.size-44 {
    width: 44px;
    height: 44px;
}
</style>
