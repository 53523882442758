import { ref } from "vue";

export function useTimer() {
  const timeLeft = ref(0);
  const timeEnded = ref(false)

  let timerInterval = null;
  const initializeTimer = (initialTime) => {
    timeLeft.value = initialTime;

    timerInterval = setInterval(() => {
      if (timeLeft.value > 0) {
        timeLeft.value -= 1;
      } else {
        timeEnded.value = true
        clearInterval(timerInterval);
       }
    }, 1000);
  };
  const stopTimer = () => {
    clearInterval(timerInterval);
  };
  return { initializeTimer, stopTimer , timeEnded , timeLeft};
}
