<template>
    <div class="contact-us-content">
        <div class="py-6">
            <v-row>
                <v-col cols="12" md="4">
                    <v-sheet
                        elevation="0"
                        class="h-100 kiwi-bg-white kiwi-text-balck-3 border kiwi-border-light-grey-3 pa-4 kiwi-radius-8 d-flex flex-wrap align-start"
                        style="grid-gap: 20px;"
                    >
                        <div class="icon-wrapper size-64 radius-circle kiwi-bg-primary-dim-50 d-flex align-center justify-center">
                            <img src="/assets/Duotone/PhoneCall.svg" alt="">
                        </div>
                        <article class="kiwi-flex-1" style="word-break: break-word;">
                            <h3 class="kiwi-fs-18 font-weight-bold">Call us now</h3>
                            <p class="kiwi-text-black-2 mt-2">Our dedicated support team is available to assist you over the phone. Dial us to get in touch.</p>
                            <a href="tel:977984XXXXXXX" class="mt-2 d-block text-decoration-none kiwi-text-black-3 font-weight-bold kiwi-fs-18">{{ configData['phone-number'] }}</a>
                        </article>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="4">
                    <v-sheet
                        elevation="0"
                        class="h-100 kiwi-bg-white kiwi-text-balck-3 border kiwi-border-light-grey-3 pa-4 kiwi-radius-8 d-flex flex-wrap align-start"
                        style="grid-gap: 20px;"
                    >
                        <div class="icon-wrapper size-64 radius-circle kiwi-bg-primary-dim-50 d-flex align-center justify-center">
                            <img src="/assets/Duotone/ChatCircleDots.svg" alt="">
                        </div>
                        <article class="kiwi-flex-1" style="word-break: break-word;">
                            <h3 class="kiwi-fs-18 font-weight-bold">Chat with us</h3>
                            <p class="kiwi-text-black-2 mt-2">Our live chat support is available during business hours to address your queries instantly.</p>
                            <a href="mailto:Support@kiwiRead.com" class="mt-2 d-block text-decoration-none kiwi-text-black-3 font-weight-bold kiwi-fs-18">{{ configData['support-email'] }}</a>
                        </article>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="4">
                    <v-sheet
                        elevation="0"
                        class="h-100 kiwi-bg-white kiwi-text-balck-3 border kiwi-border-light-grey-3 pa-4 kiwi-radius-8 d-flex flex-wrap align-start"
                        style="grid-gap: 20px;"
                    >
                        <article class="kiwi-flex-1" style="word-break: break-word;">
                            <h3 class="kiwi-fs-18 font-weight-bold">Connect with us</h3>
                            <p class="kiwi-text-black-2 mt-2">Connect with us on social media and stay updated – we love hearing from our community!</p>
                            <div class="d-flex flex-wrap mt-4" style="grid-gap: 10px;" v-if="socialIcons.length > 0">
                                <template 
                                        v-for="(icon_item, i) in socialIcons" 
                                        :key="i"
                                        >
                                    <a 
                                        :href="icon_item.link"
                                        target="_blank"
                                        v-if="icon_item.link"
                                    >
                                        <img 
                                            :src="icon_item.icon" 
                                            alt=""
                                        >
                                    </a>

                                </template>

                            </div>
                        </article>
                    </v-sheet>
                </v-col>
            </v-row>
            <div class="map-wrapper w-100 mt-6">
                <iframe 
                    :src="configData['google-maps-link']" 
                    height="450" 
                    class="w-100 rounded-lg"
                    style="border:0; object-fit: cover;" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {useConfigStore} from '@/stores/configStore'
import { storeToRefs } from 'pinia';

const config = useConfigStore()
const { getConfigData } = config
const { configData } = storeToRefs(config)

const socialIcons = ref([
            {
                link: null,
                icon: '/assets/logos_facebook.svg'
            },
            {
                link: null,
                icon: '/assets/skill-icons_instagram.svg'
            },
            {
                link: null,
                icon: '/assets/devicon_linkedin.svg'
            },
            {
                link: null,
                icon: '/assets/pajamas_twitter.svg'
            },
])

onMounted(async () => {

    if(Object.keys(configData.value).length === 0){
        await getConfigData()
        socialIcons.value = [
            {
                link: configData.value.facebook,
                icon: '/assets/logos_facebook.svg'
            },
            {
                link: configData.value.instagram,
                icon: '/assets/skill-icons_instagram.svg'
            },
            {
                link: configData.value.linkedin,
                icon: '/assets/devicon_linkedin.svg'
            },
            {
                link: configData.value.twitter,
                icon: '/assets/pajamas_twitter.svg'
            },
        ]
    }
})
</script>

<style>

</style>