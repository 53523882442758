<script setup>

import {useBookMarkeStore} from "@/stores/Sat/bookmarks-stores";
import {storeToRefs} from "pinia";


const props = defineProps({
  showGoToReview :{
    default: true
  }
})
const emits = defineEmits(['change-question'  , 'goToReviewPage'])
const bookmarkStore = useBookMarkeStore();
const {bookmarks , bookMarkedQuestions} = storeToRefs(bookmarkStore);

const changeQuestion = (index) => {
  emits('change-question' , index);
}

const goto = () =>{
 emits('goToReviewPage');
}
</script>

<template>
  <div class="box-index kiwi-fs-14 d-flex align-center justify-center">
    <div class="d-flex align-center mr-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1">
        <path d="M9.57847 14.2447C9.2894 14.5154 8.90293 14.6667 8.50073 14.6667C8.09853 14.6667 7.71213 14.5154 7.423 14.2447C4.77535 11.7507 1.22717 8.96464 2.95751 4.91981C3.89309 2.73281 6.13889 1.33337 8.50073 1.33337C10.8626 1.33337 13.1084 2.73281 14.044 4.91981C15.7721 8.95957 12.2327 11.7593 9.57847 14.2447Z" stroke="#081E20"/>
        <path d="M10.8337 7.33333C10.8337 8.622 9.78899 9.66667 8.50033 9.66667C7.21166 9.66667 6.16699 8.622 6.16699 7.33333C6.16699 6.04467 7.21166 5 8.50033 5C9.78899 5 10.8337 6.04467 10.8337 7.33333Z" stroke="#081E20"/>
      </svg>
      Current
    </div>
    <div class="d-flex align-center mr-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none" class="mr-1">
        <path d="M1.38877 13.6631C1.17067 13.5171 0.982885 13.3293 0.836869 13.1112L1.25235 12.8331C1.09296 12.595 1 12.3091 1 12V10.75H0.5V9.91667H1V7.41667H0.5V6.58333H1V4.08333H0.5V3.25H1V2C1 1.6909 1.09296 1.40502 1.25235 1.16693L0.836869 0.888769C0.982885 0.670666 1.17067 0.482885 1.38877 0.336869L1.66693 0.752353C1.90502 0.592957 2.1909 0.5 2.5 0.5H3.75V0H4.58333V0.5H7.08333V0H7.91667V0.5H10.4167V0H11.25V0.5H12.5C12.8091 0.5 13.095 0.592957 13.3331 0.752353L13.6112 0.336869C13.8293 0.482885 14.0171 0.670666 14.1631 0.888769L13.7476 1.16693C13.907 1.40502 14 1.6909 14 2V3.25H14.5V4.08333H14V6.58333H14.5V7.41667H14V9.91667H14.5V10.75H14V12C14 12.3091 13.907 12.595 13.7476 12.8331L14.1631 13.1112C14.0171 13.3293 13.8293 13.5171 13.6112 13.6631L13.3331 13.2476C13.095 13.407 12.8091 13.5 12.5 13.5H11.25V14H10.4167V13.5H7.91667V14H7.08333V13.5H4.58333V14H3.75V13.5H2.5C2.1909 13.5 1.90502 13.407 1.66693 13.2476L1.38877 13.6631Z" stroke="#C0C4C2" stroke-dasharray="3 1"/>
      </svg>
      Unanswered
    </div>
    <div class="d-flex align-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" class="mr-1">
        <mask id="mask0_12278_15920" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
          <rect x="0.5" width="16" height="16" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_12278_15920)">
          <path d="M3.83301 14V3.33333C3.83301 2.96667 3.96356 2.65278 4.22467 2.39167C4.48579 2.13056 4.79967 2 5.16634 2H11.833C12.1997 2 12.5136 2.13056 12.7747 2.39167C13.0358 2.65278 13.1663 2.96667 13.1663 3.33333V14L8.49967 12L3.83301 14Z" fill="#DB3D29"/>
        </g>
      </svg>
      For Review
    </div>
  </div>
  <v-divider />
  <div class="box-body d-flex flex-column justify-center align-center">
    <div class="d-flex flex-wrap">
      <div
        class="num-box cursor-pointer"
        v-for="(bookmark, i) in bookmarks"
        :key="i"
        :class="{'solid-border': bookmark.answered}"
        @click="changeQuestion(bookmark)"
      >
        {{bookmark.num }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="current-icon" v-if="bookmark.current">
          <path d="M9.57847 14.2447C9.2894 14.5154 8.90293 14.6667 8.50073 14.6667C8.09853 14.6667 7.71213 14.5154 7.423 14.2447C4.77535 11.7507 1.22717 8.96464 2.95751 4.91981C3.89309 2.73281 6.13889 1.33337 8.50073 1.33337C10.8626 1.33337 13.1084 2.73281 14.044 4.91981C15.7721 8.95957 12.2327 11.7593 9.57847 14.2447Z" stroke="#081E20"/>
          <path d="M10.8337 7.33333C10.8337 8.622 9.78899 9.66667 8.50033 9.66667C7.21166 9.66667 6.16699 8.622 6.16699 7.33333C6.16699 6.04467 7.21166 5 8.50033 5C9.78899 5 10.8337 6.04467 10.8337 7.33333Z" stroke="#081E20"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" class="bookmark-icon" v-if="bookmark.bookmarked">
          <mask id="mask0_12278_15920" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
            <rect x="0.5" width="16" height="16" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_12278_15920)">
            <path d="M3.83301 14V3.33333C3.83301 2.96667 3.96356 2.65278 4.22467 2.39167C4.48579 2.13056 4.79967 2 5.16634 2H11.833C12.1997 2 12.5136 2.13056 12.7747 2.39167C13.0358 2.65278 13.1663 2.96667 13.1663 3.33333V14L8.49967 12L3.83301 14Z" fill="#DB3D29"/>
          </g>
        </svg>
      </div>
    </div>
    <v-btn v-if="showGoToReview" variant="outlined" class="kiwi-btn kiwi-border-primary text-none kiwi-text-primary border mt-5" rounded="xl" @click="goto" >Go to Review Page</v-btn>
  </div>
</template>

<style>
.solid-border {
  border: 1px solid var(--color-primary); /* Solid border for answered bookmarks */
  background-color: var(--color-primary);
  color: var(--color-white) !important;
}
</style>
