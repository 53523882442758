<template>
    <v-dialog v-model="dialog" :persistent="persistent" max-width="900px">
        <v-sheet class="kiwi-modal-card d-flex flex-column kiwi-bg-white kiwi-radius-8">
            <div class="modal-header d-flex justify-space-between align-center py-5 px-4">
                <div style="max-width: 50%;">
                    <span class="kiwi-fs-18 font-weight-700 kiwi-text-black-3">Keyboard Shortcuts</span><br />
                    <span class="kiwi-text-light-black-3 kiwi-fs-14">The following keyboard shortcuts have been configured to navigate to important functions within the digital exam.</span>
                </div>
                <v-icon class="mdi mdi-close has-circular-border border-black" @click="closeDialog"></v-icon>
            </div>
            <v-divider />
            <div class="py-5 px-4 help-expansion text-center kiwi-text-light-black-3">
                <div>Keyboard Shortcuts: Mac and iPad</div>
                <v-table class="border kiwi-border-light-grey-3 kiwi-radius-8 overflow-hidden has-col-divider mt-4">
                    <thead>
                        <tr>
                            <th class="text-center kiwi-bg-light-grey-4">Action</th>
                            <th class="text-center kiwi-bg-light-grey-4">Shortcut</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Open/Close Keyboard Shortcuts</td>
                            <td>F1</td>
                        </tr>
                        <tr>
                            <td>Zoom In</td>
                            <td>Command + Plus (+) (or Pinch to Zoom)</td>
                        </tr>
                        <tr>
                            <td>Zoom Out</td>
                            <td>Command + Minus (-) (or Pinch to Zoom)</td>
                        </tr>
                        <tr>
                            <td>Zoom Back to 100%</td>
                            <td>Command + 0</td>
                        </tr>
                        <tr>
                            <td>Back</td>
                            <td>Command + CtrI + B</td>
                        </tr>
                        <tr>
                            <td>Next</td>
                            <td>Command + Ctrl + X</td>
                        </tr>
                        <tr>
                            <td>Open/Close Question Menu</td>
                            <td>Command + Ctrl + G</td>
                        </tr>
                        <tr>
                            <td>Help</td>
                            <td>Command + CtrI + H (Mac); Command + CtrI + P (iPad)</td>
                        </tr>
                        <tr>
                            <td>Open/Close Directions</td>
                            <td>Command + Ctrl + Shift + D</td>
                        </tr>
                        <tr>
                            <td>Open/Close Line Reader</td>
                            <td>Command + L</td>
                        </tr>
                        <tr>
                            <td>Hide/Show Timer or Close 5-Minute Message</td>
                            <td>Command + Option + T</td>
                        </tr>
                        <tr>
                            <td>Pause Timer (w/certain accommodations only)</td>
                            <td>Command + Option + P</td>
                        </tr>
                        <tr>
                            <td>Mark for Review</td>
                            <td>Command + Shift + V</td>
                        </tr>
                        <tr>
                            <td>Highlights & Notes</td>
                            <td>Command + H</td>
                        </tr>
                        <tr>
                            <td>Open/Close Calculator</td>
                            <td>Command + Option + C</td>
                        </tr>
                        <tr>
                            <td>Open/Close Reference Sheet</td>
                            <td>Command + Option + R</td>
                        </tr>
                        <tr>
                            <td>Select/Deselect Option A</td>
                            <td>Command + Ctrl + 1</td>
                        </tr>
                        <tr>
                            <td>Select/Deselect Option B</td>
                            <td>Command + Ctrl + 2</td>
                        </tr>
                        <tr>
                            <td>Select/Deselect Option C</td>
                            <td>Command + Ctrl + 3</td>
                        </tr>
                        <tr>
                            <td>Select/Deselect Option D</td>
                            <td>Command + Ctrl + 4</td>
                        </tr>
                        <tr>
                            <td>Select/Deselect Option E</td>
                            <td>Command + Ctrl + 5</td>
                        </tr>
                        <tr>
                            <td>Option Eliminator Mode</td>
                            <td>Command + Option + 0</td>
                        </tr>
                        <tr>
                            <td>Eliminate Option A</td>
                            <td>Command + Option + 1</td>
                        </tr>
                        <tr>
                            <td>Eliminate Option B</td>
                            <td>Command + Option + 2</td>
                        </tr>
                        <tr>
                            <td>Eliminate Option C</td>
                            <td>Command + Option + 3</td>
                        </tr>
                        <tr>
                            <td>Eliminate Option D</td>
                            <td>Command + Option + 4</td>
                        </tr>
                        <tr>
                            <td>Eliminate Option E</td>
                            <td>Command + Option + 5</td>
                        </tr>
                        <tr>
                            <td>Expand/Collapse Left</td>
                            <td>Command + Ctrl + L</td>
                        </tr>
                        <tr>
                            <td>Expand/Collapse Right</td>
                            <td>Command + Ctrl + R</td>
                        </tr>
                    </tbody>
                </v-table>
                <div class="mt-3">
                    <p class="text-left">Note: For multiselect options, use the space bar to make your selection.</p>
                    <p class="text-left pl-4">Keyboard Shortcuts for Free-Response Questions and Student-Produced Responses</p>
                </div>
                <v-table class="border kiwi-border-light-grey-3 kiwi-radius-8 overflow-hidden has-col-divider mt-4">
                    <thead>
                        <tr>
                            <th class="text-center kiwi-bg-light-grey-4">Action</th>
                            <th class="text-center kiwi-bg-light-grey-4">Shortcut</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Bold</td>
                            <td>Command + B</td>
                        </tr>
                        <tr>
                            <td>Italics</td>
                            <td>Command + I</td>
                        </tr>
                        <tr>
                            <td>Underline</td>
                            <td>Command + U</td>
                        </tr>
                        <tr>
                            <td>Superscript</td>
                            <td>Command + Ctrl + Plus (+)</td>
                        </tr>
                        <tr>
                            <td>Subscript</td>
                            <td>Command + Ctrl + Minus (-)</td>
                        </tr>
                        <tr>
                            <td>Undo</td>
                            <td>Command + Z</td>
                        </tr>
                        <tr>
                            <td>Redo</td>
                            <td>Command + Shift + Z</td>
                        </tr>
                        <tr>
                            <td>Cut</td>
                            <td>Command + X</td>
                        </tr>
                        <tr>
                            <td>Copy</td>
                            <td>Command + C</td>
                        </tr>
                        <tr>
                            <td>Paste</td>
                            <td>Command + V</td>
                        </tr>
                        <tr>
                            <td>Insert Symbols</td>
                            <td>Command + Ctrl + /</td>
                        </tr>
                        <tr>
                            <td>Focus on Editor Toolbar</td>
                            <td>Option + F10</td>
                        </tr>
                        
                    </tbody>
                </v-table>
            </div>
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';

const dialog = ref(true);
const panel = ref([]);

const props = defineProps([
    'dialog', 'persistent'
])

const emits = defineEmits(['closeDialog'])

const closeDialog = () => {
    dialog.value = false;
    emits('closeDialog', dialog.value)
}

const all = () => {
    panel.value = ['zoom', 'app-calculator', 'timer', 'highlight-note', 'line-reader', 'option-eliminator']
}

const none = () => {
    panel.value = []
}

watch(props.dialog, (val) => {
    if(val === false) {
        closeDialog();
    }
})
</script>

<style lang="scss">
.has-circular-border {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 2px solid;
    font-size: 18px;

    &.border-black {
        border-color: var(--color-black-3);
    }
}
.help-expansion {
    p + p {
        margin-top: 20px;
    }

    .v-expansion-panel-title {
        font-weight: 500;
        color: var(--color-black-3);
        min-height: auto;
    }
    .v-expansion-panel-title, .v-expansion-panel-text__wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .v-expansion-panel__shadow {
        display: none;
    }

    .v-expansion-panel {
        & + .v-expansion-panel {
            border-top: 1px solid var(--color-light-grey-3);
        }
    }
}
.has-col-divider {
    th + th, td + td {
        border-left: 1px solid var(--color-light-grey-3);
    }
}
</style>