<script setup>

</script>

<template>
<v-sheet class="kiwi-bg-white kiwi-radius-8 pa-10 text-center mx-auto d-flex flex-column align-center mt-5 justify-center mx-auto" style="max-width: 500px; min-height: 200px;">
    <div class="note kiwi-fs-24 font-weight-bold">
        <span>The Module is Over!</span>
    </div>
    <div class="mt-3 mb-5 kiwi-text-light-3">All your work has been saved. You will move on automatically just in a moment. Do not refresh the page or quit the app.</div>
    <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
    ></v-progress-circular>
</v-sheet>
</template>

<style scoped>

</style>
