<template>
    <div class="fill-height align-center justify-center d-flex">
        <v-empty-state
            headline="Opps! Something went wrong."
            text="Seems like we have encountered an error while trying to processing your request due to connectivity or gateway issues. Please try again after some time."
            image="/assets/oops.svg"
            min-height="auto"
            max-width="700"
            class="kiwi-paragraphs-bundle kiwi-text-black-2 w-100"
        >
            <!-- action-text="Go To Dashboard"
                <template v-slot:actions>
                <v-btn
                    class="kiwi-bg-primary kiwi-text-white kiwi-fs-16 text-none"
                    rounded="xl"
                    size="large"
                    :to="{name: 'dashboard'}"
                ></v-btn>
                </template> -->
        </v-empty-state>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'
import {useRoute} from 'vue-router'
const route = useRoute();
const layout = computed(() => {
   const meta =  route.meta || {};
   if(meta.requiresAuth) {
      return meta.layout || 'MainLayout'
   }else {
      return null
   }
})
</script>