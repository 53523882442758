const menus = [
    {
        label: 'Students',
        initialImgSrc: '/assets/students.png',
        activeImgSrc: '/assets/students.png',
        path: 'agent_student',
        hasChildren: false,
        svg: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="36" rx="18" fill="#7C1CA9"/>
<path d="M24.875 16.8335V20.9913C24.8754 21.144 24.8198 21.2916 24.7188 21.4062C23.8359 22.3897 21.6797 24.2499 18 24.2499C14.3203 24.2499 12.1656 22.3897 11.2813 21.4062C11.1802 21.2916 11.1246 21.144 11.125 20.9913V16.8335L17.5589 20.2646C17.8346 20.4117 18.1654 20.4117 18.4412 20.2646L24.875 16.8335Z" stroke="white"/>
<path d="M9.65901 16.0515L17.4017 20.1809C17.7749 20.3799 18.2234 20.3767 18.5937 20.1724L26.0706 16.0472C26.5014 15.8096 26.5014 15.1904 26.0706 14.9528L18.5937 10.8276C18.2234 10.6233 17.7749 10.6201 17.4017 10.8191L9.65901 14.9485C9.21783 15.1838 9.21783 15.8162 9.65901 16.0515Z" stroke="white"/>
<path d="M18 15.5L22.3566 17.8235C22.5603 17.9322 22.6875 18.1442 22.6875 18.375V26.75" stroke="white" stroke-linecap="round"/>
</svg>`
    },
    {
        label: 'Voucher',
        initialImgSrc: '/assets/vouchers.png',
        activeImgSrc: '/assets/vouchers.png',
        path: 'agent_voucher',
        hasChildren: false,
        svg: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="#7C1CA9"/>
        <path d="M25.7892 21.4739L25.214 21.9059C24.7086 22.2855 24.7494 23.0915 25.29 23.4121C26.0489 23.8621 25.7465 25.0835 24.8763 25.0835L11.1225 25.0835C10.2522 25.0835 9.94977 23.8621 10.7086 23.4121C11.2494 23.0915 11.2901 22.2855 10.7847 21.9059L10.2095 21.4739C9.48484 20.9296 9.48484 19.7929 10.2095 19.2487L10.3 19.1807C11.0689 18.6032 11.0689 17.3972 10.3 16.8196L10.2095 16.7517C9.48484 16.2074 9.48484 15.0707 10.2095 14.5264L10.7847 14.0944C11.2901 13.7148 11.2494 12.9088 10.7086 12.5882C9.94977 12.1382 10.2522 10.9168 11.1225 10.9168L24.8763 10.9168C25.7465 10.9168 26.0489 12.1382 25.29 12.5882C24.7493 12.9088 24.7086 13.7148 25.214 14.0944L25.7892 14.5264C26.5138 15.0707 26.5138 16.2074 25.7892 16.7517L25.6987 16.8196C24.9298 17.3972 24.9298 18.6032 25.6987 19.1807L25.7892 19.2487C26.5138 19.7929 26.5138 20.9296 25.7892 21.4739Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
        <path d="M15.5 15.5L20.5 20.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.5 15.5L20.5 15.5075M15.5 20.4925L15.5 20.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
    },
    {
        label: 'Order History',
        initialImgSrc: '/assets/Store.png',
        activeImgSrc: '/assets/vouchers.png',
        path: 'agent_store',
        hasChildren: false,
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" __snippetScriptLoaded__="yes">
        <rect width="36" height="36" rx="18" fill="#7C1CA9"/>
        <path d="M10.4727 16.7466V20.9147C10.4727 23.2726 10.4727 24.4515 11.2049 25.184C11.9371 25.9166 13.1156 25.9166 15.4727 25.9166H20.4727C22.8297 25.9166 24.0082 25.9166 24.7404 25.184C25.4727 24.4515 25.4727 23.2726 25.4727 20.9147V16.7466" stroke="white" stroke-width="1.25" stroke-linecap="round"/>
        <path d="M20.4727 22.1611C19.9026 22.6671 18.995 22.9945 17.9727 22.9945C16.9503 22.9945 16.0427 22.6671 15.4727 22.1611" stroke="white" stroke-width="1.25" stroke-linecap="round"/>
        <path d="M16.4211 15.0156C16.1861 15.8643 15.3315 17.3282 13.7078 17.5404C12.2741 17.7277 11.1867 17.1019 10.9089 16.8402C10.6027 16.6281 9.90477 15.9492 9.73385 15.5248C9.56292 15.1005 9.76233 14.1811 9.90477 13.8063L10.4742 12.1576C10.6132 11.7435 10.9385 10.7641 11.2721 10.4329C11.6057 10.1016 12.2811 10.0872 12.5591 10.0872H18.397C19.8997 10.1084 23.1854 10.0738 23.8349 10.0872C24.4844 10.1006 24.8747 10.645 24.9886 10.8784C25.9577 13.2258 26.3346 14.5701 26.3346 15.1429C26.2081 15.754 25.6846 16.9063 23.8349 17.4132C21.9124 17.9398 20.8225 16.9153 20.4805 16.522M15.6306 16.522C15.9013 16.8544 16.7502 17.5235 17.9808 17.5404C19.2115 17.5574 20.274 16.6988 20.6515 16.2674C20.7583 16.1401 20.989 15.7625 21.2284 15.0156" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <script xmlns=""/>
        </svg>
        `
    },
    {
        label: 'Refer your Friends',
        initialImgSrc: '/assets/promotion.svg',
        activeImgSrc: '/assets/promotion_active.svg',
        path: 'agent_referal',
        hasChildren: false,
        feature: 'referral',
        svg: `
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="none" fill="#1CA972"/>
        <path d="M20.4386 10.4257L14.8946 13.0869C14.4679 13.2917 14.012 13.3431 13.5473 13.2389C13.2431 13.1708 13.091 13.1367 12.9686 13.1228C11.4479 12.9491 10.5 14.1527 10.5 15.5367V16.2963C10.5 17.6803 11.4479 18.8839 12.9686 18.7103C13.091 18.6963 13.2432 18.6622 13.5473 18.5941C14.012 18.4899 14.4679 18.5413 14.8946 18.7461L20.4386 21.4073C21.7112 22.0183 22.3475 22.3237 23.057 22.0856C23.7664 21.8475 24.0099 21.3366 24.497 20.3148C25.8343 17.5092 25.8343 14.3239 24.497 11.5181C24.0099 10.4964 23.7664 9.98551 23.057 9.74742C22.3475 9.50934 21.7112 9.81479 20.4386 10.4257Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5485 25.3092L16.3057 26.3335C13.5043 24.1117 13.8466 23.0522 13.8466 18.8335H14.7914C15.1749 21.2176 16.0793 22.3468 17.3273 23.1643C18.096 23.6678 18.2545 24.7272 17.5485 25.3092Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.25 18.4165V13.4165" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
    },
]

export default menus