import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useEvaluationStore = defineStore("evaluationStore", () => {

  const answerList = ref([]);
  const overAll = ref({});
  const fetching = ref(false);
  const evaluation = ref([]);

  async function getResults(examId) {
    fetching.value = true;
    let url = `exam/sat/exam-instance/${examId}/final-evaluation/`;
    await api.get(url).then(res=>{
      answerList.value = res.data.questions || [];
      overAll.value = res.data.overall_answer_dict || {};
      fetching.value = false
    }).catch(error =>{
      fetching.value = false
      throw error;
    })
  }

  async function getEvaluations (id) {
    fetching.value = true;
    let url = `exam/sat/exam-instance/${id}/get-overall-score/`;
    await api.get(url).then(res=>{
      evaluation.value = res.data;
      fetching.value = false
    }).catch(error =>{
      fetching.value = false
      throw error;
    })
  }
  return { answerList , overAll, fetching ,evaluation ,getEvaluations , getResults}
});

