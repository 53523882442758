<template>
    <v-dialog v-model="dialog" persistent max-width="900px">
        <v-sheet class="w-100 kiwi-radius-4">
            <div class="w-100 pa-5">
                <div class="kiwi-fs-20 font-weight-semi-bold kiwi-text-black-3">Do You Want to Exit this Practice Test?</div>
                <p>If you exit now, we'll save your progress on this device, and you can resume this practice test any time. If you log in on another device, you'll need to start the practice test over.</p>
            </div>
            <div class="py-3 px-5 d-flex align-center flex-wrap mt-5">
                <v-spacer></v-spacer>
                <v-btn class="kiwi-button kiwi-text-primary text-none mr-2" variant="text" @click="closeDialog">Continue Practice Test</v-btn>
                <v-btn class="kiwi-button kiwi-text-black-3 kiwi-border-black-3 text-none" variant="flat" rounded="xl" @click="closeDialog" style="border: 1px solid; background-color: #FFDE5A;">Save and Exit</v-btn>
            </div>
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import {ref, computed, watch} from 'vue';

const dialog = ref(true);
const emits = defineEmits(['closeDialog'])
const props = defineProps(['dialog'])

const closeDialog = () => {
    dialog.value = false;
    emits('closeDialog', dialog.value)
}


watch(props.dialog, (val) => {
    if(val === false) {
        closeDialog();
    }
})
</script>

<style>

</style>