<template>
    <section class="">
        <v-sheet class="kiwi-paragraphs-bundle mt-5" v-html="privacyContent">
            
        </v-sheet>

    </section>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import { api } from '@/plugins/axios';

const privacyContent = ref('')

onMounted(() => {
    if(privacyContent.value == ''){
        try {
            const response = api.get('common/project-config/privacy-policy/only');
            response.then(res => {
                console.log(res.data)
                privacyContent.value = res.data['privacy-policy']
            })
        } catch(err) {
            console.log(err)
        }
    }
})
</script>

<style>

</style>