<template>
     <v-sheet
        class="kiwi-my-practice-page kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3"
        variant="flat"
    >
        <template v-if="!$route.query.topic">
        <v-sheet class="kiwi-mock-test-header py-3 px-4" variant="flat">
        <div class="d-flex flex-wrap align-center">
            <img
            src="/assets/mortarboard-02.svg"
            width="36"
            height="36"
            class="mr-2"
            />
            <span class="font-weight-bold kiwi-fs-20" style="flex: 1"
            >Individual Question Test</span
            >
        </div>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet class="pt-3 px-2" variant="flat">
            <QBTs v-for="(value, key) in topics" :key="key" class="qbt-component mt-4">
                <template v-slot:qbtsHeader>
                <img
                    :src="getImageSrc(value.title)"
                    class="mr-2"
                    alt=""
                    v-if="getImageSrc(value.title)"
                />
                <span class="kiwi-fs-18 font-weight-semi-bold"
                    :class="`kiwi-text-${value.title.toLowerCase()}`"
                >{{
                    value.title
                }}</span>
                </template>
                <div class="pa-4 pt-2">
                <v-chip
                    variant="flat"
                    class="kiwi-question-chip kiwi-bg-white border kiwi-border-light-grey-4 py-2 py-md-5 px-2 px-md-4 mt-2 mr-2 kiwi-text-black kiwi-fs-14 font-weight-medium"
                    size="small"
                    v-for="(list, i) in value.children"
                    :key="i"
                    @click="goToTests(list.id, value.id)"
                >
                    {{ list.title }}
                </v-chip>
                </div>
            </QBTs>
        </v-sheet>
        </template>
        <template v-else >
            <QuestionList />
        </template>
     </v-sheet>
</template>

<script setup>
import QuestionList from './Individual/QuestionList.vue'
import QBTs from "@/components/Dashboard/QBTsSheet.vue";
import {ref, reactive, onMounted, watch, computed} from 'vue';
import { storeToRefs } from "pinia";
import { useDashboardStore } from "@/stores/dashboard-store";
import { useRouter } from "vue-router";

const router = useRouter()
const dashboardStore = useDashboardStore();
const { dashboardData, getDashboardData } = storeToRefs(dashboardStore);

const topics = computed(() => {
  return dashboardData.value && dashboardData.value.topics !== undefined
    ? dashboardData.value.topics
    : [];
});

const getImageSrc = (title) => {
  switch (title) {
    case "Speaking":
      return "/assets/voice.svg";
    case "Reading":
      return "/assets/book-open-02.svg";
    case "Listening":
      return "/assets/listening.svg";
    case "Writing":
      return "/assets/pen-tool-03.svg";
    default:
      return null;
  }
};

const goToTests = (topicId, moduleId) => {
  router.push({
    name: 'individual-question-test',
    query: {
      topic: topicId,
      m: moduleId
    }
  })
}

onMounted(() => {
    dashboardStore.getDashboardData()
})
</script>

<style>

</style>