<template>
    <v-sheet
      class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 kiwi-bg-transparent"
      variant="flat"
    >
      <Question ref="qr" v-if="renderQuestion" :exam-type="'test'" :question="question" :payload="question.topic" :exam-id="question.id" />
      <Answer v-else :exam-type="'test'" :answer="result" :answerForIndividual="'individualQuestion'" :payload="question.topic" />
      <div class="button-group-section position-absolute" style="bottom: -55px; left: 30px;">
        <v-btn class="kiwi-bg-primary kiwi-text-white text-none" variant="flat" :loading="answerSubmitting" @click.prevent="submitAnswerForQuestion" v-if="!isSubmitted">Submit</v-btn>
        <v-btn class="kiwi-bg-light-grey-3 kiwi-text-light-black-5 text-none mr-2" :disabled="true" variant="flat" v-else-if="isSubmitted">Submitted</v-btn>
        <v-btn class="kiwi-bg-white kiwi-text-black-2 text-none kiwi-border-light-grey-3" variant="outlined" @click.native="restart" v-if="isSubmitted">Restart</v-btn>
      </div>
    </v-sheet>
</template>
<script setup>
import {useAnswerInstanceStore} from "@/stores/answerInstance-store";
import {storeToRefs} from "pinia";
import {computed, ref} from "vue";
import {useQuestionAnswer} from "@/composables/useQuestionAnswer";
import {useConfirmer} from "@/stores/confirmer";
import Question from "@/components/Question/Question.vue";
import {useIndividualQustionsTestStore} from "@/stores/individual-question-test-store";
import {useRoute} from "vue-router";
const props = defineProps({
  question: {
    type: Object,
    required: true,
  }
})
const emits = defineEmits(['isSubmitted'])
const question = computed(()=>{
  return props.question
})

const answerInstanceStore = useAnswerInstanceStore();
const individualQuestionTestStore = useIndividualQustionsTestStore();
const {questionId , answer } = storeToRefs(answerInstanceStore);
const {setQuestionId} =  answerInstanceStore;
// const isSubmitted = ref(false)
const qr = ref();
const showAnswerInQuestionInstance = ['fib-rw','mcm-r','fib-r','mcq','mcm-l','mcs-l','fib-l','hcs','smw','hiw'];
const { answerSubmitting, submitAnswer } = useQuestionAnswer();
const confirmer = useConfirmer();
const renderQuestion = ref(true);
const result = ref({});
const {currentQuestionOffset , isSubmitted} = storeToRefs(individualQuestionTestStore);
const {getIndividualQuestion , getPreviousAnswers} = individualQuestionTestStore;
const route = useRoute();

const slug = computed(() => {
  return props.question.topic_slug;
})

setQuestionId(props.question.id);

const submitAnswerForQuestion = async () => {
await  qr.value.wrapUpForSubmit();

const payload = {
  ...answer.value,
  answer_for:'question',
  answer_for_id:props.question.id,
  question:props.question.id,
  requiredResponse:true
}
try{
  const res = await submitAnswer(payload);
  if(showAnswerInQuestionInstance.includes(slug.value)){
    result.value = res
    renderQuestion.value=false
  }
  confirmer.openSnackBar({type:'success' , msg:'Answer submitted successfully'});
  getPreviousAnswers(route.params.topic_id  , question.value.id )
  isSubmitted.value = true
}catch(error){
  confirmer.openSnackBar({type:'error' , msg:'something went wrong'});
}
}
const restart = () =>{
  isSubmitted.value = false
  getIndividualQuestion(route.params.topic_id, 1, parseInt(route.params.index) , currentQuestionOffset.value);
  renderQuestion.value=true;
}
</script>
<style></style>
