<template>
    <article class="px-4 py-6 kiwi-paragraphs-bundle font-weight-medium kiwi-text-black-3">
        <strong class="kiwi-fs-24">When you arrive at the Test Center?</strong>
        <p>When you arrive at the test center you will be greeted by a PTE Test Center Administrator. They will guide you through the check-in process and make sure that you understand and are comfortable with all the rules and procedures. To confirm your identity, they will:</p>
        <ul>
            <li>request to see your ID and check the details exactly match your booking</li>
            <li>take a digital photograph of you</li>
            <li>ask for your digital signature</li>
            <li>scan the palm of your hand</li>
        </ul>
        <p>Once complete, the administrator will give you a safe place to store your personal items, and ensure you are ready for your test.</p>
        <v-divider />
        <p><strong class="kiwi-fs-24">When you arrive at the Test Center?</strong></p>
        <p>A Test Center Administrator will guide you to your test seat in a partitioned booth, which will have a computer, keyboard, audio headset, chair, and notepad and pencil.</p>
        <p>Most of our test centers are small, with around 10 seats in each center. There may be other test takers in booths near to you – and you may hear your neighbors speak as they take their test. Don’t worry – our microphones won’t pick up what they are saying, and you will be responding to different questions, so this won’t affect your test result.</p>
        <p>Your computer will use a QWERTY keyboard; if you are not used to this type of keyboard, we recommend that you practice with one before your test day. You will have two hours to complete a PTE Academic</p>
    </article>
</template>
<script setup>
</script>
