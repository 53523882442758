import { defineStore } from "pinia";
import { ref } from "vue";

export const useBookMarkeStore = defineStore("bookMarkeStore", () => {
  const bookmarks = ref([]);
  const answeredQuestions = ref([]);

  function generateBookmarks(questions) {
    bookmarks.value = questions.map((q, i) => ({
      num: i + 1,
      current: i === 0,
      bookmarked: false,
      answered:false,
      questionId: q.id
    }))

  }

  function markForBookmark(index) {
    if (index >= 0 && index < bookmarks.value.length) {
      bookmarks.value[index].bookmarked = !bookmarks.value[index].bookmarked;
    }
  }

  function setCurrentQuestionIndexForBookmark(index) {
    if (index >= 0) {
      bookmarks.value.forEach((bookmark, idx) => {
        bookmark.current = bookmark.questionId === index;
      });
    }
  }

  function setAnsweredQuestionIndexForBookmark(index) {
    const bookmark = bookmarks.value.find(bookmark => bookmark.questionId === index);
    if (bookmark) {
      bookmark.answered = true;
    }
  }

  function clearBookmarks() {
    bookmarks.value = [];
  }

  function removeCurrentIndex ()
  {
    bookmarks.value.forEach((bookmark, idx) => {
      bookmark.current = 0;
    });
  }

  return { bookmarks, generateBookmarks, markForBookmark, setCurrentQuestionIndexForBookmark,
                setAnsweredQuestionIndexForBookmark , clearBookmarks , removeCurrentIndex};
});
