<template>
    <div
        :class="plan.is_current_package ? 'kiwi-bg-gradient-4 kiwi-active-plan' : 'kiwi-border-light-grey-3'" 
        class="border kiwi-border-transparent overflow-hidden kiwi-radius-4 h-100" 
        style="padding: 1px;"
    >
        <v-card
            variant="flat"
            class="kiwi-radius-4 overflow-hidden kiwi-bg-white h-100"
            style="box-shadow: 0px 8px 30px -4px rgba(16, 24, 40, 0.04);"
        >
            <article class="kiwi-paragraphs-bundle pa-4 pa-md-4 position-relative heading-card">
                <img :src="plan.image" alt="" style="height: 57px;">
                <h2 class="font-weight-bold kiwi-fs-20">{{ plan.name }}</h2>
                <p class="font-weight-medium kiwi-text-light-black-2 kiwi-fs-14">{{ plan.description }}</p>
                <v-chip v-if="plan.is_current_package" class="kiwi-bg-upgrade kiwi-text-white position-absolute text-uppercase ma-0" size="small" style="top: 10px; right: 10px; font-size: 12px; font-weight: 600; line-height: 1;">Active</v-chip>
            </article>
            <v-divider />
            <article class="kiwi-paragraphs-bundle pa-4 pa-md-4">
                <div class="price-wrap font-weight-semi-bold">
                    
                    <div class="d-flex flex-wrap align-center">
                        <div class="currency kiwi-fs-16 mr-2">{{plan.currency}}.</div>
                        <div class="mr-2" v-if="plan.discount_remarks != null && plan.discount_amount != 0">
                            <span class="kiwi-fs-40 font-weight-bold" style="text-decoration: line-through; color: #8E9492;">{{plan.price}}</span>
                            <span class="position-relative ml-0 cursor-pointer" style="top: -20px; color: #8E9492;">
                                <v-tooltip text="Discount Via Referral">
                                    <template v-slot:activator="{ props }">
                                        <v-icon class="mdi mdi-information-outline" v-bind="props">Tooltip</v-icon>
                                    </template>
                                </v-tooltip>
                            </span>
                        </div>
                        <div>
                            <span class="kiwi-fs-40 font-weight-bold upgrade-gradient-text">{{plan.price - plan.discount_amount}}</span>
                            <sub class="kiwi-text-black-2" v-if="plan.day">{{ `${plan.day} Days` }}</sub>
                        </div>
                    </div>
                </div>
                <v-btn 
                    class="text-none font-weight-semi-bold kiwi-fs-16 py-4 py-md-5"
                    block 
                    variant="flat" 
                    v-if="!(plan.is_free && !plan.is_current_package)"
                    :class="plan.is_current_package ? 'current-button kiwi-text-black' : 'kiwi-bg-primary kiwi-text-white'"
                    :disabled="plan.is_current_package ? true : false"
                    @click.prevent="(!plan.is_current_package && !plan.is_free) ? checkout(plan) : ''"
                >{{ plan.is_current_package ? 'Your Current Plan' : 'Upgrade Now' }}</v-btn>
            </article>
            <v-divider />
            <article class="pa-4 pa-md-4 kiwi-fs-14">
                <p>What's included:</p>
                <v-list lines="1" class="kiwi-pricing-feature-list" density="compact">
                    <v-list-item
                            v-for="(item, i) in plan.features"
                            :key="i"
                            color="primary"
                            class="px-0 cursor-normal py-0"
                            :title="item.name"
                            :ripple="false"
                        >
                            <template v-slot:prepend>
                                <img src="/assets/checkmark-circle-01.svg" class="mr-2 size-18">
                            </template>
                        </v-list-item>
                </v-list>
            </article>
        </v-card>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { usePackageStore } from '@/stores/package-store';
const active = true;
const router = useRouter();
const props = defineProps({
    plan: Object,
})
const packageData = usePackageStore()
const { createTransaction } = packageData


// const planImg = ref({
//     free: '/assets/openmoji_light-bulb.svg',
//     pro: '/assets/fluent-emoji_rocket.svg',
//     premium: '/assets/basil_diamond-outline.svg',
// })

const checkout = (plan) => {
    createTransaction(plan.id).then(res => {
    router.push({name: 'checkout', params: {uuid: res.uuid}})
    })
}
</script>
