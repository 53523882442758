<template>
  <v-card min-width="300" style="max-height: calc(100vh - 100px)">
    <div
      class="d-flex flex-wrap align-center justify-space-between pa-4 kiwi-bg-light-grey-5"
    >
      <div class="font-weight-bold kiwi-fs-18 kiwi-text-black-3">
        Notifications
      </div>
    </div>
    <v-divider />
    <v-infinite-scroll class="notification-container" max-height="430" @load="load">
      <template v-for="(item, index) in notifications" :key="item.id">
        <v-list-item
          :prepend-avatar="item.image"
          class="align-start d-flex"
          :style="item.is_seen ? { opacity: 0.75 } : { opacity: 1 }"
          :data-id="item.id"
        >
          <v-list-title class="font-weight-semi-bold">
            {{ item.title }}
          </v-list-title>
          <v-list-item-subtitle
            class="my-1 kiwi-text-light-black-2"
            style="opacity: 1; line-height: 1.25"
          >
            <div v-html="item.content"></div>
          </v-list-item-subtitle>
          <v-list-item-action style="opacity: 1">
            <Time
              :time="item.created"
              class="kiwi-fs-14 kiwi-text-black-3 font-weight-medium"
            />
          </v-list-item-action>
        </v-list-item>
      </template>
      <template v-slot:empty>
        <div class="kiwi-text-light-black-2 font-weight-bold">No more items!</div>
      </template>
    </v-infinite-scroll>
  </v-card>
</template>
<script setup>
import Time from "@/components/TimeFormat/TimeFormat.vue";
import { api } from "@/plugins/axios";
import { ref, computed, watch, onMounted, nextTick, onUnmounted } from "vue";
import { useNotificationStore } from "@/stores/notification";
const notification = useNotificationStore();
const { markAsSeen , fetchUnseenCount } = notification;
const { fetchNotificationData } = notification;
const loading = ref(false);

const notifications = computed(() => {
  return notification.notificationList;
});

const notificationData = computed(() => {
  return notification.notificationData;
});

const mark = (notifications) => {
  notifications.forEach((notification) => {
    if (!notification.is_seen) {
      markAsSeen(notification.id);
      fetchUnseenCount();
    }
  });
};

const load = async ({ done }) => {
  if (loading.value) {
    return;
  }

  loading.value = true;

  let nextPage = notificationData.value.next;

  if (nextPage !== null) {
    await fetchNotificationData(nextPage);
    done("ok");
  } else {
    done("empty");
  }
  loading.value = false;
};
watch(
  notification,
  (newVal) => {
    mark(newVal.notificationData.results);
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss" scoped>
.notification-container {
  .v-list-item + .v-list-item {
    border-top: 1px solid var(--color-light-grey-3);
    padding-top: 10px;
    margin-top: 10px;
  }
}
</style>