<template>
  <Card :handleControl="calculatorControl" class="h-100 align-center microphone">
    <article class="kiwi-max-w-100">
      <div class="title kiwi-fs-20 font-weight-bold mb-2">
        {{ calculatorContent.title }}
      </div>
    </article>
    <div ref="calculatorElement" class="desmos-calculator"></div>
  </Card>
</template>

<script setup>
import Card from "@/components/Dashboard/ContentSheet.vue";
import { onBeforeUnmount, onMounted, ref } from "vue";

const calculatorControl = ref({
  bg: "black",
  textColor: "white",
  mode: "calculator",
});

const calculatorContent = ref({
  title: "Calculator",
});

const calculatorElement = ref(null)
let calculator = null
let calculatorOptions = {
  expressions: true,
  keypad: true,
  settingsMenu: true
};

const initDesmos = () => {
  if(calculatorElement.value){
    calculator = Desmos.GraphingCalculator(calculatorElement.value, calculatorOptions);
  }
}

onMounted(() => {
  initDesmos()
})

onBeforeUnmount(() => {
  if(calculator){
    calculator.destroy()
  }
})
</script>

<style>
.desmos-calculator {
  width: 100%;
  height: 400px;
}
</style>
