import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref, reactive } from "vue";

export const useMockTestEvaluationStore = defineStore("mockTestEvaluation", () => {
  const fetching = ref({
    topic: false,
    result: false,
    answers: false
  })
  const topics = ref([])
  const answers = ref([])
  const result = ref({})
  const examId = ref(null)

  async function getTopics() {
    fetching.value.topic = true
    let url = `exam/exam-instance/${examId.value}/get-topics/`;
    await api.get(url).then(res=>{
        topics.value = res.data
        fetching.value.topic = false
    }).catch(error =>{
        fetching.value.topic = false
        throw error;
    })
  }
  async function getResults() {
    fetching.value.result = true
    let url = `exam/exam-instance/${examId.value}/get-result/`;
    await api.get(url).then(res=>{
        result.value = res.data || {}
        fetching.value.result = false
    }).catch(error =>{
        fetching.value.result = false
        throw error;
    })
  }
  async function getAnswers(topic = null) {
    fetching.value.answers = true
    let url = `exam/exam-instance/${examId.value}/get-individual-result`;
    answers.value = []
    const res = await api.get(url, {params: {topic: topic}}).then(res=>{
        answers.value = res.data
        fetching.value.answers = false
    }).catch(error =>{
        fetching.value.answers = false
        throw error
    })
    return res;
  }
  function getEvaluation (id)
  {
    examId.value = id 
    getResults()
    getTopics()
  }
  return {getEvaluation, fetching, topics, answers, result, getAnswers}
});
