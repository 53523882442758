// useMicrophonePermission.js
import { ref } from "vue";
import { usePermission } from "@vueuse/core";
import { context, Player, Recorder, UserMedia } from "tone";
import  beep  from "@/assets/beep.mp3";

export function useMicrophone() {
  const microphoneAccess = ref(usePermission("microphone"));
  const requestMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      microphoneAccess.value = "granted";
    } catch (error) {
      microphoneAccess.value = "denied";
    }
  };

  const micSupported = UserMedia.supported;
  const playbackSupported = context.state === "running";

  const recording = ref(false);
  const initialized = ref(false);
  const playing = ref(false);
  const file = ref("");
  let mic, recorder, player;

  const toggleRecording = async () => {
    if (
      microphoneAccess.value === "denied" ||
      microphoneAccess.value === "prompt"
    ) {
      requestMicrophonePermission();
      return;
    }
    playing.value = false;
    context.resume();
    if (!initialized.value) {
      mic = new UserMedia();
      recorder = new Recorder();
      mic.connect(recorder);
      mic.open();
      initialized.value = true;
    }
    if (!recording.value) {
      recorder.start();
    } else {
      const data = await recorder.stop();
      file.value = data;
      const blobUrl = URL.createObjectURL(data);
      player = new Player(blobUrl).toDestination();
      player.onstop = () => {
        playerStoped();
      };
    }
    recording.value = !recording.value;
  };
  const togglePlayback = () => {
    if (player && player.loaded) {
      if (player.state === "started") {
        player.stop();
        playerStoped();
      } else {
        player.start();
        playing.value = true;
      }
    }
  };
  const playBeep = () => {
    let beepPlayer = new Player(beep).toDestination();
    beepPlayer.autostart = true
  };

  const stopPlayer = () => {
    if (player && player.loaded) {
      if (player.state === "started") {
        player.stop();
        playerStoped();
      }
    }
  };
  function playerStoped() {
    playing.value = false;
  }
  function getAudioDuration(blob) {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      const objectURL = URL.createObjectURL(blob);
  
      audio.preload = 'metadata'; // Load only metadata
      audio.src = objectURL;
  
      audio.addEventListener('loadedmetadata', () => {
        if (audio.duration === Infinity) {
          // Force load more data if necessary
          audio.currentTime = 1e101; // Seek to a large time to force duration calculation
          audio.addEventListener('timeupdate', () => {
            audio.currentTime = 0; // Reset time after getting duration
            resolve(audio.duration);
            URL.revokeObjectURL(objectURL); // Clean up
          }, { once: true });
        } else {
          resolve(audio.duration);
          URL.revokeObjectURL(objectURL); // Clean up
        }
      });
  
      audio.addEventListener('error', (err) => {
        reject(err);
      });
    });
  }
  
  return {
    microphoneAccess,
    requestMicrophonePermission,
    toggleRecording,
    togglePlayback,
    micSupported,
    recording,
    playing,
    stopPlayer,
    file,
    playBeep,
    getAudioDuration
  };
}
