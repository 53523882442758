<template>
    <div>
        <article class="pa-4 kiwi-bg-light-grey-7 rounded-lg kiwi-text-black-3 mb-4">
            <div class="answer">
                <div class="d-flex flex-wrap align-center justify-space-between" v-if="index">
                    <h2 class="font-weight-semi-bold kiwi-text-black-3 kiwi-fs-20">{{index}}. {{question.topic_slug.toUpperCase()}} #{{ n  }}</h2>
                    <v-chip variant="outlined" @click="viewDetail(answer.id)" class="cursor-pointer kiwi-border-lightest-grey-2 font-weight-semi-bold bg-white" size="large">
                        <span class="kiwi-text-black-3 mr-1">Score details:</span> <span class="kiwi-text-marigold">{{parseFloat(answer.obtained_marks)}}/{{ parseFloat(answer.full_marks) }}</span>
                    </v-chip>
                </div>
              <QText
                :text="question.header_text || (payload ? payload.header_text : '') "
                :headline="true"
                v-if="answerForIndividual"
                />
                <v-divider class="my-4" />
                <div v-if="slug === 'ra'">
                    <AText :text="question.question_text" />
                    <AHeadline :text="SAText" />
                    <APlayer :source="question.answer_audio" />
                    <AHeadline :text="YAText" />
                    <APlayer :source="answer.answer_audio_file" />
                </div>
                <div v-else-if="slug === 'rs'">
                    <AHeadline :text="QLabel" />
                    <APlayer :source="question.question_audio" />
                    <AHeadline :text="SAText" />
                    <AText :text="question.answer_text" />
                    <AHeadline :text="YAText" />
                    <APlayer :source="answer.answer_audio_file"  />
                </div>
                <div v-else-if="slug === 'di'">
                    <AImage :source="question.question_image" />
                    <AHeadline :text="SAText" />
                    <AText :text="question.answer_text" />
                    <AHeadline :text="YAText" />
                    <APlayer :source="answer.answer_audio_file"  />
                </div>
                <div v-else-if="['rl', 'asq'].includes(slug)">
                    <AHeadline :text="QLabel" />
                    <APlayer :source="question.question_audio" />
                    <AHeadline :text="SAText" />
                    <AText v-if="slug === 'asq'" :text="(question.answer_list || []).join(', ')" />
                    <AText v-else :text="question.answer_text" />
                    <AHeadline :text="YAText" />
                    <APlayer :source="answer.answer_audio_file"  />
                </div>
                <div v-else-if="['we', 'swt'].includes(slug)">
                    <AHeadline :text="question.question_text" />
                    <AHeadline :text="SAText" />
                    <AText :text="question.answer_text" />
                    <AHeadline :text="YAText" />
                    <AText :text="answer.answer_text" />
                </div>
                <div v-else-if="slug === 'fib-rw'">
                    <AFillInTheBlanks :text="question.question_text" :options="question.options" :obtained="answer.marks_obtained_json || []" :slug="slug" :answer-for="props.answerForIndividual"/>
                </div>
                <div v-else-if="slug === 'mcm-r'" class="kiwi-fs-15 mcm-r">
                  <div v-if="props.answerForIndividual && props.answerForIndividual === 'individualQuestion'">
                    <v-row>
                      <v-col cols="12" md="6">
                        <QText :text="question.question_text" :dictionaryCondition="true" />
                      </v-col>
                      <v-col cols="12" md="6">
                        <QText :text="question.sub_question_text" :headline="true" :dictionaryCondition="true" />
                        <AMultiChoice :list-view-with-select="true" :options="question.options || []" :obtained="answer.marks_obtained_json || []" :dictionaryCondition="true"/>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else-if="props.answerForIndividual && props.answerForIndividual !== 'individualQuestion'">
                    <v-row>
                      <v-col cols="12" md="6">
                        <QText :text="question.question_text" />
                      </v-col>
                      <v-col cols="12" md="6">
                        <QText :text="question.sub_question_text" :headline="true" />
                        <AMultiChoice :list-view-with-select="true" :options="question.options || []" :obtained="answer.marks_obtained_json || []"/>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <AText :text="question.question_text" />
                    <AHeadline :text="QLabel" />
                    <AText :text="question.sub_question_text" />
                    <AMultiChoice  :options="question.options || []" :obtained="answer.marks_obtained_json || []"/>
                  </div>
                </div>
                <div v-else-if="slug === 'ro'">
                    <AReorder :options="question.options || []" :correct="answer.correct_answer_list || []" :selected="answer.answer_list || []" />
                </div>
                <div v-else-if="slug === 'fib-r'">
                    <AFillInTheBlanks :text="question.question_fill_in_the_blanks" :slug="slug" :options="question.question_list" :obtained="answer.marks_obtained_json || []" :answer-for="props.answerForIndividual"/>
                </div>
                <div v-else-if="slug === 'mcq'">
                  <div v-if="props.answerForIndividual && props.answerForIndividual === 'individualQuestion'">
                    <v-row>
                      <v-col cols="12" md="6">
                        <QText :text="question.question_text" :dictionaryCondition="true" />
                      </v-col>
                      <v-col cols="12" md="6">
                        <QText :text="question.sub_question_text" :headline="true" :dictionaryCondition="true" />
                        <ASingleChoice :list-view-with-select="true" :options="question.options || []" :obtained="answer.marks_obtained_json || []" :dictionaryCondition="true" />
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else-if="props.answerForIndividual && props.answerForIndividual !== 'individualQuestion'">
                    <v-row>
                      <v-col cols="12" md="6">
                        <QText :text="question.question_text" />
                      </v-col>
                      <v-col cols="12" md="6">
                        <QText :text="question.sub_question_text" :headline="true" />
                        <ASingleChoice :list-view-with-select="true" :options="question.options || []" :obtained="answer.marks_obtained_json || []" />
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <AText :text="question.question_text" />
                    <AHeadline :text="QLabel" />
                    <AText :text="question.sub_question_text" />
                    <ASingleChoice  :options="question.options || []" :obtained="answer.marks_obtained_json || []"/>
                  </div>
                </div>
                <div v-else-if="['sst', 'wfd'].includes(slug)">
                    <AHeadline :text="QLabel" />
                    <APlayer :source="question.question_audio" />
                    <AHeadline :text="SAText" />
                    <AText :text="question.answer_text"/>
                    <AHeadline :text="YAText" />
                    <AText :text="answer.answer_text"/>
                </div>
                <div v-else-if="['hcs', 'mcs-l', 'mcm-l', 'smw'].includes(slug)">
                  <div v-if="props.answerForIndividual">
                    <AHeadline :text="question.question_text" />
                    <v-row>
                      <v-col cols="12" md="6">
                        <Player ref="player" />
                      </v-col>
                      <v-col cols="12" md="6">
                        <template v-if="answerForIndividual === 'individualQuestion'">
                          <QText :text="question.sub_question_text" :dictionaryCondition="true" />
                          <AMultiChoice :options="question.options || []" :obtained="answer.marks_obtained_json || []" v-if="['mcm-l'].includes(slug)" :list-view-with-select="true" :dictionaryCondition="true"/>
                          <ASingleChoice :options="question.options || []" :obtained="answer.marks_obtained_json || []" v-else  :list-view-with-select="true" :dictionaryCondition="true" />
                        </template>
                        <template v-else>
                          <QText :text="question.sub_question_text" />
                          <AMultiChoice :options="question.options || []" :obtained="answer.marks_obtained_json || []" v-if="['mcm-l'].includes(slug)" :list-view-with-select="true"/>
                          <ASingleChoice :options="question.options || []" :obtained="answer.marks_obtained_json || []" v-else  :list-view-with-select="true"/>

                        </template>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <APlayer :source="question.question_audio"  />
                    <AHeadline :text="QLabel" v-if="question.question_text" />
                    <AText :text="question.question_text"/>
                    <AMultiChoice :options="question.options || []" :obtained="answer.marks_obtained_json || []" v-if="['mcm-l'].includes(slug)"/>
                    <ASingleChoice :options="question.options || []" :obtained="answer.marks_obtained_json || []" v-else />
                  </div>
                </div>
                <div v-else-if="slug === 'hiw'">
                    <APlayer v-if="!props.answerForIndividual" :source="question.question_audio" />
                    <Player  v-else ref="player" />
                    <AHeadline v-if="!answerForIndividual" :text="QLabel"/>
                    <template v-if="props.answerForIndividual === 'individualQuestion'">
                      <AHighlight :text="question.question_text" :obtained="answer.marks_obtained_json || []" :dictionaryContent="true" />
                    </template>
                    <AHighlight :text="question.question_text" :obtained="answer.marks_obtained_json || []" v-else />
                </div>
                <div v-else-if="slug === 'fib-l'">
                    <APlayer  v-if="!props.answerForIndividual" :source="question.question_audio" />
                    <AFillInTheBlanks :text="question.question_fill_in_the_blanks" :slug="slug" :options="question.options" :obtained="answer.marks_obtained_json || []" :answer-for="props.answerForIndividual" />
                </div>
            </div>
        </article>
<!--        <AnswerScore  :answer="answer" v-if="detail" @handleClose="detail = null && n" :index="n" :chapter="question.topic_slug.toUpperCase()" />-->
        <AnswerScore v-if="detail" :content="answer" :index="index"  @handleClose="detail = false"/>
    </div>
</template>
<script setup>
import {defineProps, ref, computed, nextTick} from "vue";
import APlayer from "@/components/Evaluation/MockTests/Answers/APlayer.vue";
import AImage from "@/components/Evaluation/MockTests/Answers/AImage.vue";
import AText from "@/components/Evaluation/MockTests/Answers/AText.vue";
import AHeadline from "@/components/Evaluation/MockTests/Answers/AHeadline.vue";
import AFillInTheBlanks from "@/components/Evaluation/MockTests/Answers/AFillInTheBlanks.vue";
import AMultiChoice from "@/components/Evaluation/MockTests/Answers/AMultiChoice.vue";
import ASingleChoice from "@/components/Evaluation/MockTests/Answers/ASingleChoice.vue";
import AReorder from "@/components/Evaluation/MockTests/Answers/AReorder.vue";
import AHighlight from "@/components/Evaluation/MockTests/Answers/AHighlight.vue";
import AnswerScore from "@/components/Evaluation/IndividualQuestionTest/AnswerScore.vue";
import QText from "@/components/MockTest/QText.vue";
import Player from "@/components/MockTest/Player.vue";
import {useIndividualQustionsTestStore} from "@/stores/individual-question-test-store";

const individualStore = useIndividualQustionsTestStore();
const {getScoreDetails } = individualStore
const props = defineProps({
  index: {
    // required: true
  },
  n: {},
  answer: {
    type: Object,
    required: true
  },
  answerForIndividual:{
    type: String,
    required: false,
    default:null
  },
  payload:{
    default:null
  }
});
const player = ref();
nextTick(() => {
  player.value.updateProgressStatus();
})

const question = computed(() => {
    return props.answer.question_data
})
const answer = computed(() => {
    return props.answer
})
const slug = computed(() => {
    return props.answer.question_data.topic_slug
})
const detail = ref(false)
const viewDetail = async (answerId) => {
    await getScoreDetails(answerId);
    detail.value = true
}
const SAText = ref('Sample Answer: ')
const YAText = ref('Your Answer: ')
const QLabel = ref('Question: ')
</script>
