<template>
    <v-card 
        variant="flat" 
        color="transparent"
        :class="[
            mode
        ]"
    >
        <template v-if="mode === 'single'">
            <v-row>
                <v-col cols="12">
                    <img
                        class="blog-media-control blog-feature-image" 
                        :class="[`kiwi-radius-8`]"
                        :src="blogContent?.image" 
                        :alt="blogContent?.title != null ? blogContent?.title : ''" 
                        @click.prevent="OpenBlog(blogContent?.title_slug)"
                    />
                </v-col>
                <v-col cols="12">
                    <Article
                        v-if="blogContent"
                        :isBlogContent="true" 
                        :content="blogContent"
                        :mode="mode"
                    />
                </v-col>
            </v-row>
        </template>
        <template v-else-if="mode === 'two-column'">
            <v-row class="align-center">
                <v-col cols="12" md="6">
                    <img
                        class="blog-media-control blog-feature-image"
                        :class="`kiwi-radius-8`" 
                        :src="blogContent?.image" 
                        :alt="blogContent?.title != null ? blogContent.title : ''" 
                        @click.prevent="OpenBlog(blogContent?.title_slug)"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <Article
                        v-if="blogContent"
                        :isBlogContent="true" 
                        :content="blogContent"
                        :mode="mode"
                    />
                </v-col>
            </v-row>
        </template>
    </v-card>
</template>

<script setup>
import {ref} from 'vue'
import Article from './ArticleFormatCard1.vue'
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const router = useRouter();
const props = defineProps({
    mode: {
        type: String,
        default: 'single'
    },
    blogContent: {
        required: true,
    }
})

const OpenBlog = (slug) => {
    router.push(`/blog/${slug}`)
}

</script>

<style>

</style>