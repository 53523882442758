// authService.js
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';
import firebaseApp from '@/plugins/firebase';
import {api, apiWithoutAuth} from '@/plugins/axios'
// import Vue from 'vue';
import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
const app = createApp({});
app.use(VueCookies)
const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();
const fbprovider = new FacebookAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    return result.user;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const signInWithFacebook = async () => {
  try {
    const result = await signInWithPopup(auth, fbprovider);
    return result.user;
  } catch (error) {
    throw new Error(error.message);
  }
};

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const VERIFY_TOKEN_KEY = 'register_token';
const USER_EMAIL = 'kiwi_email';
export const AuthTokenService = {
  setCookie(key, value, time = null){
    time !== null ? app.config.globalProperties.$cookies.set(key, value, time) : app.config.globalProperties.$cookies.set(key, value)
  },
  setTokens(accessToken, refreshToken, rememberMe) {
    // if (rememberMe) {
      this.setCookie(ACCESS_TOKEN_KEY, accessToken); // Store access token in cookie for 7 days
      this.setCookie(REFRESH_TOKEN_KEY, refreshToken); // Store refresh token in cookie for 7 days
    // } else {
    //   sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken); // Store access token in session storage
    //   sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken); // Store refresh token in session storage
    // }
  },
  setVerifyToken(verifyToken) {
      this.setCookie(VERIFY_TOKEN_KEY, verifyToken); // Store access token in cookie for 7 days
  },
  setEmailCookie(email) {
      this.setCookie(USER_EMAIL, email); // Store access token in cookie for 7 days
  },
  getEmail() {
    return app.config.globalProperties.$cookies.get(USER_EMAIL) || sessionStorage.getItem(USER_EMAIL);
  },
  getAccessToken() {
    return app.config.globalProperties.$cookies.get(ACCESS_TOKEN_KEY) || sessionStorage.getItem(ACCESS_TOKEN_KEY);
  },
  getRefreshToken() {
    return app.config.globalProperties.$cookies.get(REFRESH_TOKEN_KEY) || sessionStorage.getItem(REFRESH_TOKEN_KEY);
  },
  getVerifyToken() {
    return app.config.globalProperties.$cookies.get(VERIFY_TOKEN_KEY) || sessionStorage.getItem(VERIFY_TOKEN_KEY);
  },
  getCookie(cookie_key) {
    return app.config.globalProperties.$cookies.get(cookie_key) || sessionStorage.getItem(cookie_key);
  },
  removeCookie(cookie_key) {
    app.config.globalProperties.$cookies.remove(cookie_key);
  },
  redirectToDashboardIfLoggedIn() {
    const accessToken = app.config.globalProperties.$cookies.get(ACCESS_TOKEN_KEY);

    if (accessToken) {
      // Access token found, redirect to dashboard
      // Replace '/dashboard' with your actual dashboard route
      window.location.href = '/dashboard';
    }
  },
  clearTokens(mode = null) {
    localStorage.removeItem('rememberMe')
    app.config.globalProperties.$cookies.remove('access_token');
    app.config.globalProperties.$cookies.remove(VERIFY_TOKEN_KEY);
    app.config.globalProperties.$cookies.remove('refresh_token');
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    if(mode !== 'switch') {
      localStorage.removeItem('SWITCH_TOKEN')
    }
  },
  logout(mode = null) {
    this.clearTokens(mode);
  },
  isAuthenticated(){
    let token = this.getAccessToken();
    return token !== null && token !== undefined;
  },
  refreshToken()
  {
    try {
      api.post('user/token/refresh/',{refresh:this.getRefreshToken()}).then((res) => {
        return res
      });
    } catch (error) {
      throw error.response.data
    }
  },
  redirectToLogin()
  {
    if (window.location.pathname !== "/login") {
      window.location.href = '/login';
    }
  }
};

