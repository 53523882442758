<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="30000"
    :color="color"
    location="top"
    right
  >
    {{ message }}
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click="closeSnackbar"
      >
        x
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref } from 'vue';

const snackbar = ref(true);
const message = ref('5 minutes left in this part of the test.');
const color = ref('');

const closeSnackbar = () => {
  snackbar.value = false;
};
</script>

<style scoped>

</style>
