import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref } from "vue";
export const useDictionaryStore = defineStore('dictionaryStore' , () =>{
  const baseUrl  = `https://api.dictionaryapi.dev/api/v2/entries/en/`;
  const dictionaryData = ref({})
  const meanings = ref([])
  const getDictionary = async (word) => {
    const url = `${baseUrl}${word}`;
    await api.get(url).then((res)=>{
      dictionaryData.value  = res;
      meanings.value = res[0].meanings;
    }).catch(error=>{
      dictionaryData.value = {}
      meanings.value = [];
    })
  };
  return {getDictionary , dictionaryData , meanings}
})
