<template>
    <v-sheet class="expansion-shrink-card kiwi-radius-8 overflow-hidden kiwi-border-light-grey-3 w-100 position-fixed" style="border: 1px solid; top: 100px; height: calc(100vh - 30vh);" :style="[{maxWidth: toggleWidth ? `800px` : `400px`}, defineLocation]">
        <div class="content-header py-2 px-3 kiwi-bg-black-3 kiwi-text-white">
            <v-row class="align-center">
                <v-col class="text-left">
                    {{title}}
                </v-col>
                <v-col class="text-center">
                    <v-icon class="mdi mdi-dots-grid" />
                </v-col>
                <v-col class="d-flex align-center justify-end">
                    <button class="mr-2 d-inline-flex align-center justify-center" @click="toggleWidthModule" style="width: 24px; height: 24px;" :style="toggleWidth ? {border: `1px solid var(--color-white)`, borderRadius: `4px`} : {}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M11.5817 8.94482C10.9493 8.31244 11.0545 5.62637 11.0545 5.62637M11.5817 8.94482C12.2139 9.57708 14.9 9.47172 14.9 9.47172M11.5817 8.94482L16 4.52637M9.41442 11.1119C8.78217 10.4796 6.09605 10.5848 6.09605 10.5848M9.41442 11.1119C10.0467 11.7442 9.94144 14.4303 9.94144 14.4303M9.41442 11.1119L5 15.5264" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <slot name="closeModal" />
                </v-col>
            </v-row>
        </div>
        <div class="content-body pa-1" style="height: calc(100% - 50px); overflow-y: auto;">
            <slot></slot>
        </div>
    </v-sheet>
</template>

<script setup>
import {ref, computed, onMounted, watch} from 'vue';

const toggleWidth = ref(false);
const dialog = ref(true);
const props = defineProps(['title', 'location'])
const emit = defineEmits(['expansionWidth'])

const defineLocation = computed(() => {
    return props.location === 'left' ? {left: `20px`} : {right: `20px`}
})

const toggleWidthModule = () => {
    toggleWidth.value = !toggleWidth.value;
    emit('expansionWidth', toggleWidth.value)
}
</script>

<style lang="scss">

</style>