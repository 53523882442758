import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref , reactive } from "vue";

export const useQustionsBasedTestStore = defineStore("questionBased", () => {
  const questionsBasedTests = ref([]);
  const total = ref(0);
  const topics = ref([]);
  const fetching = ref({
    tests: false
  });
  const pagination = reactive({
    pageSize: 10,
    page: 1,
  });
  function getQuestionBasedTests(topicId) {
    fetching.value.tests = true
    let url = `exam/scheduled-test/?page_size=${pagination.pageSize}&page=${pagination.page}&topic=${topicId}`;
    api
      .get(url)
      .then((res) => {
        const response = res.data;
        const {results, ...pagination} = response
        total.value = response.count;
        questionsBasedTests.value = results;
        fetching.value.tests = false
      })
      .catch((error) => {});
  }
  function getAllTopics ()
  {
    let url = `question/topic/?fields=children`;
    api
      .get(url)
      .then((res) => {
        topics.value = res.data?.results;
      })
      .catch((error) => {});
  }
  return { questionsBasedTests, getQuestionBasedTests, total, pagination, topics, getAllTopics, fetching};
});
