<script setup>
import Bookmarks from "@/components/SAT/MockTest/Bookmarks.vue";
const emits = defineEmits(['change-question']);
const changeQuestion = (index) => {
  emits('change-question' , index);
}
</script>

<template>
  <div class="review-section-module d-flex flex-column align-center justify-center fill-height">
      <article class="text-center intro-article-box kiwi-paragraphs-module kiwi-text-black-3" style="max-width: 750px;">
          <p class="title kiwi-fs-24 font-weight-bold">Check your work!</p>
          <p>On the test day you wont be able to move to the text module until the time expires. For the practice questions, you can click <span class="font-weight-bold">Next</span> when you are ready to move on.</p>
      </article>
      <div class="bookmark-pagination-box-r mt-5">
        <Bookmarks @change-question="(val) => changeQuestion(val)" :show-go-to-review="false" />
      </div>
  </div>
</template>

<style lang="scss">
.bookmark-pagination-box-r {
  //position: absolute;
  //top: calc(-100% - 248px);
  //left: 50%;
  //transform: translateX(-50%);
  max-width: 500px;
  // min-height: 322px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 60px -4px #10182814;
  padding: 0 20px;

  .box-header, .box-body, .box-index {
    padding: 20px 0;
  }

  .box-body {
    margin: -6px;
  }

  .num-box {
    position: relative;
    border: 1px dashed var(--color-black-3);
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-primary);
    margin: 6px;
    .current-icon, .bookmark-icon {
      position: absolute;
    }
    .current-icon {
      top: -18px;
      left: 50%;
      transform: translateX(-50%);
    }
    .bookmark-icon {
      top: -6px;
      right: -6px;
    }
  }
  .solid-border {
    border: 1px solid var(--color-primary); /* Solid border for answered bookmarks */
  }
}
</style>
