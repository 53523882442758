<template>
    <v-dialog v-model="dialog" max-width="900px" scrollable>
        <v-sheet class="kiwi-modal-card d-flex flex-column kiwi-bg-white kiwi-radius-8">
            <div class="modal-header d-flex justify-space-between align-center py-5 px-4">
                <div style="max-width: 50%;">
                    <span class="kiwi-fs-20 font-weight-bold kiwi-text-black-3">Acknowledging our sources</span><br />
                    <span class="kiwi-text-light-black-3 kiwi-fs-14">Content drawn from the following resources</span>
                </div>
                <v-icon class="mdi mdi-close has-circular-border border-black" @click="closeDialog"></v-icon>
            </div>
            <div class="py-5 px-4">
                <div class="py-5 px-4 help-expansion kiwi-text-black-3 kiwi-radius-8" style="background-color: #F7F8F9;">
                    <ul style="padding-left: 30px;" class="kiwi-fs-14 font-weight-medium kiwi-text-black-3">
                        <li>The Lady from the Sea by Henrik Ibsen</li>
                        <li>The Federalist Papers by Alexander Hamilton, John Jay, and James Madison</li>
                        <li>The Professor by Charlotte Brontë</li>
                        <li>Manual for the preparation and sale of fruits and vegetables by Andres F. Lopez Camelo</li>
                        <li>Global_Bio_Ass</li>
                        <li>MODERN MYSTICISM AND OTHER ESSAYS BY FRANCIS GRIERSON</li>
                        <li>LITERARY AND PHILOSOPHICAL ESSAYS</li>
                        <li>TPM DEVELOPMENT PROGRAM Implementing Total Productive Maintenance</li>
                        <li>A Dome of Many-Coloured Glass by Amy Lowell</li>
                        <li>Why will my question be closed_ ([ACM ICSE '20_ 42nd International Conference on Software Engineering - Seoul South Korea (27 06 2020 19 07 2020)] Proceedings of the ACM_IEEE 42nd International Conference on Software Engineering_ New Ideas and Emergi</li>
                        <li>End-to-End Deep Reinforcement Learning based Recommendation with Supervised Embedding ([ACM WSDM '20_ The Thirteenth ACM International Conference on Web Search and Data Mining - Houston TX USA (03 02 2020 07 02 2020)] Proceedings of the 13th Internat</li>
                        <li>The Natural History of Norway by Erik Pontoppidan</li>
                        <li>Microbiology from openstax (Rice University)</li>
                        <li>Modern Mysticism and other essays by Francis Grierson</li>
                        <li>Learner English by Michael Swan and Bernard Smith</li>
                        <li>Biology from openstax (Rice University)</li>
                        <li>Why Will My Question Be Closed? NLP-Based Pre-Submission</li>
                        <li>DETAILS FOR CONVENTIONAL WOOD FRAME CONSTRUCTION</li>
                        <li>Warsaw Weekly</li>
                        <li>TPM DEVELOPMENT PROGRAM Implementing Total Productive Maintenance</li>
                        <li>TECHNICAL MANUAL</li>
                        <li>ARMY FACILITIES COMPONENTS SYSTEM-PLANNING (TROPICAL)</li>
                        <li>Thomas-de-quincey_confessions-of-an-english-opium-eater</li>
                        <li>Thenewgenetics</li>
                        <li>The_Three_Musketeers</li>
                        <li>The neuropsychological basis of delusions</li>
                        <li>The influence of unemployment on functioning of the family</li>
                        <li>The History of Poland, In Several Letters to Persons of Quality</li>
                        <li>THE GOLD MINES OF THE RAND</li>
                        <li>The conditioning of individualization in nursing care</li>
                        <li>TEXTBOOK OF ZOOLOGY</li>
                        <li>A TEXT-BOOK OF BOTANY</li>
                        <li>Accounting Principles: A Business Perspective, Financial Accounting (Chapters 1 – 8)</li>
                        <li>PREVENTION AND MANAGEMENT OF COLD-WEATHER INJURIES</li>
                        <li>The Stock Exchange Business</li>
                        <li>Spacecraft_Dynamics</li>
                        <li>Social and Legal Aspects of Alcohol Abuse in Poland</li>
                        <li>Securingyourscad0000tech</li>
                        <li>Schiff-QuantumMechanics</li>
                        <li>Polsih Economist</li>
                        <li>Partition chromatography of metals in oxo systems. 2 Alcohols as extractants of thiocyanate complexes of metals</li>
                        <li>Nomenclature of the apple</li>
                        <li>Newzealandfamil00neil</li>
                        <li>Nature 1894(NEWSPAPER)</li>
                        <li>Nature 1883(NEWSPAPER)</li>
                        <li>Miguel-de-cervantes-saavedra_don-quixote_john-ormsby</li>
                        <li>Metamorphosis by Franz Kafka</li>
                        <li>MEMOIRS OF THE MUSEUM OF COMPARATIVE ZOOLOGY</li>
                        <li>Meet the Mousmé the otherness of the Japanese woman in the writings of French women travellers of the first half of the 20th century</li>
                        <li>Mary-shelley_frankenstein</li>
                        <li>Mark-twain_a-connecticut-yankee-in-king-arthurs-court</li>
                        <li>MAGICK</li>
                        <li>LANGUAGE OF FLOWERS</li>
                        <li>LandauLifshitz-StatisticalPhysics</li>
                        <li>Journal of researches</li>
                        <li>Joseph-conrad_heart-of-darkness</li>
                        <li>Histological examination of the liver after experimental</li>
                        <li>Hermann-hesse_siddhartha_gunther-olesch_anke-dreher_amy-coulter_stefan-langer_semyon-chaichenets</li>
                        <li>A HANDBOOK of PLANT-FORM</li>
                        <li>A HANDBOOK OF PICTORIAL ART</li>
                        <li>HANDBOOK Of MECHANICAL DESIGN</li>
                        <li> THE GOOD COOK GAME</li>
                        <li>FUNDAMENTALS AND PROCEDURES OF AIRFRAME MAINTENANCE </li>
                        <li>Drug-taking and Polish Criminal Law</li>
                        <li>Culpeper's complete herbal</li>
                        <li>THE NATURE AND PROPERTIES OF SOILS</li>
                        <li>Organic Chemistry</li>
                        <li>Journal of POLISH CIMAC</li>
                        <li>Brave_New_World_Aldous_Huxley</li>
                        <li>Biology_for_Beginners</li>
                        <li>BERGEY'S MANUAL OF DETERMINATIVE BACTERIOLOGY</li>
                        <li>Benjamin-franklin_the-autobiography-of-benjamin-franklin</li>
                        <li>THE ART OF SCIENTIFIC INVESTIGATION</li>
                        <li>A HISTORY OF ART IN ANCIENT EGYPT</li>
                        <li>APPLIED PHYSIOLOGY</li>
                        <li>Anton-chekhov_the-duel_constance-garnett</li>
                        <li>A-merritt_the-moon-pool</li>
                        <li>A rare case of tooth-nail syndrome</li>
                        <li>A NEW SPECIES OF CALIFORNIAN COLEOPTERA</li>
                        <li>A HISTORY OF MUSIC By Theodore M Finney</li>
                        <li>A guide to the history of science</li>
                        <li>THE MESSENGER OF THE SACRED HEART</li>
                        <li>101 BASIC Computer Games</li>
                    </ul>
                </div>
            </div>
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';

const dialog = ref(true);

const props = defineProps([
    'dialog', 'persistent'
])

const emits = defineEmits(['closeDialog'])

const closeDialog = () => {
    dialog.value = false;
    emits('closeDialog', dialog.value)
}
watch(props.dialog, (val) => {
    if(val === false) {
        closeDialog();
    }
})
</script>

<style lang="scss">
.has-circular-border {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 2px solid;
    font-size: 18px;

    &.border-black {
        border-color: var(--color-black-3);
    }
}
.help-expansion {
    p + p {
        margin-top: 20px;
    }

    .v-expansion-panel-title {
        font-weight: 500;
        color: var(--color-black-3);
        min-height: auto;
    }
    .v-expansion-panel-title, .v-expansion-panel-text__wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .v-expansion-panel__shadow {
        display: none;
    }

    .v-expansion-panel {
        & + .v-expansion-panel {
            border-top: 1px solid var(--color-light-grey-3);
        }
    }
}
.has-col-divider {
    th + th, td + td {
        border-left: 1px solid var(--color-light-grey-3);
    }
}
</style>