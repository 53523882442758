<template>
    <v-snackbar
      v-model="openSnackbar"
      location="top right"
      class="kiwi-text-white"
      :color="displayMsg.type === 'error' ? 'red lighten-3' : 'success'"
      variant="flat"
    >
      {{ displayMsg.msg }}

      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          @click="closeSnakbar"
          icon
        >
          <v-icon class="mdi mdi-close" />
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script setup>
import {ref} from 'vue'
import {useConfirmer} from '@/stores/confirmer'
import { storeToRefs } from 'pinia';

const confirmer = useConfirmer()

const {openSnackbar, displayMsg } = storeToRefs(confirmer);

const closeSnakbar = () => {
    confirmer.closeSnackBarInitialize()
};
</script>

<style>

</style>