<template>
  <div class="h-100" style="max-height: 100%; overflow-y: auto;">
    <div v-if="examOver" class="d-flex align-center justify-center h-100">
        <ExamOver  @view-score="goToEvaluation" />
    </div>
    <div v-else-if="activeStack" class="fill-height d-flex flex-wrap flex-column align-center justify-center">
    <template v-if="activeStack.name === 'Question'">
      <Question
        ref="currentStackRef"
        v-bind="activeStack.props"
        :key="currentStackIndex"
        :isLastQuestion="isLastQuestion"
        :freeze="promptUnique !== null"
        :examId="examId"
        @handleSubmit="next"
        @mark-for-review="markForReview"
        @set-current-answer="setAnswerStateInQuestion"
        :index="paginationIndex"
      ></Question>
    </template>
    <template v-else-if="activeStack.name === 'ModuleIntro'">
      <ModuleIntro
        ref="currentStackRef"
        v-bind="activeStack.props"
        :key="currentStackIndex"
        @handleSubmit="next"
      ></ModuleIntro>
    </template>
    <template v-else-if="activeStack.name === 'InitialTest'">
      <InitialTest
        v-bind="activeStack.props"
        :key="currentStackIndex"
        @handleSubmit="next"
      />
    </template>
    <template v-else-if="activeStack.name === 'ReviewSection'">
      <ReviewSection
        v-bind="activeStack.props"
        :key="currentStackIndex"
        @handleSubmit="next"
        @change-question="(val)=>changeQuestion(val)"
      />
    </template>
    <template v-else-if="activeStack.name === 'ModuleOver'">
      <ModuleOver
        v-bind="activeStack.props"
        :key="currentStackIndex"
        @handleSubmit="next"
      />
    </template>
      <template v-else-if="activeStack.name === 'ExamOver'">
        <ExamOver @view-score="goToEvaluation"/>
      </template>
      <template v-else-if="activeStack.name === 'Break'">
        <PracticeTestBreak @handle:next="moveNextForModule"/>
      </template>
    <div class="position-fixed w-100 " style="bottom: 0; left: 0; z-index: 200;">
      <v-divider style="border-style: dashed; border-color: var(--color-black-3) !important;"></v-divider>
      <v-footer v-if="!['ExamOver','ModuleOver'].includes(activeStack.name)" class="py-0" style="background-color: #F3FFFA;">
        <v-container class="d-flex px-0 py-2 align-center" variant="flat">
          <v-row class="w-100">
            <v-col class="d-flex align-center justify-start"><span>{{user.user_full_name}}</span></v-col>
            <v-col class="d-flex justify-center align-center">
              <v-chip
                v-if="['Question'].includes(activeStack.name)"
                variant="flat"
                ref="bookmarkChip"
                @click="openBookmarkBox = !openBookmarkBox"
              >Question {{ stackData.list[0]?.name === 'InitialTest' ? currentStackIndex : currentStackIndex + 1 }} of {{questionsCount}} <v-icon class="mdi mdi-chevron-up ml-2" /></v-chip>
              <Pagination v-if="openBookmarkBox"
                          @closeDialog="closeBookMark"
                          @change-question="(val)=>changeQuestion(val)"
                          @go-to-review-page="goToReviewPage"
                          ref="bookmarkPagination"
              />
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
                class="text-none kiwi-bg-light-grey-3 kiwi-text-black-3 mr-2 kiwi-fs-16"
                size="large"
                variant="flat"
                rounded="xl"
                @click="handleBackAction"
                v-if="!isFirstQuestion && !['InitialTest'].includes(activeStack.name) && timeEndedForModule !== -1"
              >
                Back
              </v-btn>
              <v-btn
                class="text-none kiwi-bg-primary kiwi-text-white kiwi-fs-16"
                size="large"
                variant="flat"
                rounded="xl"
                @click="handleNextAction"
              >
                {{ isLastQuestion ? "Submit" : "Next" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </div>
  </div>
    <PromptDialog v-bind="prompt" v-if="prompt !== null" />
    <PromptDialog v-bind="promptUnique" v-if="promptUnique !== null" />
    <!-- <SaveExit v-bind="saveExitPrompt" :dialog="saveExitPrompt" @close-dialog="closeDialog" /> -->
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  defineProps,
  computed,
  defineAsyncComponent,
  defineExpose,
  watch,
  onMounted,
  onBeforeMount,
  onBeforeUnmount, nextTick
} from "vue";
import {useQuestionBasedSatStore} from "@/stores/Sat/questionBasedSat-store";
import Pagination from '@/components/SAT/Prompt/BookmarkPagination.vue';
import Question from "@/components/SAT/Question/Question.vue";
import ModuleIntro from "@/components/SAT/Question/ModuleIntro.vue";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import { useExamTimerInstanceStore } from "@/stores/examTimerInstance-store";
import { storeToRefs } from "pinia";
import InitialTest from "@/components/SAT/Question/InitialTest.vue";
import {useRoute, useRouter} from "vue-router";
import { useQuestionAnswer } from "@/composables/useQuestionAnswer";
import { useAnswerInstanceStore } from "@/stores/answerInstance-store";
import { mockPromise } from "@/utils/utils";
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { useQuestionBasedTestStore } from "@/stores/questionbased-test-store";
import { reportLog } from "@/sentry";
import {useAuthStore} from '@/stores/auth/authStore';
import ModuleOver from "@/components/SAT/Question/ModuleOver.vue";
import ReviewSection from "@/components/SAT/Question/ReviewSection.vue";
import {useModuleStore} from "@/stores/Sat/module-store";
import ExamOver from "@/components/SAT/Question/ExamOver.vue";
import PracticeTestBreak from "@/components/SAT/Question/PracticeTestBreak.vue";
import {useBookMarkeStore} from "@/stores/Sat/bookmarks-stores";
import SaveExit from '@/components/SAT/Prompt/SaveExit.vue'

const questionBasedStore = useQuestionBasedSatStore();
const bookmarkStore = useBookMarkeStore();
const moduleStore = useModuleStore();
const router = useRouter();
const route = useRoute();
const timerStore = useExamTimerInstanceStore();
const {timeEndedForModule } = storeToRefs(timerStore);
const {
  clearModuleTimer,
  clearTimer,
  setReduceTime,
  updateModuleTimerForType,
  moduleTimer
} = timerStore;
const { questionList, introContent, examDetailData,  examType, examId, lastAttemptedQuestionId , takeFromProps} = defineProps({
  examType: {
    required: false,
    default: () => "mock",
  },
  answer_content_type:{
    required: false,
    default: () => "mcq",
  },
  examDetailData:{},
  questionList: {
    required: false,
    default: () => []
  },
  examId: {},
  lastAttemptedQuestionId: {},
  introContent: {},
  takeFromProps:{
    default: false,
    type:Boolean
  }
});
const openBookmarkBox = ref(false);
const store = useAuthStore();
const {user} = storeToRefs(store)
const { answerSubmitting, submitAnswer } = useQuestionAnswer();
const answerInstanceStore = useAnswerInstanceStore();
const examStore = useExamInstanceStore();
const {loader} = storeToRefs(examStore);
const questionBasedTestStore = useQuestionBasedTestStore();
const { answer } = storeToRefs(answerInstanceStore);
const emit = defineEmits(["timer:change"]);
const currentStackRef = ref(null);
const stackCompleted = ref(false);
const currentStackIndex = ref(0);
const prompt = ref(null);
const promptUnique = ref(null);
const currentAnswer = ref(null);
const finalizing = ref(false);
const userState = ref( examDetailData?.exam_attempt_data?.user_state ? {...examDetailData?.exam_attempt_data?.user_state} : null);
const stackData = reactive({
  list: [],
  stacked_module: [],
});
const questions = computed(() =>{
  if(takeFromProps){
    return questionList;
  }
  return examStore.questions
})
const {moduleTime} = storeToRefs(moduleStore);
const moduleChanging = ref(false);
const {setCurrentQuestionIndexForBookmark , setAnsweredQuestionIndexForBookmark , clearBookmarks , generateBookmarks , markForBookmark , removeCurrentIndex} = bookmarkStore;

const  currentModuleIdentity = computed(()=>{
  return examStore.examDetail?.exam_attempt_data?.exam_state?.current_section_module;
})
const examOver = ref(false);
const bookmarkChip = ref(null);
const bookmarkPagination = ref(null);

const buildStack = (pushIntro = false) => {
  stackCompleted.value = false
  currentStackIndex.value = 0;
  if (examType === "empty" || examType === 'question') {
    questions.value.forEach((q) => {
      const { topic, ...question } = q;
      stackData.list.push({
        name: "Question",
        type: "question",
        props: {
          moduleData: null,
          payload: topic,
          question: question,
          examId: examId,
          examType: examType,
        },
      });
    });
    stackCompleted.value = true;
  } else {
    if(pushIntro && examType !== "test") {
      stackData.list.push({
        name: "InitialTest",
        type: "initial",
        props: {
          contentView:1,
          moduleData: {},
        },
      });
    }
    questions.value.forEach((question) => {
      stackData.list.push({
        name: "Question",
        type: "question",
        props: {
          question: question,
        }
      })

    });
    pushReviewAndIntroPage();
    stackCompleted.value = true;
  }
}
const pushReviewAndIntroPage = () =>
{
  stackData.list.push(
    {
      name: "ReviewSection",
      type: "reviewSection",
      props: {
      },
    }
  );
  stackData.list.push(
    {
      name: "ModuleOver",
      type: "moduleOver",
      props: {
      },
    }
  );
}

buildStack(examDetailData.is_attempted !== true);
generateBookmarks(questions.value);

const closeBookMark = () => {
  openBookmarkBox.value = false;
}

const activeStack = computed(() => {
  return stackData.list[currentStackIndex.value] || null;
});
const nextStack=computed(()=>{
  return stackData.list[currentStackIndex.value + 1] || null;
})
const currentModulePayload = computed(() => {
  if (!activeStack.value) {
    return null;
  }
  if (activeStack.value.type === "question") {
    return activeStack.value.props.payload;
  }
});
const currentModule = computed(() => {
  if (!activeStack.value) {
    return null;
  }
  if (activeStack.value.type === "question") {
    return activeStack.value.props.moduleData;
  }
});
const next = async () => {
  const hasAns = await captureCurrentAnswerPayload();
  moveNext();
  executeAnswerSubmit();
};
const handleTimeEnded = async () => {
  stackData.list = []
  currentStackIndex.value = 0;
  stackData.list.push(
    {
      name: "ModuleOver",
      type: "moduleOver",
      props: {
      },
    }
  )
  await executeAnswerSubmit();
  await finishExam();
  const isExamOver = handleExamOver();
  if(!isExamOver) {
     await moveNextForModule();
  }
}
const handleExamOver = () => {
  if(!isLastModule.value) {
    return false
  }
  clearBookmarks();
  promptUnique.value=null;
  examOver.value=true;
  stackData.list = []
  currentStackIndex.value = 0
  return isLastModule.value
}

const moveNextForModule = async (refetch = true) =>{
  clearBookmarks();
  promptUnique.value=null;
  moduleChanging.value = true;
  clearTimer();
  if(refetch) {
    await examStore.getExamDetail(examId);
    await examStore.refetchExamInstance(examId);
  }
  generateBookmarks(examStore.questions);
  setTimeout(() => {
    stackData.list = []
    moduleChanging.value = false;
    loader.value = false;
    buildStack();
  }, 1000)
}

const handleBackAction = async () => {
  const hasAns = await captureCurrentAnswerPayload();
  if (!hasAns) {
    return false;
  }
  await executeAnswerSubmit();
  currentStackIndex.value -= 1;
}
const handleNextAction = async () => {
  openBookmarkBox.value = false;
  if(["reviewSection"].includes(activeStack.value.type)) {
    clearTimer()
    moduleChanging.value = true;
    moveNext();
    await finishExam();
    const examOverStatus = handleExamOver();
    if(examOverStatus) {
      return false;
    }
      await examStore.getExamDetail(examId);
      await examStore.refetchExamInstance(examId);
      setTimeout(() => {
        moduleChanging.value = false;
      }, 2000)

  }

  if(activeStack.value.type === "initial"){
    moveNext();
    return;
  }
  const hasAns = await captureCurrentAnswerPayload();
  if (!hasAns) {
    return false;
  }
    await executeAnswerSubmit();
    moveNext();
}

const saveCurrentState  = () => {
  if(examType !== "mock") {
    return false;
  }
  examStore.saveCurrentState({
      current_chapter_id: activeStack.value.props?.payload?.id,
      module_total_time: timerStore.moduleTimer,
      module_current_time: timerStore.timeProgress?.module || null,
      chapter_total_time: timerStore.chapaterTimer,
      chapter_current_time: timerStore.timeProgress?.chapter || null,
  })
}
const saveAndExit = async () => {
  prompt.value = {
    title: "Alert!",
    content:
      "Are you sure to save and exit the test?",
    actions: [
      {
        text: "No",
        onClick: () => (prompt.value = null),
      },
      {
        text: "Yes",
        type: "fill",
        onClick: () => {
          if(examType === "test") {
            router.push({
              name: 'question-based-tests',
              query: {
                topic: activeStack.value.props?.payload ? activeStack.value.props?.payload?.id :null,
                module: activeStack.value.props?.module ? activeStack.value.props?.module?.id :null,
              }
            });
          }else {
            saveCurrentState()
            router.push({
              name:  "mock-tests",
            });
          }
        },
      },
    ],
  };

};

const moveNext = () => {
  currentStackIndex.value += 1;
};

const captureCurrentAnswerPayload = async () => {
  if(activeStack.value.props?.question?.id) {
    const payload = {
      ...answer.value,
      question: activeStack.value.props.question.id,
      answer_for: examType,
      answer_for_id: examId,
      isLastQuestion: isLastQuestion.value,
    };
    currentAnswer.value = payload;
    return true;
  }
};

watch(
  currentModule,
  (newVal, oldVal) => {
    if (newVal) {
    } else {
      clearModuleTimer();
    }
  },
  { immediate: true }
);

watch(
  timeEndedForModule,
  (newVal, oldVal) => {
    if (newVal === -1 && activeStack.value.type !== "initial") {
      handleTimeEnded();
    }
  },
  { immediate: true , deep: true }
);
const currentQuestionStatus = computed(() => {
  if (
    (!currentModule.value && examType === "mock") ||
    !currentModulePayload.value
  ) {
    return null;
  }
  const totalQuestion = stackData.list.filter(
    (item) =>
      item.type === "question" &&
      (examType === "test" ||
        item.props.moduleData.slug === currentModule.value.slug)
  );
  let currentQIndex = 0;
  if (activeStack.value && activeStack.value.type === "question") {
    currentQIndex = totalQuestion.findIndex(
      (q) => q.props.question.id === activeStack.value.props.question.id
    );
  }
  return {
    total: totalQuestion.length,
    current: currentQIndex < 0 ? currentQIndex : currentQIndex + 1,
  };
});
const isLastQuestion = computed(() => {
  const qs = stackData.list
    .filter((item) => item.type === "question")
    .map((q) => q.props.question.id);
  if (qs.length > 0) {
    const lastQId = qs[qs.length - 1];
    return lastQId === activeStack.value?.props?.question?.id;
  } else {
    return false;
  }
});
const isFirstQuestion = computed(() => {
  const qs = stackData.list
      .filter((item) => item.type === "question")
      .map((q) => q.props.question.id);
  if (qs.length > 0) {
    const firstQId = qs[0];
    return firstQId === activeStack.value?.props?.question?.id;
  } else {
    return false;
  }
})
function goToEvaluation() {
    return router.push({
      name: "SatEvaluation",
      params: { id: examId },
      query: { isQbt :  isQbt.value }
    });
}
const isQbt = computed(() => {
  return route.name === 'satquestionbasedtest';
})
const setAnswerStateInQuestion = async () => {
  await captureCurrentAnswerPayload();
  const currentQuestion = stackData.list[currentStackIndex.value];
  if(currentQuestion) {
    const answer  =  getAnswerDetails(currentAnswer.value);
    if(answer !== null && answer.answer_id !== null && answer.answer_id !== undefined){
      currentQuestion.props.question.user_answer = answer.answer_id ;
      setAnsweredQuestionIndexForBookmark(currentQuestion.props.question.id);
    }else if(answer.answer_text !== null && answer.answer_text !== undefined ){
        currentQuestion.props.question.user_answer = answer.answer_text;
        setAnsweredQuestionIndexForBookmark(currentQuestion.props.question.id);
    }
  }
}
function getAnswerDetails(obj) {
  if (obj.answer_id !== undefined || null ) {
    return { answer_id: obj.answer_id };
  } else if (obj.answer_text !== undefined || null) {
    return { answer_text: obj.answer_text };
  }
  return null;
}
async function executeAnswerSubmit(options = {}) {
  prompt.value = null;
  try {
    const res = await submitAnswer(currentAnswer.value);
    currentAnswer.value = null;
    return res;
  } catch (err) {
    finalizing.value = false;
  }
  return null;
}
async function finishExam() {
  const finishRes = await (examType !== "test"
    ? examStore.finish()
    : questionBasedTestStore.finish());
  if (finishRes &&  false) {
    finalizing.value = false;
  }
}
defineExpose({
  next,
  saveAndExit,
  currentQuestionStatus,
  activeStack
});
const handleBeforeUnload = (event) => {
  saveCurrentState()
  event.preventDefault();
  event.returnValue = ''; // Required for Chrome
};
const handleClickOutside = (event) => {
  // Check if the click was outside of the bookmark chip and pagination
  if (
    bookmarkChip.value &&
    bookmarkPagination.value &&
    !bookmarkChip.value.$el.contains(event.target) &&
    !bookmarkPagination.value.$el.contains(event.target)
  ) {
    openBookmarkBox.value = false;
  }
};
onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
  document.addEventListener("click", handleClickOutside);
});

const isLastModule  = computed(() => {
  if(takeFromProps) {
    return true; //here also check for qbts
  }
  return examStore.examDetail.exam_attempt_data.exam_state.is_last_module;
})

const eachModuleTime = computed(() => {
  return moduleTime.value?.exam_state?.current_section_module_time;
})
watch(
  activeStack,
  (newVal, oldVal) => {
    if(newVal && newVal.type === "reviewSection") {
      removeCurrentIndex();
    }
    if(newVal && newVal.type === "question" && eachModuleTime.value) {
      setCurrentQuestionIndexForBookmark(newVal.props?.question?.id);
      updateModuleTimerForType(eachModuleTime.value);
    }
    if(newVal){
      examStore.setActiveStackType(newVal.type);
    }
  },
  { immediate: true }
);
watch(moduleChanging, async (changing, changed) => {
  if(!changing && changed && activeStack.value && activeStack.value.type === "moduleOver" ) {
    if(currentModuleIdentity.value === null  && examStore.questions.length === 0) {
      // stackData.list = [];
      // stackData.list.push({
      //   name: "ExamOver",
      //   type: "examOver",
      // })
      // currentStackIndex.value = 0;
    }else {
     await moduleStore.getEachModuleTime(examId);
      const nextStackItem = stackData.list[currentStackIndex.value + 1] || null;
      const hasBreak =  nextStackItem && nextStackItem.name === "Break";
      if(hasBreak) {
        moveNext();
      }else {
          await moveNextForModule(false);
      }
    }
  }
})
watch(
  stackCompleted,
  (newVal) => {
    if (stackCompleted.value) {
      if (lastAttemptedQuestionId) {
        const attemptedIndex = stackData.list.findIndex(
          (list) =>
            list.type === "question" &&
            list.props?.question?.id === parseInt(lastAttemptedQuestionId)
        );
        if (attemptedIndex >= 0) {
          if(userState.value && newVal.id === userState.value.current_chapter_id) {
            setReduceTime({
              chapter: (userState.value.chapter_total_time || 0) - (userState.value.chapter_current_time || 0),
              module: (userState.value.module_total_time || 0) - (userState.value.module_current_time || 0)
            });
            userState.value = null
          }
          currentStackIndex.value = attemptedIndex + 1;
          if (currentStackIndex.value >= stackData.list.length) {
            if (examDetailData.is_completed) {
            } else {
              finishExam();
            }
          }
        }
      }
        if(currentModuleIdentity.value === 12) {
          stackData.list.push({
            name: "Break",
            type:'break'
          });
        }
    }
  },
  { immediate: true, deep: true }
);
const questionsCount = computed(() => {
  if(takeFromProps){
    return questionList.length;
  }
  return examStore.questions.length;Ï
})
const currentQuestionIndex = computed(() => {
    const qs = stackData.list
    .filter((item) => item.type === "question")
    .map((q) => q.props.question.id);
})
const paginationIndex = computed(() => {
  return stackData.list[0]?.name === 'InitialTest' ?  currentStackIndex.value  : currentStackIndex.value + 1 ;
})
const changeQuestion=async (val) =>{
  const questionIndex = stackData.list.findIndex(s => s.type === "question" && s.props?.question?.id === val.questionId);
  if(questionIndex < 0) {
    return false
  }
  await captureCurrentAnswerPayload();
  await executeAnswerSubmit();
  setCurrentQuestionIndexForBookmark(val.questionId);
  currentStackIndex.value = questionIndex
}
const markForReview  = () =>{
  markForBookmark(paginationIndex.value - 1);
}
const goToReviewPage = async () =>{
   openBookmarkBox.value = false;
  await captureCurrentAnswerPayload();
  await executeAnswerSubmit();
   const reviewIndex = stackData.list.findIndex(s => s.type === "reviewSection");
   if (reviewIndex >= 0) {
      currentStackIndex.value = reviewIndex
   }
}
watch(questions , (newVal) =>{
  if(newVal){
    newVal.map(val => {
      if(val.user_answer !== null){
        setAnsweredQuestionIndexForBookmark(val.id);
      }
    })
  }
} , { immediate: true, deep: true });
</script>
