<script setup>

import {useIndividualQustionsTestStore} from "@/stores/individual-question-test-store";
import {storeToRefs} from "pinia";
import {useRoute, useRouter} from "vue-router";
import {onMounted , computed , watch , ref} from "vue";

const individualQuestionTestStore = useIndividualQustionsTestStore()
const {individualQuestionPagination , currentQuestionOffset , questionsBasedTests} = storeToRefs(individualQuestionTestStore)
const {getIndividualQuestion  , getPreviousAnswers} = individualQuestionTestStore
const route = useRoute()
const router = useRouter();

const  updatePagination = async () =>{
  if(currentQuestionOffset !== null){
    router.replace({
      name: 'individualQuestion',
      params: {
        id: question.value.id,
        topic_id: parseInt(route.params.topic_id),
        index: currentQuestionOffset.value - 1
      }
    });
    getIndividualQuestion(route.params.topic_id, 1, parseInt(route.params.index) , currentQuestionOffset.value , true).then((response) => {
       getPreviousAnswers(route.params.topic_id , question.value.id)
    }).catch((error) => {});
  }
}

const question = computed(()=>{
  return questionsBasedTests.value[0]
})
const previous = () => {
if(currentQuestionOffset.value !== 1) {
  currentQuestionOffset.value -= 1;
  updatePagination();
}
}
const next = () =>{
  if(currentQuestionOffset.value  <= individualQuestionPagination.value.count ){}
   currentQuestionOffset.value += 1;
   updatePagination();
}

onMounted(()=>{
  currentQuestionOffset.value = parseInt(route.params.index) + 1
})
const validateIntegerInput = (event) => {
  if (event.key === 'Enter' || event.keyCode === 13) {
    updatePagination();
  }
  const charCode = event.charCode ? event.charCode : event.keyCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
}
watch(currentQuestionOffset, (newValue) => {
  if (newValue > individualQuestionPagination.value.count ) {
    currentQuestionOffset.value = individualQuestionPagination.value.count ;
  }
});
</script>

<template>
  <div class="container justify-end">
    <v-row class="w-100">
      <v-col cols="12" md="8" lg="9">
        <v-pagination
          v-model="currentQuestionOffset"
          :length="individualQuestionPagination.count"
          variant="text"
          density="compact"
          :total-visible="5"
          @update:modelValue="updatePagination"
        >
          <template v-slot:prev>
          <v-btn
            class="text-none kiwi-border-light-grey-3"
            variant="outlined"
            @click.native="previous"
            :disabled="currentQuestionOffset === 1"
          >
            <span>Previous</span>
          </v-btn>
        </template>
          <template v-slot:next>
            <v-btn
              class="text-none kiwi-border-light-grey-3"
              variant="outlined"
              @click.native="next"
              :disabled="currentQuestionOffset  >= individualQuestionPagination.count"
            >
              <span>Next</span>
            </v-btn>
          </template>
        </v-pagination>
      </v-col>
      <v-col cols="12" md="4" lg="3">
        <div class="d-flex flex-wrap align-center px-4">
          <span class="mr-2">Go to</span>
          <v-text-field
            variant="outlined"
            v-model="currentQuestionOffset"
            hide-details
            class="custom-select mr-2"
            @keypress="validateIntegerInput"
          />
          <span>/{{individualQuestionPagination.count}}</span>

        </div>

      </v-col>
    </v-row>

  </div>
</template>

<style lang="scss" >
.container {
  display: flex;
  align-items: center;
}
#app .custom-select {
  max-width: 100px;
  margin: 0 10px;
  .v-field__input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    min-height: 36px;
  }
}
</style>
