<template>
    <WebLayout page="privacy" :isGradientBgTop="false">
        <Banner mainClass="kiwi-blog-inner-banner py-12" :content="bannerContent" />
        <section class="">
            <v-container>
                <v-sheet class="kiwi-paragraphs-bundle" v-html="termsContent">
                </v-sheet>

            </v-container>
        </section>
        
    </WebLayout>
</template>

<script setup>
import WebLayout from '../WebLayout.vue'
import Banner from '../Addon/InnerBanner.vue'
import {onMounted, ref} from 'vue'
import { apiWithoutAuth } from '@/plugins/axios';

const bannerContent = ref({})
bannerContent.value = {
    title: 'Terms & Condition',
}

const termsContent = ref('')

onMounted(() => {
    if(termsContent.value == ''){
        try {
            const response = apiWithoutAuth.get('common/project-config/terms-and-condition/only');
            response.then(res => {
                termsContent.value = res.data['terms-and-condition']
            })
        } catch(err) {
            console.log(err)
        }
    }
})
</script>