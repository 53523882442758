<template>
  <Card
    :handleControl="welcomeControl"
    class="h-100 align-center kiwi-mockup-test"
  >
    <v-row class="align-center">
      <v-col>
        <div class="d-flex flex-wrap align-center">
          <img
            src="/assets/mortarboard-02.svg"
            width="36"
            height="36"
            class="mr-2"
          />
          <span class="font-weight-bold kiwi-fs-20" style="flex: 1"
            >Question Based Tests</span
          >
        </div>
      </v-col>
    </v-row>

    <div class="mt-4 mt-md-6">
      <QBTs v-for="(value, key) in topics" :key="key" class="qbt-component mt-4">
        <template v-slot:qbtsHeader>
          <img
            :src="getImageSrc(value.title)"
            class="mr-2"
            alt=""
            v-if="getImageSrc(value.title)"
          />
          <span class="kiwi-fs-18 font-weight-semi-bold"
            :class="`kiwi-text-${value.title.toLowerCase()}`"
          >{{
            value.title
          }}</span>
        </template>
        <div class="pa-4 pt-2">
          <v-chip
            variant="flat"
            class="kiwi-question-chip kiwi-bg-white border kiwi-border-light-grey-4 py-2 py-md-5 px-2 px-md-4 mt-2 mr-2 kiwi-text-black kiwi-fs-14 font-weight-medium"
            size="small"
            v-for="(list, i) in value.children"
            :key="i"
            @click="goToTests(list.id, value.id)"
          >
            {{ list.title }}
          </v-chip>
        </div>
      </QBTs>
    </div>
  </Card>
</template>

<script setup>
import Card from "@/components/Dashboard/ContentSheet.vue";
import QBTs from "@/components/Dashboard/QBTsSheet.vue";
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDashboardStore } from "@/stores/dashboard-store";
import { useRouter } from "vue-router";
const router = useRouter()
const dashboardStore = useDashboardStore();
const { dashboardData } = storeToRefs(dashboardStore);

const topics = computed(() => {
  return dashboardData.value && dashboardData.value.topics !== undefined
    ? dashboardData.value.topics
    : [];
});
const getImageSrc = (title) => {
  switch (title) {
    case "Speaking":
      return "/assets/voice.svg";
    case "Reading":
      return "/assets/book-open-02.svg";
    case "Listening":
      return "/assets/listening.svg";
    case "Writing":
      return "/assets/pen-tool-03.svg";
    default:
      return null;
  }
};

const welcomeControl = ref({
  bg: "white",
  textColor: "black",
});

const progress = ref(Number);
progress.value = 20;

const status = ref("");
status.value = "Not Started";
const goToTests = (topicId, moduleId) => {
  router.push({
    name: 'question-based-tests',
    query: {
      topic: topicId,
      m: moduleId
    }
  })
}
</script>

<style>
.kiwi-mockup-test .v-progress-circular__underlay {
  stroke: white !important;
}
.v-progress-circular__overlay {
  stroke: #1ca972 !important;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
}

</style>
