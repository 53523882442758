<template>
    <WebLayout page="blog" :isGradientBgTop="false">
        <section class="kiwi-section pb-12 pb-md-16 pb-lg-20">
            <v-container>
                <BlogContent :content="pageContent" pageClass="blog-single-article" />
            </v-container>
        </section>
        
        <TwoColSectionArticle 
            mainClass="kiwi-ielts-preparation" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-0 py-lg-0"
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="subscribeContent"
        />
    </WebLayout>
</template>

<script setup>
import WebLayout from '../WebLayout.vue'
import BlogContent from '../Addon/articlePageCard.vue'
import TwoColSectionArticle from '../Addon/TwoColSectionArticle.vue'
import {onMounted, ref} from 'vue'
import { apiWithoutAuth } from '@/plugins/axios';
import { useRoute, useRouter } from 'vue-router';

const pageContent = ref({})
const route = useRoute()
const title_slug = route.params.slug
// pageContent.value = {
//     id: 1,
//     category: {
//         name: 'Our blog',
//         color: 'kiwi-text-primary',
//         transform: 'none'
//     },
//     title: 'Preparing for the PTE Exam: A Guide',
//     subtitle: 'Our tiered pricing plans provide the perfect balance of features and affordability.',
//     tags: ['PTE', 'Exam', 'Preparation'],
//     author: {
//         name: 'Sheetal Rai',
//         date: '18th July'
//     },
//     featureImage: {
//         rounded: 8,
//         src: '/assets/blog-image-one.png'
//     },
//     paragraphs: `
//         <p>The blog emphasizes the importance of planning, structuring, and optimizing blog content to engage readers and improve search engine visibility. Here is a breakdown of the key points covered in the blog:</p>
//         <ol>
//             <li>Planning Your Blog Post: The blog highlights the significance of planning by creating an outline that acts as a roadmap for structuring the post effectively. It suggests shortlisting relevant topics, choosing a descriptive title, and incorporating a focus keyword naturally throughout the content for SEO purposes.</li>
//             <li>Structuring Your Content: Emphasizing the importance of a clear structure, the blog advises using headings and subheadings to organize the content and help readers navigate the post easily. It also recommends adding key takeaways under each heading to enhance the informational flow.</li>
//             <li>Word Count and Intent Alignment: While suggesting a standard word count of 1,500-2,000 words for a blog post, the blog also stresses aligning each section of the outline with the search intent behind the topic to fulfill the reader's needs effectively.</li>
//             <li>Visuals and Multimedia: It encourages the inclusion of visuals and multimedia elements like images and videos to break up text and enhance visual appeal, making the blog post more engaging and informative.</li>
//             <li>Call to Action (CTA): The blog underlines the importance of including a call to action at the end of the post to prompt readers to take action, such as subscribing to a newsletter, making a purchase, or leaving a comment.</li>
//         </ol>
//         <p>Overall, the blog provides a comprehensive guide for beginners on writing blog posts, covering essential aspects from planning and structuring content to optimizing for search engines and engaging readers effectively.</p>
//     `
// }

const subscribeContent = ref({})
subscribeContent.value = {
    imgSrc: '/assets/subscribe-img.png',
    setMediaCol: 6,
    setArticleCol: 4,
    category: null,
    title: '<span class="kiwi-text-primary">Sign up</span> today and take control of your PTE journey!',
    text: `With access to our extensive resources and AI in-built study tools, you'll be empowered  achieve your desired score.`,
    hasAction: true,
    showMask: true,
    action: {
        label: 'Start Free Trial',
        color: 'kiwi-bg-primary',
        path: 'login'
    }
}
const getBlogDetail = async (slug) => {
    try {
        const response = await apiWithoutAuth.get(`common/blogs/${slug}`)
        pageContent.value = response.data
    } catch (error) {
        
    }
}

onMounted(async () => {
    window.scroll(0,0)
    await getBlogDetail(title_slug)
})
</script>