<template>
    <div class="fill-height w-100 d-flex flex-wrap align-center justify-center py-4 py-md-8 py-lg-10" style="background: url('/public/assets/Shape.svg');">
        <v-sheet class="kiwi-radius-8 d-flex flex-wrap flex-column align-center justify-center kiwi-bg-white py-6 py-md-8 px-4 px-md-8 w-100" max-width="700" variant="flat" style="box-shadow: 20px 30px 50px -12px rgba(65, 83, 123, 0.05);">
            <img src="/assets/refer-friend.svg" alt="">
            <h1 class="kiwi-fs-24 font-weight-bold mt-6 mb-1">Refer a friend, Get a free mock test</h1>
            <p class="kiwi-fs-14 font-weight-medium kiwi-text-light-black-2">Spread the word and boost your practice opportunities.</p>
            <p class="text-center mt-4 kiwi-border-b-1 w-100 kiwi-border-light-grey-3"><span class="kiwi-fs-18 d-inline-block py-2 px-3 font-weight-bold kiwi-text-primary referal-text">Referral Code</span></p>
            <div class="w-100 referal-box mt-4">
                <p class="kiwi-fs-18 font-weight-semi-bold w-100">Your Unique Referral Code</p>
                <div class="d-flex flex-wrap align-center mt-3" style="grid-gap: 10px;">
                    <div class="display-code style-dashed text-center cursor-pointer text-uppercase kiwi-fs-16 h-100 border font-weight-semi-bold flex-grow-1" @click.prevent="copyToClipboard(referal_code)">{{referal_code}}</div>
                    <v-btn class="kiwi-bg-primary flex-grow-sm-1 text-none kiwi-text-white kiwi-radius-4 kiwi-fs-16 font-weight-semi-bold px-3" @click.prevent="copyToClipboard(referal_code)" size="x-large" variant="flat" style="font-size: 14px;">
                        <img src="/assets/copy-01.svg" alt="" class="mr-2">
                        <span>Copy</span>
                    </v-btn>
                    <v-btn class="kiwi-border-primary kiwi-radius-4 kiwi-fs-16 font-weight-semi-bold px-0 whatsapp-btn" size="x-large" variant="outlined" @click.prevent="shareOnWhatsApp(referal_code)">
                        <img src="/assets/whatsapp-svgrepo-com 1.svg" alt="">
                    </v-btn>
                </div>
            </div>
            <div class="d-flex position-relative justify-center my-5 w-100">
                <v-divider class="position-absolute trans-y-center z-1" />
                <span class="d-inline-block position-relative z-2 px-4 kiwi-bg-white" style="color: #78847D">OR</span>
            </div>
            <div class="w-100 referal-box mt-4">
                <p class="kiwi-fs-18 font-weight-semi-bold w-100">Share your Referral Link</p>
                <div class="d-flex flex-wrap align-center mt-3" style="grid-gap: 10px;">
                    <div class="display-code text-center text-none cursor-pointer kiwi-fs-16 h-100 border font-weight-semi-bold flex-grow-1" @click.prevent="copyToClipboard(share_url)">{{share_url}}</div>
                    <v-btn class="kiwi-bg-primary text-none kiwi-text-white kiwi-radius-4 kiwi-fs-16 font-weight-semi-bold px-3 flex-grow-sm-1" size="x-large" variant="flat" @click.prevent="copyToClipboard(share_url)" style="font-size: 14px;">
                        <img src="/assets/copy-01.svg" alt="" class="mr-2">
                        <span>Copy</span>
                    </v-btn>
                     <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        location="top"
                        offset="5 100"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn 
                                class="border kiwi-border-light-black-2 kiwi-radius-4 text-none kiwi-fs-16 font-weight-semi-bold px-3" 
                                size="x-large" 
                                variant="flat"
                                v-bind="props"
                                style="font-size: 14px;"
                            >
                                <img src="/assets/share-03.svg" class="mr-1" alt="">
                                <span>Share</span>
                            </v-btn>
                        </template>

                        <v-card class="pa-2 d-flex" >
                            <!-- <a class="share-network-instagram" :href="instaNetwork.network" target="_blank" rel="noopener noreferrer" style="background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);">
                                <i data-v-b05ae2d0="" class="lab la-instagram"></i>
                            </a> -->
                            <ShareNetwork
                                v-for="network in networks"
                                :network="network.network"
                                :key="network.network"
                                :style="{backgroundColor: network.color}"
                                :url="sharing.url"
                                :title="sharing.title"
                                :description="sharing.description"
                                :quote="sharing.quote"
                                :hashtags="sharing.hashtags"
                                :twitterUser="sharing.twitterUser"
                                >
                                    <i :class="network.icon" v-if="network.name !== 'Twitter'"></i>
                                    <img src="/assets/pajamas_twitter.svg" v-else alt="Twitter Icon">
                                </ShareNetwork>
                        </v-card>
                    </v-menu>
                </div>
            </div>
        </v-sheet>
        <SnackBar />
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import SnackBar from '@/components/SnackBar.vue'
import {useConfirmer} from '@/stores/confirmer'
import { useAuthStore } from '@/stores/auth/authStore';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
const confirmer = useConfirmer();
const store = useAuthStore();
const {user} = storeToRefs(store)
const {getProfile} = store
const referal_code = ref(null)
// const share_url = 'https://www.kiwiread.com/ax2rscc14200'
const share_url = ref(null)
const registerUrl = ref(null)
console.log(user)
const networks = ref( [
        // { network: 'facebook', name: 'Facebook', icon: 'lab la-facebook-f', color: '#1877f2' },
        // { network: 'messenger', name: 'Messenger', icon: 'lab la-facebook-messenger', color: '#0084ff' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'lab la-whatsapp', color: '#25d366' },
        { network: 'viber', name: 'Viber', icon: 'lab la-viber', color: '#59267c' },
        { network: 'email', name: 'Email', icon: 'las la-envelope', color: '#333333' },
        // { network: 'linkedin', name: 'LinkedIn', icon: 'lab la-linkedin-in', color: '#007bb5' },
        { network: 'twitter', name: 'Twitter', icon: 'lab la-twitter', color: '#E1E8ED' },
    ])

const copyToClipboard = async (copiedContent) => {
     try {
        await navigator.clipboard.writeText(copiedContent);
        confirmer.openSnackBar({type: 'success', msg: 'Text copied to clipboard'});
    } catch (err) {
        console.error('Failed to copy: ', err);
        confirmer.openSnackBar({type: 'error', msg: `Failed to copy: ${err}`});
    }
}

const whatsappText = ref('')

const shareOnWhatsApp = (code) => {
//     let text = `Please use my referral code: _*${code}*_
// And get discount on purchase of any package in KiwiRead`

    const encodedMessage = encodeURIComponent(whatsappText.value);
    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
};

const menu = ref(false)
const share_text = ref('')
const share_title = ref('')
const sharing = ref( {
    url: '',
    title: share_title,
    description: share_text,
    quote: 'Spread the word and boost your practice opportunities.',
    hashtags: 'KiwiRead,Referral,MockTest,PTE',
    twitterUser: user.value.user_full_name
})

const constructRegisterUrl = () => {
  const protocol = window.location.protocol   // e.g., "http:"
  const hostname = window.location.hostname   // e.g., "localhost" or "www.google.com"
  const port = window.location.port           // e.g., "3000" or "" (empty string if no port)

  registerUrl.value = `${protocol}//${hostname}${port ? `:${port}` : ''}/register`
}

const assignReferralValues = () => {
    referal_code.value = user.value.referral_code
    share_url.value = `${registerUrl.value}?ref_code=${referal_code.value}`
    share_title.value = 'Referral link of KiwiRead'
    whatsappText.value = `Hello!

Get a special discount on KiwiRead, the top platform for improving English skills.
Simply use my referral code: _*${referal_code.value}*_
Sign up here: ${registerUrl.value}

Happy Learning!
                                
Best Regards,
${user.value.user_full_name}`

share_text.value = `Hello!

Get a special discount on KiwiRead, the top platform for improving English skills.
Simply sign up through my referal link: 
${share_url.value}

Happy Learning!
                                
Best Regards,
${user.value.user_full_name}`

    instaNetwork.value = {
        network: `https://instagram.com/create/story?media=encodeURIComponent('${share_url.value}')&caption=encodeURIComponent('${share_text.value}')`
    }
}

const instaNetwork = ref({
    network: ''
})

onMounted(()=>{
    constructRegisterUrl()
    if(!user.value){
        getProfile().then((res) => {
            assignReferralValues()
        })
    }else{
        assignReferralValues()
    }
})
</script>

<style lang="scss" scoped>
.referal-text {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--color-primary);
    }
}
.display-code {
    border-color: #DBE1E0 !important;
    min-height: 52px !important;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #F7F8F8;
    max-width: 432px;
    flex: 0 0 100%;

    &.style-dashed {
        border-style: dashed !important;
    }
}
.trans-y-center {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}

a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 5px;
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    // & > span {
    //     padding: 5px 8px;
    //     font-size: 12px;
    // }
}
a[class^="share-network-"] .mdi,
a[class^="share-network-"] .las,
a[class^="share-network-"] .lab {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    flex: 0 1 auto;
    font-size: 24px;
}
</style>