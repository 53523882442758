<template>
    <prompt max-width="500" :persistent="voucherSuccess || showSuccess" @on-close="closeDialog">
        <template v-if="showSuccess ? !showSuccess : !voucherSuccess ">
        <div class="pa-4 text-center">
            <h2 class="font-weight-bold kiwi-fs-24">Voucher Code</h2>
            <v-text-field 
                class="kiwi-form-input w-100 mt-4 text-left"
                variant="outlined"
                placeholder="Enter Voucher Code"
                v-model="VoucherCode"
                @input="VoucherEvent"
                :error-messages="validation"
            />
        </div>
        <v-divider />
        <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px" v-if="validVoucher">
            <div>Your Selected Plan</div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center font-weight-bold"><img src="/assets/Icons.svg" class="mr-1" alt="" style="width: 24px; height: 24px;"><span>{{ selectedPlan }}</span></div>
        </div>
        <v-divider />
        <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px">
            <v-btn
                variant="outlined"
                class="flex-md-grow-1 text-center kiwi-bg-white kiwi-text-black text-none kiwi-fs-16"
                size="large"
                @click.prevent="closeDialog"
                style="border-color: #DBE1E0;"
            >Close</v-btn>
            <v-btn
                variant="flat"
                class="flex-md-grow-1 text-center text-none kiwi-fs-16"
                size="large"
                @click.prevent="applyVoucher"
                :disabled="!enableApplyButton ? true : false"
                :class="enableApplyButton ? 'kiwi-bg-primary kiwi-text-white' : 'kiwi-bg-disabled kiwi-button-disable kiwi-text-black'"
            >Apply</v-btn>
        </div>
        </template>
        <template v-else>
            <div class="pa-4 text-center">
                <img src="/assets/successfull.svg" alt="">
                <h2 class="font-weight-bold kiwi-fs-24">Your Voucher Code has been applied Successfully!</h2>
            </div>
            <v-divider />
            <div class="pa-4 text-center">
                <v-btn
                variant="flat"
                block
                class="flex-md-grow-1 kiwi-bg-primary kiwi-text-white text-center text-none kiwi-fs-16"
                size="large"
                :to="{name: 'dashboard'}"
            >Go to Dashboard</v-btn>
            </div>
        </template>
    </prompt>
</template>

<script setup>
import Prompt from '@/components/Prompt/ConfirmAlert.vue'
import { usePackageStore } from '@/stores/package-store';
import {ref} from 'vue'
import {useRouter} from 'vue-router'

const props = defineProps({
    showSuccess: Boolean
})

const router = useRouter();
const emit = defineEmits(['closeModal'])
const enableApplyButton = ref(false);

const VoucherCode = ref(null)
const validation = ref(null)
const voucherSuccess = ref(false)

const packageData = usePackageStore()
const { redeemVoucher, checkVoucher } = packageData
const validVoucher = ref(false)
const selectedPlan = ref(null)

const VoucherEvent = () => {
    if(VoucherCode.value) {
        enableApplyButton.value = true;
        checkVoucher(VoucherCode.value).then(res => {
            if(res.success){
                validation.value = null
                validVoucher.value = true
                selectedPlan.value = res.data.package.name
            }
        }).catch(error => {
            // console.log(error)
            validVoucher.value = false
            selectedPlan.value = null
            if(error.type == 'validation'){
                validation.value = error.message
            }
        })
    }
}

const closeDialog = () => {
    emit('closeModal')
}

const applyVoucher = () => {
    redeemVoucher(VoucherCode.value).then(res => {
        voucherSuccess.value = true
    }
    ).catch(error => {
        if(error.type == 'validation'){
            validation.value = error.message
        }
    })
}
</script>

<style>

</style>