<template>
    <v-dialog v-model="dialog" :persistent="persistent" max-width="900px">
        <v-sheet class="kiwi-modal-card d-flex flex-column kiwi-bg-white kiwi-radius-8">
            <div class="modal-header d-flex justify-space-between align-center py-5 px-4">
                <span class="kiwi-fs-18 font-weight-700 kiwi-text-black-3">Help</span>
                <v-icon class="mdi mdi-close has-circular-border border-black" @click="closeDialog"></v-icon>
            </div>
            <v-divider />
            <div class="d-flex align-center py-5 px-4 font-weight-semi-bold kiwi-fs-14">
                <v-spacer />
                <div class="text-center d-flex">
                <span class="mr-2 cursor-pointer kiwi-text-primary" @click="all">
                    Expand all
                </span>
                <span class="kiwi-text-light-grey-3 font-weight-regular">
                |
                </span>
                <span class="cursor-pointer kiwi-text-primary ml-2" @click="none">
                    Collapse all
                </span>
                </div>
            </div>
            <v-divider />
            <div class="py-5 px-4 help-expansion">

                <v-expansion-panels
                v-model="panel"
                multiple
                >
                    <v-expansion-panel
                        title="Zoom In/Out"
                        value="zoom"
                    >
                        <v-expansion-panel-text>
                            <p>Use keyboard shortcuts to zoom in or out on laptops and pinch to zoom on tablets. Windows and Chromebooks: <strong>Control + Plus (+)</strong> to zoom in and <strong>Control + Minus (-)</strong> to zoom out.</p>
                            <p>Mac: <strong>Command + Plus (+)</strong> to zoom in and <strong>Command + Minus (-)</strong> to zoom out.</p>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="In App Calculator"
                        value="app-calculator"
                    >
                        <v-expansion-panel-text>
                            <p><strong>Calculator:</strong> On math questions, you will have access to a graphing calculator built into the app. You can also use your own approved calculator. </p>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Testing Timers"
                        value="timer"
                    >
                        <v-expansion-panel-text>
                            <p>During testing, a timer will let you know how much time remains for each module or part of the test.</p>
                            <p>At the end of section 1, you'll have a short break. During the break, we'll show you a timer counting down to the beginning of section 2.</p>
                            <p>When time runs out on section 2, the test will end, and your answers will be automatically submitted.</p>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Highlights and Notes"
                        value="highlight-note"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p><strong>Highlights & Notes: </strong>On all non-math questions, you can highlight text and leave yourself notes.</p>
                            <ul style="padding-left: 30px;">
                                <li>Click on <strong>Highlights & Notes</strong> from the top right of the screen.</li>
                                <li>Select some text to highlight.</li>
                                <li>You can change the highlight color, add an underline, make a note, or delete the highlight.</li>
                                <li>When you’re done, simply click outside the highlight.</li>
                                <li>You can click on a highlight any time to edit it.</li>
                            </ul>
                            <p>You cannot annotate pictures, buttons, answer content, or text that you enter. If the content you annotate is shared across multiple questions, you'll see your annotations on all of those questions. Your annotations will not be graded.</p>

                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Line Reader"
                        value="line-reader"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p><strong>Line Reader: </strong>This tool helps you focus as you're reading test content. Click the six dots at the top of the line reader to move it up and down using your keyboard arrows. If you're on a touch screen device, you can also move it with your finger. To see more than one line of text at a time, use the arrow button at the bottom of the line reader to change the number of visible lines.</p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Option Eliminator"
                        value="option-eliminator"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                <strong>Option Eliminator:</strong> On any multiple-choice question, you can cross out answer options you think are wrong. Above the answer, click the ABC button, then you'll see a button next to each answer option, allowing you to cross it out. You can always undo this action.
                            </p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Mark for Review"
                        value="mark-for-review"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                <strong>Mark for Review:</strong> Flag any question you want to come back to later.
                            </p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Option Menu"
                        value="option-menu"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                At the bottom of each testing screen, you'll find a menu that allows you to navigate to any question in the section. You'll see which questions you've answered, which you haven't, and any that you've marked for review.
                            </p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="No Penalty for Guessing"
                        value="no-penalty-for-guessing"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                Points are never deducted for wrong answers. So it’s always better to guess than to leave an answer blank.
                            </p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Restarting the App"
                        value="restarting-the-app"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                If the exam app crashes during your exam, just relaunch it and hit <strong>Resume Testing</strong> to get back to the question you were on. The testing timer will be paused until you resume testing.
                            </p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Submitting Your Answers"
                        value="submitting-your-answers"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                During testing, the app will save all your work. When the exam is over, your answers will be submitted automatically. If you're still working when time expires, your responses will be automatically saved and submitted. If your device is offline or your submission fails for any reason, you'll have time to reconnect and submit your work.
                            </p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Screen Readers"
                        value="screen-readers"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                                <p>
                                    During testing, use the typical/native key commands that you normally use to navigate a webpage with your screen reader. Most exam pages are structured with the following regions. When applicable, you can navigate with these regions/landmarks:
                                </p>
                                <ul style="padding-left: 30px;">
                                    <li>Bluebook Controls
                                        <ul style="padding-left: 30px;">
                                            <li>Test timer</li>
                                            <li>Test tools</li>
                                        </ul>
                                    </li>
                                    <li>Passage, image, or other source content
                                        <ul style="padding-left: 30px;">
                                            <li>Footnotes</li>
                                            <li>Referenced content</li>
                                            <li>Long description</li>
                                        </ul>
                                    </li>
                                    <li>Question and Answer
                                        <ul style="padding-left: 30px;">
                                            <li>Question number</li>
                                            <li>Answer options</li>
                                            <li>Long description</li>
                                        </ul>
                                    </li>
                                    <li>Questions Navigation</li>
                                </ul>
                                <p>In general, the following test components are structured with a heading:</p>
                                <ul style="padding-left: 30px;">
                                    <li>Test name</li>
                                    <li>Passage, image, or other source content</li>
                                    <li>Passage title, source documents, directions, etc.</li>
                                    <li>Question number</li>
                                </ul>
                                <p><strong>Configuration</strong></p>
                                <p>Configure the settings for your assistive technology before you open Bluebook to take a practice test and on test day before you check in. These settings may include verbosity, punctuation, text processing, and other settings as needed. Users of assistive technology must configure settings prior to check-in to be prepared for test day.</p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Screen Magnification"
                        value="screen-magnification"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                                <p>
                                    Screen magnification technology enlarges the content displayed on the computer screen in order to assist students with visual impairments. Although the exam app supports some non-embedded screen magnifier tools from third parties, all users can zoom using their device's native controls (Control +/- on a PC; Command +/- on a Mac; pinch and zoom on an iPad).
                                </p>
                                
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';

const dialog = ref(true);
const panel = ref([]);

const props = defineProps([
    'dialog', 'persistent'
])

const emits = defineEmits(['closeDialog'])

const closeDialog = () => {
    dialog.value = false;
    emits('closeDialog', dialog.value)
}

const all = () => {
    panel.value = ['zoom', 'app-calculator', 'timer', 'highlight-note', 'line-reader', 'option-eliminator', 'mark-for-review', 'option-menu', 'no-penalty-for-guessing', 'restarting-the-app', 'submitting-your-answers', 'screen-readers', 'screen-magnification']
}

const none = () => {
    panel.value = []
}

watch(props.dialog, (val) => {
    if(val === false) {
        closeDialog();
    }
})
</script>

<style lang="scss">
.has-circular-border {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 2px solid;
    font-size: 18px;

    &.border-black {
        border-color: var(--color-black-3);
    }
}
.help-expansion {
    p + p {
        margin-top: 20px;
    }

    .v-expansion-panel-title {
        font-weight: 500;
        color: var(--color-black-3);
        min-height: auto;
    }
    .v-expansion-panel-title, .v-expansion-panel-text__wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .v-expansion-panel__shadow {
        display: none;
    }

    .v-expansion-panel {
        & + .v-expansion-panel {
            border-top: 1px solid var(--color-light-grey-3);
        }
    }
}
</style>