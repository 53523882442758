<template>
  <v-sheet
    class="kiwi-bg-light-grey-6 position-relative overflow-hidden kiwi-text-black kiwi-radius-4 mb-2"
    border
  >
    <img
      src="/assets/eclipse-mask2.png"
      class="position-absolute z-1 top-0"
      alt=""
      style="object-fit: contain; right: 0px"
    />
    <div class="content position-relative z-2 pa-2 px-md-3 py-md-3">
      <v-row class="align-center">
        <v-col cols="12" md="8" class="d-flex align-center justify-start">
          <div class="title kiwi-fs-16 font-weight-semi-bold mt-1 mr-2" v-html="isIQT ? `${test?.topic_slug.toUpperCase()+'_'+ (index + 1)}` : (isQbt ? test?.title : test.description)"></div>

          <div class="d-flex align-center flex-wrap"  v-if="!isQbt && !isIQT">
            <div
              class="author-wrapper kiwi-fs-16 d-flex flex-wrap has-dot-seprator mt-1 mr-2"
            >
              <span v-html="`${test.module ?? 4} Modules`" />
              <v-icon class="mdi mdi-circle-small"></v-icon>
              <span v-html="`${test.total_number_of_question || test.number_of_questions} Questions`" />
            </div>
            <div class="kiwi-dash-sep mt-1 mr-2"></div>
            <div class="d-flex duration kiwi-fs-16 mt-1">
              <img src="/public/assets/timer-02.svg" class="mr-1 size-18" alt="" />
              <span>{{ test.total_time_duration ? test.total_time_duration / 60 : 0 }}hrs</span>
            </div>
          </div>
          <div v-if="test.is_attempted && !test.is_completed">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="bg-white d-flex rounded-circle justify-center align-center" style="width: 22px; height: 22px;">
                  <v-icon class="mdi mdi-timelapse position-relative" style="top:1px;" size="15" color="rgba(224, 130, 77, 1)"></v-icon>
                </span>
              </template>
              <span>
                <span class="rounded-circle" style="width: 4px; height: 4px; background-color: rgba(224, 130, 77, 1);"></span>
                In Progress
              </span>
            </v-tooltip>
          </div>
          <div v-else-if="test.is_completed">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="bg-white d-flex rounded-circle justify-center align-center" style="width: 22px; height: 22px;">
                  <v-icon class="mdi mdi-check-circle position-relative" style="top:1px;" size="15" color="rgba(18, 183, 106, 1)"></v-icon>
                </span>
              </template>
              <span class="custom-tooltip">
                <span class="rounded-circle" style="width: 4px; height: 4px; background-color: rgba(18, 183, 106, 1);"></span>
                Completed
              </span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-md-end">
          <template v-if="test.is_completed">
            <v-btn
              class="kiwi-bg-white border kiwi-bg-white kiwi-border-light-grey kiwi-fs-16 text-capitalize me-2"
              size="small"
              block-sm
              rounded="xl"
              variant="flat"
              style="padding: 0px 10px; font-size: 14px;"
              @click="restart(test)"
              >Restart</v-btn
            >
            <v-btn
              class="kiwi-bg-white border  kiwi-bg-white kiwi-border-light-grey kiwi-fs-16 text-capitalize"
              size="small"
              block-sm
              rounded="xl"
              variant="flat"
              style="padding: 0px 10px; font-size: 14px;"
              v-if="!isIQT"
              @click="mockTestAction(test)"
              >View Result</v-btn
            >
          </template>
          <template v-else-if="!test.is_accessible && !isIQT">
            <v-btn
            class="kiwi-bg-upgrade kiwi-text-white kiwi-fs-16 text-capitalize"
            size="small"
            block-sm
            rounded="xl"
            variant="flat"
            style="padding: 0px 10px; font-size: 14px;"
            @click="mockTestAction(test)"
            >
            <v-icon class="la la-crown mr-2"></v-icon> Upgrade</v-btn>
          </template>
          <template v-else-if="test.is_attempted">
            <v-btn
              class="kiwi-border-primary kiwi-bg-white kiwi-text-primary kiwi-fs-16 text-capitalize"
              size="small"
              block-sm
              rounded="xl"
              variant="outlined"
              style="padding: 0px 10px; font-size: 14px;"
              @click="resume(test)"
              v-if="isQbt"
              >Resume</v-btn
            >
            </template>
            <template v-else>
              <v-btn
                class="kiwi-bg-primary kiwi-text-white kiwi-fs-16 text-capitalize"
                size="small"
                block-sm
                rounded="xl"
                variant="flat"
                style="padding: 0px 10px; font-size: 14px;"
                v-if="!isIQT"
                @click="mockTestAction(test)"
                >Start</v-btn
              >
              <v-btn
                class="kiwi-bg-primary kiwi-text-white kiwi-fs-16 text-capitalize"
                size="small"
                block-sm
                rounded="xl"
                variant="flat"
                style="padding: 0px 10px; font-size: 14px;"
                v-else-if="isIQT"
                @click="action(test, index)"
                >Start</v-btn
              >
            </template>

        </v-col>
      </v-row>
    </div>
  </v-sheet>
  <PromptDialog v-if="prompt" v-bind="prompt" />
</template>

<script setup>
import { ref } from "vue";
import { useTestAction } from "@/composables/useTestAction";
import { computed } from "vue";
import PromptDialog from "../Prompt/PromptDialog.vue";
import { useQuestionBasedTestStore } from "@/stores/questionbased-test-store";
import { useIndividualQustionsTestStore } from "@/stores/individual-question-test-store";
import {storeToRefs} from "pinia";
const questionBasedStore = useIndividualQustionsTestStore();
const { isSubmitted } = storeToRefs(questionBasedStore);
const props = defineProps({
  test: Object,
  type: {
    type: String,
    default: "mock-test",
  },
  index: Number,
  offset: Number,
});
const prompt = ref(null)
const {mockTestAction, IndividualTestAction, start, startIndividual} = useTestAction(props.type)
const questionBasedTestStore = useQuestionBasedTestStore();
const isQbt = computed(() => {
  return props.type  === 'question-based'
})
const isIQT = computed(() => {
  return props.type === 'individual-question'
})
const resume = (test) => {
  prompt.value = {
    title: 'Confirm?',
    content: 'You did not finish this test last time. Do you want to continue from your saved session?',
    actions: [
      {
        text: "Close",
        onClick: () => prompt.value = null,
      },
      {
        text: "Continue",
        type: "primary",
        onClick: () => {
          prompt.value = null
          mockTestAction(test)
        }
      },
    ],
  }

}
const restart = async (test) => {
  if(!isQbt.value) {
    return false;
  }
  prompt.value = {
    content: 'Restarting..',
    progress: true
  }
  try {
    const res = await questionBasedTestStore.restart(test.id)
    if(res) {
      prompt.value = null
      if(isQbt.value) {
        start(test.id)
      } else if(isIQT.value) {
        startIndividual(test.id, test.topic.id, props.index)
      }
    }
  }catch(err) {
    prompt.value = {
      title: 'Failed to restart',
      content: 'Something went wrong! plese try again.'
    }
  }
}
const action = (test , index) => {
  IndividualTestAction(test, index);
  isSubmitted.value = false
}
</script>
