<template>
    <section class="kiwi-information-page kiwi-bg-white pa-4 kiwi-radius-8 fill-height">
        <Header :breadcrumbs="breadcrumbs" />
        <Tab />
    </section>
</template>

<script setup>
import {ref} from 'vue'
import Header from '@/components/Information/Header.vue'
import Tab from '@/components/Information/Tab.vue'

const breadcrumbs = ref([
    {
        title: '',
        disabled: true,
        href: '',
    },
    {
        title: 'Dashboard',
        disabled: false,
        href: '/dashboard',
    }
])
</script>

<style>

</style>