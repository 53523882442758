import { defineStore } from "pinia";
import { api } from "@/plugins/axios";

export const usePaymentStore = defineStore('payment' , () =>{

  function fonepayPayment(uuid, callback = null){
    const url = `subscriptions/transaction/${uuid}/initiate-fonepay-payment/`
    api.post(url).then(res => {
      callback && callback()
      window.location.href = res.data.payment_request_url
    })
  }

  function stripePayment(uuid, callback = null){
    const url = `subscriptions/transaction/${uuid}/initiate-stripe-payment/`
    api.post(url).then(res => {
      callback && callback()
      window.location.href = res.data.url
    })
  }

  async function getDataForEsewaPayment(uuid){
    try{
      const url = `subscriptions/transaction/${uuid}/initiate-esewa-payment/`;
      const res = await api.post(url)
      return res.data
    }catch(error){
      console.log('Error Message: ', error)
    }
  }

  // for test only
  // function generateSignature(total_amount, transaction_uuid, product_code, secret){
  //   const message =  `total_amount=${total_amount},transaction_uuid=${transaction_uuid},product_code=${product_code}`;
  //   const hash = CryptoJS.HmacSHA256(message, secret);
  //   const hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
  //   console.log('sig', hashInBase64)
  //   return hashInBase64;
  // }
  // end for test only

  async function esewaPayment(uuid, callback = null){
    try {
      const request_data = await getDataForEsewaPayment(uuid)
      const url = request_data.url
      const data = request_data.params
      // for test only
      // const url = "https://rc-epay.esewa.com.np/api/epay/main/v2/form"
      // const secret = '8gBm/:&EnhH.1/q'
      // const testSignature = generateSignature(data.total_amount, data.transaction_uuid, data.product_code, secret)
      // end for test only
      const params = {
          amount: data.amount,
          tax_amount: data.tax_amount,
          total_amount: data.total_amount,
          transaction_uuid: data.transaction_uuid,
          product_code: data.product_code,
          product_service_charge: data.product_service_charge,
          product_delivery_charge: data.product_delivery_charge,
          success_url: data.success_url,
          failure_url: data.failure_url,
          signed_field_names: data.signed_field_names,
          signature: data.signature,
          // signature: testSignature,
      };
      // console.log(params)

      const form = document.createElement('form')
      form.method = "POST"
      form.action = url
      form.target = '_self'

      for(const key in params){
        if(data.hasOwnProperty(key)){
          const hiddenfield = document.createElement('input')
          hiddenfield.type = 'hidden'
          hiddenfield.name = key
          hiddenfield.value = params[key]
          form.appendChild(hiddenfield)
        }
      }

      document.body.appendChild(form)
      callback && callback()
      form.submit()

    } catch (error) {
      console.error('Payment initiation error:', error);
      this.errorMessage = 'Failed to initiate payment. Please try again.';
    }
  }
    

    return {esewaPayment, fonepayPayment, stripePayment}
})