<template>
    <section class="pa-4 tab-section">
        <v-tabs
            v-model="tab"
            class=""
        >
            <v-tab 
                :value="`tab-${tab.id}`"
                class="kiwi-fs-16-overwrite font-weight-bold text-none"
                :class="current_tab === tab.id ? 'kiwi-text-primary kiwi-bg-gradient-1' : ''"
                v-for="tab in tabs" :key="tab.id"
                @click="current_tab = tab.id"
            >{{ tab.title }}</v-tab>
        </v-tabs>
        <v-divider />
        <BlogList :category="current_tab"/>
    </section>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import BlogList from '@/components/Information/BlogList.vue'
import { api } from '@/plugins/axios';

const tabs = ref([])
const current_tab = ref(null);

const getKnowledgeBaseCategory = async () => {
    try {
        const res = await api.get('/help-and-support/kb-category/')
        tabs.value = res.data
        current_tab.value = tabs.value[0].id
    } catch (error) {
        // console.log(error)
    }
}

onMounted(() => {
    getKnowledgeBaseCategory()
})
</script>

<style>

</style>