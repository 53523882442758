export function useAnswer() {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const findIndices = (marksObtained  , answerList , indexed = false)=>{

    return marksObtained
      .map((item,index)=> answerList.includes(String(item.id)) ? index : -1)
      .filter(index=>index !== -1)
      .map(index=>!indexed ? alphabet[index] : index+1 )
  }
  const findSelected = (marksObtained , indexed = false)=>{

    const index = marksObtained.findIndex(item => item.was_selected);
    return !indexed ? alphabet[index] : index +  1
  }

  const findCorrectAnswerIndices = (marksObtained , indexed = false)=>{
    return marksObtained
      .map((item,index)=>item.is_correct ? index : -1)
      .filter(index=>index !== -1)
      .map(index =>!indexed ? alphabet[index] : index+1 )
  }

  const findHighlightedAnswer = (selectedAnswerIndexes  , questionText )=>{
    const plainText = questionText.replace(/<[^>]*>/g, '');
    const wordsArray = plainText.split(/\s+/);
    const selectedWords = selectedAnswerIndexes.map(index=>wordsArray[index]);
    return selectedWords.join(',');
  }
  const findHighlightedAnswerFor = (marksObtained, questionText , correct= true , notHighlighted = false , returnCount = false)=>{
    const plainText = questionText.replace(/<[^>]*>/g, '');
    const wordsArray = plainText.split(/\s+/);
    let wordIndex = [];
    if(correct){
      wordIndex =marksObtained
        .filter(item => item.is_correct && item.is_selected)
        .map(item => parseInt(item.index));
    }else if(notHighlighted){
      wordIndex =marksObtained
        .filter(item => item.is_correct && !item.is_selected)
        .map(item => parseInt(item.index));
    } else{
      wordIndex = marksObtained
        .filter(item => !item.is_correct && item.is_selected)
        .map(item => parseInt(item.index));
    }
    const words = wordIndex.map(index => wordsArray[index])
    if(returnCount){
      return words.length;
    }
    return words.join(',');
  }

  const findIncorrectAnswer = (answerText , questionAnswer) =>{
    console.log('my ans',answerText);
    console.log('qe',questionAnswer);
    const answerArray = answerText.split(' ').map(word =>
      word.toLowerCase().replace(/[^a-z0-9]/g, '')
    );
    const questionAnswerArray = questionAnswer.split(' ').map(word =>
      word.toLowerCase().replace(/[^a-z0-9]/g, '')
    );
    console.log('my ans', answerArray);
    console.log('qe',questionAnswerArray);
    const incorrectAnswers = answerArray.filter(item =>!questionAnswerArray.includes(item));
    return incorrectAnswers.join(', ');
  }
  const findCorrectWords = (marksObtained) =>{
    const correctAnswers = marksObtained.map(item => item.correct_answer);
    return correctAnswers.join(', ');
  }

  const getWordCount = (marksObtained , correct=false  , incorrect = false , notHighlighted = false)=>{
    if(correct){
      return marksObtained.filter(item => item.correct_answer === item.selected_answer).length;
    }else if(incorrect){
      return marksObtained.filter(item => item.selected_answer !== '' && item.correct_answer != item.selected_answer).length;
    }else if(notHighlighted){
      return marksObtained.filter(item => ! item.selected_answer).length;
    }
  }


  return {
    findIndices , findSelected , findCorrectAnswerIndices,
    findHighlightedAnswer, findHighlightedAnswerFor,
    findIncorrectAnswer , findCorrectWords , getWordCount,
  }
}
