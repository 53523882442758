/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
// import pinia from '@/stores'
import router from '@/router'
import { createPinia } from 'pinia'
import { VueMasonryPlugin } from "vue-masonry";
import VueSocialSharing from 'vue-social-sharing'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const globalOptions = {
  mode: 'auto',
};
const pinia = createPinia();
export function registerPlugins (app) {
  app
    .use(vuetify)
    .use(pinia)
    .use(router)
    .use(VueMasonryPlugin)
    .use(VueTelInput, globalOptions)
    .use(VueSocialSharing)
  return {
      router
  }
}
