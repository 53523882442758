<template>
    <v-sheet
      class="kiwi-my-practice-page kiwi-mock-test kiwi-bg-white kiwi-test-black pa-0 kiwi-radius-8 overflow-hidden border kiwi-border-light-grey-3"
      variant="flat"
    >
      <v-sheet class="kiwi-mock-test-header py-3 px-4 d-flex flex-wrap" variant="flat">
        <h1 class="kiwi-fs-20 font-weight-bold">Vouchers</h1>
        <v-spacer />
        <v-btn variant="flat" class="kiwi-bg-primary kiwi-text-white kiwi-radius-4 text-none align-center" :to="{name: 'ins_create-voucher'}">
          <v-icon class="mdi mdi-plus-circle-outline mr-2" />Buy Voucher
        </v-btn>
      </v-sheet>
      <v-divider></v-divider>
      <v-sheet class="kiwi-mock-test-content py-3 px-4">
        <v-row class="justify-space-between align-top">
            <v-col cols="12" md="5" lg="5">
                <v-text-field
                    v-model="filter.search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    variant="outlined"
                    hide-details
                    single-line
                    clearable=""
                    @keyup.enter="searchData"
                    @click:clear="searchData"
                    density="compact"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" lg="2"  xlg="1" class="text-right">
                    <v-btn
                        style="margin: 0 !important;"
                        class="h-100 text-none text-uppercase mt-3 text-body-1 kiwi-bg-primary kiwi-text-white"
                        variant="flat"
                        prepend-icon="mdi mdi-tray-arrow-down"
                        @click="exportData"
                    >
                    Export
                    </v-btn>
            </v-col>
        </v-row>

      </v-sheet>
      <v-divider></v-divider>
      <v-sheet class="kiwi-mock-test-content py-3 px-4">
        <div class="justify-start d-flex flex-wrap">
            <div class="px-1" v-for="(status, i) in statusList" :key="status.status">
                <v-chip :variant="status.status === filter.status ? '' : ''"
                    class="align-center status-chip"
                    :class="{
                        'active-status': status.status === filter.status,
                    }"
                    :style="{
                        backgroundColor: status.status === filter.status ? status.color: null
                    }"
                    @click="selectStatus(status)">
                   <span class="dot" :style="{backgroundColor: status.color}"></span>
                   {{ status.name }}
                   ({{ statusCount[status.status] || 0 }})
                </v-chip>
            </div>
            <v-divider vertical class="ml-1 mr-1" />
            <div class="px-1" v-for="(pkg, i) in packages">
                <v-chip :variant="filter.package.includes(pkg.id) ? 'outlined' : ''" class="align-center pkg-chip" :class="{'active-pkg': filter.package.includes(pkg.id)}"  :color="filter.package.includes(pkg.id) ? 'rgba(255, 188, 66, 1)' : 'rgba(219, 225, 224, 1)'" @click="selectPackage(pkg)">
                    <img :src="pkg.image" width="16" class="mr-1" />
                    <span class="kiwi-text-black">{{pkg.name}} ({{ pkg.voucher_count || 0 }}) </span>
                </v-chip>
            </div>
          <div class="px-3"  v-if="filter.package.length || filter.status">
            <v-chip  @click="resetFilter()" color="red" variant="text">
              <v-icon left class="mr-1">mdi-close</v-icon>
              <span class="text-decoration-underline">Remove Filter</span>
            </v-chip>
          </div>
        </div>
      </v-sheet>
      <v-divider></v-divider>
      <v-data-table-server
            class="inst-list-table"
            v-model:items-per-page="filter.page_size"
            :headers="headers"
            :items="vouchers"
            :items-length="pagination.count || 0"
            :loading="fetching"
            @update:page="serverIndex"
            :items-per-page-options="[10, 15, 20, 30]"
            @update:items-per-page="voucherStore.getVouchers"
            loading-text=""
            items-per-page-text=""
            hide-default-footer
        >
        <template v-slot:item="{ item, index }">
            <tr>
                <td style="min-width: 110px; white-space: nowrap;" class="voucher-status" :class="[getStatus(item)]">{{ getStatus(item) }}</td>
                <td>{{}}
                    <span class="flex align-center" v-if="item.package">
                        <img :src="item.package.image" width="17" />
                         {{item.package.name}}
                    </span>
                </td>
                <td>
                    <div
                        class="d-flex flex-wrap align-center justify-space-between w-100 cursor-pointer pr-10"
                        @click.prevent="copyToClipboard(item.code)"
                        style="max-width: 490px" 
                    >
                        <span class="flex-grow-1 mr-2" style="flex: 1; word-break: break-all;">{{ item.code }}</span>
                        <i class="mdi mdi-content-copy d-flex align-center justify-center cursor-pointer" style="width: 38px; height: 38px; background-color: #F5F6F7;"></i>
                    </div>
                </td>
                <td>
                   <span v-if="item.used_by">
                    <div>{{ item.used_by.user_full_name }}</div>
                    <div style="color: rgba(78, 84, 82, 1); font-size: 13px !important;">{{ item.used_by.email }}</div>
                   </span>
                </td>
            </tr>
        </template>
        <template v-slot:no-data>
          <div class="no-data-container">
            <img src="/assets/no_data.svg" alt="No data" class="no-data-image" />
          </div>
        </template>
        </v-data-table-server>
      <v-divider></v-divider>
      <div>
        <v-sheet class="kiwi-bg-light-grey-7 kiwi-text-black py-3 px-4">
          <v-row>
            <v-col cols="12" md="6">
              <div class="d-flex align-center">
                <v-select
                  border
                  class="kiwi-bg-white kiwi-border-light-grey-6 mr-2 limit-select"
                  :items="[2,10, 15, 20, 25]"
                  v-model="filter.page_size"
                  flat
                  variant="outlined"
                  placeholder="1"
                  hide-details
                  style="max-width: 90px;"
                  @update:modelValue="voucherStore.getVouchers"
                ></v-select>
                <span v-if="vouchers.length">Showing: {{ showing }} to {{ showing + (vouchers.length - (showing === 1 ?  1 : 0))  }} </span>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <v-pagination
                v-model="filter.page"
                :length="pageCount"
                :total-visible="7"
                variant="text"
                @update:modelValue="voucherStore.getVouchers"
                density="compact"
              >
                <template v-slot:prev>
                  <v-btn icon size="sm" class="text-none kiwi-border-light-grey-3 pa-2" variant="outlined">
                    <span>Previous</span>
                  </v-btn>
                </template>
                <template v-slot:next>
                  <v-btn icon size="sm" class="text-none kiwi-border-light-grey-3 pa-2" variant="outlined">
                  <span>Next</span>
                  </v-btn>
                </template>
              </v-pagination>
            </v-col>
          </v-row>
        </v-sheet>
      </div>
    </v-sheet>
    <PromptDialog v-if="prompt" v-bind="prompt" />
  </template>
  <script setup>
  import {computed, ref} from 'vue';
    import { useInstituteVoucherStore } from '@/stores/institute/institute-voucher-store';
    import { storeToRefs } from 'pinia';
    import moment from 'moment';
    import PromptDialog from '@/components/Prompt/PromptDialog.vue';
    import SnackBar from '@/components/SnackBar.vue'
    import {useConfirmer} from '@/stores/confirmer'

    const confirmer = useConfirmer();
    const prompt = ref(null)
    const statusList = ref([
        {
            name: 'Redeemed',
            status: 'redeemed',
            color: 'rgba(247, 144, 9, 1)'
        },
        {
            name: 'Active',
            status: 'active',
            color: 'rgba(28, 169, 114, 1)'
        },
        {
            name: 'Expired',
            status: 'expired',
            color: 'rgba(240, 68, 56, 1)'
        },
    ])
    const voucherStore = useInstituteVoucherStore();
    const {fetching, vouchers, packages, filter, pagination, statusCount , total} = storeToRefs(voucherStore)
    filter.value.page = 1;
    filter.value.search = ''
    voucherStore.getPackages()
    voucherStore.getStatusCount()
    voucherStore.getVouchers()
    const selectStatus = (status) => {
        if(filter.value.status === status.status) {
            filter.value.status = null
        }else {
            filter.value.status = status.status
        }
        filter.value.page = 1
        voucherStore.getVouchers()
    }
    const selectPackage = (pkg) => {
        if(filter.value.package.includes(pkg.id)) {
            filter.value.package = filter.value.package.filter(id => id !== pkg.id)
        }else {
            filter.value.package.push(pkg.id)
        }
        filter.value.page = 1
        voucherStore.getVouchers()
    }
    const getPackageData = (id) => {
        return packages.value.find(p => p.id === id)
    }
    const searchData = () => {
        filter.value.page = 1
        voucherStore.getVouchers()
    }
    const getStatus = (v) => {
        if(v.is_used) {
            return "Redeemed"
        }else if(moment(v.expiry_date).isBefore(moment(), 'day')) {
            return "Expired"
        }else {
            return "Active"
        }
    }
    const exportData = async () => {
        prompt.value = { content: 'Exporting..', progress: true, width: 300 }
        try {
            await voucherStore.exportData()
            prompt.value = false
        }catch(err) {
            prompt.value = { title: 'Export Failed', content: 'Failed to vouchers data, please try again',
            actions: [
                {
                text: "Close",
                type: "fill",
                onClick: () => prompt.value = null,
                },
            ]
            }
        }
    }
    const headers = ref([
        { title: 'Status',  key: 'status', sortable: false},
        { title: 'Package Name', key: 'phone',  sortable: false},
        { title: 'Voucher Code', key: 'code', sortable: false},
        { title: 'Assignee', key: 'assignee', sortable: false },
      ])
    const getRemaingDays = (day) => {
        if(day === '') {
            return "-"
        }else if(day === 0) {
            return "Today"
        }else if(day) {
            return `$${day} day${day>1 ? 's' : ''}`
        }
        return '-'
    }
    const serverIndex = (page)=>{
      filter.value.page  = page;
       voucherStore.getVouchers();
    }
    const resetFilter = ()=>{
      filter.value.package= [];
      filter.value.status='';
      voucherStore.getVouchers();
    }
  const showing = computed(() => {
    return filter.value.page <= 1 ? 1 : (filter.value.page_size * (filter.value.page - 1))
  })
  const totalCount = computed(()=>{
    return pagination.value.count
  })
  const pageCount = computed(() => {
    return Math.ceil(totalCount.value  / filter.value.page_size);
  });
const copyToClipboard = async (copiedContent) => {
  try {
    await navigator.clipboard.writeText(copiedContent);
    confirmer.openSnackBar({
      type: "success",
      msg: "Voucher copied to clipboard",
    });
  } catch (err) {
    console.error("Failed to copy: ", err);
    confirmer.openSnackBar({ type: "error", msg: `Failed to copy: ${err}` });
  }
};
</script>
<style lang="scss">
    .v-table.inst-list-table {
        thead {
            tr {
                th {
                    white-space: nowrap;
                    font-size: 1rem;
                    background: rgba(242, 244, 246, 1);
                }
            }
        }
        tbody {
            tr {
                td {
                    font-size: 1rem;
                }
            }
        }
    }
    .pkg-chip {
        font-weight: 500;
    }
    .pkg-chip.active-pkg {
        background: linear-gradient(127.21deg, #FFFFFF 12.55%, #FAF3CE 111.14%);
    }
    .voucher-status.Redeemed {
        color: rgba(247, 144, 9, 1);
    }
    .voucher-status.Active {
        color: rgba(28, 169, 114, 1);
    }
    .voucher-status.Expired {
        color: rgba(240, 68, 56, 1);
    }
    .status-chip {
        font-weight: 500;;
        &.active-status {
            color: #fff !important;
            .dot {
                background-color: #fff !important;
            }
        }
        .dot {
            width: 6px;
            height: 6px;
            margin-right: 4px;
            border-radius: 50%;
            display: inline-block;
        }
    }
</style>
