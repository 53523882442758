<template>
    <section class="">
        <v-sheet class="kiwi-paragraphs-bundle mt-5" v-html="termsContent">
            
        </v-sheet>

    </section>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import { api } from '@/plugins/axios';

// const termsContent = `Terms and Conditions
// The portal, kiwiread, has been designed to align with both content and technical standards,
// providing a structured practice platform for the English Proficiency Exam. These terms and
// conditions have been carefully crafted to cover all aspects of service usage. We strongly
// recommend reading them thoroughly before using our services.
// By reviewing these Terms & Conditions, you acknowledge and agree to the legal procedures and
// obligations associated with our services. Accessing or using our website or any of our services
// signifies your agreement to all the terms outlined.
// You represent that you are over the age of 18. The Company does not permit those under 18 to
// use the Service.
// Before making any purchase, kindly read the related documents to avoid potential issues. Be sure
// to read our Terms & Conditions thoroughly before agreeing to our privacy policy.
// Definitions
// In these Terms of Service, unless stated otherwise, the following words and phrases shall have
// the meanings assigned to them here under:
// ● Application means the software program provided by the Company downloaded by You
// on any electronic device, named Kiwiread
// ● Account means an unique account on the Platform which is the primary means to access
// the Services or parts of our Service
// ● Customer means the legal person who has created an Account and accepted these Terms
// of Service
// ● Content refers to content such as text, images, or other information that can be posted,
// uploaded, linked to or otherwise made available by You, regardless of the form of that
// content.
// ● Device means any device that can access the Service such as a computer, a cell phone or
// a digital tablet.
// ● Orders mean a request by You to purchase Goods from Us.
// ● Platform means the Kiwiread application
// ● Service means the website, content, any form of service, and products made available
// through the Platform.
// ● Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that
// form the entire agreement between You and the Company regarding the use of the
// Service.
// ● Website refers to KiwiRead, accessible from https://www.kiwiread.com● You mean the individual accessing or using the Service, or the company, or other legal
// entity on behalf of which such individual is accessing or using the Service, as applicable.
// Terms & Conditions for White Label Service:
// Admin Panel Visibility: Despite our best efforts to eliminate references to "Kiwiread" in the
// Admin Panel, there may be instances where such references are visible to sub-users or admins.
// We will work diligently to minimize this visibility as much as possible.
// Limitation of Liability: We cannot guarantee the complete removal of all "Kiwiread" references
// in the Admin Panel. Therefore, we are not liable for any incidental or consequential damages that
// may arise from the visibility of "Kiwiread " in the Admin Panel, despite our efforts to reduce its
// presence.
// Confidentiality: Both parties agree to maintain the confidentiality of any proprietary
// information exchanged during the white label customization process.
// Intellectual Property Rights: All intellectual property rights related to the white-labeled
// platform, including copyrights, trademarks, and patents, remain the sole property of "Kiwiread"
// or its licensors.
// By proceeding with the white label customization of our platform, you agree to these terms and
// conditions.
// Product Copyright Information
// PTE (Pearson Test of English) is an independent product of Pearson and is not endorsed or
// affiliated with Pearson. The name and content of PTE are copyrighted by Pearson, and Kiwiread
// does not claim any rights over them
// Third-Party Links
// While navigating our website, you may encounter third-party links that may use cookies to
// gather personal information or data electronically. Please note that we do not have control over
// these activities, as they are not covered by our privacy policy. Therefore, we recommend
// referring to the privacy policy of the third party to understand their specific practices and options
// for opting out.
// Placing Orders for GoodsYour Information: When placing an order for goods through the service, you confirm that you
// are legally able to enter into binding contracts. To place an order, you may need to provide
// various information such as your name, email, phone number, credit card details. You assert that
// you have the legal right to use the payment method provided and that all information supplied is
// accurate. By submitting this information, you authorize us to share it with payment processing
// third parties to complete your order.
// Order Cancellation: We reserve the right to refuse or cancel orders for various reasons
// including service availability, errors in descriptions or prices, or suspected fraud. Refunds are
// generally not provided, except where required by applicable law.
// Payment: Goods are typically subject to a one-time payment, with various payment methods
// accepted. Payment cards undergo validation and authorization by your card issuer, and
// non-authorization may result in delays or non-delivery of your order. Refunds are generally not
// provided for unused content or partial membership periods.
// Intellectual Property
// The Service and its original content (excluding content provided by users), features, and
// functionality are and will remain the exclusive property of the Company and its licensors.
// The Service is protected by copyright, trademark, and other laws, both domestically and
// internationally.
// Our trademarks and trade dress may not be used in connection with any product or service
// without prior written consent from the Company.
// Pricing Policy
// The Company retains the right to adjust its prices at any time before an Order is accepted.
// After accepting an Order, the Company may revise the quoted prices if any external factors affect
// delivery. These factors can include government actions, changes in customs duties, increased
// shipping costs, fluctuations in foreign exchange rates, or any other issues beyond the Company’s
// control. Should this occur, You will have the option to cancel Your Order.`

const termsContent = ref('')

onMounted(() => {
    if(termsContent.value == ''){
        try {
            const response = api.get('common/project-config/terms-and-condition/only');
            response.then(res => {
                termsContent.value = res.data['terms-and-condition']
            })
        } catch(err) {
            console.log(err)
        }
    }
})
</script>

<style>

</style>