<template>
<div 
    class="info-box get-in-touch-box px-4 py-8 kiwi-radius-4 kiwi-bg-light-grey-5 kiwi-text-black-2 d-flex flex-column align-center justify-center mt-8"
>
    <img src="/assets/Avatar group.svg" alt="avatar group">
    <article class="text-center mt-4">
        <h2 class="kiwi-fs-24 font-weight-bold kiwi-text-black-3">Still have questions?</h2>
        <p class="font-weight-medium kiwi-fs-18 mt-1">Can’t find the answer you’re looking for? Please chat to our friendly team.</p>
        <v-btn 
            class="kiwi-button kiwi-radius-5 kiwi-bg-primary kiwi-text-white font-weight-semi-bold shadow-btn-1 mt-5 text-none"
            variant="flat"
            size="large"
            @click="callNumber"
        >
            Get in touch
        </v-btn>
    </article>
</div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import {useConfigStore} from '@/stores/configStore'

const config = useConfigStore()
const configData = computed(() => {
    return config.configData
})

const callNumber = () => {
    window.location.href = `tel:${configData.value['phone-number']}`
}

onMounted(async () => {
    if(Object.keys(configData.value).length === 0){
        await config.getConfigData()
    }
})
</script>