/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'
import {initSenitry} from './sentry'
// Components
import App from './App.vue'
import Loading from './components/Util/Loader.vue'
// Composables
import { createApp } from 'vue'

const app = createApp(App)
const plugins = registerPlugins(app)
app.component('Loading', Loading)
app.mount('#app')
initSenitry(app, plugins.router)