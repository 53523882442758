import { api, apiWithoutAuth } from "@/plugins/axios";
import { downloadResponse } from "@/utils/utils";
import axios from 'axios';
import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from "vue";
export const useInstituteStudentStore = defineStore('instituteStudentStore' , () =>{
    const fetching = ref(false)
    const exporting = ref(false)
    const students = ref([])
    const tokens = ref({cancel: null})
    const pagination = ref({
    })
    const packages = ref([])
    const filter = ref({
        current_package: null,
        search: null,
        page: 1,
        page_size: 10
    })
    async function getStudents() 
    {
        try {
            fetching.value = true
            await api.get('/institute/student/', {params: {...filter.value}}).then(res => {
                const {results, ...payload} = res.data
                students.value = results
                pagination.value = {...pagination.value, ...payload}
                fetching.value = false
            })
        } catch (error) {
            throw error
        }
    }
    async function getPackages() 
    {
        try {
            await api.get('/institute/package/', {params: {with_student_count: 1}}).then(res => {
                packages.value = res.data.results || []
            })
        } catch (error) {
            throw error
        }
    }
    async function exportData() 
    {
        exporting.value = true
        try {
            const res = await api.get('/institute/student/export-csv', {
                responseType: 'blob',
                params: {...filter.value, page_size: null, page: null}
            }).then(res => {
                downloadResponse(res, 'students')
                exporting.value = false
            })
            return res;
        } catch (error) {
            exporting.value = false
            throw error
        }
    }
    async function applyVoucher(id, code) 
    {
        try {
            return await api.post('subscriptions/subscribe-package/', {
                voucher_code: code,
                apply_for_user_id: id
            }).then(res => {
                return res
            })
        } catch (error) {
            throw error
        }
    }
    return {
        fetching, 
        students,
        packages,
        pagination,
        filter,
        getPackages, 
        getStudents,
        exportData,
        applyVoucher
    }
})

