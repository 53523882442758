<template>
    <v-sheet class="kiwi-profile-settings h-100 d-flex flex-wrap flex-column align-center justify-start kiwi-bg-light-grey-5 kiwi-radius-8 text-cennter border kiwi-border-light-grey-3" varaint="flat">
        <article class="profile-settings-header w-100 pa-4">
            <v-row align="center">
                <v-col cols="12" md="8" lg="9" xl="10">
                    <h1 class="kiwi-fs-24 font-weight-bold">Profile Settings</h1>
                    <p class="kiwi-fs-16 font-weight-medium kiwi-text-light-black-3">Take a look at your profile and make necessary changes</p>
                </v-col>
                <v-col cols="12" md="4" lg="3" xl="2" class="text-right">
                    <v-btn 
                        variant="flat"
                        class="kiwi-bg-primary kiwi-text-white font-weight-medium text-none kiwi-fs-16 kiwi-radius-4"
                        size="large"
                        @click.prevent="editModeEnabler"
                        v-if="!editMode"
                    >Edit Profile</v-btn>
                </v-col>
            </v-row>
        </article>
        <section class="profile-settings-content kiwi-bg-white w-100">
            <!-- tab menu section start -->
            <v-sheet class=""  variant="flat">
                <v-tabs
                    v-model="activeTab"
                >
                    <v-tab 
                        v-for="(menu, i) in tabMenus"
                        :key="i"
                        :value="menu.value" 
                        :class="activeTab === menu.value ? ' kiwi-bg-gradient-1 kiwi-text-primary' : 'kiwi-text-light-black-2'" 
                        class="font-weight-semi-bold text-none kiwi-fs-16"
                        @click.prevent="onMenuClick(menu.value)"
                    >{{ menu.label }}</v-tab>
                </v-tabs>
                <v-divider />
            </v-sheet>
            <!-- tab menu section end -->

            <!-- tab content section start -->
            <v-form class="pa-4">
                <v-row class="mt-1">
                    <template v-if="activeTab === 'account_settings'">
                        <v-col cols="12" md="6" class="py-0">
                            <div class="kiwi-form-group form-gorup">
                                <label class="kiwi-form-label">Full Name <span class="kiwi-text-error">*</span></label>
                                <v-text-field 
                                    class="kiwi-form-input"
                                    variant="outlined"
                                    v-model="formData.account.user_full_name"
                                    :disabled="!editMode ? true : false"
                                    :error-messages="validation?.user_full_name"
                                    :rules="[handleErrors.rules.fullname]"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="kiwi-form-group form-gorup">
                                <label class="kiwi-form-label">Where are you from <span class="kiwi-text-error">*</span></label>
                                <v-autocomplete 
                                    class="kiwi-form-input"
                                    variant="outlined"
                                    :items="countries"
                                    item-value="name"
                                    item-title="name"
                                    v-model="formData.account.country"
                                    @update:modelValue="countryUpdate"
                                    :disabled="!editMode ? true : false"
                                    :error-messages="validation?.country"
                                    :rules="[handleErrors.rules.required]"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="kiwi-form-group form-gorup">
                                <label class="kiwi-form-label">Email Address <span class="kiwi-text-error">*</span></label>
                                <v-text-field 
                                    type="email"
                                    class="kiwi-form-input"
                                    variant="outlined"
                                    v-model="formData.account.email"
                                    required
                                    :disabled="true"
                                    :error-messages="validation?.email"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="kiwi-form-group form-gorup">
                                <label class="kiwi-form-label">Phone Number <span class="kiwi-text-error">*</span></label>
                                <vue-tel-input
                                    v-model="formData.account.phone_number"
                                    styleClasses="kiwi-form-input kiwi-radius-4"
                                    :dropdownOptions="dropdownOptions"
                                    :defaultCountry="country_code"
                                    :key="country_code"
                                    :inputOptions="inputOptions"
                                    @validate="fetchTelError"
                                ></vue-tel-input>
                                <div class="v-input--error" v-if="validation?.phone_number">
                                    <div class="v-input__details">
                                        <div class="v-messages v-input__error" role="alert" aria-live="polite" id="input-17-messages">
                                            <span class="v-messages__message">
                                                {{ validation?.phone_number?.[0] }}
                                            </span>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </v-col>
                        <v-col cols="12" class="pt-0 d-flex" style="grid-gap: 10px;" v-if="editMode">
                            <v-btn variant="flat" class="kiwi-bg-primary kiwi-button kiwi-text-white text-none" size="large" @click="updateProfile" :disabled="!isValid">
                                Save Changes
                            </v-btn>
                            <v-btn variant="outlined" class="kiwi-bg-white kiwi-button kiwi-text-cancel kiwi-border-light-grey-3 text-none" size="large"
                                @click.prevent="cancelForm"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                    </template>
                    <template v-if="activeTab === 'security'">
                        <v-col cols="12" md="6" lg="4" class="py-0">
                            <div class="kiwi-form-group form-gorup position-relaitve">
                                <label class="kiwi-form-label">Old Password <span class="kiwi-text-error">*</span></label>
                                <v-text-field
                                    :type="toggleEye ? 'password' : 'text'"
                                    :append-inner-icon="toggleEye ? 'mdi-eye' : 'mdi-eye-off'"
                                    variant="outlined"
                                    class="kiwi-form-input  kiwi-radius-4 kiwi-position-relative z-1 w-100"
                                    placeholder="Password"
                                    v-model="formData.security.current_password"
                                    @click:append-inner="togglePassword('old')"
                                    :error-messages="validation?.current_password"
                                />
                                    <!-- @keypress.enter="login " -->
                                    <!-- @input="inputPassword" -->
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="4" class="py-0">
                            <div class="kiwi-form-group form-gorup position-relaitve">
                                <label class="kiwi-form-label">New Password <span class="kiwi-text-error">*</span></label>
                                <v-text-field
                                    :type="toggleEye1 ? 'password' : 'text'"
                                    :append-inner-icon="toggleEye1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    variant="outlined"
                                    class="kiwi-form-input  kiwi-radius-4 kiwi-position-relative z-1 w-100"
                                    placeholder="Password"
                                    v-model="formData.security.new_password"
                                    @click:append-inner="togglePassword('new')"
                                    :error-messages="validation?.new_password"
                                />
                                    <!-- @keypress.enter="login " -->
                                    <!-- @input="inputPassword" -->
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="4" class="py-0">
                            <div class="kiwi-form-group form-gorup position-relaitve">
                                <label class="kiwi-form-label">Confirm Password <span class="kiwi-text-error">*</span></label>
                                <v-text-field
                                    :type="toggleEye2 ? 'password' : 'text'"
                                    :append-inner-icon="toggleEye2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    variant="outlined"
                                    class="kiwi-form-input  kiwi-radius-4 kiwi-position-relative z-1 w-100"
                                    placeholder="Password"
                                    v-model="formData.security.verify_new_password"
                                    :error-messages="validation?.verify_new_password"
                                    @click:append-inner="togglePassword('confirm')"
                                />
                                    <!-- @keypress.enter="login " -->
                                    <!-- @input="inputPassword" -->
                            </div>
                        </v-col>
                        
                        <v-col cols="12" class="pt-0 d-flex mb-4" style="grid-gap: 10px;">
                            <v-btn variant="flat" class="kiwi-bg-primary kiwi-button kiwi-text-white text-none" size="large" @click="updatePassword">
                                Update Password
                            </v-btn>
                            <v-btn variant="outlined" class="kiwi-bg-white kiwi-button kiwi-text-cancel kiwi-border-light-grey-3 text-none" size="large" @click="onMenuClick('account_settings')">
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-divider />
                        <v-col cols="12" md="8" lg="9" xl="10">
                            <div class="py-4">

                                <h1 class="kiwi-fs-24 font-weight-bold">Delete Account</h1>
                                <p class="kiwi-fs-16 font-weight-medium kiwi-text-light-black-3">If you no longer wish to use KiwiRead, you can permanently close your account.</p>
                                <v-btn variant="outlined" class="kiwi-bg-error kiwi-button kiwi-text-white kiwi-border-light-grey-3 text-none mt-5" size="large" @click="requestUserDelete()">
                                    Delete Account
                                </v-btn>
                            </div>
                        </v-col>
                    </template>
                </v-row>
            </v-form>
            <!-- tab content section end -->
        </section>
        <UserDelete v-if="openDeletePrompt" @closeModal="closeDeletePrompt" />
    </v-sheet>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import { useAuthStore } from '@/stores/auth/authStore';
import { storeToRefs } from 'pinia';
import { useConfirmer } from '@/stores/confirmer';
import countries from "@/handler/CountryCodes";
import { useValidation } from "@/composables/useValidation";
import { computed } from 'vue';
import UserDelete from './UserDelete.vue';

const props = defineProps({
    profileData: Object,
})
const tabMenus = ref([
    {
        label: 'Account Settings',
        value: 'account_settings',
    },
    {
        label: 'Security',
        value: 'security',
    },
])

const activeTab = ref('account_settings');
const editMode = ref(false);
const telError = ref(true);

const dropdownOptions = ref({
  showDialCodeInList: true,
  showDialCodeInSelection: true,
  showFlags: true,
  disabled: true,
});


const inputOptions = ref({
  readonly: true,
})

const toggleEye = ref(true)
const toggleEye1 = ref(true)
const toggleEye2 = ref(true)

const store = useAuthStore()
const { user } = storeToRefs(store)
const { getProfile, setProfile, setNewPassword } = store

const formData = ref({
    account : {
        user_full_name : null,
        country: null,
        email: null,
        phone_number: null,
        country_code: null
    },
    security : {
        current_password : null,
        new_password : null,
        verify_new_password : null
    }
})
const openDeletePrompt = ref(false)
const validation = ref({})
const confirmer = useConfirmer()

const handleErrors = useValidation();
const { isValid } = storeToRefs(handleErrors)


const country_code = computed(() => {
    const userCountry = user.value?.country
    const found = countries.find((country) => country.name === userCountry );
    return found.code
});

const countryUpdate = () => {
    const foundCountry = countries.find(country => country.name === formData.value.account.country);
    formData.value.account.country_code  = foundCountry.code
};

const fetchTelError = (obj) => {
  if(obj && obj.formatted){
    if(handleErrors.rules.phone_number(obj) === true){
      telError.value = true;
      validation.value = {
        phone_number: [
            ''
        ]
    }
    }else{
      telError.value = false;
      validation.value = {
        phone_number: [
            handleErrors.rules.phone_number(obj)
        ]
      }
    }
  }
};

const togglePassword = (name)=> {
    if(name === 'old') {
        toggleEye.value = !toggleEye.value
    } else if (name === 'new') {
        toggleEye1.value = !toggleEye1.value
    } else if (name === 'confirm') {
        toggleEye2.value = !toggleEye2.value
    }
}

const editModeEnabler = () => {
    editMode.value = true
    inputOptions.value.readonly = false
    dropdownOptions.value.disabled = false
}

const cancelForm = () => {
    editMode.value = false;
    inputOptions.value.readonly = true
    dropdownOptions.value.disabled = true
}

const onMenuClick = (menu) => {
    if(menu === 'security') {
        editMode.value = true;
    } else {
        activeTab.value = 'account_settings'
        cancelForm()
    }
}

const updateForm = () => {
    formData.value.account.user_full_name = user.value.user_full_name
    formData.value.account.country = user.value.country
    formData.value.account.email = user.value.email
    formData.value.account.phone_number = user.value.phone_number
}

const updateProfile = () => {
    setProfile(formData.value.account).then(res => {
        updateForm()
        editMode.value = false
        inputOptions.value.readonly = true
        dropdownOptions.value.disabled = true
        validation.value = {}
        confirmer.openSnackBar({type: 'success', msg: res.message})

    }).catch(error => {
        if(error?.type === 'validation' ){
            validation.value = error.messages
        }
    })
}

const updatePassword = () => {
    setNewPassword(formData.value.security).then(res => {
        activeTab.value = 'account_settings'
        validation.value = {}
        formData.value.security = {
            current_password : null,
            new_password : null,
            verify_new_password : null
        }
        confirmer.openSnackBar({type: 'success', msg: res.message})
    }).catch(error => {
        if(error?.type === 'validation'){
            validation.value = error.messages
        }
    })
}

const requestUserDelete = () => {
    openDeletePrompt.value = true;
}

const closeDeletePrompt = () => {
    openDeletePrompt.value = false;
}

onMounted(() => {
    if(!user.value){
        getProfile().then(() => {
            updateForm()
        })
    }else{
        updateForm()
    }
})
</script>

<style lang="scss" scoped>
.outer-layer {
    padding: 10px;
    border: 1px dashed #DBE1E0;
    border-radius: 100%;
    height: 150px;
    width: 150px;

    .v-icon {
        font-size: 30px !important;
    }
}
.v-btn.has-shadow-1 {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
}
</style>