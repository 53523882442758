<template>
  <v-layout class="fill-height kiwi-bg-white">
    <v-main class="d-flex flex-column" style="min-height: 300px;">
        <router-view />
    </v-main>
    <SnackBar />
  </v-layout>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import {useRouter} from 'vue-router'
import {AuthTokenService} from '@/handler/authService';
import {api} from '@/plugins/axios';
import {useConfirmer} from '@/stores/confirmer';
import SnackBar from '@/components/SnackBar.vue'
const {confirmer} = useConfirmer();
const router = useRouter();

</script>

<style>

</style>
