<template>
    <v-layout class="fill-height kiwi-bg-light-grey-9 kiwi-site-main-layout kiwi-site-dashboard"
    :class="!rail ? 'kiwi-open-drawer' :  ''"
    >
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        mobile-breakpoint="sm"
        @click="rail = false"
        width="220"
        style="position: fixed; left: 0; max-height: 100vh;"
      >
        <v-list-item
          nav
          class="kiwi-bg-primary"
          style="height: 64px; border-radius: 0;"
        >
          <img :src="institute.invertedLogo" class="logo" height="50" v-if="!rail" alt="kiwi logo">
          <img :src="institute.invertedFavicon" class="logo" width="40" v-else :class="'cursor-pointer'" alt="kiwi logo">
          <template v-slot:append>
            <img @click.stop="rail = !rail" v-if="rail === false" :class="!rail ? 'cursor-pointer' :  ''" src="/assets/admin_menu.svg" alt="">
          </template>
        </v-list-item>
  
        <v-divider></v-divider>
  
        <Navigation/>
      </v-navigation-drawer>
      <v-app-bar class="kiwi-bg-primary kiwi-nav-app-bar kiwi-text-white" elevation="0" density="comfortable" height="72"
        style="position: fixed;"
      >
        <v-toolbar-title class="header-title">
        {{appBarTitle??''}}
      </v-toolbar-title>
      <v-list-item
        nav
        class="kiwi-bg-primary header-logo"
        style="height: 64px; border-radius: 0;"
        @click.stop="rail = !rail"
      >
        <img :src="institute.invertedLogo" height="50" max-width="165" class="logo" v-if="!rail" alt="kiwi logo">
        <img :src="institute.invertedFavicon" width="40" v-else class="logo" :class="'cursor-pointer'" alt="kiwi logo">
      </v-list-item>
        <v-spacer></v-spacer>
        <v-menu
          v-model="profileMenuToggle"
          :close-on-content-click="true"
          location="bottom"
          offset="15 10"
          max-width="160"
          variant="flat"
          elevation="0"
          content-class="notification-module profile-module rounded-lg w-100"
        >
          <template v-slot:activator="{ props }">
            <v-btn 
              class="kiwi-button kiwi-profile-btn text-capitalize kiwi-radius-4 mr-3 px-3" 
              :class="profileMenuToggle?'kiwi-bg-white kiwi-text-primary':'kiwi-bg-primary-dark kiwi-text-white'"
              size="large" height="44" variant="flat" v-bind="props"
            >
              <v-avatar class="kiwi-profile-avatar font-weight-semi-bold kiwi-border-white-dim-20 mr-2" image="/assets/PLaceholder.svg" size="34"></v-avatar>
              <span class="hide-on-mobile">{{user?limitString(user.user_full_name, 25):'User'}}</span>
            </v-btn>
          </template>
          <v-card min-width="140">
            <template v-if="false">
                <div class="d-flex flex-wrap flex-column px-4 py-3 kiwi-bg-light-grey-5" style="grid-gap: 0px">
                <div class="font-weight-bold kiwi-fs-18 kiwi-text-black-3 text-capitalize">
                    {{user?user.user_full_name:'User'}}
                </div>
                <span>{{user?user.email:''}}</span>
                </div>
                <v-divider />
            </template>
            <v-list lines="one">
              <template  v-for="(item, index) in items">
              <v-list-item
                :to="item.value ? {name: item.value} : ''"
                :prepend-avatar="item.icon"
                class="cursor-pointer" 
                @click=" item.label === 'Logout' ? confirmDialog = true : () => true"
              >
                <v-list-item-title v-if="item.label === 'Logout'">{{ item.label }}</v-list-item-title>
                <v-list-item-title v-else>
                  {{ item.label }}
                  <template v-if="item.value === 'subscription'">
                    <v-chip class="ml-2 kiwi-bg-upgrade kiwi-text-whtie kiwi-fs-12" size="small" variant="flat">{{ free_package ? 'You are on free plan' : `Plan left for ${user?.current_package?.day} days` }}</v-chip>
                  </template>
                </v-list-item-title>
              </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>
      <v-main class="d-flex kiwi-page-main-wrapper" :class="!rail ? 'kiwi-drawer-expand' : 'kiwi-drawer-shrink'" style="min-height: 300px;"
      :style="rail ? {'--v-layout-left': '56px'} : {'--v-layout-left': '220px'}">
        <div class="pa-5 w-100">
          <div class="kiwi-fs-18 font-weight-semi-bold kiwi-text-black mb-4" v-if="isMobileView">{{pages[currentPage]}}</div>
          <!-- <slot /> -->
          <router-view />
        </div>
      </v-main>
      <SnackBar />
    </v-layout>
    <ModalDialog class="text-center" @update:dialog="confirmDialog = false" @confirm="logoutUser" v-if="confirmDialog" >
      <template #title> 
          <div class="w-100 mb-2 mt-7">
            <img src="/assets/Notifications.svg" alt="">
          </div>
          <span class="kiwi-fs-24 font-weight-bold">Confirm Logout</span>
      </template>
      Are you sure you want to logout your account?
  </ModalDialog>
  </template>
  
  <script setup>
  import { ref, computed, watch , onMounted  } from 'vue';
  import Navigation from '@/layouts/Admin/sidebar.vue'
  import {useRouter , useRoute} from 'vue-router'
  import {AuthTokenService} from '@/handler/authService';
  import {api} from '@/plugins/axios';
  import {useConfirmer} from '@/stores/confirmer';
  import {useAuthStore} from '@/stores/auth/authStore'
  import SnackBar from '@/components/SnackBar.vue'
  import { storeToRefs } from "pinia";
  import { limitString } from '@/utils/utils';
import ChangeTypeModal from '@/components/Dashboard/Tutorial/ChangeExamTypeModal.vue'
  
  import { useInstituteStore } from '@/stores/institute/institute-store';
  const institute = useInstituteStore()
  
  const {confirmer} = useConfirmer();
  const {logout , clearAuth} = useAuthStore();
  const router  = useRouter();
  const route = useRoute();
  const openSnackBar = confirmer
  
  const drawer = ref(true);
  const rail = ref(true);
  const store = useAuthStore();
  const {user} = storeToRefs(store)
  const {getProfile } = store
  const confirmDialog = ref(false)
  const pages = ref({})
  const free_package = ref(true)
  
  pages.value = {
    'students': 'Students',
    'mock-tests': 'My Practice > Mock Test',
    'question-based-tests': 'My Practice > Question Based Test',
    'subscription': 'Subscription',
    'inforamtion': 'Information',
    'referal': 'Refer your Friends',
    'contact': 'Contact Us',
    'contactTab': 'Contact Us',
    'faqTab': 'FAQ & Help',
    'information': 'Information',
    'termsTab': 'Terms & Conditions',
    'privacyTab': 'Privacy Policy',
  }
  const items = ref([
    {
      label: 'Logout',
      icon: '/assets/logout-02.svg'
    },
  ])
  const isMobileView = ref(false)
window.addEventListener('resize', function(event) {
  if (window.innerWidth <= 1280) {
    isMobileView.value = true
  }  else if(window.innerWidth >= 1100) {
    rail.value = false;
    isMobileView.value = false
  }
  if(window.innerWidth <= 767) {
    isMobileView.value = true;
  } else {
    isMobileView.value = false;
  }
});

window.addEventListener('load', function() {
  if(window.innerWidth <= 767) {
    isMobileView.value = true;
  } else {
    isMobileView.value = false;
  }
  if(window.innerWidth >= 1100) {
    rail.value = false
  }
});
  
  
  const logoutUser = async() => {
    await logout().then(res=>{
      clearAuth();
      router.push({name:'login'});
    }).catch(err=>{
      confirmer.openSnackBar({type: 'error', msg: err.response.data.data.detail})
      confirmer.openSnackBar({type: 'error', msg: err.response.data.data.error})
    });
  }
  onMounted(()=>{
    if(window.innerWidth >= 1100) {
    rail.value = false
    }
    if(!user.value){
      getProfile()
    }
  })
  
  const appBarTitle = computed(() => {
    if (isMobileView.value) {
      return '';
    }
    const routeName = route.name;
    return pages.value[routeName]
  });
  
  const profileMenuToggle = ref(false)
  
  const notificationToggle = ref(false)
  
  </script> 
  
  