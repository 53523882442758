<template>
    <template v-if="renderType === 'answerList'">
      <AText v-if="answerList.length" :text="answerList.join(', ')" />
    </template>
  <template v-else-if="renderType === 'correctList'">
    <AText :text="correctList.join(', ')" />
  </template>
    <template v-else>
            <AHeadline :text="'Question:'" />
            <div class="kiwi-paragraphs-bundle mb-2">
                <ul class="ans-ol">
                    <li v-for="(option, i) in optionList" :key="i">
                         <span v-html="option.text"
                         class="opt-text"
                         ></span>
                    </li>
                </ul>
            </div>
            <AHeadline :text="'Answer:'" />
            <AText :text="correctList.join(', ')" />
            <AHeadline :text="'Your Answer:'" />
            <AText v-if="answerList.length" :text="answerList.join(', ')" />
            <AText v-else :text="'Not Submitted'" />
    </template>
</template>
<script setup>
import {computed } from "vue";
import AHeadline from "@/components/Evaluation/MockTests/Answers/AHeadline.vue";
import AText from "@/components/Evaluation/MockTests/Answers/AText.vue";
import { removeTags } from '@/utils/utils';
const props = defineProps({
    options: {
        required: true,
        type: Array
    },
    correct: {
        required: true,
        type: Array
    },
    selected: {
        required: true,
        type: Array
    },
    renderType:{
      type: String,
      default: 'mock'
    },
    charType: {
      type: String,
      default: 'char'
    }

})
const optionList = computed(() => {
    return props.options.map((o, i) => {
        return {
            id: o.id,
            text: removeTags(o.option),
            num: i + 1,
            char: String.fromCharCode(65 + i)
        }
    })
})
const answerList = computed(() => {
    return props.selected.map(val => {
       const id = parseInt(val)
       const opt = optionList.value.find(o => o.id === id)
       if(opt) {
         return props.charType === 'num' ? opt.num : opt.char
       }else {
        return ''
       }
    })
})
const correctList = computed(() => {
    return props.correct.map(val => {
       const id = parseInt(val)
       const opt = optionList.value.find(o => o.id === id)
       if(opt) {
        return props.charType === 'num' ? opt.num : opt.char
       }else {
        return ''
       }
    })
})
</script>
<style lang="scss" scoped>
.ans-ol {
    padding-left: 15px;
    list-style: upper-alpha;
}
</style>
