<script setup>
import {ref} from 'vue';
import {storeToRefs} from "pinia";
import VueCountdown from "@chenfengyuan/vue-countdown";
import {useExamInstanceStore} from "@/stores/examInstance-store";
const examInstanceStore = useExamInstanceStore();
const {loader} = storeToRefs(examInstanceStore);
const time  = ref(600000);
const emits  = defineEmits(['handle:next']);

const next=()=>{
  loader.value = 'break';
  emits('handle:next');
}
const formatTime = (minutesPassed) => {
  let minutes = Math.floor(minutesPassed);
  let seconds = Math.floor((minutesPassed - minutes) * 60);

  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return `${minutes}:${seconds}`;
};

const dialog = ref(true);

</script>

<template>
  <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
    <v-sheet class="fill-height kiwi-bg-black-3 kiwi-text-white d-flex align-center justify-center">
      <v-container>
        <v-row class="align-center">
          <v-col cols="12" md="6" class="d-flex align-center jusstify-center flex-column">
            <div class="box d-flex flex-column align-center justify-center text-center kiwi-border-white kiwi-radius-8 w-100 mb-5" style="max-width: 266px; height: 210px; border: 1px solid;">
              <span class="font-weight-medium">Remaining Break Time:</span>
              <vue-countdown
                :time="time"
                ref="chapterTimerRef"
                v-slot="{ minutes, seconds }"
                class="kiwi-fs-70 font-weight-bold"
                @end="next()"
              >
                {{ minutes }}:{{ seconds.toString().padStart(2, '0') }} 
                <!-- /
                {{ formatTime(time / 60) }} -->
              </vue-countdown>
            </div>
            <v-btn @click="next" class="mr-2 border kiwi-bg-orange-3 kiwi-text-black-3 text-none kiwi-fs-16 font-weight-semi-bold " rounded="xl">Resume Testing</v-btn>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column align-center justify-center">
            <article style="max-width: 454px;">
              <div class="kiwi-paragraphs-bundle mb-5">
                <p class="kiwi-fs-36 font-weight-semi-bold">Practice Test Break</p>
                <p class="">You can resume this practice test as soon as you're ready to move on. On test day, you'll wait until the clock counts down. Read below to see how breaks work on test day.</p>
              </div>
              <v-divider />
              <div class="kiwi-paragraphs-bundle mt-3">
                <p class="kiwi-fs-36 font-weight-semi-bold">Take a Break: Do Not Close Your Device</p>
                <p class="">After the break, a Resume Testing Now button will appear and you'll start the next session.</p>
                <p class="font-weight-bold">Follow these rules during the break:</p>
                <ol style="padding-left: 30px;">
                  <li>Do not disturb students who are still testing.</li>
                  <li>Do not exit the app or close your laptop.</li>
                  <li>Do not access phones, smartwatches, textbooks, notes, or the internet.</li>
                  <li>Do not eat or drink near any testing device.</li>
                  <li>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
                </ol>
              </div>
            </article>
          </v-col>
        </v-row>
      </v-container>

    </v-sheet>
  </v-dialog>
</template>

<style scoped>

</style>
