<template>
    <article class="px-4 py-6 kiwi-paragraphs-bundle font-weight-semi-bold">
        <QBTs v-for="(value, i) in topics" :key="i">
            <template v-slot:qbtsHeader>
                <img
                    :src="getImageSrc(value.slug)"
                    class="mr-2"
                    alt=""
                    v-if="getImageSrc(value.slug)"
                />
                <span class="kiwi-fs-18 font-weight-semi-bold"
                    :class="value.color"
                >{{
                    value.title
                }}</span>
            </template>

            <article class="pa-4 font-weight-medium kiwi-text-black-2 kiwi-paragraphs-bundle kiwi-fs-18 kiwi-bg-white">
                <p>{{content[value.slug].description}}</p>
                <ol v-if="value.slug === 'Part 1'">
                    <li
                        v-for="(text, i) in content[value.slug].list"
                        :key="i"
                    >{{text}}</li>
                </ol>
                <ul v-else>
                    <li
                        v-for="(text, i) in content[value.slug].list"
                        :key="i"
                    >{{text}}</li>
                </ul>
            </article>
        </QBTs>
    </article>
</template>
<script setup>
import {ref} from 'vue'
import QBTs from "@/components/Dashboard/QBTsSheet.vue";

const topics = ref([
    {
        title: 'Part 1: Speaking and Writing',
        slug: 'Part 1',
        color: 'kiwi-text-speaking'
    },
    {
        title: 'Part 2: Reading',
        slug: 'Part 2',
        color: 'kiwi-text-reading'
    },
    {
        title: 'Part 3: Listening',
        slug: 'Part 3',
        color: 'kiwi-text-listening'
    },
])
const getImageSrc = (title) => {
  switch (title) {
    case "Part 1":
      return "/assets/voice.svg";
    case "Part 2":
      return "/assets/book-open-02.svg";
    case "Part 3":
      return "/assets/listening.svg";
    case "Part 4":
      return "/assets/pen-tool-03.svg";
    default:
      return null;
  }
};

const content = ref({
    'Part 1': {
        description: "This part of the test is 54–67 minutes long and it contains seven different question types. It is the longest section of the test. You will be tested on your speaking and writing skills, using English you might hear in an academic environment.",
        list: [
            'Personal Introduction',
            'Read Aloud (RA)',
            'Repeat Sentence (RS)',
            'Describe Image (DL)',
            'Re-tell Lecture (RL)',
            'Answer Short Question (ASQ)',
            'Summarize Written Text (SWT)',
            'Write Essay (WE)',
        ]
    },
    'Part 2': {
        description: "This part of the test is 29–30 minutes long and it contains five different question types. Because PTE Academic is an integrated skills test, one item type ('Reading and Writing: Fill in the blanks') also assesses writing skills.",
        list: [
            'Reading & Writing: Fill in the blanks (FIB-R)', 
            'Multiple Choice, Choose Multiple Answer (MSC-R)', 
            'Re-order Paragraphs (RO)',
            'Fill in the Blanks (FIB)',
            'Multiple Choice, Choose Single Answer (MCS-R)',
        ]
    },
    'Part 3': {
        description: "This part of the test is 30–43 minutes long and it contains eight different question types.The questions are based on audio or video clips, which begin to play automatically. You hear each audio or video clip once. You are allowed to take notes.",
        list: [
            'Summarize Spoken Text (SST)',
            'Multiple Choice, Choose Multiple Answer (MSC-L)',
            'Fill in the blanks (FIB-L)',
            'Highlight Correct Summary (HCS)',
            'Multiple Choice, Chose Single Answer (MCS-L)',
            'Select Missing Word (SMW)',
            'Highlight Incorrect Words (HIW)',
            'Write from Dictation(WFD)',
        ]
    },
})
</script>
