<template>
  <v-navbar class="d-flex justify-space-between">
    <div>
      <p class="kiwi-text-black-3 font-weight-bold">Overview</p>
    </div>

    <div class="d-flex ga-2">
      <p class="rounded-pill px-5 nav" :class="{ 'kiwi-bg-primary kiwi-text-white' : section === 'all' }" @click="filterAnswers('all')"  style="cursor: pointer;">All Questions</p>
      <p class="rounded-pill px-5 nav" :class="{ 'kiwi-bg-primary kiwi-text-white' : section === 'english' }" @click="filterAnswers('english')" >Reading and Writting</p>
      <p class="rounded-pill px-5 nav"  :class="{ 'kiwi-bg-primary kiwi-text-white' : section === 'maths' }" @click="filterAnswers('maths')"> Maths</p>
    </div>
  </v-navbar>

  <div class="card-detail d-flex justify-space-around text-center">
    <v-row>
      <v-col v-for="n in 3" :key="n" cols="12" sm="4">
        <v-sheet class="text-center">
          <!-- Conditional content based on n -->
          <template v-if="n === 1">
            <div class="border-e-sm">
              <p class="kiwi-text-black-3 font-weight-bold kiwi-fs-36"> {{ filterdAnswers.length }} </p>
              <p class="kiwi-text-light-black-3 kkiwi-fs-14">Total Questions</p>
            </div>
          </template>
          <template v-else-if="n === 2">
            <div class="border-e-sm">
              <p class="kiwi-text-black-3 font-weight-bold kiwi-fs-36"> {{ rightAnswersCount }} </p>
              <p class="kiwi-text-light-black-3 kkiwi-fs-14">Correct Answers</p>
            </div>
          </template>
          <template v-else>
            <p class="kiwi-text-black-3 font-weight-bold kiwi-fs-36"> {{ wrongAnswersCount }} </p>
            <p class="kiwi-text-light-black-3 kkiwi-fs-14">Incorrect Answers</p>
          </template>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
  <v-table class="mt-5 rounded custom-table">
    <thead>
      <tr>
        <th class="text-center justify-center">Question</th>
        <th class="text-center justify-center">Section</th>
        <th class="text-center justify-center">Correct Answer</th>
        <th class="text-center justify-center">YourAnswer</th>
        <th class="text-center justify-center">Actions</th>
      </tr>
    </thead>
    <tbody v-if="filterdAnswers.length > 0">
        <tr v-for="(answer, index) in filterdAnswers" :key="index">
          <td class="text-center justify-center">{{ index + 1 }}</td>
          <td class="text-center justify-center">{{ currentSection(answer.topic_section) }}</td>
          <td class="text-center justify-center">{{ findCorrectAnswer(answer.options , answer.answer_text) }}</td>
          <td class="text-center justify-center" v-html="findUserAnswer(answer.options , answer.user_answer , answer.is_user_answer_correct)"></td>
          <td class="text-center justify-center">  <v-btn class="rounded-pill" @click.native="viewReview(index)">Review</v-btn></td>
        </tr>
    </tbody>
  </v-table>
  <ReviewDialog v-if="showReview"  @close-dialog="showReview = false" :questions="filterdAnswers" :index="questionIndex" @next="next" @back="back"/>
</template>

<script setup>
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import ReviewDialog from "./ReviewDialog.vue";
import { ref, computed , watch } from "vue";
import {useEvaluationStore} from "@/stores/Sat/evaluation-store";
import {storeToRefs} from "pinia";
import {useQuestionBasedSatStore} from "@/stores/Sat/questionBasedSat-store";
const questionBasedSatStore = useQuestionBasedSatStore();


const evaluationStore = useEvaluationStore();
const {   fetching} = storeToRefs(evaluationStore);
const questionIndex = ref(0);
const props = defineProps({
  overAll:{},
  isQbt:{}
})
const overAllEvaluation = computed(() => {
  return props.overAll;
})
const isDialogVisible = ref(false);
const selectedQuestion = ref(null);
const filterdAnswers = ref([]);
const section = ref('All');
const showReview = ref(false);

const answertList = computed(() => {
  if(props.isQbt.value){
    return  questionBasedSatStore.evaluationForQbt?.questions;
  }
  return  evaluationStore.answerList;
})

watch(answertList , (newVal, oldVal) => {
  filterdAnswers.value = newVal;
})

filterdAnswers.value  = answertList.value;

const viewResult = (question) => {
  selectedQuestion.value = question;
  isDialogVisible.value = true; // Show the dialog
};

const totalQuestion = ref(54)
const correctAnswer = ref(11)
const incorrectAnswer = ref(43)
const showMathAnswer = ref(false)
const alpahbet = ['A','B','C','D','E'];


const findCorrectAnswer = (options , answer) => {
  if (options && options.length > 0) {
    const index =  options.findIndex(option => option.is_correct_answer);
    return alpahbet[index] || null
  }else{
    return answer
  }
}

const findUserAnswer = (options , answer , isCorrect) =>{
  if (options && options.length > 0) {
    const index = options.findIndex(option => option.selected);
    if (index === -1) {
      return "No answer selected";
    }
    const isCorrectIndex = options[index].is_correct_answer;
    const color = isCorrectIndex ? 'green' : 'red';

    return `<span style="color: ${color};">${alpahbet[index]}; ${isCorrect ? 'correct' : 'incorrect'}</span>`;
  }else {
    const color = isCorrect ? 'green' : 'red';
    return answer ? `<span style="color: ${color};">${answer}; ${isCorrect ? 'correct' : 'incorrect'}</span>` : 'Answer Not Entered';
  }
}

const currentSection = (id) =>{
  if(id === '1'){
    return 'Reading And Writing'
  }else {
    return 'Maths'
  }
}
const filterAnswers = (selection) => {
  section.value = selection;
  if(selection === 'all'){
    filterdAnswers.value = answertList.value;
  }else if (selection === 'english'){
    filterdAnswers.value =  answertList.value.filter(answer => answer.topic_section === '1');
  }else if(selection === 'maths'){
    filterdAnswers.value =  answertList.value.filter(answer => answer.topic_section === '2');

  }
}
filterAnswers('all');

const rightAnswersCount = computed(() => {
  let count = 0;
  filterdAnswers.value.map(val=>{
    if(val.is_user_answer_correct){
      count += 1;
    }
  })
  return count
})

const wrongAnswersCount = computed(() => {
  let count = 0;
  filterdAnswers.value.map(val=>{
    if(!val.is_user_answer_correct){
      count += 1;
    }
  })
  return count
})

const viewReview = (index) =>{
questionIndex.value = index;
showReview.value = true;
}
const next = () =>{
  questionIndex.value += 1;
}
const back = ()=>{
  questionIndex.value -= 1;
}

</script>

<style scoped>
.nav {
  background: #ffffff;
  color: #4e5452;
  border: 1px solid #dbe1e0;
}

.nav:hover {
  background: #1ca972;
  color: #ffffff;
}

.card-detail {
  height: 105px;
  max-width: 1550px;
  background: #ffffff;
  border: 1px solid #e2e6e4;
  border-radius: 8px;
  margin-top: 15px;
  align-items: center;
}

thead {
  background: black;
  color: #ffffff;
}

.custom-table tbody tr.bold-separator td {
  border-top: 2px solid #333 !important; /* Thicker and darker border for the middle row */
}

</style>
