import { defineStore } from "pinia";
import { ref } from "vue";

export const useExamTimerInstanceStore = defineStore(
  "timerinstancestore",
  () => {
    const moduleTimer = ref(0);
    const chapaterTimer = ref(0);
    const reduceTime = ref(null);
    const timeEndedForModule = ref(false);
    const timeEndedForChapter = ref(false);
    const timeProgress = ref({chapter: null, module: null});
    function updateModuleTimer(examInstance, moduleDeductTime = null) {
      if (examInstance.moduleData.time_to_complete_the_answer !== 0) {
        timeEndedForModule.value = false;
        if (moduleDeductTime) {
          moduleTimer.value =
            examInstance.moduleData.time_to_complete_the_answer -
            moduleDeductTime;
        } else {
          moduleTimer.value =
            examInstance.moduleData.time_to_complete_the_answer;
        }
      }
    }
    function updateChapterTimer(examInstance, moduleDeductTime = null) {
      timeEndedForChapter.value = false;
      if (!examInstance.payload.respect_own_timing) {
        clearChapterTimer();
      }
      if (examInstance.payload.respect_own_timing) {
        chapaterTimer.value = examInstance.payload.time_to_complete_the_answer;
      }
    }

    function clearModuleTimer() {
      moduleTimer.value = 0;
    }
    function setReduceTime(payload) {
      reduceTime.value = payload;
    }
    function clearChapterTimer() {
      chapaterTimer.value = 0;
    }
    function handleProgress(type, time) {
      timeProgress.value[type] = time
    }
    function clearTimer() {
      timeEndedForModule.value = false;
      timeEndedForChapter.value = false;
      clearModuleTimer();
      clearChapterTimer();
      setReduceTime(null)
      timeProgress.value = {chapter: null, module: null}
    }

    function setStatusTimeEnded(type) {
      if (type === "module") {
        timeEndedForModule.value = -1;
      } else {
        timeEndedForChapter.value = -1;
      }
    }
    function updateIntroductionTimer() {
      chapaterTimer.value = 55;
    }
    function updateModuleTimerForType(time ,type = null) {
      moduleTimer.value = time;
    }
    return {
      updateModuleTimer,
      moduleTimer,
      chapaterTimer,
      timeProgress,
      clearTimer,
      clearModuleTimer,
      clearChapterTimer,
      setStatusTimeEnded,
      updateChapterTimer,
      timeEndedForChapter,
      timeEndedForModule,
      updateIntroductionTimer,
      handleProgress,
      setReduceTime,
      reduceTime,
      updateModuleTimerForType
    };
  }
);
