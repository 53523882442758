import { ref } from "vue";
import { api } from "@/plugins/axios";
import { useFormData } from "@/composables/useFormData";
import {useAuthStore} from "@/stores/auth/authStore";
export function useQuestionAnswer() {
  const answerSubmitting = ref(false)
  const {createFormData} = useFormData();
  const store = useAuthStore();
  const submitAnswer = async (payload) => {
    answerSubmitting.value = true;
    let url = `answer/${store.isSAT ? 'sat/' : ''}common-answer/`;
    const hasFile = payload.answer_audio_file;
    let payloadData = payload, headers = {}
    const requiredResponse = payload.requiredResponse??false
    if(hasFile) {
      payloadData = createFormData(payloadData)
      headers = {
        'Content-Type': 'multipart/form-data'
      }
    }
    const res = await api.post(url, payloadData, {
      headers: headers
    }).then(res=>{
      answerSubmitting.value = false
      if(requiredResponse){return res.data}
      return true
    }).catch(err => {
      answerSubmitting.value = false
      throw err
    })
    return res;
  }

  return {
    answerSubmitting,
    submitAnswer
  };
}
