<template>
  <div
    class="text-left kiwi-bg-white w-100 mx-auto recorder-component kiwi-radius-4 pa-6 my-3 border"
    style="max-width: 400px; border-color: #8e9492"
  >
    <p class="font-weight-semi-bold mt-2">Current Status:</p>
    <p class="mt-2">
      <template v-if="playerStatus === 'initial'">
        Beginning in
        <span class="font-weight-semi-bold">
          <vue-countdown
            :time="counterTime"
            @end="onCountdownEnd"
            v-slot="{ seconds }"
          >
            {{ seconds }} seconds
          </vue-countdown>
        </span>
      </template>
      <template v-else-if="playerStatus === 'processing'">
        Playing...
      </template>
      <template v-else-if="playerStatus === 'complete'"> Complete </template>
    </p>
    <v-row class="my-4" align="center" justify="start">
      <v-col cols="10">
        <Bar ref="barRef" :range="playing ? barRange : 0" :use-watch="true" />
      </v-col>
      <v-col cols="2">
        <VolumeSlider />
      </v-col>
    </v-row>
  </div>
</template>
<script setup>
import VueCountdown from "@chenfengyuan/vue-countdown";
import {ref, computed, onMounted, nextTick, onBeforeUnmount, defineExpose} from "vue";
import { Player } from "tone";
import VolumeSlider from "@/components/Volume/VolumeSlider.vue";
const barRef = ref("");
const playerInstance = ref(null);
const playerStatus = ref("initial");
const timeDuration = ref(0);
const playing = ref(false);
const props = defineProps({
  url: {
    type: String,
    // default: "https://tonejs.github.io/audio/berklee/gong_1.mp3",
    // default:
    //   "https://kiwiread.s3.amazonaws.com/kiwiread/media/question/audio/sst_7.mp3  ",
  },
  playAfter: {
    type: Number,
    default: 10,
  },
});
const barRange = computed(() => {
  return timeDuration.value;
});
const emit = defineEmits(["player:ended"]);
const counterTime = computed(() => {
  return props.playAfter * 1000;
});
const onCountdownEnd = async () => {
  await nextTick();
  playerStatus.value = "processing";

  const onloadCallback = () => {
    timeDuration.value = player.buffer.duration.toFixed(2);
    player.autostart = true;
    playing.value = true;
    player.onstop = () => {
      playing.value = false;
      playerStatus.value = "complete";
      emit("player:ended");
      if (barRef.value) {
        barRef.value.clear();
      }
    };
  };

  const player = new Player(props.url, onloadCallback).toDestination();
  playerInstance.value = player;
  barRef.value.updateProgressRange();
};
const updateProgressStatus = () => {
  playerStatus.value  = 'complete'
}
onBeforeUnmount(() => {
  try {
    playerInstance.value && playerInstance.value.stop();
    playerInstance.value && playerInstance.value.dispose();
  } catch (err) {}
});
defineExpose({
  updateProgressStatus
});
</script>
