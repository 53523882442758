<template>
    <v-dialog v-model="dialog" :persistent="persistent" max-width="900px">
        <v-sheet class="kiwi-modal-card d-flex flex-column kiwi-bg-white kiwi-radius-8">
            <div class="modal-header d-flex justify-space-between align-center py-5 px-4">
                <span class="kiwi-fs-18 font-weight-700 kiwi-text-black-3">Help</span>
                <v-icon class="mdi mdi-close has-circular-border border-black" @click="closeDialog"></v-icon>
            </div>
            <v-divider />
            <div class="d-flex align-center py-5 px-4 font-weight-semi-bold kiwi-fs-14">
                <v-spacer />
                <div class="text-center d-flex">
                <span class="mr-2 cursor-pointer kiwi-text-primary" @click="all">
                    Expand all
                </span>
                <span class="kiwi-text-light-grey-3 font-weight-regular">
                |
                </span>
                <span class="cursor-pointer kiwi-text-primary ml-2" @click="none">
                    Collapse all
                </span>
                </div>
            </div>
            <v-divider />
            <div class="py-5 px-4 help-expansion">

                <v-expansion-panels
                v-model="panel"
                multiple
                >
                    <v-expansion-panel
                        title="Assistive Technology"
                        value="assistive_technology"
                    >
                        <v-expansion-panel-text>
                            <p>Any approved assistive technology that you use when you're online should still work during your exam. If you use assistive technologies (AT), such as screen readers (JAWS, NVDA, VoiceOver, ChromeVox, ZoomText Fusion, etc.) to interact with a standard web browser, the same commands can be used to navigate through content in the digital exam. The exception to this would be if you use a web-based assistive technology or a browser extension.</p>
                            <p>If you use assistive technology, open/start the AT device or software before you open Bluebook for practice and on exam day. On exam day, adjust any necessary settings before you start to check in for the digital exam. Exceptions are noted when configuration is not required ahead of the exam.</p>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Text-to-Speech"
                        value="app-calculator"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">

                                <p>Text-to-Speech (TTS) tools read aloud text that appears on the screen. Below are configuration instructions for specific TTS tools for Windows, Mac, and Chromebooks. Configuration steps must be done before opening the digital testing application for practice and on exam day before starting check-in.<br /><strong>Windows Narrator</strong></p>
                                <p>To start Narrator:</p>
                                <ul style="padding-left: 30px;">
                                    <li>Press the <strong>Windows logo key+Ctrl+Enter</strong> together to start Narrator. Press these keys again to stop Narrator.</li>
                                    <li>Press the <strong>Windows logo key+CtrI+N</strong> to open Narrator settings, and then turn on the toggle under <strong>Use Narrator</strong>.</li>
                                </ul>
                                <p>To use your mouse cursor to read text:</p>
                                <ul style="padding-left: 30px;">
                                    <li>Go to the Narrator settings for <strong> Narrator Cursor</strong></li>
                                    <li>Activate the following settings:</li>
                                    <li>Show the Narrator cursor</li>
                                    <li>Move my text cursor with the Narrator cursor as Narrator reads text</li>
                                    <li>Sync the Narrator cursor and system focus</li>
                                    <li>Activate any other settings per your preference.</li>
                                </ul>
                                <p><strong>MacOS</strong></p>
                                <p>On all supported Mac operating systems:</p>
                                <ul style="padding-left: 30px;">
                                    <li>Go to the Apple menu and <strong>select System Preferences</strong>.</li>
                                    <li>Select <strong>Accessibility</strong>.</li>
                                </ul>
                                <p>Then complete the following steps based on your device's operating system.</p>
                                <p><strong>macOS 11 (Big Sur) macOS 12 (Monterey) and macOS 13 (Ventura)</strong></p>
                                <ul style="padding-left: 30px;">
                                    <li>On the Accessibility screen, select the <strong>Spoken Content</strong> tab.</li>
                                    <li>Check the option <strong>Speak Selection</strong>.</li>
                                    <li>To change preferences, select Options/Info</li>
                                    <li>To use the mouse cursor to read text, select <strong>Speak items under the pointer</strong>.</li>
                                    <li>Select<strong>Options/Info</strong> to customize the speech and verbosity settings.</li>
                                </ul>
                                <p><strong>iPadOS</strong></p>
                                <p>The iPad can speak selected text or the entire screen.</p>
                                <p>To start, for all supported iOS devices:</p>
                                <ul style="padding-left: 30px;">
                                    <li>Tap on the <strong>Settings</strong> icon and select <strong>Accessibility</strong>.</li>
                                    <li>From the Accessibility screen, select <strong>Spoken Content</strong>.</li>
                                    <li>Then select <strong>Speak Screen</strong>.</li>
                                    <li>Enable <strong>Speech Controller</strong> and/or <strong>Highlight Content</strong>, as preferred.</li>
                                </ul>
                                <p><strong>For an optimized test experience, we recommend using a keyboard with the iPad.</strong></p>
                                <p>Go to <strong>Settings > Accessibility > Keyboards</strong>, tap <strong>Full Keyboard Access</strong>, then turn on <strong>Full Keyboard Access</strong>.</p>
                                <p><strong>ChromeVox - Select to Speak</strong></p>
                                <p><strong>You can hear parts of a page, including specific words, read aloud with the text-to-speech tool called Select-to-speak. Or, you can hear full pages read aloud with the ChromeVox screen reader.</strong></p>
                                <ul style="padding-left: 30px">
                                    <li>
                                        Select-to-speak can be opened from the accessibility kiosk menu when enabled (Alt + Shift + L).
                                        <ul style="padding-left: 30px;">
                                            <li>Then, enable Select-to-Speak. Students can use the Search key (or Launcher key) along with the mouse pointer to read, or announce, content on the screen.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        If the accessibility menu is not available, the ChromeVox screen reader can be opened with Control + Alt+Z.
                                        <ul style="padding-left: 30px;">
                                            <li>Students that require ChromeVox to work with the mouse pointer must enable the ChromeVox setting "Speak text under the mouse" for the screen reader to function as a traditional text-to-speech tool.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p><strong>Speak text under the mouse</strong></p>
                                <p>With ChromeVox enabled, you can access the ChromeVox options and enable Speak text under the mouse</p>
                                <p>From the floating accessibility menu > <strong>Settings > Text-to-speech > ChromeVox > ChromeVox settings</strong> > enable (toggle on) <strong>Speak text under the mouse</strong></p>
                                <p><strong>Note:</strong> You may need to restart ChromeVox for this setting to take effect.</p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Speech-to-Text"
                        value="timer"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                                <p>Speech-to-text (STT) technology transcribes a student's spoken words into text for item responses in the digital exam. Students approved for this technology or for a writer/scribe may use STT assistive technology for digital exams.</p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Screen Readers"
                        value="highlight-note"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>During testing, use the typical/native key commands that you normally use to navigate a webpage with your screen reader. Most exam pages are structured with the following regions. When applicable, you can navigate with these regions/landmarks:</p>
                            <ul style="padding-left: 30px">
                                <li>
                                    Bluebook Controls
                                    <ul style="padding-left: 30px;">
                                        <li>Test timer</li>
                                        <li>Test tools</li>
                                    </ul>
                                </li>
                                <li>
                                    Passage, image, or other source content
                                    <ul style="padding-left: 30px;">
                                        <li>Footnotes</li>
                                        <li>Referenced content</li>
                                        <li>Long description</li>
                                    </ul>
                                </li>
                                <li>
                                    Question and Answer
                                    <ul style="padding-left: 30px;">
                                        <li>Question number</li>
                                        <li>Answer options</li>
                                        <li>Long description</li>
                                    </ul>
                                </li>
                                <li>Questions Navigation</li>
                            </ul>
                            <p>In general, the following test components are structured with a heading:</p>
                            <ul style="padding-left: 30px;">
                                <li>Test name</li>
                                <li>Passage, image, or other source content</li>
                                <li>Passage title, source documents, directions, etc.</li>
                                <li>Question number</li>
                            </ul>
                            <p><strong>Configuration</strong></p>
                            <p>Configure the settings for your assistive technology before you open Bluebook to take a practice test and on test day before you check in. These settings may include verbosity, punctuation, text processing, and other settings as needed. Users of assistive technology must configure settings prior to check-in to be prepared for test day.</p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Screen Magnification"
                        value="line-reader"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>Screen magnification technology enlarges the content displayed on the computer screen in order to assist students with visual impairments. Although the exam app supports some non-embedded screen magnifier tools from third parties, all users can zoom using their device's native controls (Control +/- on a PC; Command +/- on a Mac; pinch and zoom on an iPad).</p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="MathJax"
                        value="option-eliminator"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                Bluebook implements MathJax AssistiveMML technology to:
                            </p>
                            <ul style="padding-left: 30px;">
                                <li>Display mathematical content, such as expressions, equations, and inequalities elegantly in browsers.</li>
                                <li>Provide students who use screen readers access to off-screen accessible MathML and Nemeth braille support with refreshable Braille displays.</li>
                                <li>Access a variety of accessibility features via menus attached to the MathJax content to assist students with low vision and students that need highlighted rendering.</li>
                            </ul>
                            <p>College Board assessments have been tested with a variety of screen readers and operating systems. The exam content is designed to function without requiring students to activate the MathJax Accessibility menu. <br />
                                However, the College Board does not prohibit students from activating any of the accessibility features and users have discretion to activate the features and settings to cater their needs. The MathJax menu can be accessed by focusing on the math object with the keyboard (or right-click with the mouse), pressing the spacebar, and then choosing Accessibility > Activate from the menu.
                            </p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Link to Referenced Content"
                        value="mark-for-review"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                On questions that refer to a specific section of the stimulus content, this symbol >> will display after the reference. If you're using a screen reader and you navigate to "link to referenced content,' the referenced content will be read to you. For all users, if you scroll past the referenced content and you click on this symbol, the passage will scroll back so the referenced content is at the top of your screen.
                            </p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        title="Refreshable Braille Displays"
                        value="option-menu"
                    >
                        <v-expansion-panel-text>
                            <div class="kiwi-paragraphs-bundle">
                            <p>
                                Refreshable Braille Displays (RBDs) are used to read text-only content on digital exams. Alternative text descriptions are embedded in the exams for all graphics. RBDs must be properly set up before they can be used by students. For information about installing and setting up RBDs, refer to the product's provided instructions and manuals.
                            </p>
                            <p>Refreshable braille is not recommended for students that require tactile graphics. Students can request a Braille exam or Raised Line Drawings.</p>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    
                </v-expansion-panels>
            </div>
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';

const dialog = ref(true);
const panel = ref([]);

const props = defineProps([
    'dialog', 'persistent'
])

const emits = defineEmits(['closeDialog'])

const closeDialog = () => {
    dialog.value = false;
    emits('closeDialog', dialog.value)
}

const all = () => {
    panel.value = ['assistive_technology', 'app-calculator', 'timer', 'highlight-note', 'line-reader', 'option-eliminator', 'mark-for-review', 'option-menu']
}

const none = () => {
    panel.value = []
}

watch(props.dialog, (val) => {
    if(val === false) {
        closeDialog();
    }
})
</script>

<style lang="scss">
.has-circular-border {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 2px solid;
    font-size: 18px;

    &.border-black {
        border-color: var(--color-black-3);
    }
}
.help-expansion {
    p + p {
        margin-top: 20px;
    }

    .v-expansion-panel-title {
        font-weight: 500;
        color: var(--color-black-3);
        min-height: auto;
    }
    .v-expansion-panel-title, .v-expansion-panel-text__wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .v-expansion-panel__shadow {
        display: none;
    }

    .v-expansion-panel {
        & + .v-expansion-panel {
            border-top: 1px solid var(--color-light-grey-3);
        }
    }
}
</style>