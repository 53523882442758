// Utilities
import { defineStore } from 'pinia'

export const useConfirmer = defineStore('kiwiMsgConfirmer', {
  state: () => ({
    openSnackbar: false,
    displayMsg: {
        type: '',
        msg: '',
    }
  }),
  getters: {

  },
  actions: {
    openSnackBar(payload) {
        this.openSnackbar = true
        this.displayMsg = {...payload}
    },
    closeSnackBarInitialize() {
        this.openSnackbar = false
        this.displayMsg = {
            type: '', msg: ''
        }
    }
  },

})
