<template>
    <section class="kiwi-coming-soon-page position-relative d-flex fill-height align-center justify-center">
        <v-sheet class="outer-wrapper position-relative z-2 kiwi-bg-white pa-4 rounded-xl w-100 d-flex align-center justify-center overflow-hidden" style="height: 100%; max-height: 700px; max-width: 75%;">
            <article class="position-relative z-2 d-flex flex-column align-center justify-center kiwi-text-black-3 mx-auto" style="max-width: 800px;">
                <h1 class="kiwi-fs-56 font-weight-bold">Please Wait...</h1>
                <p class="kiwi-fs-18 mobile-w-75 mt-4 font-weight-medium kiwi-lh-1-5 kiwi-text-black-2 text-center">
                    <template v-if="redirecting === 1">
                        Redirecting...
                    </template>
                    <template v-else-if="redirecting === 2">
                        Redirecting...
                    </template>
                    <template v-else>
                        Processing your request..
                    </template>
                </p>
            </article>
        </v-sheet>
    </section>
</template>
<script setup>
import {AuthTokenService} from '@/handler/authService';
import { useAuthStore } from '@/stores/auth/authStore';
import { getUserDefaultRoute } from '@/utils/utils';
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
const isAuthenticated = AuthTokenService.isAuthenticated();
const userStore = useAuthStore();
const router = useRouter();
const {query} = useRoute()
let redirectRoute = null
let redirecting = ref(false)
if(!query.override_access || !query.access_token || !query.refresh_token) {
    router.push({name: 'login'})
}
let existingToken = null
if(isAuthenticated){
    // existingToken = {
    //     id: userStore.user?.id,
    //     user_type: userStore.userType,
    //     access: AuthTokenService.getAccessToken(),
    //     refresh: AuthTokenService.getRefreshToken(),
    // }
    AuthTokenService.logout('switch');
}
if(query.requested_by === 'third_party') {
    redirectRoute = 'pricing'
}
AuthTokenService.setTokens(query.access_token, query.refresh_token)
try {
    redirecting.value = 1
    userStore.getProfile().then(res => {
        const user_type = res?.user_type
        // const isSameUser = false && existingToken && existingToken.id === res?.id
        // if(existingToken.user_type !== 'student' && !localStorage.getItem('SWITCH_TOKEN') && !isSameUser) {
        //     localStorage.setItem('SWITCH_TOKEN', JSON.stringify(existingToken))
        // }
        if(!redirectRoute) {
            redirectRoute = getUserDefaultRoute(user_type)
        }
        redirecting.value = 2
        router.push({name: redirectRoute})
    })
}catch(err) {
    next({name: 'login'})
}


</script>

<style>

</style>