<template>
  <div class="question-render-container fill-height">
    <reading-and-writing :question="question"  :layout="optionLayout"  @sync="(key ,val) => syncAnswer(key, val)"  @mark-for-review="markForReview"  :index="index" class="px-12" />
  </div>
</template>
<script setup>
import { defineProps, ref, computed, watch, onMounted } from "vue";
import { useAnswerInstanceStore } from "@/stores/answerInstance-store";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { mockPromise } from "@/utils/utils";
import ReadingAndWriting from "@/components/SAT/MockTest/ReadingAndWriting.vue";
import MultiChoice from "@/components/MockTest/MultiChoice.vue";
import {useNotesStore} from "@/stores/Sat/notes-store";

const route = useRoute();
const props = defineProps({
  payload: {
    type: Object,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  examType: {
    required: true,
    default: () => "mock",
  },
  mathMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  index:{
    type: Number,
  },
  examId: {
    
  }
});
const emits = defineEmits(['markForReview' , 'setCurrentAnswer']);
const examStore = useAnswerInstanceStore();
const { questionId, answer } = storeToRefs(examStore);
const { setAnswer } = examStore;
const notesStore = useNotesStore();
const question = computed(() => {
  return props.question;
});

function syncAnswer(key, value) {
  setAnswer({
    [key]: value,
  });
  emits('setCurrentAnswer');
}

const optionLayout = computed(()=>{

  return question.value.layout;
})
const markForReview = () =>{
  emits("markForReview");
}
notesStore.resetWords()
onMounted(() => {
  notesStore.getHighlightedWords(props.examId, props.question.id)
})
</script>

