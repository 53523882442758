<template>
  <v-chip
    v-if="chapaterTimer || moduleTime"
    label
    class="kiwi-radius-4 kiwi-bg-light-grey-3 kiwi-text-black font-weight-bold my-2"
    v-show="show"
  >
    <img src="/assets/Mock Tests/timer-02.svg" class="mr-2" alt="" />
    <vue-countdown
      v-if="chapaterTimer !== 0"
      :time="chapterTime"
      ref="chapterTimerRef"
      v-slot="{ minutes, seconds }"
      @progress="handleChapterProgress"
      @end="onCountdownEnd('chapter')"
    >
      {{ minutes }}:{{ seconds.toString().padStart(2, '0') }} /
      {{ formatTime(chapaterTimer / 60) }}
    </vue-countdown>
    <vue-countdown
      v-else-if="moduleTimer"
      :time="moduleTime"
      ref="moduleTimerRef"
      v-slot="{ minutes, seconds }"
      @progress="handleModuleProgress"
      @end="onCountdownEnd('module')"
    >
      {{ minutes }}:{{ seconds.toString().padStart(2, '0') }}/
      {{ formatTime(moduleTimer / 60) }}
    </vue-countdown>
  </v-chip>
  <TimerSnackBar v-if="showSnack" />
</template>
<script setup>
import VueCountdown from "@chenfengyuan/vue-countdown";
import { computed, watch, ref, nextTick } from "vue";
import { useExamTimerInstanceStore } from "@/stores/examTimerInstance-store";
import { storeToRefs } from "pinia";
import {useAuthStore} from "@/stores/auth/authStore";

const authStore = useAuthStore();
const moduleTimerRef = ref(null)
const chapterTimerRef = ref(null)
const examTimer = useExamTimerInstanceStore();
const { chapaterTimer, moduleTimer, reduceTime } = storeToRefs(examTimer);
import TimerSnackBar from "@/components/SAT/MockTest/TimerSnackBar.vue";
const {setStatusTimeEnded, handleProgress } = examTimer
const props = defineProps({
  totalTime: {
    type: Number,
    default: 1800,
  },
  examType: {
    required: false,
  },
  showTimer:{
    default: true,
  }
});
const show = computed(() => {
  return props.showTimer;
})
const moduleTimeVal = ref(moduleTimer.value)
const chapterTimerVal = ref(chapaterTimer.value)
watch(moduleTimer, (val) => {
  moduleTimeVal.value = val
}, {immediate: true})
watch(chapaterTimer, (val) => {
  chapterTimerVal.value = val
}, {immediate: true})
const moduleTime = computed(() => {
  return (moduleTimeVal.value / 60) * 60 * 1000;
});
const displayWhenZero = computed(() => {
  return props.examType !== 'test'
})
const chapterTime = computed(() => {
  return (chapterTimerVal.value / 60) * 60 * 1000;
});
const showSnack = ref(false);

const formatTime = (minutesPassed) => {
  let minutes = Math.floor(minutesPassed);
  let seconds = Math.floor((minutesPassed - minutes) * 60);

  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return `${minutes}:${seconds}`;
};
const onCountdownEnd = (type) => {
  setStatusTimeEnded(type)
};
const handleModuleProgress = (val) => {
  const { totalMinutes, minutes } = val;
  if(totalMinutes === 5 && authStore.isSAT){
    showSnack.value = true;
  }else{
    showSnack.value = false;
  }
  handleProgress('module', val.totalSeconds)
}
const handleChapterProgress = (val) => {
  handleProgress('chapter', val.totalSeconds)
}
watch(reduceTime, async () => {
  if(reduceTime.value) {
    await nextTick()
    if(chapaterTimer.value && reduceTime.value.chapter) {
      if(chapterTimerRef.value) {
        const s = chapterTimerVal.value - reduceTime.value.chapter
        examTimer.setReduceTime(null)
        if(s > 0 && s < chapterTimerVal.value) {
          chapterTimerVal.value = s
        }
      }
    }else if(moduleTimer.value && reduceTime.value.module) {
      if(moduleTimerRef.value) {
        const s = moduleTimeVal.value - reduceTime.value.module
        examTimer.setReduceTime(null)
        if(s > 0 && s < moduleTimeVal.value) {
          moduleTimeVal.value = s
        }
      }
    }
  }
}, {immediate: true})


</script>
