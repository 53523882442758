import { api, apiWithoutAuth } from "@/plugins/axios";
import { downloadResponse } from "@/utils/utils";
import axios from 'axios';
import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from "vue";
export const useInstituteVoucherStore = defineStore('instituteVoucherStore' , () =>{
    const fetching = ref(false)
    const vouchers = ref([])
    const exporting = ref(false)
    const statusCount = ref({})
    const pagination = ref({
    })
    const packages = ref([])
    const filter = ref({
        package: [],
        search: null,
        page: 1,
        status: 'active',
        page_size: 10
    })
  async function getVouchers()
    {
        try {
            fetching.value = true
            await api.get('/institute/voucher/', {params: {...filter.value, package: filter.value.package.join(',')}}).then(res => {
                const {results, ...payload} = res.data
                vouchers.value = results
              pagination.value = {...pagination.value, ...payload}
                fetching.value = false
            })
        } catch (error) {
            throw error
        }
    }
    async function getPackages()
    {
        try {
            await api.get('/institute/package/', {params: {with_voucher_count: 1}}).then(res => {
                packages.value = res.data.results || []
            })
        } catch (error) {
            throw error
        }
    }
    async function getStatusCount()
    {
        try {
            await api.get('/institute/voucher/status_wise_count/').then(res => {
                statusCount.value = res.data || {}
            })
        } catch (error) {
            throw error
        }
    }
    async function exportData()
    {
        exporting.value = true
        try {
            const res = await api.get('/institute/voucher/export-csv', {
                responseType: 'blob',
                params: {...filter.value, page_size: null, page: null,package: filter.value.package.join(',')}
            }).then(res => {
                downloadResponse(res, 'voucher')
                exporting.value = false
            })
            return res;
        } catch (error) {
            exporting.value = false
            throw error
        }
    }
    return {
        fetching,
        vouchers,
        packages,
        pagination,
        filter,
        getPackages,
        getVouchers,
        getStatusCount,
        statusCount,
        exportData,
    }
})

