import { defineStore } from "pinia";
import { downloadResponse } from "@/utils/utils";
import {api, apiWithoutAuth} from '@/plugins/axios';
import { ref } from "vue";
export const useShopStore = defineStore('shopStore' , () =>{
    const packages = ref([])
    const calcSummary = ref([])
    const createCartResponse = ref([])
    const transaction_uuid = ref(null)
    const total = ref(null)
    const orderHistory = ref([])

    async function getPackages() {
        try {
            api.get('/store/cart/packages').then(res => {
                packages.value = [...res.data.filter((pkg) => pkg.price !== 0)]
            })
        }
        catch (err) {
            throw err
        }
    }

    async function getCartCalculation(payload) {
        try {
            api.post('/store/cart/get-calculations/', {...payload}).then(res => {
                calcSummary.value = {...res.data}
                total.value = res.data.total
            })
        }
        catch (err) {
            throw err
        }
    }

    async function createCart(payload) {
        try {
            return api.post('/store/cart/', {...payload}).then(res => {
                createCartResponse.value = res
                transaction_uuid.value = res?.data?.transaction_uuid
                return transaction_uuid.value
            })
        }
        catch (err) {
            throw err
        }
    }

    async function getOrderHistory() {
        try {
            api.get('/store/cart/').then(res => {
                orderHistory.value = res?.data
            })
        }
        catch (err) {
            throw err
        }
    }

    return {
        packages, getPackages, calcSummary, getCartCalculation, createCart, createCartResponse, transaction_uuid, total, orderHistory, getOrderHistory
    }
})