<template>
    <v-dialog
        v-model="dialog"
        max-width="280"
        class="error-recognition"
    >
        <v-sheet
            class="kiwi-bg-white kiwi-text-black evaluation-check-box py-3 px-4"
            variant="flat"
        >
            <article class="error-recognition-article">
                <div class="error-recognition-title d-flex">
                    <img src="/assets/alert-02.svg" class="mr-2" alt="vector alert">
                    <span class="kiwi-fs-14 kiwi-text-black-3 font-weight-semi-bold">{{error.error_title}}</span>
                </div>
                <div class="paragraph mt-3" >{{error.description}}</div>
            </article>
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import { computed ,ref, watch} from 'vue'
const props = defineProps(['error'])
const emit = defineEmits(['closeDialog'])
const dialog = ref(true);
watch(dialog, (newVal, oldVal) => {
  if(!newVal && oldVal) {
    emit('closeDialog')
  }
})
</script>

<style scoped>
.q-text span {
  display: inline-block;
}
.font-style-italic {
    font-style: italic !important;
}
</style>
