<template>
  <div class="q-text mt-1">
    <template v-for="(coloredWord, index) in coloredWords" :key="index">
      <span @click.native="openDictionary(coloredWord.word , coloredWord.color, coloredWord.error)" :style="{ color:  coloredWord.color }">{{ coloredWord.word }}</span>
      <span v-if="index < coloredWords.length - 1">&nbsp;</span>
    </template>
  </div>
  <DictionaryDialog v-if="dialog && !['swt' , 'we' , 'sst'].includes(slug)" @close-dialog="dialog=false" :word="word" :color="color"/>
  <ErrorRecognitionDialog v-if="dialog && ['swt' , 'we' , 'sst'].includes(slug) && Object.keys(error).length"  @close-dialog="dialog=false" :error="error"/>
</template>

<script setup>
import { computed ,ref} from 'vue'
import DictionaryDialog from "@/components/Evaluation/IndividualQuestionTest/DictionaryDialog.vue";
import ErrorRecognitionDialog from '@/components/Evaluation/IndividualQuestionTest/errorRecognition.vue';

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  result: {
    type: Array,
    required: true
  },
  slug:{
    required: false,
    type: String,
  }
})
const dialog = ref(false)
const word = ref()
const color=ref()
const error = ref()
//const words = computed(() => props.text.match(/[\w']+|[.,!?;]/g))
const words = computed(() => props.text.split(/[ -]+/))
// Define the characters to ignore
const charsToIgnore = ['.', ',', '"', '(', ')', '-', '?', '!'];
// Create a regular expression to match any of the characters in charsToIgnore
const regex = new RegExp(`[${charsToIgnore.join('')}]`, 'g');

const coloredWords = computed(() => {
  return words.value.map((word, index) => {
    if(!['swt' , 'we' , 'sst'].includes(props.slug)){
      const resultItem = props.result[index] || {}
      return {
        word,
        color: resultItem.color || 'green',
      };
    }else{
      console.log('results' , props.result , word)
      const resultItem = props.result[index] || []
      return  {
        word,
        color: resultItem.color??'black',
        error:resultItem
      };
    }
  })
})


const openDictionary = (wo , co, type) =>{
  let processWord = wo.replace(regex, '');
  word.value = processWord
  color.value = co
  error.value = type
  dialog.value= true
}
</script>

<style scoped>
.q-text span {
  display: inline-block;
}
</style>
