<template>
    <Alert :max-width="width" :persistent="!closable">
        <article class="text-center pa-4 pa-md-6">
            <h4 class="kiwi-fs-24 font-weight-bold">{{title}}</h4>
            <div class="font-weight-medium prmt-content mt-2" v-if="content" v-html="content"></div>
            <div class="py-3" v-if="progress">
                <Loading  />
            </div>
        </article>
        <template v-if="actions.length">
            <v-divider />
            <v-row class="text-center pa-4 pa-md-6">
                <v-col cols="12" :md="12 / actions.length" v-for="(action, i) in actions" :key="i">
                    <v-btn
                        block
                        size="large"
                        variant="flat"
                        :class="{
                            'kiwi-bg-black-4 kiwi-text-white kiwi-radius-4 text-none': action.type === 'fill',
                            'kiwi-bg-primary kiwi-text-white kiwi-radius-4 text-none': action.type === 'primary',
                            'kiwi-bg-white kiwi-text-black kiwi-radius-4 text-none mr-2 kiwi-border-light-grey-8 border' : !action.type
                        }"
                        @click="action.onClick"
                    >{{action.text}}</v-btn>
                </v-col>
            </v-row>
        </template>
    </Alert>
</template>

<script setup>
import {ref, defineProps} from 'vue'
import Alert from '@/components/Prompt/ConfirmAlert.vue'
const dialog = ref(true)
const props = defineProps({
    title: {
        required: false
    },
    content: {
        required: false
    },
    actions: {
        type: Array,
        default: () => []
    },
    progress: {
        type: Boolean,
        required: false,
        default: () => false
    },
    closable: {
        type: Boolean,
        required: false,
        default: () => false
    },
    width: {
        type: Number,
        required: false,
        default: () => 350
    },
})
</script>
<style>
.prmt-content strong {
    font-weight: bold !important;
}
</style>
