<template>
    <section class="kiwi-profile-page kiwi-bg-white fill-height pa-4 kiwi-radius-6">
        <v-row>
            <v-col cols="12" md="3" xl="3">
                <About :profile-data="user" />
            </v-col>
            <v-col cols="12" md="9" xl="9">
                <Settings :profile="{}" />
            </v-col>
        </v-row>
    </section>
</template>

<script setup>
import About from '@/components/Profile/About.vue'
import Settings from '@/components/Profile/Settings.vue'
import { useAuthStore } from '@/stores/auth/authStore';
import { storeToRefs } from 'pinia';

const { user } = storeToRefs(useAuthStore())
</script>

<style>

</style>