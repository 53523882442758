<template>
    <div class="blog-list-content">
        <div class="py-6">
            <v-row>
                <v-col 
                    cols="12" 
                    md="4"
                    v-for="(list, index) in bloglists"
                    :key="index"
                >
                    <router-link class="text-decoration-none" :to="{name: 'single_information', params: {slug: list.id}}">
                    <v-sheet
                        elevation="0"
                        class="kiwi-text-balck-3 h-100 pa-6 kiwi-radius-8 d-flex flex-wrap align-start position-relative overflow-hidden"
                        style="min-height: 180px;"
                        :style="{backgroundColor: bgColors[index % bgColors.length]}"
                    >
                        <div class="icon-wrapper position-absolute z-1" style="top: 0; right: 0;">
                            <img :src="icons[index % icons.length]" style="width: 80px;" alt="">
                        </div>
                        <article class="kiwi-flex-1 position-relative z-2 w-100" style="max-width: calc(100% - 100px);">
                            <h3 class="kiwi-fs-20 font-weight-bold">{{list.title}}</h3>
                        </article>
                    </v-sheet>
                    </router-link>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue';
import { api } from '@/plugins/axios';

const props = defineProps({
    category: String
})

const bgColors = [
    '#F3F7FF', '#F3FAFF', '#FEF3FF'
]

const icons = [
    '/assets/bubble-chat-question.svg',
    '/assets/bubble-chat-question_2.svg',
    '/assets/bubble-chat-question_3.svg',
]
const bloglists = ref([])
// const bloglists = ref([
//     {
//         id: 1,
//         title: 'What you need to know about PTE?',
//         slug: 'PTE'
//     },
//     {
//         id: 2,
//         title: 'What are the Test Pattern in PTE?',
//         slug: 'patterns'
//     },
//     {
//         id: 3,
//         title: 'What to expect on test day?',
//         slug: 'preparation'
//     },
    
// ])
const getKnowledgeBaseContent = async () => {
    try {
        const res = await api.get(`/help-and-support/kb-content/?category=${props.category}`)
        bloglists.value = res.data
    } catch (error) {
        // console.log(error)
    }
}

watch(() => props.category, () => {
    bloglists.value = []
    getKnowledgeBaseContent()
})
</script>

<style>

</style>