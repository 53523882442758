<template>
    <div>
        <Header :title="examDetail.description || ''" />
        <v-divider />
        <Tab />
    </div>
</template>

<script setup>
import { useMockTestEvaluationStore } from "@/stores/mocktest-evaluation-store";
import { useExamInstanceStore } from "@/stores/examInstance-store";
import {ref, computed} from 'vue'
import {useRoute} from 'vue-router'
import Header from '@/components/Evaluation/MockTests/Header.vue'
import Tab from './Tab.vue'
const route = useRoute();
const examId = parseInt(route.params.exam);
const examStore = useExamInstanceStore();
const mockTestEvaluation = useMockTestEvaluationStore();
examStore.getExamDetail(examId)
mockTestEvaluation.getEvaluation(examId)

const examDetail = computed(() => {
    return examStore.examDetail ? examStore.examDetail : {}
})
const answers = computed(() => {
    return mockTestEvaluation.answers
})
const fetching = computed(() => {
    return examStore.fetching;
})
</script>

<style>

</style>