<template>
    <v-sheet
        variant="flat"
        class="kiwi-bg-light-grey-7 pa-4"
    >
        <v-card class="pa-4 kiwi-radius-8" variant="flat">
            <div>
                <h2 class="kiwi-fs-20 font-weight-bold mb-2">Communicative Skills</h2>
                <v-divider />
                <v-row class="mt-3" align="center">
                    <v-col cols="12" md="9" lg="10" class="d-flex flex-wrap justify-center justify-md-start" style="grid-gap: 20px">
                        <div 
                            class="d-flex flex-wrap flex-column justify-center align-center" 
                            style="grid-gap: 10px"
                            v-for="(score, i) in reorderedArray"
                            :key="i"
                        >
                            <div 
                                class="score-wrapper outer-border border b-w-5 size-96 radius-circle d-flex flex-wrap align-center justify-center kiwi-fs-30 font-weight-bold"
                                :class="`kiwi-border-cs-${score.name.toLocaleLowerCase()}`"
                            >
                                {{ score.value }}
                            </div>
                            <span class="kiwi-fs-18 font-weight-medium">{{ score.name }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" class="d-flex justify-center justify-md-end">
                        <div class="overall-score-badge overflow-hidden inline-block w-100"
                            style="border-radius: 40px 40px 100% 100%; max-width: 106px;"
                        >
                            <div class="pa-2 text-center kiwi-fs-13 font-weight-bold kiwi-text-white"
                                style="background-color: #005469; min-height: 35px;"
                            >
                                Overall Score
                            </div>
                            <div class="pa-2 text-center d-flex align-center justify-center kiwi-fs-46 font-weight-bold kiwi-text-white font-weight-black"
                                style="background-color: #9E007E; height: 78px;"
                            >
                                {{overallScore}}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-6 mb-4">
                <h2 class="kiwi-fs-20 font-weight-bold mb-2">Skills Breakdown</h2>
                <v-divider />
                <div class="mt-6 d-flex flex-wrap flex-column position-relative" style="grid-gap: 10px; max-width: 605px; padding-top: 30px; padding-bottom: 30px;">
                    
                    <template v-for="(score, j) in reorderedArray" :key="j">
                        <div class="d-flex align-center" style="grid-gap: 10px;">
                            <span class="kiwi-fs-16 font-weight-medium kiwi-text-light-black-4 text-right" style="width: 80px;">{{score.name}}</span>
                            <span class="kiwi-fs-16 font-weight-medium kiwi-text-black-3" style="width: 25px;">{{score.value}}</span>
                            <div class="flex-grow-1 position-relative" style="max-width: 500px;">
                                <template v-if="j==0">
                                    <span class="kiwi-fs-14 font-weight-semi-bold position-absolute z-1 overall-line-text" :style="{ left: `${overallScoreToHundred}%` }">Overall {{overallScore}}</span>
                                    <v-divider :thickness="4" vertical class="position-absolute z-1 overall-line" color="#8096AB" :style="{ left: `${overallScoreToHundred}%` }"></v-divider>
                                </template>
                                <v-progress-linear
                                    bg-color="white"
                                    :class="`kiwi-text-${score.name.toLowerCase()}`"
                                    :model-value="score.progress"
                                    height="24"
                                ></v-progress-linear>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <v-divider />
            <div class="py-4">
                <span class="font-weight-bold">*Note:</span> <span>This score report is unofficial and cannot be used for university and college applications or visa purposes. It's based solely on your performance in the mock test.</span>
            </div>
        </v-card>
    </v-sheet>
</template>

<script setup>
import {ref, computed} from 'vue'
const props = defineProps({
    result: {
        required: true
    }
})
const scores = computed(() => {
    const keys = Object.keys(props.result).filter(k => k !== 'Overall') 
    return keys.map(k => ({
        name: k,
        progress: props.result[k] ? (props.result[k] / 90) * 100 : 0,
        value: props.result[k]
    }))
})

const order = ref(["Listening", "Reading", "Speaking", "Writing"]);
// Create a map for easy access
const scoresMap = computed(() => {
    return scores.value.reduce((map, item) => {
        map[item.name] = item;
        return map;
    }, {})
});
const reorderedArray = computed(() => {
    return order.value.map(name => scoresMap.value[name])
});

const overallScore = computed(() => {
    return props.result.Overall || null
})
const overallScoreToHundred = computed(() => {
    if(!overallScore.value) {
        return 0;
    }
    return (overallScore.value / 90) * 100
})
</script>

<style lang="scss" scoped>
.overall-line-text {
    top: -30px;
}
.overall-line {
    top: -15px;
    min-height: 160px !important;

    opacity: 1;
    z-index: 999;
}
.kiwi-text-listening {
    --color-listening: #173057;
}
.kiwi-text-reading {
    --color-reading: #D1DB0D;
}
.kiwi-text-speaking {
    --color-speaking: #595959;
}
.kiwi-text-writing {
    --color-writing: #9E007D;
}
</style>