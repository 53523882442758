<script setup>
const emit = defineEmits(['view-score']);

const viewScore = () =>{
  emit('view-score');
}
</script>

<template>
  <v-sheet class="w-100 fill-height d-flex align-center justify-center">
    <div class="d-flex flex-column flex-wrap text-center w-100" style="max-width: 456px;">
      <img src="/assets/sat-exam-over.png" alt="exam over vector image" class="mx-auto" style="max-width: 348px;">
      <div class="kiwi-fs-36 font-weight-bold mt-4">You-re all finished!</div>
      <p class="mt-3">Return to the homepage to see the preview again. Remainder: There are no scores or feedbacks in this preview.</p>
      <p class="mt-4">When you see this page on the test day, you’ll know your scores have been submitted.</p>
      <div class="text-center">
        <v-btn class="kiwi-button kiwi-bg-primary kiwi-text-white kiwi-radius-4 text-none mt-4" variant="flat" @click="viewScore">View Score</v-btn>
      </div>
    </div>
  </v-sheet>
</template>

<style scoped>

</style>
