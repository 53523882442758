import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useAnswerInstanceStore = defineStore("answerInstance", () => {

    const questionId = ref(null)
    const answer = ref({})

    function setAnswer(answerObj){
        answer.value = {
            ...answer.value,
            ...answerObj
        }
    }
    function resetAnswer () {
        answer.value = {}
        questionId.value = null
    }
    function setQuestionId (val) {
        questionId.value = val
    }

    return {questionId , answer , setAnswer , resetAnswer , setQuestionId}
})
