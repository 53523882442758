<template>
    <Prompt 
        max-width="500" 
        :persistent="voucherSuccess" 
        @on-close="closeDialog"
    >
        <template v-if="isLoading">
            <div class="w-100 h-100 d-flex align-center justify-center kiwi-bg-white kiwi-radius-4" style="min-height: 250px;">
                <img src="/assets/loader/loader.webp" alt="loader" style="width: 50px; height: 50px;">
            </div>
        </template>
        <template v-else>

            <template v-if="!voucherSuccess">
                <div class="pa-4 pb-2 text-center">
                    <v-text-field 
                        type="hidden"
                        class="hidden-field"
                        :error-messages="validation"
                        v-if="validation"
                    />
                    <h2 class="font-weight-bold kiwi-fs-24">Apply Voucher</h2>
                    
                    <v-text-field
                        type="text"
                        class="kiwi-form-input w-100 mt-4 text-left"
                        variant="outlined"
                        placeholder="Voucher Code"
                        v-model="formData.voucher_code"
                        @input="maskVoucherMethod"
                        :error-messages="validation"
                    />
                </div>
                <v-divider />
                <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px" v-if="validVoucher">
                    <div>Your Selected Plan</div>
                    <v-spacer></v-spacer>
                    <div class="d-flex align-center font-weight-bold"><img src="/assets/Icons.svg" class="mr-1" alt="" style="width: 24px; height: 24px;"><span>{{ selectedPlan }}</span></div>
                </div>
                <v-divider />
                <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px">
                    <v-btn
                        variant="outlined"
                        class="flex-md-grow-1 text-center kiwi-bg-white kiwi-text-black text-none kiwi-fs-16"
                        size="large"
                        @click.prevent="closeDialog"
                        style="border-color: #DBE1E0;"
                    >Close</v-btn>
                    <v-btn
                        variant="flat"
                        class="flex-md-grow-1 text-center text-none kiwi-fs-16"
                        size="large"
                        :disabled="!formData.email || submitting"
                        @click.prevent="applyVoucher"
                        :class="formData.email || submitting ? 'kiwi-bg-primary kiwi-text-white' : 'kiwi-bg-disabled kiwi-button-disable kiwi-text-black'"
                    >Apply</v-btn>
                </div>
            </template>
            <template v-else>
                <div class="pa-4 text-center" style="background-color: #F7F8F9; border-bottom: 1px solid #E2E6E4;">
                    <img src="/assets/kiwi-logo.svg" style="max-width: 150px;" alt="logo">
                </div>
                <div class="pa-4 text-center">
                    <img src="/assets/successfull.svg" alt="" style="height: 80px;">
                    <!-- <h2 class="font-weight-bold kiwi-fs-20">{{ successMessage}}</h2> -->
                    <h2 class="font-weight-bold kiwi-fs-20">Subscription added successfully!</h2>
                </div>
                <div class="pa-4">
                    <div class="pa-4 kiwi-radius-4" style="background-color: #F7F8F9;">
                        <div>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <span class="kiwi-fs-14 kiwi-text-light-black-2 font-weight-medium">Subscription Type</span>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div class="d-flex justify-end align-center">
                                        <img :src="student?.current_package?.image" :alt="student?.current_package?.name" style="width: 24px; height: 24px;">
                                        <span class="kiwi-fs-14 font-weight-bold kiwi-text-black-3 ml-2">{{student?.current_package?.name}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <div style=" border-top: 1px dashed #AEB3B1; padding-top: 8px; margin-top: 8px;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <span class="kiwi-fs-14 kiwi-text-light-black-2 font-weight-medium">Voucher Code</span>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div class="d-flex justify-end align-center">
                                        <span class="kiwi-fs-14 font-weight-bold kiwi-text-black-3 ml-2">{{maskCode}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="pa-4 kiwi-radius-4 mt-3" style="background-color: #F7F8F9;">
                        <div class="d-flex align-center mb-3">
                            <div style="width: 8px; height: 8px; background-color: var(--color-primary); border-radius: 100%; margin-right: 5px"></div>
                            <div class="kiwi-fs-16 kiwi-test-black-3 font-weight-bold">Assigned to</div>
                        </div>
                        <div class="py-4 px-3 kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white" style="box-shadow: 0px 12px 20px 0px #10182805;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <span class="kiwi-fs-14 kiwi-text-light-black-2 font-weight-medium">Student Name</span>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div class="d-flex justify-end align-center">
                                        <span class="kiwi-fs-14 font-weight-bold kiwi-text-black-3 ml-2">{{responseData?.user_detail?.user_full_name}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row style="border-top: 1px solid var(--color-light-grey-3) !important;">
                                <v-col cols="12" sm="6">
                                    <span class="kiwi-fs-14 kiwi-text-light-black-2 font-weight-medium">Email Address</span>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div class="d-flex justify-end align-center">
                                        <span class="kiwi-fs-14 font-weight-bold kiwi-text-black-3 ml-2">{{responseData?.user_detail?.email}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
                <v-divider />
                <div class="pa-4 text-center">
                    <v-btn
                    variant="flat"
                    block
                    class="flex-md-grow-1 kiwi-bg-primary kiwi-text-white text-center text-none kiwi-fs-16"
                    size="large"
                    @click="goto_vouchers"
                >Goto Vouchers</v-btn>
                </div>
            </template>
        </template>
    </Prompt>
</template>

<script setup>
import Prompt from '@/components/Prompt/ConfirmAlert.vue'
import {ref, watch} from 'vue'
import {useValidation} from '@/composables/useValidation'
import {useRouter} from 'vue-router'
import {useAgentVoucherStore} from '@/stores/institute/agent-voucher-store'
import { usePackageStore } from '@/stores/package-store';

const props = defineProps([
    'student', 'currentPackage'
])

const emit = defineEmits(['closeModal', 'onSuccess'])
const packageData = usePackageStore()
const router = useRouter()
const {checkVoucher } = packageData
const validVoucher = ref(false)
const selectedPlan = ref(null)
const voucherSuccess = ref(false)
const validation = ref(null)
const submitting = ref(false)
const handleErrors = useValidation()
const successMessage = ref(null)
const studentData = ref({})
const enableApplyButton = ref(false);
const agentVoucherStore = useAgentVoucherStore()
const responseData = ref({})
const formData = ref({
    voucher_code: null,
    email: props.student?.email
})

const closeDialog = () => {
    emit('closeModal')
}

const goto_vouchers = () => {
    closeDialog()
    router.go()
}
const isLoading = ref(false)
const applyVoucher = () => {
    successMessage.value = null
    isLoading.value = true
    if(formData.value.voucher_code) {
        submitting.value= true
        voucherSuccess.value = false
        agentVoucherStore.applyVoucher(formData.value.email, formData.value.voucher_code)
        .then(res => {
            if(res.success){
                isLoading.value = false
                emit('onSuccess')
                voucherSuccess.value = true
                validation.value = null
                validVoucher.value = true
                submitting.value = false
                successMessage.value = res.data?.message
                studentData.value = {...res?.data?.user_detial}
                selectedPlan.value = res.data?.package?.name
                responseData.value = res.data
            }
        }).catch(error => {
            isLoading.value = false
            submitting.value = false
            validVoucher.value = false
            selectedPlan.value = null
            validation.value = error?.response?.data?.data?.message || error?.response?.data?.data?.error || error?.response?.data?.data?.apply_for_user_email || 'Invalid Email'
        })
    }
}
const asigneeEmailEvent = () => {
    if(formData.value.voucher_code && formData.value.email) {
        enableApplyButton.value = true;
  }
}


const maskCode = ref(null)
const maskVoucherCode = (code) => {
    // Get the first two characters
    const firstTwo = code.slice(0, 3);
    
    // Get the last two characters
    const lastTwo = code.slice(-2);
    
    // Calculate the number of 'X's needed
    const numXs = code.length - 5;
    
    // Create the masked middle part with 'X's
    const maskedMiddle = 'X'.repeat(numXs);
    
    // Concatenate the parts
    return `${firstTwo}${maskedMiddle}${lastTwo}`;
}
const maskVoucherMethod = () => {
    if(formData.value.voucher_code) {
        maskCode.value = maskVoucherCode(formData.value.voucher_code)       
    }
    asigneeEmailEvent();
}

watch(formData.value.voucher_code, (val) => {
    if(val) {
        maskCode.value = maskVoucherCode(val)
    }
})
</script>

<style lang="scss">
.hidden-field .v-field__outline {
    display: none;
}
</style>