<script setup>
import {useAnswer} from "@/composables/useAnswer";
const {findHighlightedAnswerFor  } = useAnswer();
const props = defineProps({
  content:{
    required: true,
  }
})
</script>

<template>
  <div class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
    <span class="answer-box" style="background-color: #d4edda;color: #24b445; border-color: #c3e6cb;" >Correct Word</span>
    <v-divider class="mt-2" />
    {{findHighlightedAnswerFor(content.marks_obtained_json , content.question_data.question_text )}}
  </div>
  <div class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
    <span style="background-color: #f8d7da; color: #d0071a ;border-color: #f5c6cb;"  class="answer-box">Incorrect Word</span>
    <v-divider class="mt-2" />
    {{findHighlightedAnswerFor(content.marks_obtained_json , content.question_data.question_text , false )}}
  </div>
  <div  class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
    <span style="background-color: #efefec;color: #f6d303; border-color: #f3f0f0;" class="answer-box" >Not Highlighted</span>
    <v-divider class="mt-2"/>
    {{findHighlightedAnswerFor(content.marks_obtained_json , content.question_data.question_text , false  , true)}}
  </div>
</template>

<style scoped>
.answer-box {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
}
</style>
