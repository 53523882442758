import {api} from '@/plugins/axios';
import {AuthTokenService} from "@/handler/authService";
import { defineStore } from 'pinia'
import { ref, watch } from 'vue';
import { computed } from 'vue';
import { getUserDefaultRoute } from '@/utils/utils';


export const useAuthStore = defineStore('auth', () => {
  const satUser = ref(true);
  const user = ref(localStorage.getItem('AUTH_USER') ? JSON.parse(localStorage.getItem('AUTH_USER')) : null);
  const userType = ref(localStorage.getItem('AUTH_U_TYPE') ?  localStorage.getItem('AUTH_U_TYPE') : null);
  async function getProfile(){
      const res = await api.get('user/profile/').then(res=>{
          user.value = res.data;
          localStorage.setItem('AUTH_USER', JSON.stringify(user.value))
          return res.data
      }).catch(error =>{
        throw new Error(error)
      })
      return res;
  }
  async function setProfile(data){
    const url = 'user/profile/'
    try {
      const res = await api.patch(url, data)
      user.value = res.data
      return res
    } catch (error) {
      if(error?.response?.data?.data){
        throw {
          type: 'validation',
          messages: error.response.data.data
        }
      }
    }
  }

  async function setNewPassword(data){
    const url = 'user/change-password/'
    try {
      const res = await api.post(url, data)
      return res
    } catch (error) {
      if(error?.response?.data?.data){
      throw {
          type: 'validation',
          messages: error.response.data.data
        }
      }
    }
  }

  function verifyEmail(){
    return api.post('user/send-verification/',{email:user.value.email});
  }
  function logout()
  {
    user.value = null;
    return api.post('user/user/logout/', {refresh_token: AuthTokenService.getRefreshToken()})
  }
  function clearAuth(){
      localStorage.removeItem('rememberMe')
      // localStorage.removeItem('GoogleAuthUser')
      // localStorage.removeItem('FacebookAuthUser')
      localStorage.removeItem('AUTH_U_TYPE')
      localStorage.removeItem('AUTH_USER')
      AuthTokenService.clearTokens();
      setTimeout(() => {
        cleanUserData()
      }, 300)
  }
  const setUserType = (type) => {
    userType.value = type
    if(type) {
      localStorage.setItem('AUTH_U_TYPE', type)
    }else {
      localStorage.removeItem('AUTH_U_TYPE')
    }
  }
  const cleanUserData  = () => {
    user.value = null
    setUserType(null)
  }
  watch(user, (u, oldVal) => {
    if(u) {
      setUserType(u ? u.user_type : null)
    }else {
      localStorage.removeItem('AUTH_USER')
    }
  }, {immediate: true, deep: true})
  const isStudent = computed(() => {
    return userType.value === 'student'
  })
  const isInstitute = computed(() => {
    return userType.value === 'institute'
  })
  const isAgent = computed(() => {
    return userType.value === 'agent'
  })
  const isSAT = computed(() => {
    return user.value?.stream?.name === 'SAT';
  })
  const satScoreIcon = computed(() => {
    return isSAT.value ? '+' : ''
  })
  async function switchToAnotherUser(id, type, backpath){
      const currentUser = user.value
      const switchPayload = {
        id: currentUser.id,
        user_type: userType.value,
        path: backpath,
        access: AuthTokenService.getAccessToken(),
        refresh: AuthTokenService.getRefreshToken()
      }
      return await api.post('user/get-student-login-url/', {student_id: id}).then(res=>{
          localStorage.setItem('SWITCH_TOKEN', JSON.stringify(switchPayload))
          return res.data
      })
  }
  async function switchBack(router){
      const switchPayload = localStorage.getItem('SWITCH_TOKEN') ? JSON.parse(localStorage.getItem('SWITCH_TOKEN')) : null;
      if(!switchPayload || !switchPayload.access || !switchPayload.refresh) {
        return false
      }
      AuthTokenService.setTokens(switchPayload.access, switchPayload.refresh)
      const profile = await getProfile()
      router.push({name : switchPayload.path || getUserDefaultRoute(userType.value)})
      return profile;
  }
  return {logout , clearAuth , user , getProfile , verifyEmail ,setProfile, setNewPassword, isInstitute, isAgent, isStudent, isSAT, userType, setUserType, cleanUserData, satScoreIcon, switchToAnotherUser, switchBack}
})
