<template>
  <v-sheet
    class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent"
    variant="flat"
    v-if="contentView === 1"
  >
    <div class="note kiwi-fs-16 font-weight-semi-bold">
      <span class="note kiwi-text-error mr-1">Note:</span>
      <span>The test is approximately 2 hours long.</span>
    </div>
    <v-table density="comfortable" class="custom-table border mt-6 answer-table">
      <thead class="kiwi-bg-light-grey-5">
        <tr>
          <th>Part</th>
          <th>Content</th>
          <th>Time Allowed</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Intro</td>
          <td>Introduction</td>
          <td>1 Minute</td>
        </tr>
        <tr>
          <td>Part1</td>
          <td>Speaking and Writing</td>
          <td>54-67 Minutes</td>
        </tr>
        <tr>
          <td>Part2</td>
          <td>Reading</td>
          <td>29-30 Minutes</td>
        </tr>
        <tr>
          <td>Part3</td>
          <td>Listening</td>
          <td>30-43 Minutes</td>
        </tr>
      </tbody>
    </v-table>
  </v-sheet>
  <v-sheet
    class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent kiwi-paragraphs-bundle"
    variant="flat"
    v-else-if="contentView === 2"
  >
    <h1 class="kiwi-fs-36 font-weight-bold">Headset Check</h1>
    <p>
      This is an opportunity to check that your headset is working correctly.
    </p>
    <v-divider class="my-6" />
    <ul class="has-bullet pl-5">
      <li>
        Put your headset on and adjust it so that it fits comfortably over your
        ears.
      </li>
      <li>
        When you are ready, click on the [Play] button. You will hear a short
        recording.
      </li>
      <li>
        If you do not hear anything in your headphones while the status reads
        [Playing], raise your hand to get the attention of the Test
        Administrator. <br />
        <audio controls class="mt-4 mb-2">
          <source :src="headsetCheck" />
          Your browser does not support the audio element.
        </audio>
      </li>
      <li>
        During the practice you will not have [Play] and [Stop] buttons. The
        audio recording will start playing automatically.
      </li>
      <li>
        Please do not remove your headset. You should wear it throughout the
        test.
      </li>
    </ul>
  </v-sheet>
  <v-sheet
    class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent kiwi-paragraphs-bundle"
    variant="flat"
    v-else-if="contentView === 3"
  >
    <h1 class="kiwi-fs-36 font-weight-bold">Microphone Check</h1>
    <p>
      This is an opportunity to check that your microphone is working correctly.
    </p>
    <v-divider class="my-6" />
    <ul class="has-bullet pl-5">
      <li>
        Make sure your headset is on and the microphone is in the downward
        position near your mouth.
      </li>
      <li>
        When you are ready, click on the Record button and say "Testing,
        testing, one, two, three" into the microphone.
      </li>
      <li>
        After you have spoken, click on the Stop button. Your recording is now
        complete.
      </li>
      <li>
        Now click on the Playback button. You should clearly hear yourself
        speaking.
      </li>
      <li>
        If you can not hear your voice clearly, please raise your hand. <br />
        <Recorder />
      </li>

      <li>
        During the test, you will not have Record, Playback and Stop buttons.
        The voice recording will start automatically.
      </li>
    </ul>
  </v-sheet>
  <v-sheet
    class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent kiwi-paragraphs-bundle"
    variant="flat"
    v-else-if="contentView === 4"
  >
    <h1 class="kiwi-fs-36 font-weight-bold">Keyboard Check</h1>
    <p>This is an opportunity to check that you have the correct keyboard.</p>
    <v-divider class="my-6" />
    <ul class="has-bullet pl-5">
      <li>Look at the top row of letters on the keyboard.</li>
      <li>The letters should appear in this order Q W E R T Y.</li>
      <li>
        If you do not have a Q W E R T Y keyboard, raise your hand to get the
        attention of Test Administrator.
      </li>
      <li>
        Now click on the Playback button. You should clearly hear yourself
        speaking.
      </li>
    </ul>
    <figure>
      <img
        src="/public/assets/Mock Tests/_PlayerPreview-playerControls.svg"
        alt=""
      />
    </figure>
  </v-sheet>
  <v-sheet
    class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent kiwi-paragraphs-bundle"
    variant="flat"
    v-else-if="contentView === 5"
  >
    <h1 class="kiwi-fs-36 font-weight-bold">Test Introduction</h1>
    <p>
      This test will measure the English Reading, Writing, Listening and
      Speaking skills that you need in an academic setting.
    </p>
    <v-divider class="my-6" />
    <ul class="has-bullet pl-5">
      <li>
        The test is divided into 3 parts. Each part may contain a number of
        sections. The sections are individually timed. The timer will be shown
        in the top right corner of your screen. The number of items in the
        section will also be displayed. <br />
        <v-chip
          label
          class="kiwi-radius-4 kiwi-bg-light-grey-3 kiwi-text-black font-weight-bold my-2"
        >
          <img
            src="/assets/Mock Tests/timer-02.svg"
            class="mr-2 size-18"
            alt=""
          />
          <span>00:02 / 30:00</span>
        </v-chip>
      </li>
      <li>
        At the beginning of each part you will receive instructions. These will
        provide details on what to expect in that part of the test.
      </li>
      <li>
        By clicking on the Next button at the bottom of each screen you confirm
        your answer and move to the next question. If you click on Next you will
        not be able to return to the previous question. You will not be able to
        revisit any questions at the end of the test.
      </li>
      <li>
        You will be offered a break of up 10 minutes after Part 2. The break is
        optional.
      </li>
      <li>
        This test makes use of different varieties of English, for example,
        British, American, Australian. You can answer in the standard English
        variety of your choice.
      </li>
    </ul>
  </v-sheet>
  <v-sheet
    class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent kiwi-paragraphs-bundle"
    variant="flat"
    v-else-if="contentView === 6"
  >
    <p>
      Read the prompt below. In 25 seconds, you must reply in your own words, as
      naturally and clearly as possible. You have 30 seconds to record your
      response. Your response will be sent together with your score report to
      the institutions selected by you.
    </p>
    <v-divider class="my-6" />
    <p>
      Please introduce yourself. For example, you could talk about one of the
      following.
    </p>
    <ul class="has-bullet pl-5 font-weight-semi-bold">
      <li>Your interests</li>
      <li>Your plans for future study</li>
      <li>Why you want to study abroad</li>
      <li>Why you need to learn English</li>
      <li>Why you chose this test</li>
    </ul>
    <Recorder
      :auto-record="true"
      :record-after="25"
      :recordFor="30"
      :playBeepBeforeRecord="true"
    />
  </v-sheet>
</template>
<script setup>
import { onMounted } from "vue";
import Recorder from "./Recorder.vue";
import headsetCheck from "@/assets/headsetCheck.mp3";
import { useExamTimerInstanceStore } from "@/stores/examTimerInstance-store";
import Player from "./Player.vue";

const examTimerStore = useExamTimerInstanceStore();
const { updateIntroductionTimer } = examTimerStore;
const { contentView } = defineProps({
  contentView: {
    type: Number,
    default: 1,
  },
});
onMounted(() => {
  if (contentView === 6) {
    updateIntroductionTimer();
  }
});
</script>
