<template>
  <v-list density="compact" nav class="px-0 kiwi-navigation" v-model:opened="openMenu">
    <template v-for="(menu, i) in menuList" :key="i">
      <template v-if="!menu.hidden">
        <template v-if="!menu.hasChildren">
          <v-list-item
            rounded="0"
            :title="menu.label"
            :to="{ name: menu.path}"
            :value="menu.path"
            v-if="menu.path !== 'contact'"
            class="kiwi-border-l-transparent border-l-4"
            :active-class="[
              (
                $route.name === menu.path )
                ? 'font-weight-bold kiwi-border-l-primary kiwi-bg-primary-menu kiwi-text-primary v-list-item--active'
                : '',
              (menu.path === 'ins_voucher' && $route.name === 'ins_create-voucher')
                ? 'font-weight-bold kiwi-border-l-primary kiwi-bg-primary-menu kiwi-text-primary v-list-item--active'
                : '',
              (menu.path === 'agent_voucher' && $route.name === 'agent_create-voucher')
                ? 'font-weight-bold kiwi-border-l-primary kiwi-bg-primary-menu kiwi-text-primary v-list-item--active'
                : '',
              (menu.path === 'agent_voucher' && $route.name === 'agent_checkout')
                ? 'font-weight-bold kiwi-border-l-primary kiwi-bg-primary-menu kiwi-text-primary v-list-item--active'
                : '',
              (menu.path === 'ins_voucher' && $route.name === 'ins_checkout')
                ? 'font-weight-bold kiwi-border-l-primary kiwi-bg-primary-menu kiwi-text-primary v-list-item--active'
                : '',
            ]
            "
            :ripple="false"
            :active="menu.path === $route.name || (menu.path === 'ins_voucher' && $route.name === 'ins_create-voucher') || (menu.path === 'agent_voucher' && $route.name === 'agent_create-voucher') || (menu.path === 'agent_voucher' && $route.name === 'agent_checkout') || (menu.path === 'ins_voucher' && $route.name === 'ins_checkout')"
            @click="closeSidebarForMobile"
          >
            <template v-slot:prepend>
              <v-avatar
                size="32"
                class="menu-active-avatar"
                v-if="
                  router.currentRoute._value.name === menu.path ||
                  ($route.name === 'single_information' && $route.params.id && menu.path === 'information') ||
                  (menu.path === 'ins_voucher' && $route.name === 'ins_create-voucher') ||
                  (menu.path === 'agent_voucher' && $route.name === 'agent_create-voucher') ||
                  (menu.path === 'agent_voucher' && $route.name === 'agent_checkout') ||
                  (menu.path === 'ins_voucher' && $route.name === 'ins_checkout')
                "
                v-html="menu.svg"
              ></v-avatar>
              <v-avatar size="32" :image="menu.initialImgSrc" v-else></v-avatar>
            </template>
          </v-list-item>
          <v-list-item
            rounded="0"
            :title="menu.label"
            :to="{ name: menu.path, query: {activeTab: menu.activeTabMenu}}"
            :value="menu.path"
            v-else
            class="kiwi-border-l-transparent border-l-4"
            :active-class="
              (
                router.currentRoute._value.name === menu.path &&
                $route.query.activeTab === menu.activeTabMenu )
                ? 'font-weight-bold kiwi-border-l-primary kiwi-bg-primary-menu kiwi-text-primary v-list-item--active'
                : ''
            "
            :ripple="false"
            @click="closeSidebarForMobile"
          >
            <template v-slot:prepend>
              <v-avatar
                size="32"
                :image="menu.activeImgSrc"
                v-if="
                  router.currentRoute._value.name === menu.path &&
                  $route.query.activeTab === menu.activeTabMenu
                "
              ></v-avatar>
              <v-avatar size="32" :image="menu.initialImgSrc" v-else></v-avatar>
            </template>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-group
            :key="i"
            value="my-practice"
          >
            <template v-slot:activator="{  props }">
              <v-list-item
                rounded="0"
                :title="menu.label"
                class="kiwi-border-l-transparent border-l-4"
                active-class="font-weight-bold kiwi-border-l-primary kiwi-bg-primary-menu kiwi-text-primary"
                :ripple="false"
                v-bind="props"
                @click="closeSidebarForMobile"
              >
                <template v-slot:prepend>
                  <v-avatar
                    size="32"
                    class="menu-active-avatar"
                    v-if="
                      $route.name === 'mock-tests' ||
                      $route.name === 'question-based-tests' ||
                      $route.name === 'evaluation'
                    "
                    v-html="menu.svg"
                  ></v-avatar>
                  <v-avatar
                    size="32"
                    :image="menu.initialImgSrc"
                    v-else
                  ></v-avatar>
                </template>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, j) in menu.children"
              :key="j"
              rounded="0"
              :title="child.label"
              :to="{ name: child.path }"
              class="kiwi-border-l-transparent border-l-4 child-menu-list"
              :active-class="[
                ($route.name === 'individualQuestion' && child.path === 'individual-question-test') ? `font-weight-bold kiwi-bg-white kiwi-text-primary` : ''
              ]"
              active-color="font-weight-bold kiwi-bg-white kiwi-text-primary"
              :ripple="false"
              :active="child.path === $route.name || ($route.name === 'individualQuestion' && child.path === 'individual-question-test')"
              @click="closeSidebarForMobile"
            ></v-list-item>
          </v-list-group>
        </template>
        </template>
    </template>
    <v-list-item
      rounded="0"
      @click="confirmDialog = true"
      title="Logout"
      value="logout"
      class="kiwi-border-l-transparent border-l-4"
      active-class="font-weight-bold kiwi-border-l-primary kiwi-bg-primary-menu kiwi-text-primary"
      :ripple="false"
    >
      <template v-slot:prepend>
        <v-avatar
          size="32"
          image="/assets/logout-01.svg"
          v-if="router.currentRoute._value.path !== '/'"
        ></v-avatar>
        <v-avatar
          size="32"
          image="/assets/logout-01_active.svg"
          v-else
        ></v-avatar>
      </template>
    </v-list-item>
  </v-list>
  <div>
    <ModalDialog class="text-center" @update:dialog="confirmDialog = false" @confirm="logoutUser" v-if="confirmDialog" >
      <template #title>
          <div class="w-100 mb-2 mt-7">
            <img src="/assets/Notifications.svg" alt="">
          </div>
          <span class="kiwi-fs-24 font-weight-bold">Confirm Logout</span>
      </template>
      Are you sure you want to logout your account?
  </ModalDialog>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import menus from "@/constant/Menu";
import sat_menus from "@/constant/SAT_Menu";
import instituteMenu from "@/constant/InstituteMenu";
import agentMenu from "@/constant/AgentMenu";
const router = useRouter();
import { useAuthStore } from "@/stores/auth/authStore";
import { useConfirmer } from "@/stores/confirmer";
import ModalDialog from "@/components/Prompt/ModalDialog";
import { ref, computed, onMounted } from "vue";
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()

const props = defineProps({
  userType: {
    required: false
  }
})

const emits = defineEmits(['close:sidebarForMobile'])
const confirmer = useConfirmer();
const userStore = useAuthStore();
const { logout, clearAuth } = userStore
const confirmDialog = ref(false);

const logoutUser = async () => {
  try {
    const res = await logout();
    clearAuth();
    router.push({ name: "login" });
  } catch (err) {}
};
const menuList = computed (() => {
    let list = []
    if(userStore.isInstitute) {
      list = [...instituteMenu]
    }else if(userStore.isStudent) {
      list = userStore.isSAT ? [...sat_menus] : [...menus]
    } else if(userStore.isAgent) {
      list = [...agentMenu]
    }
    return list.map(menu => {
      menu.hidden =  menu.feature && !institute.$hasFeature(menu.feature)
      return menu
    })
})
const closeSidebarForMobile = () => {
  emits('close:sidebarForMobile', true);
}

const openMenu = ref(['my-practice'])
onMounted(() => {
  setTimeout(() => {
   openMenu.value = ['my-practice'];
  }, 300)
})

// const openMenu = computed(() => {
//    return ['my-practice']
// });
</script>

<style lang="scss">
.kiwi-navigation .v-list-item--active .v-list-item-title {
  font-weight: 700 !important;
}
.kiwi-navigation .v-list-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
.menu-active-avatar {
  svg, rect, path {
    fill: var(--color-primary) !important;
  }
}
#app .kiwi-navigation {
  .v-list-group--open .v-list-group__items {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 10px;
      z-index: 1;
      width: 1px;
      height: calc(100% - 18px);
      background-color: #D4D7D6;
    }

    & > * {
      padding-inline-start:  40px !important;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 7px;
        z-index: 1;
        transform: translateY(-50%);
        width: 25px;
        height: 1px;
        background-color: #D4D7D6;
      }
    }
  }
}
</style>
