<template>
    <prompt max-width="500" :persistent="voucherSuccess" @on-close="closeDialog">
        <template v-if="isLoading">
            <div class="w-100 h-100 d-flex align-center justify-center kiwi-bg-white kiwi-radius-4" style="min-height: 250px;">
                <img src="/assets/loader/loader.webp" alt="loader" style="width: 50px; height: 50px;">
            </div>
        </template>
        <template v-else>
            <template v-if="!voucherSuccess">
            <div class="pa-4 text-center">
                <h2 class="font-weight-bold kiwi-fs-24">Voucher Code</h2>
                <v-text-field
                    class="kiwi-form-input w-100 mt-4 text-left"
                    variant="outlined"
                    placeholder="Enter Voucher Code"
                    v-model="VoucherCode"
                    :error-messages="validation"
                    @input="VoucherEvent"
                />
            </div>
            <v-divider />
            <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px" v-if="validVoucher">
                <div>Your Selected Plan</div>
                <v-spacer></v-spacer>
                <div class="d-flex align-center font-weight-bold"><img src="/assets/Icons.svg" class="mr-1" alt="" style="width: 24px; height: 24px;"><span>{{ selectedPlan }}</span></div>
            </div>
            <v-divider />
            <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px">
                <v-btn
                    variant="outlined"
                    class="flex-md-grow-1 text-center kiwi-bg-white kiwi-text-black text-none kiwi-fs-16"
                    size="large"
                    @click.prevent="closeDialog"
                    style="border-color: #DBE1E0;"
                >Close</v-btn>
                <v-btn
                    variant="flat"
                    class="flex-md-grow-1 text-center text-none kiwi-fs-16"
                    size="large"
                    @click.prevent="applyVoucher"
                    :disabled="!VoucherCode || submitting"
                    :class="VoucherCode || submitting ? 'kiwi-bg-primary kiwi-text-white' : 'kiwi-bg-disabled kiwi-button-disable kiwi-text-black'"
                >Apply</v-btn>
            </div>
            </template>
            <template v-else>
                <div class="pa-4 text-center">
                    <img src="/assets/successfull.svg" alt="">
                    <h2 class="font-weight-bold kiwi-fs-24">{{successMessage}}</h2>
                </div>
                <v-divider />
                <div class="pa-4 text-center">
                    <v-btn
                    variant="flat"
                    block
                    class="flex-md-grow-1 kiwi-bg-primary kiwi-text-white text-center text-none kiwi-fs-16"
                    size="large"
                    @click="closeDialog"
                >Close</v-btn>
                </div>
            </template>

        </template>
    </prompt>
</template>

<script setup>
import Prompt from '@/components/Prompt/ConfirmAlert.vue'
import { usePackageStore } from '@/stores/package-store';
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import { useInstituteStudentStore } from '@/stores/institute/institute-student-store';
const studentStore = useInstituteStudentStore();
const packageData = usePackageStore()
const {checkVoucher } = packageData
const validVoucher = ref(false)
const VoucherCode = ref(null)
const selectedPlan = ref(null)
const voucherSuccess = ref(false)
const validation = ref(null)
const submitting = ref(false)
const successMessage = ref(null)
const enableApplyButton = ref(false);
const {student} = defineProps({
    student: {
        type: Object
    }
})
const emit = defineEmits(['closeModal', 'onSuccess'])
const isLoading = ref(false);

const closeDialog = () => {
    emit('closeModal')
}
const applyVoucher = () => {
    successMessage.value = null
    isLoading.value = true;
    if(VoucherCode.value) {
        submitting.value= true
        voucherSuccess.value = false
        studentStore.applyVoucher(student.id, VoucherCode.value)
        .then(res => {
            if(res.success){
                isLoading.value = false
                emit('onSuccess')
                voucherSuccess.value = true
                validation.value = null
                validVoucher.value = true
                submitting.value = false
                successMessage.value = res.data?.message
                selectedPlan.value = res.data?.package?.name
            }
        }).catch(error => {
            isLoading.value = false
            submitting.value = false
            validVoucher.value = false
            selectedPlan.value = null
            validation.value = error?.response?.data?.data?.message || error?.response?.data?.data?.error || 'Invalid Voucher'
        })
    }
}
const VoucherEvent = () => {
  if(VoucherCode.value) {
    enableApplyButton.value = true;
    checkVoucher(VoucherCode.value).then(res => {
      if(res.success){
        validation.value = null
        validVoucher.value = true
        selectedPlan.value = res.data.package.name
      }
    }).catch(error => {
      // console.log(error)
      validVoucher.value = false
      selectedPlan.value = null
      if(error.type == 'validation'){
        validation.value = error.message
      }
    })
  }
}
</script>

<style>

</style>
