<template>
    <div>
        <article class="kiwi-paragraphs-bundle mb-3 q-text" :class="{'font-weight-bold q-text-headline': props.headline}" v-if="text && dictionaryCondition"> 
            <span v-for="(word, index) in words" :key="index" @click="handleClick(word, index)" class="cursor-pointer kiwi-question-word" :class="{'kiwi-text-primary': (selectedWords === word && selectedIndex === index)}">
                {{ word }}
                <span v-if="index !== words.length - 1"> </span> <!-- To maintain spaces between words -->
            </span>
        </article>
        <article class="kiwi-paragraphs-bundle mb-3 q-text" :class="{'font-weight-bold q-text-headline': props.headline}" v-html="text" v-else-if="text && !dictionaryCondition"> </article>
        <DictionaryDialog v-if="dialog && !['swt' , 'we' , 'sst', 'mcm-l', 'mcq', 'mcm-r', 'mcs', 'smw'].includes(slug)" @close-dialog="dialog=false" :word="word" :returnData="dictionaryStore?.dictonaryData" />
    </div>
</template>
<script setup>
import { useIndividualQustionsDictonaryStore } from "@/stores/individual-question-dictionary";
import { storeToRefs } from "pinia";
import { defineProps, ref, onMounted, computed, watch } from "vue";
import {useRoute} from 'vue-router';
import DictionaryDialog from "@/components/IndividualQuestion/DIctionaryDialog.vue";
const props = defineProps({
    text: {
        required: false,
    },
    headline: {
        required: false
    },
    dictionaryCondition: {
        type: Boolean,
        required: false,
        default: false,
    }
})

const route = useRoute();
const dialog = ref(false);
const word = ref(null);
const selectedWords = ref('');
const selectedIndex = ref('');
const dictionaryStore = useIndividualQustionsDictonaryStore();
const { getDictonaryData, dictonaryData } = storeToRefs(dictionaryStore)

const cleanText = computed(() => {
    return props.text?.replace(/<\/?[^>]+(>|$)/g, '');
})
const words = computed(() => {
    return cleanText.value.split(' ');
})

const handleClick = (word, i) => {
    if(props.dictionaryCondition) {
        let text = word;
        
        if(word.split('.')) {
            text = word.split(/[^a-zA-Z]+/);
            text = text.filter(word => word);
        } else {
            text = word;
        }

        selectedWords.value = word
        selectedIndex.value = i

        dictionaryStore.getDictonaryData(text).then(res => {
            dialog.value = true;
            word.value = text;
        });
    }
}

watch(dialog, (newVal, oldVal) => {
  if (newVal === false) {
    selectedWords.value = ''
  }
});
</script>
  
<style lang="scss">
.q-text:not(.q-text-headline) {
    font-size: 16px;
}
.q-text:not(.q-text-headline),
.q-text:not(.highlight) {
    * {
        background-color: transparent !important;
        background: transparent !important;
    }
}
.q-text-headline {
    font-size: 17px;
    margin-bottom: 24px !important;
}
.kiwi-question-word {
    &:hover, &:active, &:visited, &:focus-visible {
        color: var(--color-primary);
    }
}
</style>