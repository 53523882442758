<template>
  <div class="fill-height">
    <v-app-bar
      class="kiwi-bg-light-grey-5 kiwi-text-black px-3 py-2 kiwi-border-b-1 kiwi-border-black-3 position-fixed w-100 top-0"
      elevation="0"
      density="comfortable"
      height="100"
      style="border-style: dashed !important;"
      ref="examBar"
      v-if="!['initial' , 'moduleOver'].includes(activeStackType) && !fetching"
    >
      <v-row class="align-center">
        <v-col>
          <div  class="d-flex align-center">
            <img
              :src="institute.logo"
              class="mr-2 d-none d-lg-block"
              alt="kiwi logo"
              style="height: 40px; width: auto; object-fit: contain;"
            />
            <img
              :src="institute.favicon"
              class="mr-2 d-lg-none"
              alt="kiwi logo"
              style="height: 40px; width: auto; object-fit: contain;"
            />
            <span class="slash mr-2">|</span>
            <div>
              <span class="font-weight-semi-bold d-block">{{ title }}</span>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                location="left"
                offset="-200"
              >
                <template v-slot:activator="{ props }">
                <span
                  class="cursor-pointer"
                  v-bind="props"
                >
                  Direction <v-icon class="mdi mdi-chevron-down"></v-icon>
                </span>
                </template>
                <div class="py-3"></div>
                <v-card max-width="600" class="kiwi-radius-8 d-flex flex-column px-4 pt-4" style="max-height: calc(100vh - 20vh); min-height: calc(100vh - 30vh); overflow-y: auto;">
                  <v-card-text class="kiwi-paragraphs-bundle" v-if="!mathModule">
                    <p>The questions in this section address a number of important reading and writing skills. Each question includes one or more passages, which may include a table or graph. Read each passage and question carefully, and then choose the best answer to the question based on the passage(s).</p>
                    <p>All questions in this section are multiple-choice with four answer choices. Each question has a single best answer.</p>
                  </v-card-text>

                  <v-card-text class="kiwi-paragraphs-bundle" v-else>
                    <p>The questions in this section address a number of important math skills. Use of a calculator is permitted for all questions. A reference sheet, calculator, and these directions can be accessed throughout the test. Unless otherwise indicated:</p>
                    <ul style="padding-left: 30px;">
                      <li>All variables and expressions represent real numbers.</li>
                      <li>Figures provided are drawn to scale.</li>
                      <li>All figures lie in a plane.</li>
                      <li>The domain of a given function f is the set of all real numbers x for which f(x) is a real number.</li>
                    </ul>
                    <p>For <strong>multiple-choice questions</strong>, solve each problem and choose the correct answer from the choices provided. </p>
                    <p>Each multiple-choice question has a single correct answer. For <strong>student-produced response questions</strong>, solve each problem and enter your answer as described below.</p>
                    <ul style="padding-left: 30px;">
                      <li>If you find <strong>more than one correct answer</strong>, enter only one answer.</li>
                      <li>You can enter up to 5 characters for a <strong>positive</strong> answer and up to 6 characters (including the negative sign) for a <strong>negative</strong> answer.</li>
                      <li>If your answer is a <strong>fraction</strong> that doesn't fit in the provided space, enter the decimal equivalent.</li>
                      <li>If your answer is a <strong>decimal</strong> that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.</li>
                      <li>If your answer is a <strong>mixed number</strong> (such as 3½), enter it as an improper fraction (7/2) or its decimal equivalent (3.5).</li>
                      <li>Don’t enter <strong>symbols</strong> such as a percent sign, comma, or dollar sign</li>
                    </ul>
                    <p class="text-center">Examples</p>
                    <v-table class="kiwi-table direction-table">
                      <thead>
                      <tr>
                        <th>Answer</th>
                        <th>Acceptable ways to enter answer</th>
                        <th>Unacceptable: will NOT receive credit</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>3.5</td>
                        <td>
                          <p>3.5</p>
                          <p>3.50</p>
                          <p>7/2</p>
                        </td>
                        <td>
                          <p>3 1/2</p>
                          <br>
                          <p>3 1/2</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <div style="max-width: 24px; text-align: center;">
                              <span class="d-block" style="border-bottom: 1px solid var(--color-black-3);">2</span>
                              <span class="d-block">3</span>
                            </div>
                          </p>
                        </td>
                        <td>
                          <p>⅔</p>
                          <p>.6666</p>
                          <p>.6667</p>
                          <p>0.666</p>
                          <p>0.667</p>
                        </td>
                        <td>
                          <p>0.66</p>
                          <p>.66</p>
                          <p>0.67</p>
                          <p>.67</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p class="d-flex flex-row align-center">
                            -
                            <div style="max-width: 24px; text-align: center;">
                              <span class="d-block" style="border-bottom: 1px solid var(--color-black-3);">1</span>
                              <span class="d-block">3</span>
                            </div>
                          </p>
                        </td>
                        <td>
                          <p>-⅓</p>
                          <p>-.3333</p>
                          <p>-0.333</p>
                        </td>
                        <td>
                          <p>-.33</p>
                          <p>-0.33</p>
                        </td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-card-text>

                  <v-card-actions style="margin-block-start: auto; padding-bottom: 20px;">
                    <v-spacer></v-spacer>

                    <v-btn
                      variant="flat kiwi-border-black-3 text-none"
                      rounded="xl"
                      @click="menu = false"
                      style="border: 1px solid; background-color: #FFDE5A;"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </div>
        </v-col>
        <v-col class="d-flex justify-center">
          <slot name="timer" />
        </v-col>
        <v-col class="d-flex justify-end">
          <slot name="headerAction" />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-app-bar  color="#DEE1E6" elevation="0" density="comfortable" v-else-if="!['moduleOver'].includes(activeStackType)">
      <div class="w-100 text-center">
        SAT Exams
      </div>
    </v-app-bar>
    <section v-if="!['initial' , 'moduleOver'].includes(activeStackType) && !fetching"  :style="{paddingTop: examBarHeight+'px'}">
        <v-container fluid class="py-0">
            <div class="kiwi-bg-black-3 py-2 d-flex align-center justify-center kiwi-text-white" style="border-radius: 0 0 30px 30px;">{{ examName }}</div>
        </v-container>
    </section>
    <div>
      <div
        class="px-5 py-0 w-100 sat-exam-wrapper"
        style="height: 100vh; overflow-y: auto;"
        :class="activeStackType === 'moduleOver' ? 'module-over-wrapper' : ''"
        :style="!['initial'].includes(activeStackType) && !fetching && activeStackType !== 'moduleOver' ? {maxHeight: `calc(100vh - 218px) !important`} : {maxHeight: `calc(100vh - 100px)`}"
      >
        <v-container
          class="kiwi-bg-white fill-height align-start kiwi-radius-6 py-5 py-md-0 px-8 px-md-0"
          fluid
        >
          <slot />
        </v-container>
      </div>

    </div>
  </div>
</template>

<script setup>
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()
const route = useRoute();
const examInstanceStore = useExamInstanceStore();
const questionBasedStore = useQuestionBasedTestStore();
import { useExamTimerInstanceStore } from "@/stores/examTimerInstance-store";
import { useRoute } from "vue-router";
import { useQuestionBasedTestStore } from "@/stores/questionbased-test-store";
const props = defineProps({
  mathModule: {
    type: Boolean,
    default: false,
  }
})
const timer = useExamTimerInstanceStore();
const showHeaders = ref(true);
const {activeStackType} = storeToRefs(examInstanceStore)
timer.clearTimer()
const examName = computed(() => {
  if(route.name === 'questionbasedtest' || route.name === 'satquestionbasedtest' ) {
    return questionBasedStore.testDetail
    ? questionBasedStore.testDetail.title
    : "";
  }
  return examInstanceStore.examDetail
    ? examInstanceStore.examDetail.description
    : "";
});
const title = computed(() => {
  const index =  examInstanceStore.examDetail?.exam_attempt_data?.exam_state?.current_section_module;
  if(index === 11 ){
    return 'Section 1,Module 1: Reading And Writing'
  }else if(index === 12 ){
    return 'Section 1,Module 2: Reading And Writing'
  }else if(index === 21){
    return 'Section 2,Module 1: Math'
  }else if(index === 22){
    return 'Section 2,Module 2: Math'
  }
})
const examBar = ref();

const examBarHeight = computed(() => {
    return 17;
})
const fetching = computed(() => {
  return examInstanceStore.fetching;
});
const menu = ref(false);
const examInstance = ref(null);

</script>
<style lang="scss">
  .direction-table.kiwi-table {
    color: var(--color-black-3);
    border: 1px solid var(--color-light-grey-3);
    border-radius: 4px;
    overflow: hidden;
    th {
      background-color: #F0F1F2;
      font-weight: 600;
    }
    th, td {
      padding: 8px !important;
      border-bottom: 1px solid var(--color-light-grey-3);
    }
    td + td {
      border-left: 1px solid var(--color-light-grey-3);
    }
  }
  .sat-exam-wrapper.module-over-wrapper {
    max-height: 100vh !important;
    height: 100vh;
  }
</style>
