<template>
    <header class="kiwi-header-section pa-2" style="z-index: 3">
        <!-- <v-container> -->
           <v-app-bar :elevation="0" class="kiwi-bg-transparent kiwi-mobile-app-bar" height="48">
                <router-link to="/"><img src="@/assets/logo.svg" alt=""></router-link>
                <template v-slot:append>
                    <v-icon @click.prevent="mobileFunctionInitiater" class="mdi mdi-menu" size="24"></v-icon>
                </template> 
            </v-app-bar>
            <v-navigation-drawer
                class="kiwi-nav-drawer"
                v-model="drawer"
                :location="$vuetify.display.mobile ? 'right' : undefined"
                temporary
            >
                <template v-slot:prepend>
                    <div class="text-right pa-1">
                        <v-icon @click.prevent="mobileFunctionCloser" class="mdi mdi-close cursor-pointer" size="24"></v-icon>
                    </div>
                </template>
                <v-list
                >
                    <v-list-item 
                        v-for="(menu, i) in items" :key="i"
                        :title="menu.title"
                        :to="{name: menu.value}"
                        @click="mobileFunctionCloser"
                    />
                </v-list>
            </v-navigation-drawer>
        <!-- </v-container> -->
    </header>
</template>

<script setup>
import { ref } from 'vue'
const drawer = ref(false)

const items = ref([
    {
        title: 'Home',
        value: 'home'
    },
    {
        title: 'Products',
        value: 'product'
    },
    {
        title: 'Features',
        value: 'feature'
    },
    {
        title: 'Pricing',
        value: 'pricing'
    },
    {
        title: 'Blog',
        value: 'blog'
    },
    {
        title: 'Sign Up',
        value: 'register'
    },
    {
        title: 'Sign In',
        value: 'login'
    },
])

const mobileFunctionInitiater = () => {
    drawer.value = true;
    document.body.classList.add('mobile-btn-initialize')
}

const mobileFunctionCloser = () => {
    drawer.value = false;
    document.body.classList.remove('mobile-btn-initialize')
}
</script>