<template>
    <div class="kiwi-bg-white">
        <v-sheet class="pt-3 px-4"  variant="flat">
                <v-tabs
                v-model="activeTab"
                hide-slider
            >
                <v-tab 
                    v-for="(menu, i) in tabMenus"
                    :key="i"
                    :value="menu.value" 
                    :class="activeTab === menu.value ? ' kiwi-bg-light-grey-7 kiwi-border-b-light-grey-7 kiwi-text-black' : ''" 
                    class="mr-2 kiwi-border-light-grey-4 text-none font-weight-semi-bold"
                    style="border: 1px solid transparent; border-radius: 8px 8px 0 0; color: var(--color-light-black-2);"
                    @click="$router.replace({name: 'mockTestEvaluation', query: {currentTab: menu.value}})"
                >{{ menu.label }}</v-tab>
            </v-tabs>
        </v-sheet>
        <v-divider style="border-color: var(--color-light-grey-4); opacity: 1; margin-top: -1px;" />
        <ScoreReport v-if="activeTab === 'score_report'" :result="result" />
        <AllAnswers v-else-if="activeTab === 'all_answers'" />
    </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue'
import { useMockTestEvaluationStore } from "@/stores/mocktest-evaluation-store";
import AllAnswers from '@/components/Evaluation/MockTests/AllAnswers.vue'
import {useRoute} from 'vue-router'
const mockTestEvaluation = useMockTestEvaluationStore();
const route = useRoute()

const tabMenus = ref([
    {
        label: 'Score Report',
        value: "score_report"
    },
    {
        label: 'All Answers',
        value: "all_answers"
    },
])
const activeTab = ref('score_report')

const topics = computed(() => {
    return mockTestEvaluation.topics
})
const result = computed(() => {
    return mockTestEvaluation.result
})

onMounted(() => {
    (route.query && route.query.currentTab) ?  activeTab.value = route.query.currentTab : ''
})
</script>

<style>

</style>