<template>
  <div v-if="activeStack">
    <template v-if="activeStack.name === 'Question'">
      <Question
        ref="currentStackRef"
        v-bind="activeStack.props"
        :key="currentStackIndex"
        :isLastQuestion="isLastQuestion"
        :freeze="promptUnique !== null"
        @handleSubmit="next"
      ></Question>
    </template>
    <template v-else-if="activeStack.name === 'ModuleIntro'">
      <ModuleIntro
        ref="currentStackRef"
        v-bind="activeStack.props"
        :key="currentStackIndex"
        @handleSubmit="next"
      ></ModuleIntro>
    </template>
    <template v-else-if="activeStack.name === 'InitialTest'">
      <InitialTest
        v-bind="activeStack.props"
        :key="currentStackIndex"
        @handleSubmit="next"
      />
    </template>

    <div class="position-fixed w-100" style="bottom: 0; left: 0">
      <v-divider></v-divider>
      <v-footer class="py-0">
        <v-container class="d-flex px-0 py-2" variant="flat">
          <v-btn
            v-if="activeStack.name !== 'InitialTest'"
            class="kiwi-bg-light-grey-6 kiwi-text-black kiwi-fs-16 text-none"
            size="large"
            variant="flat"
            @click="saveAndExit"
          >
            Save and Exit
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="text-none kiwi-bg-black-4 kiwi-text-white kiwi-radius-4 kiwi-fs-16"
            size="large"
            variant="flat"
            @click="handleNextAction"
          >
            {{ isLastQuestion ? "Submit" : "Next" }}
          </v-btn>
        </v-container>
      </v-footer>
    </div>
  </div>
  <PromptDialog v-bind="prompt" v-if="prompt !== null" />
  <PromptDialog v-bind="promptUnique" v-if="promptUnique !== null" />
  <PromptDialog
    v-bind="{ title: 'Submitting..', progress: true }"
    v-if="finalizing"
  />
</template>
<script setup>
import {
  ref,
  reactive,
  defineProps,
  computed,
  defineAsyncComponent,
  defineExpose,
  watch,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
} from "vue";
import Question from "@/components/Question/Question.vue";
import ModuleIntro from "@/components/Question/ModuleIntro.vue";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import { useExamTimerInstanceStore } from "@/stores/examTimerInstance-store";
import { storeToRefs } from "pinia";
import InitialTest from "../MockTest/InitialTest.vue";
import { useRouter } from "vue-router";
import { useQuestionAnswer } from "@/composables/useQuestionAnswer";
import { useAnswerInstanceStore } from "@/stores/answerInstance-store";
import { mockPromise } from "@/utils/utils";
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { useQuestionBasedTestStore } from "@/stores/questionbased-test-store";
import { reportLog } from "@/sentry";
const router = useRouter();
const timerStore = useExamTimerInstanceStore();
const { timeEndedForChapter,timeEndedForModule } = storeToRefs(timerStore);
const {
  updateModuleTimer,
  clearModuleTimer,
  updateChapterTimer,
  clearChapterTimer,
  clearTimer,
  setReduceTime
} = timerStore;
const { examInstance, examType } = defineProps({
  examInstance: {
    type: Object,
    required: true,
  },
  examType: {
    required: false,
    default: () => "mock",
  },
});
const { answerSubmitting, submitAnswer } = useQuestionAnswer();
const answerInstanceStore = useAnswerInstanceStore();
const examStore = useExamInstanceStore();
const questionBasedTestStore = useQuestionBasedTestStore();
const { answer } = storeToRefs(answerInstanceStore);
const emit = defineEmits(["timer:change"]);
const currentStackRef = ref(null);
const stackCompleted = ref(false);
const currentStackIndex = ref(0);
const prompt = ref(null);
const promptUnique = ref(null);
const currentAnswer = ref(null);
const finalizing = ref(false);
const userState = ref( examInstance.examDetail?.exam_attempt_data?.user_state ? {...examInstance.examDetail?.exam_attempt_data?.user_state} : null);
const stackData = reactive({
  list: [],
  stacked_module: [],
});
if (examType === "test" || examType === 'question') {
  examInstance.questions.forEach((q) => {
    const { topic, ...question } = q;
    stackData.list.push({
      name: "Question",
      type: "question",
      props: {
        moduleData: null,
        payload: topic,
        question: question,
        examId: examInstance.id,
        examType: examType,
      },
    });
  });
  stackCompleted.value = true;
} else {
  for (let i = 0; i < 6; i++) {
    stackData.list.push({
      name: "InitialTest",
      type: "initial",
      props: {
        contentView: i + 1,
        moduleData: {},
      },
    });
  }

  examInstance.questions.forEach((module) => {
    const { children, ...moduleData } = module;
    const isStackIntro =
      !stackData.stacked_module.includes(module.slug) &&
      module.slug in examInstance.introContent;
    if (isStackIntro) {
      stackData.list.push({
        name: "ModuleIntro",
        type: "intro",
        props: {
          moduleData: moduleData,
          content: examInstance.introContent[module.slug] || "",
        },
      });
    }
    children.forEach((c) => {
      const { questions, ...payload } = c;
      questions.forEach((q) => {
        stackData.list.push({
          name: "Question",
          type: "question",
          props: {
            moduleData: moduleData,
            payload: payload,
            question: q,
            examId: examInstance.id,
            examType: examType,
          },
        });
      });
    });
  });
  stackCompleted.value = true;
}
watch(
  stackCompleted,
  (newVal) => {
    if (stackCompleted.value) {
      if (examInstance.lastAttemptedQuestionId) {
        const attemptedIndex = stackData.list.findIndex(
          (list) =>
            list.type === "question" &&
            list.props?.question?.id === parseInt(examInstance.lastAttemptedQuestionId)
        );
        if (attemptedIndex >= 0) {
          currentStackIndex.value = attemptedIndex + 1;
          if (currentStackIndex.value >= stackData.list.length) {
            if (examInstance.examDetail.is_completed) {
            } else {
              finishExam();
            }
          }
        }
      }
    }
  },
  { immediate: true }
);

const activeStack = computed(() => {
  return stackData.list[currentStackIndex.value];
});
const currentModulePayload = computed(() => {
  if (!activeStack.value) {
    return null;
  }
  if (activeStack.value.type === "question") {
    return activeStack.value.props.payload;
  }
});
const currentModule = computed(() => {
  if (!activeStack.value) {
    return null;
  }
  if (activeStack.value.type === "question") {
    return activeStack.value.props.moduleData;
  }
});
const next = async () => {
  const hasAns = await captureCurrentAnswerPayload();
  if (!hasAns) {
    return false;
  }
  moveNext();
  executeAnswerSubmit();
};
const handleNextAction = async () => {
  if (activeStack.value.type === "initial" && currentStackIndex.value === 5) {
    moveNextForIntroduction();
    return;
  }
  if (activeStack.value.type !== "question") {
    moveNext();
    return;
  }
  const hasAns = await captureCurrentAnswerPayload();
  if (!hasAns) {
    return false;
  }
  prompt.value = {
    title: "Confirm?",
    content:
      isLastQuestion.value ? "By submitting, you confirm that you have completed this test." : "Are you sure you want to submit this answer and go to next question?",
    actions: [
      {
        text: "Cancel",
        onClick: () => (prompt.value = null),
      },
      {
        text: "Confirm",
        type: "fill",
        onClick: async () => {
          await captureCurrentAnswerPayload();
          const hasAns = await captureCurrentAnswerPayload();
          if (!hasAns) {
            return false;
          }
          if (!isLastQuestion.value) {
            moveNext();
          }
          executeAnswerSubmit();
        },
      },
    ],
  };
};
const saveCurrentState  = () => {
  if(examType !== "mock") {
    return false;
  }
  examStore.saveCurrentState({
      current_chapter_id: activeStack.value.props?.payload?.id,
      module_total_time: timerStore.moduleTimer,
      module_current_time: timerStore.timeProgress?.module || null,
      chapter_total_time: timerStore.chapaterTimer,
      chapter_current_time: timerStore.timeProgress?.chapter || null,
  })
}
const saveAndExit = async () => {
  prompt.value = {
    title: "Alert!",
    content:
      "Are you sure to save and exit the test?",
    actions: [
      {
        text: "No",
        onClick: () => (prompt.value = null),
      },
      {
        text: "Yes",
        type: "fill",
        onClick: () => {
          if(examType === "test") {
            router.push({
              name: 'question-based-tests',
              query: {
                topic: activeStack.value.props?.payload ? activeStack.value.props?.payload?.id :null,
                module: activeStack.value.props?.module ? activeStack.value.props?.module?.id :null,
              }
            });
          }else {
            saveCurrentState()
            router.push({
              name:  "mock-tests",
            });
          }
        },
      },
    ],
  };

};

const moveNext = () => {
  prompt.value = null;
  currentStackIndex.value += 1;
};
const moveNextForIntroduction = () => {
  prompt.value = {
    title: "Confirm?",
    content:
      "Are you sure you want to submit this answer and go to next question?",
    actions: [
      {
        text: "Cancel",
        onClick: () => (prompt.value = null),
      },
      {
        text: "Confirm",
        type: "fill",
        onClick: () => {
          clearChapterTimer();
          moveNext();
        },
      },
    ],
  };
};
const captureCurrentAnswerPayload = async () => {
  let validate = true;
  if (activeStack.value.type === "question") {
    await currentStackRef.value.wrapUpForSubmit();
    validate = currentStackRef.value.validate();
  }
  if (activeStack.value.type !== "question") {
    return true;
  }
  const payload = {
    ...answer.value,
    question: activeStack.value.props.question.id,
    answer_for: examType,
    answer_for_id: examInstance.id,
    isLastQuestion: isLastQuestion.value,
  };
  currentAnswer.value = payload;
  if (Object.keys(answer.value).length === 0 || validate !== true) {
    prompt.value = {
      title: "Cannot Skip",
      content:
        "You need to finish answering this question before going to the next.",
      actions: [
        {
          text: "Close",
          type: "fill",
          onClick: () => (prompt.value = null),
        },
      ],
    };
    return false;
  }
  return true;
};
watch(
  currentModulePayload,
  (newVal, oldVal) => {
    const toChapter = !oldVal && newVal ? true : false;
    const exitChapter = !newVal && oldVal ? true : false;
    const chapterSlugChanged =
      newVal && oldVal && newVal.slug !== oldVal.slug ? true : false;
    const isDirty = chapterSlugChanged || toChapter || exitChapter;
    if (toChapter || chapterSlugChanged) {
      if (oldVal && oldVal.slug === "sst") {
        updateModuleTimer(
          activeStack.value.props,
          oldVal.time_to_complete_the_answer
        );
      }
      updateChapterTimer(activeStack.value.props);
      if(userState.value && newVal.id === userState.value.current_chapter_id) {
          setReduceTime({
            chapter: (userState.value.chapter_total_time || 0) - (userState.value.chapter_current_time || 0),
            module: (userState.value.module_total_time || 0) - (userState.value.module_current_time || 0)
          });
          userState.value = null
      }
    } else {
      clearChapterTimer();
    }
  },
  { deep: true, immediate: true }
);
watch(
  currentModule,
  (newVal, oldVal) => {
    if (newVal) {
        updateModuleTimer(activeStack.value.props);
    } else {
      clearModuleTimer();
    }
  },
  { immediate: true }
);
watch(
  timeEndedForChapter,
  (newVal, oldVal) => {
    if (newVal === -1 && activeStack.value.type !== "initial") {
      promptUnique.value = prompt.value = {
        title: "Time's Up",
        content: "Please click <strong>“Next”</strong> to go to the next question",
        actions: [
          {
            text: "Next",
            type: "fill",
            onClick: goToNextPossibleChapter,
          },
        ],
      };
    } else if (newVal === -1 && activeStack.value.type === "initial") {
      prompt.value = prompt.value = {
        title: "Time's Up",
        content: "Please click <strong>“Next”</strong> to go to the next question",
        actions: [
          {
            text: "Next",
            type: "fill",
            onClick: moveNext,
          },
        ],
      };
    }
  },
  { immediate: true }
);
watch(
  timeEndedForModule,
  (newVal, oldVal) => {
    if (newVal === -1 && activeStack.value.type !== "initial") {
      promptUnique.value  = {
        title: "Time's Up",
        content: "Please click <strong>“Next”</strong>",
        actions: [
          {
            text: "Next",
            type: "fill",
            onClick: goToNextPossibleModule,
          },
        ],
      };
    }
  },
  { immediate: true }
);
const goToNextPossibleChapter = async () => {
  const currentChapter = activeStack.value.props.payload.slug;
  const nextChapterIndex = stackData.list.findIndex(
    (list, i) =>
      i > currentStackIndex.value &&
      list.props.payload &&
      list.props.payload.slug !== currentChapter
  );
  const hasAns = await captureCurrentAnswerPayload();
  promptUnique.value = null
  const res = await executeAnswerSubmit({
    silent: true,
    isLastChapter: nextChapterIndex < 0,
  });
  if (true || res) {
    if (nextChapterIndex >= 0) {
      const nextData = stackData.list[nextChapterIndex];
      const isNewModule =
        nextData.props.moduleData.slug !==
        activeStack.value.props.moduleData.slug;
      if (isNewModule) {
        const newModuleIntroIndex = stackData.list.findIndex(
          (list, i) =>
            i > currentStackIndex.value &&
            list.type === "intro" &&
            list.props.moduleData.slug === nextData.props.moduleData.slug
        );
        if (newModuleIntroIndex >= 0) {
          currentStackIndex.value = newModuleIntroIndex;
        } else {
          currentStackIndex.value = nextChapterIndex;
        }
      } else {
        currentStackIndex.value = nextChapterIndex;
      }
    }
  }
  promptUnique.value = null;
};
const goToNextPossibleModule = async () => {
  const currentModule = activeStack.value.props.moduleData.id;
  const nextModuleIndex = stackData.list.findIndex(
    (list, i) =>
      i > currentStackIndex.value &&
      list.props.moduleData &&
      list.props.moduleData.id !== currentModule
  );
  if (nextModuleIndex >= 0) {
    currentStackIndex.value = nextModuleIndex
    promptUnique.value = null
  }else {
    promptUnique.value = null;
    finalizing.value = true;
    await finishExam();
    finalizing.value = false;
    currentAnswer.value = null;
  }
  promptUnique.value = null;
};
const currentQuestionStatus = computed(() => {
  if (
    (!currentModule.value && examType === "mock") ||
    !currentModulePayload.value
  ) {
    return null;
  }
  const totalQuestion = stackData.list.filter(
    (item) =>
      item.type === "question" &&
      (examType === "test" ||
        item.props.moduleData.slug === currentModule.value.slug)
  );
  let currentQIndex = 0;
  if (activeStack.value && activeStack.value.type === "question") {
    currentQIndex = totalQuestion.findIndex(
      (q) => q.props.question.id === activeStack.value.props.question.id
    );
  }
  return {
    total: totalQuestion.length,
    current: currentQIndex < 0 ? currentQIndex : currentQIndex + 1,
  };
});
const isLastQuestion = computed(() => {
  const questions = stackData.list
    .filter((item) => item.type === "question")
    .map((q) => q.props.question.id);
  if (questions.length > 0) {
    const lastQId = questions[questions.length - 1];
    return lastQId === activeStack.value?.props?.question?.id;
  } else {
    return false;
  }
});
function goToEvaluation() {
  if (examType === "test") {
    return router.push({
      name: "QBTEvaluation",
      params: { test: examInstance.id },
    });
  } else {
    return router.push({
      name: "mockTestEvaluation",
      params: { exam: examInstance.id },
    });
  }
}
async function executeAnswerSubmit(options = {}) {
  prompt.value = null;
  const finalize = currentAnswer.value.isLastQuestion || options.isLastChapter || options.isLastModule;
  if (finalize) {
    finalizing.value = true;
  }
  if(currentAnswer.value.answer_audio_text && !currentAnswer.value.answer_audio_fluency) {
      reportLog("Audio fluency is empty", currentAnswer.value, 'error')
  }
  try {
    const res = await submitAnswer(currentAnswer.value);
    if (finalize) {
      await finishExam();
      finalizing.value = false;
    }
    currentAnswer.value = null;
    return res;
  } catch (err) {
    finalizing.value = false;
    // prompt.value = {
    //   title: "Oops Error",
    //   content: `Failed to submit answer silent ${JSON.stringify(
    //     err.response.data
    //   )}`,
    //   actions: [
    //     {
    //       text: "Close",
    //       type: "fill",
    //       onClick: () => (prompt.value = null),
    //     },
    //   ],
    // };
  }
  return null;
}
async function finishExam() {
  finalizing.value = true;
  const finishRes = await (examType !== "test"
    ? examStore.finish()
    : questionBasedTestStore.finish());
  if (finishRes) {
    finalizing.value = false;
    prompt.value = {
        title: "Submission Successful!",
        actions: [
          {
            text: "Check Results",
            type: "fill",
            onClick: goToEvaluation
          },
        ],
      };
  }
}
defineExpose({
  next,
  saveAndExit,
  currentQuestionStatus,
});
const handleBeforeUnload = (event) => {
  // Custom logic before the page unloads
  saveCurrentState()
  event.preventDefault();
  event.returnValue = ''; // Required for Chrome
};

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>
