<template>
    <v-sheet
            class="pa-4 kiwi-bg-transparent kiwi-text-black-3"
            variant="flat"
        >
            <!-- Faq Section start -->
            <v-expansion-panels multiple elevation="0" class="kiwi-faq font-weight-medium">
                <v-expansion-panel
                    v-for="(accordion, i) in faqContent"
                    :key="i"
                    :title="accordion.question"
                >
                <v-expansion-panel-text>
                    <div v-html="accordion.answer"></div>
                </v-expansion-panel-text>
            </v-expansion-panel>
            </v-expansion-panels>
            <!-- Faq Section end -->

            <GetInTouch v-if="institute.$hasFeature('contact-us-tab')"></GetInTouch>
    </v-sheet>
</template>

<script setup>
import {onMounted} from 'vue'
import {useConfigStore} from '@/stores/configStore'
import { storeToRefs } from 'pinia';
import GetInTouch from '@/components/Contact/GetInTouch';
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()
const config = useConfigStore()
const { getFaqContent } =config

const { faqContent } = storeToRefs(config)

onMounted(async () => {
    if(Object.keys(faqContent.value).length === 0){
        await getFaqContent()
    }
})

</script>

<style>

</style>