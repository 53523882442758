<template>
  <Wrapper>
    <template v-slot:headerAction>
      <v-chip
        label
        class="kiwi-radius-4 kiwi-bg-white border kiwi-border-light-grey-3 kiwi-text-black font-weight-bold my-2 mr-2"
        variant="flat"
        v-if="questionStack && questionStack.currentQuestionStatus"
      >
        <img
          src="/assets/Question Based Tests/Reading/layers-01.svg"
          class="mr-2"
          alt=""
        />
        <span
          >{{ questionStack.currentQuestionStatus.current }} of
          {{ questionStack.currentQuestionStatus.total }}</span
        >
      </v-chip>
      <Timer />
    </template>
    <v-sheet
      class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent"
      variant="flat"
    >
      <div v-if="fetching">
        <PromptDialog
          v-bind="{ content: 'Please Wait..', progress: true, width: 300 }"
        />
      </div>
      <div v-else-if="needUpgrade">
        <UpgradePrompt />
      </div>
      <div v-else-if="startExam">
        <QuestionStack ref="questionStack" :examInstance="instanceData" />
      </div>
    </v-sheet>
  </Wrapper>
  <PromptDialog v-bind="micPrompt" v-if="micPrompt" />
  <PromptDialog v-bind="prompt" v-if="prompt" />
</template>

<script setup>
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Wrapper from "../ExamWrapper.vue";
import QuestionStack from "@/components/Question/QuestionStack.vue";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import { onBeforeUnmount } from "vue";
import UpgradePrompt from "@/components/Prompt/UpgradePrompt.vue";
import { useMicrophone } from "@/composables/useMicrophone";

const { microphoneAccess, requestMicrophonePermission } = useMicrophone();
const questionStack = ref("");
const prompt = ref(null);
const micPrompt = ref(null);
const needUpgrade = ref(false);
const startExam = ref(false);
const route = useRoute();
const router = useRouter();
const examId = parseInt(route.params.id);
const examStore = useExamInstanceStore();
examStore.reset();
examStore.getExamInstance(examId);
const instanceData = computed(() => {
  return {
    questions: examStore.questions,
    id: examStore.examId,
    introContent: examStore.introContent,
    lastAttemptedQuestionId: route.query.start
      ? null
      : examStore.examDetail?.last_attempted_question_id,
    examDetail: examStore.examDetail,
  };
});
const fetching = computed(() => {
  return examStore.fetching;
});
const examDetail = computed(() => {
  return examStore.examDetail;
});
watch(
  examDetail,
  (newVal, oldVal) => {
    if (newVal && newVal.id) {
      if (startExam.value) {
        return false;
      }
      if (!examDetail.value.is_accessible) {
        needUpgrade.value = true;
      }
      if (examDetail.value.is_completed) {
        prompt.value = {
          title: "You have already completed the test!",
          actions: [
            {
              text: "View Results",
              type: "fill",
              onClick: goToEvaluation,
            },
          ],
        };
      } else if (
        examDetail.value.is_attempted &&
        !examDetail.value.is_completed
      ) {
        prompt.value = {
          title: "Test Resumed",
          content: "Click <strong>“Continue”</strong> to start the test",
          actions: [
            {
              text: "Continue",
              type: "fill",
              onClick: () => {
                prompt.value = null;
                startExam.value = true;
              },
            },
          ],
        };
      } else {
        startExam.value = true;
      }
    }
  },
  { immediate: true, deep: true }
);
onBeforeUnmount(() => {
  examStore.reset();
});

watch(
  microphoneAccess,
  (newVal, oldVal) => {
    if(newVal && newVal === "prompt"){
      requestMicrophonePermission();
    }
    if (newVal && newVal === "denied" ) {
      startExam.value = false;
      micPrompt.value = {
        title: "Opps!",
        content:
          "Looks like we can't detect a microphone. Please check your settings and ensure your microphone is connected and allowed!",
        actions: [
          {
            text: "Ok",
            type: "fill",
            onClick: () => {
              startExam.value = true;
              micPrompt.value = null;
            },
          },
        ],
      };
    }
  },
  { immediate: true, deep: true }
);
function goToEvaluation() {
  return router.push({
    name: "mockTestEvaluation",
    params: { exam: instanceData.value.id },
  });
}
</script>

<style></style>
