<template>
    <WebLayout page="pricing" :isGradientBgTop="true">
        <Banner mainClass="kiwi-pricing-inner-banner py-12" :content="bannerContent" />
        <section class="">
            <v-container class="py-8 py-md-0 pricing-nav-container">
                <div class="bg-white rounded-lg px-2 py-3">
                    <div
                        bg-color="white"
                        rounded="lg"
                        class="pricing-tabs h-100 position-relative d-flex"
                        stacked
                    >
                        <template v-for="(strm, index) in streams" :key="index">
                        <v-tab @click="tab = strm.id" class="pricing-single-tab ms-2 px-1 px-lg-16 py-1 py-md-4" :class="tab === strm.id ? 'v-tab--selected' : ''">
                            <!-- {{strm.name}} -->
                            <div class="d-flex align-center logo position-relative z-3"  v-if="strm.id == 1">
                                <img src="/assets/PTE.png" class="mr-2" alt="PTE Logo" style="height: 44px; max-width: 32px;"> <span class="kiwi-fs-20 kiwi-text-black-3">PTE</span>
                            </div>
                            <div class="d-flex align-center logo position-relative z-3" v-else>
                                <img src="/assets/SAT.png" class="" alt="SAT Logo" style="height: 32px; max-width: 64px;">
                            </div>
                        </v-tab>
                        </template>
                        <div class="position-relative is-disabled">
                            <div class="coming-soon position-absolute border-2 border-md pa-2">
                                <span>Coming Soon</span>
                            </div>
                            <v-tab class="pricing-single-tab ms-2 px-1 px-lg-16 py-1 py-md-4 h-100" 
                                disabled="true">
                                <div class="d-flex align-center logo">
                                    <img src="/assets/PTE_disabled.png" class="mr-2" alt="PTE Logo" style="height: 44px; width: 32px;"> <span class="kiwi-fs-20 kiwi-text-black-3">PTE | Core</span>
                                </div>
                            </v-tab>
                        </div>
                        <div class="position-relative is-disabled">
                            <div class="coming-soon position-absolute border-2 border-md pa-2">
                                <span>Coming Soon</span>
                            </div>
                            <v-tab class="pricing-single-tab ms-2 px-1 px-lg-16 py-1 py-md-4 h-100" 
                                disabled="true">
                                <div class="d-flex align-center logo" >
                                    <img src="/assets/IELTS-disabled.png" class="" alt="IELTS Logo" style="height: 32px; max-width: 64px;">
                                </div>
                            </v-tab>
                        </div>
                        <div class="position-relative is-disabled">
                            <div class="coming-soon position-absolute border-2 border-md pa-2">
                                <span>Coming Soon</span>
                            </div>
                            <v-tab class="pricing-single-tab ms-2 px-1 px-lg-2 py-1 py-md-4 duolingo-btn h-100" disabled="true" style="max-width: 200px;">
                                <div class="d-flex align-center logo">
                                    <img src="/assets/Duolingo-disabled.png" class="" alt="Duolingo Logo" style="height: 64px;">
                                </div>
                            </v-tab>
                        </div>
                    </div>
                </div>
            </v-container>
        </section>
        <section class="kiwi-section kiwi-pricing-list py-12 pt-md-0 pb-md-16 pt-lg-0 pb-lg-20">
            <v-container>
                <v-row>
                    <template  v-for="(content, i) in packages" :key="i">
                        <v-col cols="12" md="4" v-if="content.streams[0].id === tab">
                            <PricingCard :content="content" />
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </section>
        
        <TwoColSectionArticle 
            mainClass="kiwi-ielts-preparation" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-0 py-lg-0"
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="subscribeContent"
        />
    </WebLayout>
</template>

<script setup>
import WebLayout from '../WebLayout'
import Banner from '../Addon/InnerBanner.vue'
import TwoColSectionArticle from '../Addon/TwoColSectionArticle.vue'
import PricingCard from '../Addon/PricingCard.vue'
import {onMounted, ref, computed} from 'vue'
import { usePackageStore } from "@/stores/package-store";
import { storeToRefs } from 'pinia'

const packageList = usePackageStore()
const { packages } = storeToRefs(packageList)
const { getPackageList } = packageList

const bannerContent = ref({})
const tab = ref(2)
bannerContent.value = {
    title: 'Pricing Plans',
    text: 'Our tiered pricing plans provide the perfect balance of features and affordability.'
}

const streams = computed(() => {
    let streamList = []
    packages.value.forEach(pkg => {
        pkg.streams.forEach(s => {
            if(!streamList.find(item => item.id === s.id)) {
                streamList.push({id: s.id, name: s.name})
            }
        })
    })
    return streamList
})

// const pricingLists = ref([])
// pricingLists.value = [
//     {
//         id: 1,
//         active: false,
//         bgColor: 'kiwi-bg-white',
//         activeColor: 'kiwi-bg-primary',
//         plan: {
//             transform: 'capitalize',
//             fontSize: '16',
//             label: 'free plan'
//         },
//         price: {
//             fontSize: '64',
//             weight: 'semi-bold',
//             price: '$9.99',
//             subSize: '20',
//             sublabel: 'mo'
//         },
//         featureList: [
//             {
//                 title: 'Unlimited applicants',
//                 value: 1,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: '500 MB CMST storage',
//                 value: 2,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: 'Unlimited lead generation',
//                 value: 3,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//         ],
//         action: {
//             label: 'Get Started',
//             rounded: 'xl',
//             height: '44px',
//             width: '310px',
//         }
//     },
//     {
//         id: 2,
//         active: true,
//         bgColor: 'kiwi-bg-white',
//         activeColor: 'kiwi-bg-primary',
//         plan: {
//             transform: 'capitalize',
//             fontSize: '16',
//             label: 'pro plan'
//         },
//         price: {
//             fontSize: '64',
//             weight: 'semi-bold',
//             price: '$9.99',
//             subSize: '20',
//             sublabel: 'mo'
//         },
//         featureList: [
//             {
//                 title: 'Unlimited applicants',
//                 value: 1,
//                 props: {
//                     prependIcon: 'mdi-check',
//                 }
//             },
//             {
//                 title: '500 MB CMST storage',
//                 value: 2,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: 'Unlimited lead generation',
//                 value: 3,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: 'Unlimited lead generation',
//                 value: 4,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//         ],
//         action: {
//             label: 'Get Started',
//             rounded: 'xl',
//             height: '44px',
//             width: '310px',
//         }
//     },
//     {
//         id: 3,
//         active: false,
//         bgColor: 'kiwi-bg-white',
//         activeColor: 'kiwi-bg-primary',
//         plan: {
//             transform: 'capitalize',
//             fontSize: '16',
//             label: 'premium plan'
//         },
//         price: {
//             fontSize: '64',
//             weight: 'semi-bold',
//             price: '$9.99',
//             subSize: '20',
//             sublabel: 'mo'
//         },
//         featureList: [
//             {
//                 title: 'Unlimited applicants',
//                 value: 1,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: '500 MB CMST storage',
//                 value: 2,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//             {
//                 title: 'Unlimited lead generation',
//                 value: 3,
//                 props: {
//                     prependIcon: 'mdi-check'
//                 }
//             },
//         ],
//         action: {
//             label: 'Get Started',
//             rounded: 'xl',
//             height: '44px',
//             width: '310px',
//         }
//     },
// ]

const subscribeContent = ref({})
subscribeContent.value = {
    imgSrc: '/assets/subscribe-img.png',
    setMediaCol: 6,
    setArticleCol: 4,
    category: null,
    title: '<span class="kiwi-text-primary">Sign up</span> today and take control of your SAT & PTE journey!',
    text: `With access to our extensive resources and AI in-built study tools, you'll be empowered  achieve your desired score.`,
    hasAction: true,
    showMask: true,
    action: {
        label: 'Start Free Trial',
        color: 'kiwi-bg-primary',
        to: {name: 'register'}
    }
}

onMounted(() => {
    let isPublic = true;
    getPackageList(isPublic)
})
</script>

<style>
.v-tab--selected .v-tab__slider {
  opacity: 0;
}
.pricing-tabs .pricing-single-tab{
    border: 1px solid #E2E6E4;
    border-radius: 6px !important;
    background: linear-gradient(180deg, rgba(221, 247, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
    min-width: 150px !important;
}
.pricing-tabs .position-relative.is-disabled {
    border: 1px solid #E2E6E4;
    margin-left: 10px;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(238, 248, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #FFFFFF;
}
.pricing-tabs .position-relative.is-disabled button.v-btn {
    opacity: 0.7 !important;
    border: none !important;
}
@media screen and (max-width: 991px) {
    .pricing-nav-container {
        max-width: 100% !important;
        overflow-x: auto !important;
        padding-top: 20px !important;
        padding-bottom: 0 !important;
    }
}
@media screen and (min-width: 1280px) {
    .duolingo-btn {
        width: 200px !important;
    }
    .pricing-tabs .pricing-single-tab {
        padding-left: 20px !important;
        padding-right: 20px !important;
        min-width: 200px !important;
        flex: 1 !important;
    }
    .pricing-tabs .position-relative.is-disabled {
        flex: 1 !important;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}
.pricing-tabs .pricing-single-tab.v-tab--selected{
    border: 2px solid transparent;
    box-shadow: 0px 8px 30px 0px #1249260F;
    background: linear-gradient(180deg, #E8FFEC 0%, #F0FFED 100%) padding-box, linear-gradient(348.23deg, rgba(35, 147, 35, 0.3) 21.04%, #1CA972 76.52%) border-box;
}
.coming-soon{
    top: -30px;
    left: 50%;
    text-wrap: nowrap;
    transform: translateX(-50%);
    border: 1.5px solid transparent;
    border-radius: 6px;
    background: linear-gradient(87.7deg, #ffffff -19.21%, #ffffff 101.48%) padding-box, linear-gradient(87.7deg, #F4BF2D -19.21%, #F3637B 101.48%) border-box;
    text-align: center;
}
.coming-soon::after{
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    bottom: -6px;
    left: 30%;
    transform: translateX(-50%) rotateZ(45deg); /* Adjust centering and rotation */
    border: 2px solid transparent;
    background: linear-gradient(87.7deg, #ffffff -19.21%, #ffffff 101.48%) padding-box, 
                linear-gradient(87.7deg, #F4BF2D -19.21%, #F3637B 101.48%) border-box;
    clip-path: polygon(100% -1%, -1% 100%, 100% 100%);
}
.coming-soon span{
    background: linear-gradient(87.7deg, #F4BF2D -19.21%, #F3637B 101.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
</style>