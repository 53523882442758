import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref, reactive } from "vue";

export const useQuestionBasedEvaluation = defineStore("questionBasedEvaluation", () => {
  const fetching = ref({
    answers: false
  })
  const answers = ref([])
  const testId = ref(null)

  async function getAnswers(topic = null) {
    fetching.value.answers = true
    let url = `exam/scheduled-test/${testId.value}/get-answers`;
    answers.value = []
    const res = await api.get(url, {params: {topic: topic}}).then(res=>{
        answers.value = res.data
        fetching.value.answers = false
    }).catch(error =>{
        fetching.value.answers = false
        throw error
    })
    return res;
  }
  function getEvaluation (id)
  {
    testId.value = id 
    getAnswers()
  }
  return {getEvaluation, fetching, answers, getAnswers}
});
