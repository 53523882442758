<template>
    <WebLayout page="feature" :isGradientBgTop="true">
        <Banner mainClass="kiwi-pricing-inner-banner py-12" :content="bannerContent" />

        <section class="kiwi-section kiwi-feature-list py-12 pt-md-0 pb-md-16 pt-lg-0 pb-lg-20">
            <img src="/assets/Star.svg" alt="" class="img-bg">
            <v-container>
                <v-row class="align-center justify-center">
                    <v-col cols="12" sm="6" v-for="(feature, i) in featureLists" :key="i">
                        <v-sheet variant="flat" color="transparent" class="feature-card d-flex flex-row align-center justify-center" :class="[feature.location === 'top' || feature.location === 'bottom' ? 'flex-md-column' : 'flex-md-row', feature.location]">
                            <img 
                                :src="feature.mediaSrc" 
                                :alt="feature.title" 
                                :class="[(feature.imgLocation === 'bottom' || feature.imgLocation === 'right') ? 'order-md-last' : (feature.imgLocation === 'top' || feature.imgLocation === 'left') ? 'order-md-first' : '']"
                                style="width: 72px; height: 72px; object-fit: contain;"
                            >
                            <v-card 
                                class="feature-card-article text-center kiwi-text-balck-3" 
                                variant="flat" 
                                color="transparent" 
                                :title="feature.title" 
                                :text="feature.text" 
                                :class="[(feature.imgLocation === 'bottom' || feature.imgLocation === 'right') ? 'order-md-first' : (feature.imgLocation === 'top' || feature.imgLocation === 'left') ? 'order-md-last' : '']"
                                max-width="310px"
                            />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        
        <TwoColSectionArticle 
            mainClass="kiwi-ielts-preparation" 
            bgColor="kiwi-bg-light-green" 
            class="py-12 py-md-0 py-lg-0"
            :orderControl="{ media: 'last', article: 'first' }" 
            :content="subscribeContent"
        />
    </WebLayout>
</template>

<script setup>
import WebLayout from '../WebLayout'
import Banner from '../Addon/InnerBanner.vue'
import TwoColSectionArticle from '../Addon/TwoColSectionArticle.vue'
import PricingCard from '../Addon/PricingCard.vue'
import {ref} from 'vue'

const bannerContent = ref({})
bannerContent.value = {
    title: '15+ Features On The Go!',
    text: `Kiwiread.com offers a variety of AI-powered features to help SAT & PTE aspirants prepare, practice, and achieve their desired scores.`
}

const featureLists = ref([])
featureLists.value = [
    {
        id: 1,
        location: 'top',
        col: '12',
        title: 'Best AI Technology',
        text: 'Our advance AI  provides accurate and instant scores for your SAT & PTE practice, similar to the actual SAT & PTE algorithms',
        mediaSrc: '/assets/bookmark-svgrepo-com 2.svg',
        imgLocation: 'bottom'
    },
    {
        id: 2,
        col: '6',
        location: 'top-right',
        title: 'Detailed Score Analysis:',
        text: `The portal offers an accurate evaluation of a student's performance`,
        mediaSrc: '/assets/bookmark-svgrepo-com 5.svg',
        imgLocation: 'left'
    },
    {
        id: 3,
        col: '6',
        location: 'bottom-right',
        title: 'Realistic Exam Experience:',
        text: `Engage in practice sessions within an environment that closely replicates real SAT & PTE exam conditions.`,
        mediaSrc: '/assets/bookmark-svgrepo-com 2 (1).svg',
        imgLocation: 'left'
    },
    {
        id: 4,
        col: '12',
        location: 'bottom',
        title: 'Prediction',
        text: `Practice with the most frequently occurring prediction questions powered by AI`,
        mediaSrc: '/assets/bookmark-svgrepo-com 3.svg',
        imgLocation: 'top'
    },
    {
        id: 5,
        col: '6',
        location: 'bottom-left',
        title: 'Extensive Question Bank',
        text: `Gain access to a comprehensive question bank featuring thousands of practice questions across all sections of the SAT & PTE exam.`,
        mediaSrc: '/assets/bookmark-svgrepo-com 1.svg',
        imgLocation: 'right'
    },
    {
        id: 6,
        col: '6',
        location: 'top-left',
        title: 'Instant payment',
        text: `Kiwi Read now offers a seamless payment experience through an online platform. With Instant Payment, users can swiftly and securely complete transactions in real-time, eliminating the delays associated with traditional payment processing. `,
        mediaSrc: '/assets/bookmark-svgrepo-com 3 (1).svg',
        imgLocation: 'right'
    },
]

const subscribeContent = ref({})
subscribeContent.value = {
    imgSrc: '/assets/subscribe-img.png',
    setMediaCol: 6,
    setArticleCol: 4,
    category: null,
    title: '<span class="kiwi-text-primary">Sign up</span> today and take control of your SAT & PTE journey!',
    text: `With access to our extensive resources and AI in-built study tools, you'll be empowered  achieve your desired score.`,
    hasAction: true,
    showMask: true,
    action: {
        label: 'Start Free Trial',
        color: 'kiwi-bg-primary',
        to: {name: 'register'}
    }
}
</script>

<style>

</style>