<template>
  <v-dialog :model-value="modelValue" persistent :max-width="maxWidth" style="overflow: visible">
    <v-card :style="{ minWidth: minWidth, borderRadius: '8px' }">
      <div class="px-4 pt2">
        <slot name="title" />
      </div>

      <v-card-text>
        <slot />
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-4">
        <v-row>
          <v-col>
            <v-btn
              @click="closeDialog"
              variant="flat"
              block
              class="kiwi-radius-4 flex-grow-1 border kiwi-border-light-grey-3 kiwi-text-black-3 text-none kiwi-bg-white font-weight-semi-bold kiwi-fs-16"
              style="font-size: 16px;"
              height="44"
              >Cancel</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              @click="confirm"
              variant="flat"
              block
              class="kiwi-radius-4 flex-grow-1 kiwi-text-white text-none kiwi-bg-primary font-weight-semi-bold kiwi-fs-16"
              style="font-size: 16px;"
              height="44"
              >Ok</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: true,
  },
  minWidth: {
    type: String,
    default: "400px", 
  },
  maxWidth: {
    type: String,
    default: "400px",
  },
});

const emits = defineEmits(["update:dialog", "confirm"]);

const closeDialog = () => {
  emits("update:dialog", false);
};

const confirm = () => {
  emits("confirm");
  closeDialog();
};
</script>

<style scoped>
.v-card {
  border-radius: 8px;
}
</style>
