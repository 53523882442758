import {defineStore} from "pinia";
import { ref } from "vue";
import { api } from "@/plugins/axios";


export const useNotesStore = defineStore("notesStore", () => {
  const wordsArray  = ref([]);

  async function saveHighLightedWords (examInstanceId , payload)
  {
    const url = `exam/sat/exam-instance/${examInstanceId}/highlight-questions-word/`;
    await api.post(url , payload ).then(res=>{
      return res;
    }).catch(error =>{})
  }

  function getHighlightedWords (examInstanceId , questionId){
    const url = `exam/sat/exam-instance/${examInstanceId}/highlight-questions-word/?question_id=${questionId}`;
    wordsArray.value = []
    api.get(url).then(res=>{
      // wordsArray.value =  [...wordsArray.value, ...res.data.highlight_words];
      wordsArray.value = res.data.highlight_words;
    })
  }

  async function  deleteHighlightedWord (examInstanceId , payload){
    const url = `exam/sat/exam-instance/${examInstanceId}/remove-highlighted-word/`;
    await api.post(url, payload ).then(res=>{
      getHighlightedWords(examInstanceId , payload.question_id);
      return res;
    })
  }
  function resetWords ()
  {
    wordsArray.value = []
  }
  return {wordsArray , saveHighLightedWords , getHighlightedWords , deleteHighlightedWord, resetWords}
});
