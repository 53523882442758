<template>
  <div class="fill-height w-100 position-relative sat-question-wrapper-content">
    <QuestionRender ref="qr" v-bind="props" @mark-for-review="markForReview" @set-current-answer="setCurrentAnswer" :index="index"/>
    <PromptDialog v-bind="prompt" v-if="prompt !== null" />
  </div>
</template>
<script setup>
import { ref, defineProps, computed } from "vue";
import { storeToRefs } from "pinia";
import {useRoute, useRouter} from 'vue-router'
import QuestionRender from "@/components/SAT/Question/QuestionRender.vue";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import { useAnswerInstanceStore } from "@/stores/answerInstance-store";

const route = useRoute();
const router = useRouter();
const mode = (route.query.mode);
const props = defineProps({
  payload: {
    type: Object,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  examId: {
    required: true,
  },
  examType: {
    required: true,
    default: () => 'mock'
  },
  isLastQuestion: {
    required: true,
    default: () => false
  },
  freeze: {
    required: true,
    default: () => false
  },
  index:{
    required: true,
  }
});

const emit = defineEmits(["handleSubmit" , "markForReview" , "setCurrentAnswer"]);
const prompt = ref(null);
const qr = ref("")

const answerInstanceStore = useAnswerInstanceStore();
const { setQuestionId, resetAnswer } = answerInstanceStore;
const { answer } = storeToRefs(answerInstanceStore);
const slug = computed(() => {
  return props.question.topic_slug;
});
resetAnswer();
setQuestionId(props.question.id);

const markForReview = () =>{
  emit("markForReview");
}
const setCurrentAnswer = () =>{
  emit("setCurrentAnswer");
}
</script>
<style lang="scss">
  .sat-question-wrapper-content {
    // @media screen and (min-width: 1400px) {
    //    top -58px;
    // }
  }
</style>
