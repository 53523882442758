<template>
    <div>
        <v-sheet
            variant="flat"
            class="kiwi-bg-light-grey-7 pa-4 d-flex flex-wrap"
            style="grid-gap: 10px;"
        >
            <v-btn
                v-for="(tab, i) in tabs"
                :key="i"
                variant="outlined"
                size="large"
                rounded="xl"
                class="kiwi-bg-white kiwi-text-black kiwi-border-light-grey-3 text-none font-weight-medium"
                :class="activeModule === tab.id ? 'kiwi-bg-primary-overwrite kiwi-border-primary kiwi-text-white' : ''"
                @click.prevent="setActiveModule(tab.id, i)"
            >
                <img :src="activeModule === tab.id ? tab.icon_w : tab.icon" class="mr-1" alt="icon" /> {{ tab.label }}
            </v-btn>
        </v-sheet>
        <v-divider />
        <v-sheet
            variant="flat"
            class="kiwi-bg-white pa-4"
        >
            <v-row>
                <v-col cols="12" md="4" lg="3" order-md="2">
                    <aside class="pa-4 kiwi-bg-light-grey-7 rounded-lg">
                        <v-btn
                            v-for="(menu, index) in moduleItems"
                            :key="index"
                            block
                            variant="outlined"
                            class="kiwi-border-light-grey-3 text-none justify-start my-2 px-3 kiwi-button rounded-lg"
                            :class="activeFilterMenu === menu.id ? 'kiwi-text-primary kiwi-border-shadow kiwi-bg-white' : ''"
                            size="large"
                            style="word-break: break-word; font-size: 14px;"
                            @click.prevent="setActiveFilter(menu.id)"
                        > <span class="mr-1" style="min-width: 15px;">{{index + 1}}.</span> <span class="flex-grow-1 text-left">{{ menu.title }}</span></v-btn>
                    </aside>
                </v-col>
                <v-col cols="12" md="8" lg="9" order-md="1">
                    <div class="text-center pb-4 pt-10" v-if="isLoading">
                        <Loading  />
                    </div>
                    <div class="d-flex flex-column flex-wrap" style="grid-gap: 20px;">
                        <div v-for="(answer, a) in activeAnswers" :key="answer.id">
                            <Answer :answer="answer" :index="a + 1" :n="((perPage*page) + a + 1) - perPage" />
                        </div>
                        <template v-if="!isLoading">
                            <v-divider />
                            <div class="pa-4 kiwi-bg-light-grey-7 rounded-lg kiwi-text-black-3 d-flex align-center justify-center">
                                <v-pagination
                                    v-model="page"
                                    :length="Math.ceil(pageCount / perPage)"
                                    :total-visible="perPage"
                                    variant="text"
                                    density="compact"
                                ></v-pagination>
                            </div>
                        </template>
                    </div>

                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script setup>
import {ref, computed, watch, onMounted} from 'vue'
import Answer from "@/components/Evaluation/MockTests/Answers/Answer.vue";
import { useMockTestEvaluationStore } from "@/stores/mocktest-evaluation-store";
import {useRouter, useRoute} from 'vue-router';
const router = useRouter()
const route = useRoute()

const mockTestEvaluation = useMockTestEvaluationStore();
const activeModule = ref(null)
const activeFilterMenu = ref(null)
const page = ref(1)
const perPage = ref(5)
const answers = computed(() => {
    return mockTestEvaluation.answers
})
const iconMap = {
    speaking: {
        icon: '/assets/voice_black.svg',
        icon_w: '/assets/voice_white.svg',
    },
    writing: {
        icon: '/assets/pencil-edit-01_black.svg',
        icon_w: '/assets/pencil-edit-01_white.svg',
    },
    reading: {
        icon: '/assets/book-open-black.svg',
        icon_w: '/assets/book-open-white.svg',
    },
    listening: {
        icon: '/assets/headphones-black.svg',
        icon_w: '/assets/headphones-white.svg',
    },
}
const topics = computed(() => {
    return mockTestEvaluation.topics
})
const isLoading = computed(() => {
    return mockTestEvaluation.fetching.answers && !answers.value.length
})
const tabs = computed(() => {
    return topics.value.map(topic => {
        return {
            ...(iconMap[topic.slug] || {}),
            label: topic.title,
            value: topic.slug,
            id: topic.id,
        }
    })
}) 
const activeAnswers = computed(() => {
    const start = (page.value - 1) * perPage.value;
    const end = start + perPage.value;
    return answers.value.slice(start, end);
}) 
const moduleItems = computed(() => {
    const topic =  topics.value.find(t => t.id === activeModule.value)
    if(!topic) {
        return [];
    }
    return topic.children
})
watch(tabs.value, (newVal, oldVal) => {
    if(!activeModule.value && newVal.length) {
        activeModule.value = tabs.value[0].id
    }
}, {immediate: true, deep: true})
watch(activeModule, (newVal, oldVal) => {
    if(activeModule.value && !activeFilterMenu.value && moduleItems.value.length) {
        activeFilterMenu.value = moduleItems.value[0].id
    }
}, {immediate: true, deep: true})
watch(activeFilterMenu, (newVal, oldVal) => {
    if(activeFilterMenu.value) {
        page.value = 1
        mockTestEvaluation.getAnswers(activeFilterMenu.value)
    }
}, {immediate: true})


const pageCount = computed(() => {
    return answers.value.length
})

const setActiveModule = (module, index) => {
    activeModule.value = module
    activeFilterMenu.value = null
    router.replace({name: 'mockTestEvaluation', query: {currentTab: route.query.currentTab, currentModule: module, currentFilter: mockTestEvaluation.topics[index]?.children[0]?.id}})
}

const setActiveFilter = (filter) => {
    activeFilterMenu.value = filter
    router.replace({name: 'mockTestEvaluation', query: {...route.query, currentFilter: filter}})
}
onMounted(() => {
    if(route.query) {
        if(route.query.currentModule && route.query.currentFilter) {
            console.log('all condition met')
            activeModule.value = parseInt(route.query.currentModule)
            activeFilterMenu.value = parseInt(route.query.currentFilter)
        } else if(route.query.currentTab && !route.query.currentModule && !route.query.currentFilter) {
            console.log('only current tab available')
            setTimeout(() => {
                activeModule.value = mockTestEvaluation.topics[0]?.id;
                activeFilterMenu.value = mockTestEvaluation.topics[0]?.children[0]?.id;
            }, 1000)
            
        }
        
    }
})

</script>

<style>

</style>