<template>
    <article class="kiwi-page-article" :class="pageClass">
        <div class="text-center">
            <span 
                v-if="content && content.category" 
                :class="[category.color, category.transform ? `text-${category.transform}` : 'text-uppercase']" 
                class="kiwi-card-category text-body-1 font-weight-semi-bold" 
                v-html="category.name" 
            />
            <template v-if="content.tags && content.tags.length">
                <v-chip-group class="mt-2 kiwi-overwrite-justify-center-item" column variant="flat">
                    <v-chip v-for="(tag, i) in content.tags" label>{{tag.title}}</v-chip>
                </v-chip-group>
            </template>
            <h1 class="kiwi-fs-40 font-weight-bold" v-html="content.title" />
            <!-- <p class="kiwi-text-body-1 mt-2" v-html="content.subtitle" /> -->
            <div v-if="content && (content.author_name || content.created_date)" class="author-wrapper d-flex flex-wrap justify-center has-dot-seprator mt-4">
                <span v-if="content.author_name" v-html="content.author_name" />
                <span v-if="!content.author_name" v-html="'N/A'" />
                <v-icon class="mdi mdi-circle-small px-4"></v-icon>
                <span v-if="content.published_date || content.created_date" v-html="formatDate(content.published_date) || formatDate(content.created_date)" />
            </div>
        </div>
        <v-img
            class="blog-media-control blog-feature-image mt-4"
            :class="`kiwi-radius-${featureImage.rounded}`" 
            :src="content.image" 
            :alt="content && content.title ? content.title : ''" 
            cover
            :rounded="featureImage.rounded"
        />
        <div class="kiwi-paragraphs-bundle mt-4" v-html="content.content" />
    </article>
</template>

<script setup>
import TimeFormat from '@/components/TimeFormat/TimeFormat.vue'
    const props = defineProps({
        content: {
            required: true,
        },
        pageClass: {
            type: String,
        }
    })
    const category = {
            name: 'Our blog',
            color: 'kiwi-text-primary',
            transform: 'none'
        }
    const featureImage = {
        rounded: 8,
    }

     const formatDate = (dateString) => {
        // Parse the date string into a Date object
        const date = new Date(dateString)

        // Extract the day and month
        const day = date.getUTCDate() // get the day of the month
        const month = date.toLocaleString('default', { month: 'long' }) // get the month name

        return `${getOrdinalSuffix(day)} ${month}`
    }
    const getOrdinalSuffix = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    // const content = {
    //     id: 1,
    //     category: {
    //         name: 'Our blog',
    //         color: 'kiwi-text-primary',
    //         transform: 'none'
    //     },
    //     title: 'Preparing for the PTE Exam: A Guide',
    //     subtitle: 'Our tiered pricing plans provide the perfect balance of features and affordability.',
    //     tags: ['PTE', 'Exam', 'Preparation'],
    //     author: {
    //         name: 'Sheetal Rai',
    //         date: '18th July'
    //     },
    //     featureImage: {
    //         rounded: 8,
    //         src: '/assets/blog-image-one.png'
    //     },
    //     paragraphs: `
    //         <p>The blog emphasizes the importance of planning, structuring, and optimizing blog content to engage readers and improve search engine visibility. Here is a breakdown of the key points covered in the blog:</p>
    //         <ol>
    //             <li>Planning Your Blog Post: The blog highlights the significance of planning by creating an outline that acts as a roadmap for structuring the post effectively. It suggests shortlisting relevant topics, choosing a descriptive title, and incorporating a focus keyword naturally throughout the content for SEO purposes.</li>
    //             <li>Structuring Your Content: Emphasizing the importance of a clear structure, the blog advises using headings and subheadings to organize the content and help readers navigate the post easily. It also recommends adding key takeaways under each heading to enhance the informational flow.</li>
    //             <li>Word Count and Intent Alignment: While suggesting a standard word count of 1,500-2,000 words for a blog post, the blog also stresses aligning each section of the outline with the search intent behind the topic to fulfill the reader's needs effectively.</li>
    //             <li>Visuals and Multimedia: It encourages the inclusion of visuals and multimedia elements like images and videos to break up text and enhance visual appeal, making the blog post more engaging and informative.</li>
    //             <li>Call to Action (CTA): The blog underlines the importance of including a call to action at the end of the post to prompt readers to take action, such as subscribing to a newsletter, making a purchase, or leaving a comment.</li>
    //         </ol>
    //         <p>Overall, the blog provides a comprehensive guide for beginners on writing blog posts, covering essential aspects from planning and structuring content to optimizing for search engines and engaging readers effectively.</p>    `
    // }
</script>