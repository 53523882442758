const menus = [
    {
        label: 'My Dashboard',
        initialImgSrc: '/assets/admin_dashboard-icon.svg',
        activeImgSrc: '/assets/admin_dashboard-icon_active.svg',
        path: 'dashboard',
        hasChildren: false,
        svg: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="#1CA972"/>
            <path d="M15.5514 12.0219L10.6993 16.2678C10.2832 16.6319 10.5621 17.2786 11.1353 17.2786C11.4855 17.2786 11.7694 17.5407 11.7694 17.8642V20.5672C11.7694 22.8925 11.7694 24.0552 12.5515 24.7776C13.3336 25.5 14.5923 25.5 17.1099 25.5H18.8901C21.4077 25.5 22.6664 25.5 23.4485 24.7776C24.2307 24.0552 24.2307 22.8925 24.2307 20.5672V17.8642C24.2307 17.5407 24.5145 17.2786 24.8647 17.2786C25.4378 17.2786 25.7168 16.6319 25.3008 16.2678L20.4486 12.0219C19.2891 11.0073 18.7093 10.5 18 10.5C17.2907 10.5 16.7109 11.0073 15.5514 12.0219Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18 21.3335H18.006" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
    },
    {
        label: 'My Practice',
        initialImgSrc: '/assets/admin_practice-icon.svg',
        activeImgSrc: '/assets/admin_practice-icon_active.svg',
        svg: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="#1CA972" stroke="none"/>
            <path d="M18 14.5046C17.4275 13.9034 15.7681 12.4753 12.9838 11.9747C11.5732 11.721 10.8679 11.5942 10.2673 12.0803C9.66663 12.5664 9.66663 13.3557 9.66663 14.9344V20.6081C9.66663 22.0516 9.66663 22.7733 10.0521 23.2239C10.4376 23.6746 11.2863 23.8272 12.9838 24.1324C14.4969 24.4045 15.6778 24.8381 16.5326 25.2737C17.3736 25.7023 17.7941 25.9167 18 25.9167C18.2058 25.9167 18.6263 25.7023 19.4673 25.2737C20.3221 24.8381 21.503 24.4045 23.0161 24.1324C24.7136 23.8272 25.5623 23.6746 25.9478 23.2239C26.3333 22.7733 26.3333 22.0516 26.3333 20.6081V14.9344C26.3333 13.3557 26.3333 12.5664 25.7326 12.0803C25.132 11.5942 23.8333 11.9747 23 12.5833" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.8439 21.404C19.6497 22.6399 18.3977 25.2009 18 25.9168V14.6668C18.3454 14.0451 19.335 12.2639 21.0264 11.0532C21.739 10.5432 22.0952 10.2882 22.5477 10.5207C23 10.7532 23 11.2665 23 12.293V19.6596C23 20.2141 23 20.4914 22.8862 20.6862C22.7722 20.8811 22.4628 21.0554 21.8439 21.404Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
        path: null,
        hasChildren: true,
        children: [
            {
                label: 'Mock Test',
                path: 'mock-tests',
            },
            {
                label: 'Question Based Test',
                path: 'question-based-tests',
            },
            {
                label: 'Individual Question Test',
                path: 'individual-question-test',
            },
        ]
    },
    {
        label: 'Subscription',
        initialImgSrc: '/assets/sketch.svg',
        activeImgSrc: '/assets/sketch_active.svg',
        path: 'subscription',
        hasChildren: false,
        svg: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="none" fill="#1CA972"/>
            <path d="M22.1667 15.9167L18 10.5L13.8334 15.9167L18 25.5L22.1667 15.9167Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.5833 11.3333L26.3333 15.9167H22.1666M22.5833 11.3333L22.1666 15.9167M22.5833 11.3333L18 10.5L13.4166 11.3333M22.1666 15.9167H13.8333M13.4166 11.3333L9.66663 15.9167H13.8333M13.4166 11.3333L13.8333 15.9167" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.3333 15.9165L18 25.4998L9.66663 15.9165" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
    },
    {
        label: 'Information',
        initialImgSrc: '/assets/message-question.svg',
        activeImgSrc: '/assets/message-question_active.svg',
        path: 'information',
        hasChildren: false,
        feature: 'information',
        svg: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="none" fill="#1CA972"/>
            <path d="M19.8088 25.4089C23.2946 25.1772 26.0713 22.3612 26.2998 18.8259C26.3445 18.1341 26.3445 17.4176 26.2998 16.7257C26.0713 13.1905 23.2946 10.3744 19.8088 10.1427C18.6195 10.0637 17.378 10.0638 16.1911 10.1427C12.7053 10.3744 9.92863 13.1905 9.70016 16.7257C9.65545 17.4176 9.65545 18.1341 9.70016 18.8259C9.78337 20.1135 10.3528 21.3057 11.0232 22.3123C11.4125 23.0171 11.1556 23.8967 10.7501 24.665C10.4578 25.219 10.3116 25.496 10.429 25.6961C10.5464 25.8962 10.8085 25.9026 11.3328 25.9153C12.3697 25.9406 13.0689 25.6466 13.6239 25.2373C13.9386 25.0052 14.096 24.8892 14.2045 24.8758C14.313 24.8625 14.5264 24.9504 14.9533 25.1262C15.337 25.2842 15.7824 25.3817 16.1911 25.4089C17.378 25.4878 18.6195 25.488 19.8088 25.4089Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"/>
            <path d="M16.75 15.9486C16.75 15.2405 17.3097 14.6665 18 14.6665C18.6903 14.6665 19.25 15.2405 19.25 15.9486C19.25 16.2038 19.1772 16.4416 19.0519 16.6413C18.6784 17.2368 18 17.8046 18 18.5127V18.8332" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
            <path d="M18 20.5H18.0067" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
    },
    {
        label: 'Refer your Friends',
        initialImgSrc: '/assets/promotion.svg',
        activeImgSrc: '/assets/promotion_active.svg',
        path: 'referal',
        hasChildren: false,
        feature: 'referral',
        svg: `
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="none" fill="#1CA972"/>
        <path d="M20.4386 10.4257L14.8946 13.0869C14.4679 13.2917 14.012 13.3431 13.5473 13.2389C13.2431 13.1708 13.091 13.1367 12.9686 13.1228C11.4479 12.9491 10.5 14.1527 10.5 15.5367V16.2963C10.5 17.6803 11.4479 18.8839 12.9686 18.7103C13.091 18.6963 13.2432 18.6622 13.5473 18.5941C14.012 18.4899 14.4679 18.5413 14.8946 18.7461L20.4386 21.4073C21.7112 22.0183 22.3475 22.3237 23.057 22.0856C23.7664 21.8475 24.0099 21.3366 24.497 20.3148C25.8343 17.5092 25.8343 14.3239 24.497 11.5181C24.0099 10.4964 23.7664 9.98551 23.057 9.74742C22.3475 9.50934 21.7112 9.81479 20.4386 10.4257Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5485 25.3092L16.3057 26.3335C13.5043 24.1117 13.8466 23.0522 13.8466 18.8335H14.7914C15.1749 21.2176 16.0793 22.3468 17.3273 23.1643C18.096 23.6678 18.2545 24.7272 17.5485 25.3092Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.25 18.4165V13.4165" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
    },
    {
        label: 'Contact Us',
        initialImgSrc: '/assets/headset.svg',
        activeImgSrc: '/assets/headset_active.svg',
        path: 'contactTab',
        hasChildren: false,
        feature: 'contact-us-menu',
        svg: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="none" fill="#1CA972"/>
            <path d="M10.5 19.6665V17.1665C10.5 13.0244 13.8579 9.6665 18 9.6665C22.1422 9.6665 25.5 13.0244 25.5 17.1665V21.205C25.5 22.8786 25.5 23.7153 25.2063 24.3679C24.8721 25.1106 24.2774 25.7053 23.5348 26.0395C22.8822 26.3332 22.0454 26.3332 20.3718 26.3332H18" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5 18.0002C20.5 17.0797 21.2462 16.3335 22.1667 16.3335C24.0076 16.3335 25.5 17.8259 25.5 19.6668C25.5 21.5077 24.0076 23.0002 22.1667 23.0002C21.2462 23.0002 20.5 22.254 20.5 21.3335V18.0002Z" stroke="white" stroke-width="1.2"/>
            <path d="M15.5 18.0002C15.5 17.0797 14.7538 16.3335 13.8333 16.3335C11.9924 16.3335 10.5 17.8259 10.5 19.6668C10.5 21.5077 11.9924 23.0002 13.8333 23.0002C14.7538 23.0002 15.5 22.254 15.5 21.3335V18.0002Z" stroke="white" stroke-width="1.2"/>
            </svg>`
    },
    {
        label: 'FAQ & Help',
        initialImgSrc: '/assets/quiz-05.svg',
        activeImgSrc: '/assets/quiz-05_active.svg',
        path: 'faqTab',
        hasChildren: false,
        feature: 'help-and-faq-tab',
        svg: `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="none" fill="#1CA972"/>
            <path d="M15.0834 14.2502C15.0834 13.4064 15.8296 12.5835 16.75 12.5835C17.6705 12.5835 18.4167 13.2675 18.4167 14.1113C18.4167 14.4154 18.3198 14.6988 18.1526 14.9369C17.6545 15.6464 16.75 16.3231 16.75 17.1668" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
            <path d="M16.75 19.25H16.7567" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.6666 24.25C15.5419 24.9748 16.5954 25.4365 17.8029 25.5157C18.7543 25.5781 19.7475 25.578 20.697 25.5157C21.024 25.4943 21.3804 25.4172 21.6873 25.2925C22.0287 25.1537 22.1995 25.0843 22.2864 25.0948C22.3731 25.1053 22.499 25.197 22.7509 25.3803C23.1949 25.7033 23.7542 25.9354 24.5837 25.9155C25.0031 25.9054 25.2129 25.9003 25.3067 25.7424C25.4006 25.5844 25.2837 25.3658 25.0498 24.9284C24.7255 24.3218 24.52 23.6274 24.8314 23.071C25.3677 22.2762 25.8232 21.3351 25.8898 20.3186C25.9255 19.7724 25.9255 19.2067 25.8898 18.6606C25.8455 17.9832 25.681 17.3394 25.4176 16.75" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.1971 22.1825C20.9858 21.9996 23.2071 19.7764 23.3899 16.9854C23.4256 16.4392 23.4256 15.8736 23.3899 15.3274C23.2071 12.5364 20.9858 10.3132 18.1971 10.1303C17.2457 10.0678 16.2524 10.068 15.303 10.1303C12.5143 10.3132 10.293 12.5364 10.1102 15.3274C10.0744 15.8736 10.0744 16.4392 10.1102 16.9854C10.1768 18.0019 10.6323 18.9431 11.1686 19.7378C11.48 20.2942 11.2745 20.9887 10.9502 21.5952C10.7163 22.0326 10.5994 22.2512 10.6933 22.4092C10.7872 22.5672 10.9969 22.5722 11.4163 22.5823C12.2458 22.6022 12.8052 22.3702 13.2492 22.0471C13.501 21.8638 13.6269 21.7722 13.7137 21.7617C13.8004 21.7512 13.9712 21.8206 14.3127 21.9593C14.6196 22.0841 14.976 22.1611 15.303 22.1825C16.2524 22.2448 17.2457 22.2449 18.1971 22.1825Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"/>
            </svg>`
    },
    // {
    //     label: 'Logout',
    //     initialImgSrc: '/assets/logout-01.svg',
    //     activeImgSrc: '/assets/logout-01_active.svg',
    //     path: null,
    //     hasChildren: false,
    // },
]

export default menus