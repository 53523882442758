<template>
    <v-dialog
        v-model="dialog"
        max-width="1000"
        :persistent="false"
        style="box-shadow: 0px 8px 30px -4px rgba(16, 24, 40, 0.04) !important;"
    >
        <v-sheet
            class="kiwi-bg-white kiwi-text-black evaluation-check-box"
            variant="flat"
        >
            <div class="pa-5 position-relative">
                <h3 class="h3"> Score Details: {{`${content?.question_data?.topic_slug.toUpperCase()} Question`}} {{ index??1 }}</h3>
                <p class="mt-1 kiwi-fs-14" style="color: rgba(78, 84, 82, 1); max-width: 80vw;"> These scores can be used to analyse how you can improve in this question, but they are not directly related to the PTE scores.</p>

                <v-btn class="kiwi-radius-4 kiwi-border-error kiwi-text-error text-none position-absolute" @click="closeDialog" size="small" variant="outlined" style="top: 20px; right: 20px;">
                    <div class="d-flex align-center">
                    <span style="line-height: 1;">Close</span> <v-icon class="mdi mdi-close" style="margin-top: 1px;" />
                    </div>
                </v-btn>
            </div>
            <v-divider />
            <div class="py-2 px-5 kiwi-bg-light-grey-8">
                <v-row>
                    <v-col cols="12" md="5" lg="4">
                        <v-row class="score-widget ma-0 mt-4 kiwi-bg-white border kiwi-border-light-grey-3">
                            <v-col :cols="12" class="pa-0">
                                <div class="d-flex justify-center align-center kiwi-bg-light-grey-3 h-100 py-2">
                                    <v-progress-circular :model-value="overAllScore" :size="130" :width="12" color="rgba(28, 169, 114, 1)" class="mr-3">
                                                <template v-slot:default>
                                            <div class="inner-status d-flex flex-column flex-wrap align-center justify-center">
                                                <span class="kiwi-text-light-black-2 kiwi-fs-13 font-weight-medium">Score</span>
                                                <span class="kiwi-fs-18 font-weight-bold">{{ parseFloat(content?.obtained_marks)+'/'+parseFloat(content?.full_marks) }}</span>
                                            </div>
                                        </template>
                                    </v-progress-circular>
                                </div>
                            </v-col>
                            <v-col cols="12" v-if="content?.ai_marks_obtained_json.length || (content?.marks_obtained_json.length && !['hiw', 'fib-rw', 'fib-r', 'fib-l'].includes(slug))">
                                <div class="py-3">
                                    <v-table density="compact" class="border-0 custom-table w-100" id="score-table" v-if="content?.ai_marks_obtained_json.length">
                                        <tbody>
                                            <template  v-for="(score, i) in content?.ai_marks_obtained_json" :key="i">
                                            <tr v-if="score?.name !== 'Overall'">
                                                <td style="word-break: break-word;" class="border-0 kiwi-text-light-black-2">{{ score?.name }}</td>
                                                <td class="border-0">{{`${parseFloat(score?.obtained_marks)}/${parseFloat(score?.full_marks)}`}} </td>
                                            </tr>
                                            </template>

                                        </tbody>
                                    </v-table>

                                </div>
                            </v-col>
                            <div v-else> <div class="py-3">
                              <v-table density="compact" class="border-0 custom-table w-100" id="score-table" >
                                <tbody>
                                <tr v-if="['fib-l','fib-rw','fib-r'].includes(slug)">
                                  <td style="word-break: break-word;" class="border-0 kiwi-text-light-black-2 text-capitalize"> Total Blanks </td>
                                  <td class="border-0"> {{content.marks_obtained_json.length}} </td>
                                </tr>
                                <tr >
                                  <td style="word-break: break-word;" class="border-0 kiwi-text-light-black-2 text-capitalize"> Correct </td>
                                  <td v-if="!['hiw'].includes(slug)" class="border-0"> {{ getWordCount(content.marks_obtained_json , true) }} </td>
                                  <td class="border-0" v-else>{{findHighlightedAnswerFor(content.marks_obtained_json , content.question_data.question_text ,true ,false,true)}}</td>
                                </tr>
                                <tr>
                                  <td style="word-break: break-word;" class="border-0 kiwi-text-light-black-2 text-capitalize"> Incorrect </td>
                                  <td v-if="!['hiw'].includes(slug)" class="border-0"> {{ getWordCount(content.marks_obtained_json , false , true) }} </td>
                                  <td v-else>{{findHighlightedAnswerFor(content.marks_obtained_json , content.question_data.question_text , false , false ,true )}}</td>
                                </tr>
                                <tr v-if="!['hiw'].includes(slug)">
                                  <td style="word-break: break-word;" class="border-0 kiwi-text-light-black-2 text-capitalize"> {{['fib-rw' , 'fib-r' , 'fib-l'].includes(slug) ? 'Left Blanks' : 'Not Highlighted'}} </td>
                                  <td class="border-0"> {{ getWordCount(content.marks_obtained_json , false , false , true) }} </td>
                                </tr>
                                </tbody>
                              </v-table>
                            </div></div>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="7" lg="8">
                      <!-- <div  v-if="slug === 'asq'"  class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
                          <div>
                            <span class="kiwi-fs-20 font-weight-bold kiwi-text-black-3">Question</span>
                            {{content?.question_data.question_text}}
                          </div>
                      </div> -->
                      <div v-if="slug === 'wfd'" class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
                            <div>
                              <span class="kiwi-fs-15 font-weight-bold kiwi-text-black-3">Sample Answer</span>
                              <div>
                                {{content.question_data.answer_text}}
                              </div>
                              <div>
                                <Player  auto-play="true" :source="content.question_data.question_audio" />
                              </div>
                            </div>
                      </div>
                        <div class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4" v-if="!['sst' , 'hiw' ,'swt','we'].includes(slug)">
                            <div>
                              <span class="kiwi-fs-20 font-weight-bold kiwi-text-black-3">{{!['asq' ,'fib-rw' , 'mcm-r' ,'fib-r' , 'ro' , 'mcq' , 'mcm-l' , 'fib-l' ,'hcs' ,'mcs-l','smw' , 'wfd'].includes(slug) ? 'Answer:' : 'Your Answer'}}</span>
                              <div v-if="['fib-rw' , 'fib-r'].includes(slug)">
                                <no-name :data="content.marks_obtained_json"/>
                              </div>
                              <div v-else-if="['mcm-r' , 'mcm-l'].includes(slug)">
                                {{ findIndices(content.marks_obtained_json , content.answer_list  , false).join(' , ')  }}
                              </div>
                              <div v-else-if="['ro'].includes(slug)">
                                <AReorder :charType="'num'" :options="content.question_data?.options || []" :correct="content.correct_answer_list || []" :selected="content.answer_list || []" :render-type="'answerList'" />
                              </div>
                              <div v-else-if="['mcq' , 'hcs' ,'mcs-l' ,'smw'].includes(slug)">
                                {{findSelected(content.marks_obtained_json )}}
                              </div>
                              <div v-else-if="['fib-l'].includes(slug)">
                                {{content.answer_list.join(', ')}}
                              </div>
                              <div v-else-if="['wfd'].includes(slug)">
                                {{content.answer_text}}
                              </div>
                              <Player :auto-play="true"  v-else :source="content.answer_audio_file" />
                            </div>
                        </div>
                        <div v-if="!['asq' , 'rl' , 'di' , 'fib-rw' , 'mcm-r' , 'ro' , 'fib-r' , 'mcq' , 'mcm-l' ,'fib-l','hcs' ,'mcs-l','smw','hiw','wfd'].includes(slug)" class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
                            <div class="kiwi-fs-16 font-weight-semi-bold kiwi-text-black-3">Indication</div>
                            <div v-if="!['swt' , 'we' , 'sst'].includes(slug)" class="d-flex font-weight-semi-bold kiwi-text-black-3">
                                <span class="indication-chip d-flex align-center mx-2">
                                    <div class="dot good mr-2"></div> <span>Good</span>
                                </span>
                                <span class="indication-chip d-flex align-center mx-2">
                                    <div class="dot average mr-2"></div> <span>Average</span>
                                </span>
                                <span class="indication-chip d-flex align-center mx-2">
                                    <div class="dot bad mr-2"></div> <span>Bad</span>
                                </span>
                            </div>
                            <div v-else>
                              <span class="indication-chip d-flex align-center mx-2">
                                    <div class="dot bad mr-2"></div> <span>Error</span>
                                </span>
                            </div>
                            <v-divider class="mt-3" />
                            <div class="py-4">
                                <div class="kiwi-fs-16 font-weight-semi-bold kiwi-text-black-3">AI Speech Recognition:</div>
                                      <SpeechRecognition :slug="slug" :result="scoreDetail?.diff_result??[]" :text="scoreDetail?.text"/>
                            </div>
                        </div>
                      <div v-if="['asq' , 'rl' , 'di'].includes(slug)" class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
                        <div>
                          <span class="kiwi-fs-20 font-weight-bold kiwi-text-black-3">Sample Answer</span>
                          <div>
                            {{!['asq'].includes(slug)? content?.question_data.answer_text : content?.question_data.answer_list.join(',') }}
                          </div>
                        </div>
                      </div>
                      <div v-if="['fib-rw' , 'mcm-r' , 'ro' , 'fib-r' ,'mcq' , 'mcm-l','fib-l' , 'hcs','mcs-l','smw'].includes(slug)" class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
                        <div>
                          <span class="kiwi-fs-20 font-weight-bold kiwi-text-black-3">Correct Answer</span>
                          <div v-if="['mcm-l' , 'mcq' ,'mcm-r' ].includes(slug)">
                            {{findCorrectAnswerIndices(content.marks_obtained_json , false).join(', ')}}
                          </div>
                          <div v-else-if="['hcs' ,'mcs-l','smw'].includes(slug)">
                            {{findCorrectAnswerIndices(content.marks_obtained_json ).join(', ')}}
                          </div>
                          <div v-else-if="['fib-rw' , 'fib-r' , 'fib-l'].includes(slug)">
                            {{findCorrectWords(content.marks_obtained_json)}}
                          </div>
                          <div v-else-if="['ro'].includes(slug)">
                            <AReorder :charType="'num'" :options="content.question_data?.options || []" :correct="content.correct_answer_list || []" :selected="content.answer_list || []" :render-type="'correctList'" />
                          </div>
                          <div v-else>
                            {{content.correct_answer_list.join(', ')}}
                          </div>
                        </div>
                      </div>
                      <div v-if="['hiw'].includes(slug)">
                        <hiw-answer :content="content" />
                      </div>
                      <div v-if="['wfd'].includes(slug)" class="d-flex flex-wrap w-100 justify-space-between kiwi-radius-8 border kiwi-border-light-grey-3 kiwi-bg-white mt-3 py-4 px-4">
                        <span class="kiwi-fs-15  kiwi-text-black-3 answer-box" style="background-color: #f8d7da; color: #d0071a ;border-color: #f5c6cb;" >Incorrect Word </span>
                        <v-divider class="mt-3"/>
                        {{findIncorrectAnswer(content.answer_text , content.question_data.answer_text)}}
                      </div>
                    </v-col>
                </v-row>
            </div>
        </v-sheet>
    </v-dialog>
</template>

<script setup>
import {storeToRefs} from "pinia";
import {ref, watch,computed} from 'vue'
import Player from '@/components/Evaluation/MockTests/Answers/APlayer.vue'
import {useIndividualQustionsTestStore} from "@/stores/individual-question-test-store";
import SpeechRecognition from "@/components/Evaluation/IndividualQuestionTest/SpeechRecognition.vue";
import NoName from "@/components/Evaluation/IndividualQuestionTest/NoName.vue";
import AReorder from "@/components/Evaluation/MockTests/Answers/AReorder.vue";
import {useAnswer} from "@/composables/useAnswer";
import HiwAnswer from "@/components/Evaluation/IndividualQuestionTest/hiwAnswer.vue";

const {findSelected , findIndices ,findCorrectAnswerIndices,findIncorrectAnswer , findCorrectWords , getWordCount, findHighlightedAnswerFor} =useAnswer();
const store  = useIndividualQustionsTestStore();
const {scoreDetail} = storeToRefs(store);
const dialog = ref(true)
const props = defineProps({
    index: {
        required: false
    },
    content: {
        type: Object,
    }
})
const overAllScore = computed(() => {
    const obtained = parseFloat(props.content.obtained_marks) || 0;
    if(obtained) {
        return (props.content.obtained_marks / parseFloat(props.content.full_marks)) * 100
    }
    return 0;
})
const emit = defineEmits(['handleClose'])
const closeDialog = () => {
    dialog.value = false;
    setTimeout(() => {
        dialog.value = true;
        emit('handleClose')
    }, 1000)
}

const marks_obtained_json_edited = computed(() => {
  const summary = props.content?.marks_obtained_json.reduce((acc, item) => {
    if (item.is_selected && item.is_correct) {
      acc.correct++;
    } else if (item.is_selected && !item.is_correct) {
      acc.incorrect++;
    } else if (item.is_correct && !item.is_selected) {
      acc.not_highlighted++;
    }
    return acc;
  }, { correct: 0, incorrect: 0, not_highlighted: 0 });
  const summaryArray = [
    { category: 'correct', count: summary.correct },
    { category: 'incorrect', count: summary.incorrect },
    { category: 'not_highlighted', count: summary.not_highlighted }
  ]
  return summaryArray
})
watch(dialog, (newVal, oldVal) => {
    if(!newVal && oldVal) {
        emit('handleClose')
    }
})
const slug = computed(() => {
  return props.content.question_data.topic_slug
});

</script>
<style lang="scss">
.score-widget {
    border: 1px solid rgba(236, 238, 237, 1);
    border-radius: 12px;
    overflow: hidden;
    .score-circle {
        background-color: rgba(236, 238, 237, 1);
    }
}
#score-table {
    .v-table__wrapper {
        border: none !important;
    }
    tr, td, th {
        border-left: hidden !important;
        border-right: hidden !important;
        font-weight: 600;
        font-size: 14px;
    }
}
.indication-chip {
    .dot {
        width: 10px;
        height: 10px;
        border-radius: 100%;

        &.good {
            background-color: #12B76A;
        }
        &.average {
            background-color: #F79009;
        }
        &.bad {
            background-color: #F04438;
        }
    }
}

.answer-box {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
}

.evaluation-check-box .q-text span {
  cursor: pointer;
}

</style>
