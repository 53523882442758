<template>
    <v-sheet
        variant="flat"
        class="kiwi-bg-transparent kiwi-text-black-3"
    >
        <!-- Breadcrumb start -->
        <v-breadcrumbs :items="breadcrumbs" class="kiwi-text-primary kiwi-breadcrumbs font-weight-semi-bold pl-0">
            <template v-slot:divider>
                <img src="/assets/arrow-left-01.svg" alt="">
            </template>
        </v-breadcrumbs>
        <!-- Breadcrumb end -->

        <!-- Article start -->
        <article class="pl-4">
            <h1 class="kiwi-fs-28 font-weight-bold">How can we help you?</h1>
            <p class="kiwi-fs-16 kiwi-text-black-2">Have a question or need assistance? Reach out to us anytime – we're here to help you.</p>
        </article>
        <!-- Article end -->
    </v-sheet>
</template>

<script setup>
import {ref} from 'vue'

const breadcrumbs = ref([
    {
        title: '',
        disabled: true,
        href: '',
    },
    {
        title: 'Dashboard',
        disabled: false,
        href: 'dashboard',
    }
])
</script>

<style>

</style>