<template>
    <div 
        class="fill-height kiwi-bg-white w-100 d-flex flex-column flex-wrap kiwi-radius-8 align-center justify-center py-4 py-md-8 py-lg-10 px-4 px-md-6 px-lg-10 position-relative" 
    >
        <img src="/assets/Shape.png" class="position-absolute z-1 w-100 h-100" style="top: 0; left: 0; object-fit: cover;">
        <div class="position-relative z-2 w-100">
            <article class="text-left">
                <h1 class="font-weight-bold kiwi-fs-36 kiwi-text-black"><span class="gradient-text-1">Study Smart, Not Hard</span> with {{institute.name}}</h1>
                <p class="font-weight-medium kiwi-fs-14 mt-1">Choose a right package, to speed up your learning</p>
            </article>
            <v-row class="mt-8">
                <v-col 
                    cols="12" md="6" lg="4"
                    v-for="(price, i) in pricingList"
                    :key="i"
                >
                    <Pricing :plan="price" />
                </v-col>
                <v-col 
                    cols="12" md="6" lg="4"
                    v-if="institute.$hasFeature('voucher')"
                >
                    <v-card
                        variant="flat"
                        class="kiwi-radius-4 border kiwi-border-light-grey-3 overflow-hidden kiwi-bg-white h-100"
                        style="box-shadow: 0px 8px 30px -4px rgba(16, 24, 40, 0.04);"
                    >
                        <article class="pa-4 pa-md-6">
                            <img src="/assets/coupon-02-gradient.svg" alt="" style="height: 57px;">
                            <p class="font-weight-semi-bold kiwi-fs-18 kiwi-text-black-2">Have a Voucher Code? <span class="upgrade-gradient-text">Apply now!</span></p>
                            <div class="kiwi-form-group mt-4">
                                <v-text-field
                                    class="kiwi-form-input voucher-input kiwi-fs-14 kiwi-bg-white"
                                    variant="outlined"
                                    placeholder="Enter Voucher Code"
                                    v-model="VoucherCode"
                                    @input="VoucherEvent"
                                    :error-messages="validation"
                                ></v-text-field>
                                <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px" v-if="validVoucher">
                                    <v-divider />
                                    <div>Your Selected Plan</div>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex align-center font-weight-bold"><img src="/assets/Icons.svg" class="mr-1" alt="" style="width: 24px; height: 24px;"><span>{{ selectedPlan }}</span></div>
                                    <v-divider />
                                </div>
                                <v-btn 
                                    variant="flat"
                                    class="kiwi-bg-primary kiwi-text-white text-none"
                                    :class="validVoucher ? 'mt-4' : ''"
                                    height="44"
                                    block
                                    @click="applyVoucher"
                                >Apply</v-btn>
                            </div>
                        </article>
                    </v-card>
                </v-col>
            </v-row>
            <v-sheet 
                class="border kiwi-border-light-grey-3 kiwi-radius-8 py-4 px-6 mt-8 kiwi-fs-14" 
                varaint="flat" 
                style="background: linear-gradient(85.01deg, #F1F6F7 -7.12%, #F4F4F6 82.2%);"
            >
                <v-icon class="mdi mdi-information-outline mr-1"></v-icon> 
                <span>Learn more about Terms and Conditions applied!</span>
                <router-link :to="{name: 'termsTab'}" class="kiwi-text-primary ml-1">Click Here</router-link>
            </v-sheet>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import Pricing from '@/components/Packages/PricingCard.vue'
import { usePackageStore } from "@/stores/package-store";
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useConfirmer } from '@/stores/confirmer';

const packageList = usePackageStore()
const { packages } = storeToRefs(packageList)
const { getPackageList, checkVoucher, redeemVoucher } = packageList
const VoucherCode = ref(null)
const validation = ref(null)
const voucherSuccess = ref(false)
const validVoucher = ref(false)
const selectedPlan = ref(null)
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()
const router = useRouter()
const confirmer = useConfirmer()

const VoucherEvent = () => {
    if(VoucherCode.value) {
        checkVoucher(VoucherCode.value).then(res => {
            if(res.success){
                validation.value = null
                validVoucher.value = true
                selectedPlan.value = res.data.package.name
            }
        }).catch(error => {
            // console.log(error)
            validVoucher.value = false
            selectedPlan.value = null
            if(error.type == 'validation'){
                validation.value = error.message
            }
        })
    }
}

const applyVoucher = () => {
    redeemVoucher(VoucherCode.value).then(res => {
        voucherSuccess.value = true
        // message to success and redirect to dashboard
        confirmer.openSnackBar({type: "success" , msg: res.data.message});
        router.push({name: 'dashboard'})
    }
    ).catch(error => {
        if(error.type == 'validation'){
            validation.value = error.message
        }
    })
}

onMounted(() => {
    getPackageList()
})
const pricingList = packages

// const pricingList = ref([
//     {
//         type: 'free',
//         is_current_plan: true,
//         name: 'Free Plan',
//         description: 'Ideal for individuals who need quick access to basic features.',
//         duration: null,
//         price: '0',
//         is_referal_user: false,
//         features: [
//             '3 Mock Tests', 
//             '3 Question based Tests each'
//         ]
//     },
//     {
//         type: 'pro',
//         is_current_plan: false,
//         name: 'Pro Plan',
//         description: 'Ideal for students who are intermediate to the course and need premium features.',
//         duration: 15,
//         price: '1,400',
//         is_referal_user: true,
//         discounted_price: '1,500',
//         features: [
//             '10 Mock Tests', 
//             '20 Question based Tests each',
//             'Tests do’s and dont’s',
//             'Tips and Tricks for Exam',
//             'Study Planner',
//         ]
//     },
//     {
//         type: 'premium',
//         is_current_plan: false,
//         name: 'Premium Plan',
//         description: 'Ideal for students who want expertise feedbacks, plans and prepare in a full-fledged way.',
//         duration: 30,
//         price: '2,400',
//         is_referal_user: false,
//         features: [
//             '20 Mock Tests', 
//             '20 Question based Tests each',
//             'Tips and Tricks for Exam',
//             'Study Planner',
//             'Live chats with Experts',
//             'Access to Learning Videos by Experts',
//         ]
//     },
// ])

</script>

<style scoped>
.gradient-text-1 {
    background: linear-gradient(90.16deg, #F26C67 0.14%, #F19F52 51.49%);
    -webkit-background-clip: text;
    color: transparent;
}
</style>