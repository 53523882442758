<template>
  <Wrapper :mathModule="isCurrentModuleMath">
    <template v-if="activeStack &&activeStack !== 'initial'" v-slot:timer>
        <div class="d-flex flex-column align-center justify-center">
          <Timer :show-timer="toggleTimer"  />
          <img src="/assets/Mock Tests/timer-02.svg" class="mb-2" alt="" v-if="!toggleTimer" />
          <v-btn class="kiwi-btn kiwi-fs-14 px-2 py-1 text-none" variant="outlined" size="sm" rounded="xl" @click="toggleTimer = !toggleTimer">{{toggleTimer ? 'Hide' : 'Show'}} </v-btn>
        </div>
    </template>
    <template v-if="activeStack && activeStack !== 'initial'" v-slot:headerAction>
      <button class="d-flex flex-column text-center align-center justify-center mr-3" style="border: none; outline: none;" :style="highLightMode ? {borderBottom: `1px solid`, paddingBottom: `3px`} : {}" @click="highlightModeModule" v-if="!isCurrentModuleMath">
        <img src="/assets/Icons.png" height="20" alt="">
        <span class="kiwi-fs-14 font-weight-medium">Highlights & Notes</span>

          <v-tooltip
            activator="parent"
            location="bottom"
          >
            <div>
              <p><strong>Hightlight Text Mode On:</strong></p>
              <p>Select text to create Highlight automatically.</p>
            </div>
          </v-tooltip>
        </button>
        <template v-else>
          <button class="d-flex flex-column text-center align-center justify-center mr-3" style="border: none; outline: none;"  @click="showDesmos = true">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <g clip-path="url(#clip0_12803_3907)">
                <path d="M2.25024 0.37793H14.6252V18.3779H2.25024V0.37793ZM13.5002 17.2529V1.50293H3.37524V17.2529H13.5002ZM12.3752 2.62793V6.00293H4.50024V2.62793H12.3752ZM11.2502 4.87793V3.75293H5.62524V4.87793H11.2502ZM6.18136 8.25293V9.37793H4.50024V8.25293H6.18136ZM7.4426 9.37793V8.25293H9.25576V9.37793H7.4426ZM10.6992 9.37793V8.25293H12.3752V9.37793H10.6992ZM6.18136 11.6279V12.7529H4.50024V11.6279H6.18136ZM7.4426 12.7529V11.6279H9.25576V12.7529H7.4426ZM10.6992 12.7529V11.6279H12.3752V12.7529H10.6992ZM6.18136 15.0029V16.1279H4.50024V15.0029H6.18136ZM7.4426 16.1279V15.0029H9.25576V16.1279H7.4426ZM10.6992 16.1279V15.0029H12.3752V16.1279H10.6992Z" fill="black"/>
              </g>
              <defs>
                <clipPath id="clip0_12803_3907">
                  <rect width="18" height="18" fill="white" transform="translate(0.000244141 0.37793)"/>
                </clipPath>
              </defs>
            </svg>
            <span class="kiwi-fs-14 font-weight-medium">Calculator</span>
          </button>
          <MathDialog v-if="showDesmos" title="Calculator" location="left">
            <template v-slot:closeModal>
              <button @click="showDesmos = false"><v-icon class="mdi mdi-close" /></button>
            </template>
            <Desmos class="h-100" />
          </MathDialog>

          <button class="d-flex flex-column text-center align-center justify-center mr-3" style="border: none; outline: none;" @click="showReference = true;">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
              <path d="M0.0012207 16.5696L0.0772656 16.1704L0.647602 15.9993C1.14189 15.8598 1.5538 15.5873 1.88333 15.1818L5.45744 10.8282L3.23313 5.44803C3.03034 4.92839 2.66913 4.59886 2.14949 4.45944L1.94037 4.40241L2.01641 4.00317H6.7502L6.67416 4.40241L6.36998 4.49746C6.12917 4.54816 5.97074 4.65589 5.8947 4.82066C5.81865 4.98542 5.82499 5.17553 5.91371 5.39099L7.4156 9.02214L10.4004 5.31495C10.5398 5.13751 10.5841 4.97275 10.5334 4.82066C10.4954 4.66857 10.3687 4.5545 10.1532 4.47845L9.90606 4.40241L9.98211 4.00317H12.8528L12.7768 4.40241L12.3585 4.51648C11.8515 4.66857 11.4396 4.94106 11.1228 5.33396L7.64373 9.59247L9.88705 15.0487C10.1025 15.543 10.4637 15.8788 10.9707 16.0563L11.2939 16.1704L11.2178 16.5696H6.54108L6.63614 16.1704L6.80724 16.0943C7.27618 15.9169 7.4156 15.5937 7.22548 15.1247L5.68557 11.4175L2.66279 15.1818C2.5107 15.3719 2.46001 15.5493 2.5107 15.7141C2.5614 15.8662 2.70081 15.9739 2.92895 16.0373L3.36621 16.1704L3.29016 16.5696H0.0012207Z" fill="#081E20"/>
              <path d="M13.7737 7.86266L13.9134 7.19025L15.1796 6.03756C15.6454 5.60676 16.0092 5.23999 16.2712 4.93726C16.539 4.62872 16.7282 4.35219 16.8388 4.10768C16.9494 3.85734 17.0047 3.60992 17.0047 3.36541C17.0047 3.06269 16.929 2.83564 16.7777 2.68428C16.6321 2.52709 16.44 2.4485 16.2013 2.4485C15.9917 2.4485 15.8025 2.5038 15.6337 2.61442C15.4707 2.71921 15.3281 2.89386 15.2058 3.13837L14.8652 3.76711H14.6033L14.7517 2.53582C14.938 2.43103 15.1651 2.34662 15.4329 2.28258C15.7007 2.21854 15.9743 2.18652 16.2537 2.18652C16.801 2.18652 17.2288 2.30005 17.5374 2.52709C17.8459 2.74832 18.0002 3.07433 18.0002 3.50513C18.0002 3.82533 17.9012 4.12805 17.7033 4.41331C17.5054 4.69858 17.2201 4.98966 16.8475 5.28657C16.4808 5.58347 16.0441 5.90948 15.5376 6.26461L14.5509 6.98067H17.5112L17.354 7.86266H13.7737Z" fill="#081E20"/>
            </svg>
            <span class="kiwi-fs-14 font-weight-medium">Reference</span>
          </button>
          <MathDialog v-if="showReference" title="Reference:" location="right" @expansionWidth="toggleWidth">
            <template v-slot:closeModal>
              <button @click="showReference = false"><v-icon class="mdi mdi-close" /></button>
            </template>
            <div class="kiwi-paragraphs-bundle">
              <template v-if="expansionWidth">
                <img src="/assets/reference-2.png" class="w-100" alt="">
              </template>
              <template v-else>
                <img src="/assets/reference-1.png" class="w-100" alt="">
              </template>
              <ul style="padding-left: 30px">
                <li>The number of degrees of arc in a circle is 360 degrees.</li>
                <li>The number of radians of arc in a circle is 2π.</li>
                <li>The sum of measures in degrees of the angles of the triangle is 180.</li>
              </ul>
            </div>
          </MathDialog>
        </template>
        <v-menu>
          <template v-slot:activator="{ props }">
            <button v-bind="props" class="d-flex flex-column text-center align-center justify-center" style="border: none; outline: none;">
              <img src="/assets/more-vertical.png" height="20" alt="">
              <span class="kiwi-fs-14 font-weight-medium">More</span>
            </button>
          </template>
          <v-list>
            <v-list-item @click="openHelpDialog">
              <v-list-item-title class="d-flex align-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="mr-2">
                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.3125 14.0625C10.3125 14.2351 10.1726 14.375 10 14.375C9.82741 14.375 9.6875 14.2351 9.6875 14.0625C9.6875 13.8899 9.82741 13.75 10 13.75C10.1726 13.75 10.3125 13.8899 10.3125 14.0625Z" fill="#03120C" stroke="#03120C" stroke-width="1.25"/>
                <path d="M10 11.2505V10.6255C10.4326 10.6255 10.8556 10.4972 11.2153 10.2568C11.575 10.0165 11.8554 9.67482 12.021 9.27511C12.1866 8.8754 12.2299 8.43556 12.1455 8.01123C12.0611 7.5869 11.8527 7.19712 11.5468 6.89119C11.2409 6.58527 10.8511 6.37693 10.4268 6.29252C10.0024 6.20812 9.56259 6.25144 9.16288 6.417C8.76317 6.58257 8.42153 6.86295 8.18116 7.22268C7.9408 7.58241 7.8125 8.00534 7.8125 8.43799" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Help
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="shortcutPromt = true">
              <v-list-item-title class="d-flex align-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="mr-2">
                <path d="M12.5 7.5V12.5H7.5V7.5H12.5Z" stroke="#03120C" stroke-width="1.25" stroke-linejoin="round"/>
                <path d="M12.5 12.5H15C16.3807 12.5 17.5 13.6192 17.5 15C17.5 16.3807 16.3807 17.5 15 17.5C13.6192 17.5 12.5 16.3807 12.5 15V12.5Z" stroke="#03120C" stroke-width="1.25" stroke-linejoin="round"/>
                <path d="M7.5 12.5015H5C3.61929 12.5015 2.5 13.6207 2.5 15.0015C2.5 16.3821 3.61929 17.5015 5 17.5015C6.38071 17.5015 7.5 16.3821 7.5 15.0015V12.5015Z" stroke="#03120C" stroke-width="1.25" stroke-linejoin="round"/>
                <path d="M12.5 7.5V5C12.5 3.61929 13.6192 2.5 15 2.5C16.3807 2.5 17.5 3.61929 17.5 5C17.5 6.38072 16.3807 7.5 15 7.5H12.5Z" stroke="#03120C" stroke-width="1.25" stroke-linejoin="round"/>
                <path d="M7.5 7.5V5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38072 3.61929 7.5 5 7.5H7.5Z" stroke="#03120C" stroke-width="1.25" stroke-linejoin="round"/>
                </svg>
                Keyboard Shortcuts
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="showAssistentModal">
              <v-list-item-title class="d-flex align-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="mr-2">
                <path d="M15.8333 8.01708C15.8333 10.1664 14.7751 11.8208 13.1652 12.9096C12.7902 13.1632 12.6027 13.29 12.5101 13.4342C12.4176 13.5783 12.386 13.7677 12.3229 14.1463L12.2738 14.4405C12.163 15.1057 12.1076 15.4382 11.8744 15.6357C11.6413 15.8332 11.3042 15.8332 10.6298 15.8332H8.45358C7.77932 15.8332 7.44216 15.8332 7.20904 15.6357C6.97592 15.4382 6.92049 15.1057 6.80964 14.4405L6.76059 14.1463C6.6977 13.7689 6.66626 13.5803 6.57465 13.4368C6.48305 13.2933 6.29515 13.1648 5.91935 12.9079C4.3265 11.8192 3.33325 10.1653 3.33325 8.01708C3.33325 4.50975 6.13147 1.6665 9.58325 1.6665C10.0113 1.6665 10.4293 1.71024 10.8333 1.79354" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.7499 1.6665L13.9648 2.24736C14.2467 3.00902 14.3876 3.38985 14.6654 3.66766C14.9433 3.94548 15.3241 4.0864 16.0858 4.36824L16.6666 4.58317L16.0858 4.7981C15.3241 5.07995 14.9433 5.22087 14.6654 5.49868C14.3876 5.77649 14.2467 6.15732 13.9648 6.91898L13.7499 7.49984L13.535 6.91898C13.2532 6.15732 13.1123 5.77649 12.8344 5.49868C12.5566 5.22087 12.1758 5.07995 11.4141 4.7981L10.8333 4.58317L11.4141 4.36824C12.1758 4.0864 12.5566 3.94548 12.8344 3.66766C13.1123 3.38985 13.2532 3.00902 13.535 2.24736L13.7499 1.6665Z" stroke="#03120C" stroke-width="1.25" stroke-linejoin="round"/>
                <path d="M11.2501 15.8335V16.6668C11.2501 17.4525 11.2501 17.8453 11.006 18.0894C10.7619 18.3335 10.3691 18.3335 9.58341 18.3335C8.79775 18.3335 8.40491 18.3335 8.16082 18.0894C7.91675 17.8453 7.91675 17.4525 7.91675 16.6668V15.8335" stroke="#03120C" stroke-width="1.25" stroke-linejoin="round"/>
                </svg>
                Assisted Technology
              </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-title class="d-flex align-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="mr-2">
                <path d="M2.5 2.9165H7.5" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 7.9165H7.5" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 12.9165H17.5" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 17.9165H17.5" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.3214 8.262C11.8096 8.75016 12.5953 8.75016 14.1666 8.75016C15.7379 8.75016 16.5236 8.75016 17.0118 8.262C17.4999 7.77385 17.4999 6.98818 17.4999 5.41683C17.4999 3.84548 17.4999 3.0598 17.0118 2.57165C16.5236 2.0835 15.7379 2.0835 14.1666 2.0835C12.5953 2.0835 11.8096 2.0835 11.3214 2.57165C10.8333 3.0598 10.8333 3.84548 10.8333 5.41683C10.8333 6.98818 10.8333 7.77385 11.3214 8.262Z" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Line Reader
              </v-list-title>
            </v-list-item>
            <v-list-item @click="unschduledBreakPrompt = true">
              <v-list-title class="d-flex align-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="mr-2">
                <path d="M12.5 1.6665L17.5 6.6665M17.5 1.6665L12.5 6.6665" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 18.3335C6.38071 18.3335 7.5 17.2142 7.5 15.8335C7.5 14.4528 6.38071 13.3335 5 13.3335C3.61929 13.3335 2.5 14.4528 2.5 15.8335C2.5 17.2142 3.61929 18.3335 5 18.3335Z" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.0001 4.1665H7.08341C5.47258 4.1665 4.16675 5.47234 4.16675 7.08317C4.16675 8.694 5.47258 9.99984 7.08341 9.99984H12.9167C14.5276 9.99984 15.8334 11.3057 15.8334 12.9165C15.8334 14.5273 14.5276 15.8332 12.9167 15.8332H10.0001" stroke="#03120C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Unschduled Break
              </v-list-title> -->
            <!-- </v-list-item> -->
            <v-list-item @click="questionStack.saveAndExit()">
              <v-list-title class="d-flex align-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="mr-2">
                <path d="M2.08325 10.0002C2.08325 6.26821 2.08325 4.40223 3.24262 3.24286C4.40199 2.0835 6.26797 2.0835 9.99992 2.0835C13.7318 2.0835 15.5978 2.0835 16.7573 3.24286C17.9166 4.40223 17.9166 6.26821 17.9166 10.0002C17.9166 13.7321 17.9166 15.5981 16.7573 16.7574C15.5978 17.9168 13.7318 17.9168 9.99992 17.9168C6.26797 17.9168 4.40199 17.9168 3.24262 16.7574C2.08325 15.5981 2.08325 13.7321 2.08325 10.0002Z" stroke="#03120C" stroke-width="1.25"/>
                <path d="M5.86426 10.0229H11.6772M11.6772 10.0229C11.6772 10.4983 9.88125 12.0957 9.88125 12.0957M11.6772 10.0229C11.6772 9.53513 9.88125 7.96925 9.88125 7.96925M14.1976 6.6626V13.3293" stroke="#03120C" stroke-width="1.25" stroke-linecap="round"/>
                </svg>
                Save and Exit the Exam
              </v-list-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    <v-sheet
      class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent fill-height"
      variant="flat"
    >
      <div v-if="fetching" class="d-flex align-center justify-center h-100 w-100">
        <InitialTest :content-view="0"/>
      </div>
      <div class="d-flex align-center justify-center fill-height w-100 flex-wrap flex-column" v-else-if="['break'].includes(examStore.loader || '')">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else-if="needUpgrade">
        <UpgradePrompt />
      </div>
      <div v-else-if="startExam" class="fill-height">
        <QuestionStack ref="questionStack"
                       :examDetailData="examDetail"
                       :questionList="questions"
                       :introContent="introContent"
                       :examId="examId"
                       :lastAttemptedQuestionId="examStore.examDetail?.last_attempted_question_id"
                       />
      </div>
    </v-sheet>
  </Wrapper>
  <PromptDialog v-bind="prompt" v-if="prompt" />
  <HelpPrompt v-if="dialog" :dialog="dialog" :persistent="persistent" @close-dialog="closeDialog" />
  <Shortcut v-if="shortcutPromt" :dialog="shortcutPromt" :persistent="persistent" @close-dialog="closeDialog" />
  <AssistentPrompt v-if="assistentPrompt" :dialog="assistentPrompt" :persistent="persistent" @close-dialog="closeDialog" />
  <UnschduledBreak v-if="unschduledBreakPrompt" :dialog="unschduledBreakPrompt" @close-dialog="closeDialog" />
  <SaveExit v-if="saveExitPrompt" :dialog="saveExitPrompt" @close-dialog="closeDialog" />
</template>

<script setup>
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { ref, computed, watch, onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import Wrapper from "../ExamWrapper.vue";
import QuestionStack from "@/components/SAT/Question/QuestionStack.vue";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import { onBeforeUnmount } from "vue";
import UpgradePrompt from "@/components/Prompt/UpgradePrompt.vue";
import HelpPrompt from '@/components/SAT/Prompt/ActionModal.vue'
import Shortcut from '@/components/SAT/Prompt/keyboard_shortcuts.vue'
import AssistentPrompt from '@/components/SAT/Prompt/AssistiveTechnology.vue'
import Timer from "@/components/MockTest/Timer.vue";
import {useModuleStore} from "@/stores/Sat/module-store";
import {storeToRefs} from "pinia";
import InitialTest from "@/components/SAT/Question/InitialTest.vue";
import PracticeTestBreak from "@/components/SAT/Question/PracticeTestBreak.vue";
import MathDialog from '@/components/SAT/Prompt/ExpandShrinkWrapper.vue'
import Desmos from "@/components/Calculators/DesmosMT.vue";
import UnschduledBreak from '@/components/SAT/Prompt/UnschduledBreak.vue'
import SaveExit from '@/components/SAT/Prompt/SaveExit.vue'
const moduleStore = useModuleStore();
const questionStack = ref("");
const prompt = ref(null);
const micPrompt = ref(null);
const needUpgrade = ref(false);
const startExam = ref(false);
const route = useRoute();
const router = useRouter();
const examIdParam = parseInt(route.params.id);
const examStore = useExamInstanceStore();
const dialog = ref(false);
const shortcutPromt = ref(false);
const assistentPrompt = ref(false);
const unschduledBreakPrompt = ref(false);
const saveExitPrompt = ref(false);
const persistent = ref(true);
const closeDialog = (status) => {
  dialog.value = status;
  shortcutPromt.value = status;
  assistentPrompt.value = status;
  unschduledBreakPrompt.value = status;
  saveExitPrompt.value = status;
}
const toggleTimer = ref(true);

examStore.reset();
examStore.getExamInstance(examIdParam);
moduleStore.getEachModuleTime(examIdParam);
const {questions, examId, introContent, examDetail} = storeToRefs(examStore)
const {highLightMode} = storeToRefs(examStore)

const highlightModeModule = () => {
  highLightMode.value = !highLightMode.value;
}
const fetching = computed(() => {
  return examStore.fetching;
});
const openHelpDialog = () => {
  dialog.value = true;
}
const showDesmos = ref(false);
const showReference = ref(false);
const expansionWidth = ref(false);
const toggleWidth = (status) => {
  expansionWidth.value = status
}
const showAssistentModal = () => {
  assistentPrompt.value = true;
}
watch(
  examDetail,
  (newVal, oldVal) => {
    if (newVal && newVal.id) {
      if (startExam.value) {
        return false;
      }
      if (!examDetail.value.is_accessible) {
        needUpgrade.value = true;
      }
      if (examDetail.value.is_completed) {
        prompt.value = {
          title: "You have already completed the test!",
          actions: [
            {
              text: "View Results",
              type: "fill",
              onClick: goToEvaluation,
            },
          ],
        };
      } else if (
        examDetail.value.is_attempted &&
        !examDetail.value.is_completed
      ) {
        startExam.value = true;
      } else {
        startExam.value = true;
      }
    }
  },
  { immediate: true, deep: true }
);
onBeforeUnmount(() => {
  examStore.reset();
});

function goToEvaluation() {
  return router.push({
    name: "mockTestEvaluation",
    params: { exam: examId},
  });
}

const isCurrentModuleMath = computed(() => {
  const currentModule = examStore?.examDetail?.exam_attempt_data?.exam_state?.current_section_module;
  return currentModule === 22 || currentModule === 21;
})

const activeStack = computed(() => {
  return questionStack.value?.activeStack?.type;
});

</script>

<style></style>
