import InstituteStudent from "@/pages/Institute/InstituteStudent.vue";
import InstituteVoucher from "@/pages/Institute/InstituteVoucher.vue";
import Store from "@/pages/Institute/Store/Store.vue";
import BuyingVoucherForm from "@/pages/Institute/Store/BuyingVoucherForm.vue";
import Checkout from '@/pages/Student/Packages/Checkout.vue';

const protected_routes = [
  {
    path: "/students",
    component: InstituteStudent,
    name: "ins_student",
  },
  {
    path: "/voucher",
    component: InstituteVoucher,
    name: "ins_voucher",
  },
  {
    path: "/order-history",
    component: Store,
    name: "ins_store",
  },
  {
    path: "/voucher/create-voucher",
    component: BuyingVoucherForm,
    name: "ins_create-voucher",
  },
  {
    path: "/voucher/create-voucher/checkout/:uuid",
    component: Checkout,
    name: "ins_checkout",
  },
];
protected_routes.forEach((r) => {
  r.meta = {
    ...(r.meta || {}),
    layout: 'InstituteLayout',
    requiresAuth: true,
    user_type: 'institute'
  };
});
export default protected_routes;
