<script setup>
import {useNotesStore} from "@/stores/Sat/notes-store";
import {storeToRefs} from "pinia";
import { debounce } from 'lodash';

const props = defineProps({
  examInstanceId:{
    required: true,
  },
  questionId:{
    required: true,
  },
  isQbt:{
    default: false,
  }
})
const noteStore = useNotesStore();
const {wordsArray} = storeToRefs(noteStore);
const {getHighlightedWords , saveHighLightedWords , deleteHighlightedWord} =  noteStore;

if(!props.isQbt){
  // getHighlightedWords(props.examInstanceId ,props.questionId);
}

const onInput = (word) => {
  if(!props.isQbt){
    word.question_id = props.questionId;
    debouncedApiCall(word);
  }
}

const debouncedApiCall = debounce((word) => {
saveHighLightedWords(props.examInstanceId ,word);
}, 1000);

const deleteNote = (word) =>{
  if(!props.isQbt){
    const payload = {
      question_id:props.questionId,
      word:word,
    }
    deleteHighlightedWord(props.examInstanceId ,payload);
  }else{
    const index = wordsArray.value.findIndex((w) => w.word === word);
    if(index > -1){
      wordsArray.value.splice(index, 1);
    }
  }

}
</script>

<template>
  <template v-for="(word, cIndex) in wordsArray" :key="cIndex">
      <v-card variant="flat" class="border kiwi-border-black-3 kiwi-radius-8 kiwi-fs-14" :class="cIndex > 0 ? 'mt-5' : ''" >
        <v-card-title class="text-capitalize d-flex justify-space-between kiwi-fs-14" :style="{backgroundColor: word.color}">
          {{word.word}}
          <button
            type="button"
            class="kiwi-bg-white kiwi-text-black-3 d-flex align-center justify-center"
            style="width: 30px; height: 30px; border-radius: 100%;"
            @click="deleteNote(word.word)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
            <path d="M11.375 3.70834L11.0135 9.55632C10.9211 11.0504 10.875 11.7975 10.5005 12.3346C10.3153 12.6002 10.0769 12.8243 9.80041 12.9927C9.24122 13.3333 8.49275 13.3333 6.99574 13.3333C5.49682 13.3333 4.74734 13.3333 4.18778 12.992C3.91113 12.8233 3.67267 12.5988 3.48756 12.3328C3.11318 11.7949 3.06801 11.0467 2.97769 9.55054L2.625 3.70834" stroke="#081E20" stroke-linecap="round"/>
            <path d="M1.75 3.70832H12.25M9.36582 3.70832L8.96764 2.88683C8.7031 2.34114 8.5708 2.06829 8.34266 1.89813C8.29208 1.86038 8.23848 1.8268 8.18242 1.79773C7.92978 1.66666 7.62656 1.66666 7.02012 1.66666C6.39847 1.66666 6.08767 1.66666 5.83081 1.80323C5.77389 1.8335 5.71957 1.86843 5.66842 1.90767C5.43762 2.08473 5.3087 2.36756 5.05086 2.93322L4.69754 3.70832" stroke="#081E20" stroke-linecap="round"/>
            <path d="M5.5415 10.125V6.625" stroke="#081E20" stroke-linecap="round"/>
            <path d="M8.4585 10.125V6.625" stroke="#081E20" stroke-linecap="round"/>
            </svg>
          </button>
        </v-card-title>
        <v-card-text class="px-0 py-0" style="min-height: 100px;">
          <textarea v-model="word.sub_text" @input="onInput(word)" class="kiwi-bg-white kiwi-text-black-3 px-3 w-100 h-100 py-2" style="border: none; outline: none;"></textarea>
        </v-card-text>
      </v-card>
  </template>
</template>

<style scoped>
.kiwi-bg-white.v-textarea .v-field__input {
  background-color: var(--color-white) !important;
}
</style>
