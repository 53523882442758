<template>
    <div
        :class="'kiwi-border-light-grey-3'" 
        class="border kiwi-border-transparent overflow-hidden kiwi-radius-4 h-100 hover-transform" 
        style="padding: 1px;"
    >
        <v-card
            variant="flat"
            class="kiwi-radius-4 overflow-hidden kiwi-bg-white h-100 "
            style="box-shadow: 0px 8px 30px -4px rgba(16, 24, 40, 0.04);"
        >
            <article class="kiwi-paragraphs-bundle pa-4 pa-md-4 position-relative heading-card">
                <img :src="content.image" alt="" style="height: 57px;">
                <h2 class="font-weight-bold kiwi-fs-20">{{ content.name }}</h2>
                <p class="font-weight-medium kiwi-text-light-black-2 kiwi-fs-14">{{ content.description }}</p>
            </article>
            <v-divider />
            <article class="kiwi-paragraphs-bundle pa-4 pa-md-4">
                <div class="price-wrap font-weight-semi-bold">
                    
                    <div class="d-flex flex-wrap align-center">
                        <div class="currency kiwi-fs-16 mr-2">{{content.currency}}.</div>
                        <div class="mr-2" v-if="content.discount_remarks != null && content.discount_amount != 0">
                            <span class="kiwi-fs-40 font-weight-bold" style="text-decoration: line-through; color: #8E9492;">{{content.price}}</span>
                            <span class="position-relative ml-0 cursor-pointer" style="top: -20px; color: #8E9492;">
                                <v-tooltip text="Discount Via Referral">
                                    <template v-slot:activator="{ props }">
                                        <v-icon class="mdi mdi-information-outline" v-bind="props">Tooltip</v-icon>
                                    </template>
                                </v-tooltip>
                            </span>
                        </div>
                        <div>
                            <span class="kiwi-fs-40 font-weight-bold kiwi-text-black-3">{{content.price - content.discount_amount}}</span>
                            <sub class="kiwi-text-black-2" v-if="content.day">{{ `${content.day} Days` }}</sub>
                        </div>
                    </div>
                </div>
                <v-btn 
                    class="text-none font-weight-semi-bold kiwi-fs-16 py-4 py-md-5"
                    block 
                    variant="flat" 
                   
                    :class="'kiwi-bg-primary kiwi-text-white'"
                    :to="getStart"
                >Get Started</v-btn>
            </article>
            <v-divider />
            <article class="pa-4 pa-md-4 kiwi-fs-14">
                <p>What's included:</p>
                <v-list lines="1" class="kiwi-pricing-feature-list" density="compact">
                    <v-list-item
                            v-for="(item, i) in content.features"
                            :key="i"
                            color="primary"
                            class="px-0 cursor-normal py-0"
                            :title="item.name"
                            :ripple="false"
                        >
                            <template v-slot:prepend>
                                <img src="/assets/checkmark-circle-01.svg" class="mr-2 size-18">
                            </template>
                        </v-list-item>
                </v-list>
            </article>
        </v-card>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import {ref} from 'vue'
import { useAuthStore } from '@/stores/auth/authStore';
const props = defineProps({
    content: {
        type: Object,
        required: true,
    }
})
const {isAgent, user} = useAuthStore()
const getStart = computed(() => {
    if(isAgent && user) {
        return {name: 'agent_create-voucher', query: {pId: props.content.id}}
    }
    return {name: 'register'}
})
</script>

<style lang="scss">
    .kiwi-pricing-feature-list {
        .v-list-item {
            opacity: 1;

            .mdi {
                opacity: 1;
            }
        }
        &.white-list {
            background: transparent !important;

            .v-list-item {
                color: var(--color-white);
            }
        }
        &.black-list {
            background: transparent !important;

            .v-list-item {
                color: var(--color-black);

                .mdi {
                    color: var(--color-primary);
                }
            }
        }
        .v-list-item__spacer {
            width: 15px !important;
        }
    }

    .hover-transform:hover{
        transform: scale(1.05);
        position: relative;
        z-index: 1;
        transition: all 0.5s ease-in-out;
        .v-card{
            border: 1px solid var(--color-primary)
        }
    }
</style>