<template>
  <Wrapper >
    <template v-if="activeStack !== 'initial'"  v-slot:timer>
    </template>
    <template v-if="activeStack !== 'initial'" v-slot:headerAction>
      <button class="d-flex flex-column text-center align-center justify-center mr-3" style="border: none; outline: none;" @click="highlightModeModule" v-if="!isCurrentModuleMath">
        <img src="/assets/Icons.png" height="20" alt="">
        <span class="kiwi-fs-14 font-weight-medium">Highlights & Notes</span>

        <v-tooltip
          activator="parent"
          location="bottom"
        >
          <div>
            <p><strong>Hightlight Text Mode On:</strong></p>
            <p>Select text to create Highlight automatically.</p>
          </div>
        </v-tooltip>
      </button>
      <template v-else>
        <button class="d-flex flex-column text-center align-center justify-center mr-3" style="border: none; outline: none;"  @click="showDesmos = true">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <g clip-path="url(#clip0_12803_3907)">
              <path d="M2.25024 0.37793H14.6252V18.3779H2.25024V0.37793ZM13.5002 17.2529V1.50293H3.37524V17.2529H13.5002ZM12.3752 2.62793V6.00293H4.50024V2.62793H12.3752ZM11.2502 4.87793V3.75293H5.62524V4.87793H11.2502ZM6.18136 8.25293V9.37793H4.50024V8.25293H6.18136ZM7.4426 9.37793V8.25293H9.25576V9.37793H7.4426ZM10.6992 9.37793V8.25293H12.3752V9.37793H10.6992ZM6.18136 11.6279V12.7529H4.50024V11.6279H6.18136ZM7.4426 12.7529V11.6279H9.25576V12.7529H7.4426ZM10.6992 12.7529V11.6279H12.3752V12.7529H10.6992ZM6.18136 15.0029V16.1279H4.50024V15.0029H6.18136ZM7.4426 16.1279V15.0029H9.25576V16.1279H7.4426ZM10.6992 16.1279V15.0029H12.3752V16.1279H10.6992Z" fill="black"/>
            </g>
            <defs>
              <clipPath id="clip0_12803_3907">
                <rect width="18" height="18" fill="white" transform="translate(0.000244141 0.37793)"/>
              </clipPath>
            </defs>
          </svg>
          <span class="kiwi-fs-14 font-weight-medium">Calculator</span>
        </button>
        <MathDialog v-if="showDesmos" title="Calculator" location="left">
          <template v-slot:closeModal>
            <button @click="showDesmos = false"><v-icon class="mdi mdi-close" /></button>
          </template>
          <Desmos class="h-100" />
        </MathDialog>

        <button class="d-flex flex-column text-center align-center justify-center mr-3" style="border: none; outline: none;" @click="showReference = true;">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path d="M0.0012207 16.5696L0.0772656 16.1704L0.647602 15.9993C1.14189 15.8598 1.5538 15.5873 1.88333 15.1818L5.45744 10.8282L3.23313 5.44803C3.03034 4.92839 2.66913 4.59886 2.14949 4.45944L1.94037 4.40241L2.01641 4.00317H6.7502L6.67416 4.40241L6.36998 4.49746C6.12917 4.54816 5.97074 4.65589 5.8947 4.82066C5.81865 4.98542 5.82499 5.17553 5.91371 5.39099L7.4156 9.02214L10.4004 5.31495C10.5398 5.13751 10.5841 4.97275 10.5334 4.82066C10.4954 4.66857 10.3687 4.5545 10.1532 4.47845L9.90606 4.40241L9.98211 4.00317H12.8528L12.7768 4.40241L12.3585 4.51648C11.8515 4.66857 11.4396 4.94106 11.1228 5.33396L7.64373 9.59247L9.88705 15.0487C10.1025 15.543 10.4637 15.8788 10.9707 16.0563L11.2939 16.1704L11.2178 16.5696H6.54108L6.63614 16.1704L6.80724 16.0943C7.27618 15.9169 7.4156 15.5937 7.22548 15.1247L5.68557 11.4175L2.66279 15.1818C2.5107 15.3719 2.46001 15.5493 2.5107 15.7141C2.5614 15.8662 2.70081 15.9739 2.92895 16.0373L3.36621 16.1704L3.29016 16.5696H0.0012207Z" fill="#081E20"/>
            <path d="M13.7737 7.86266L13.9134 7.19025L15.1796 6.03756C15.6454 5.60676 16.0092 5.23999 16.2712 4.93726C16.539 4.62872 16.7282 4.35219 16.8388 4.10768C16.9494 3.85734 17.0047 3.60992 17.0047 3.36541C17.0047 3.06269 16.929 2.83564 16.7777 2.68428C16.6321 2.52709 16.44 2.4485 16.2013 2.4485C15.9917 2.4485 15.8025 2.5038 15.6337 2.61442C15.4707 2.71921 15.3281 2.89386 15.2058 3.13837L14.8652 3.76711H14.6033L14.7517 2.53582C14.938 2.43103 15.1651 2.34662 15.4329 2.28258C15.7007 2.21854 15.9743 2.18652 16.2537 2.18652C16.801 2.18652 17.2288 2.30005 17.5374 2.52709C17.8459 2.74832 18.0002 3.07433 18.0002 3.50513C18.0002 3.82533 17.9012 4.12805 17.7033 4.41331C17.5054 4.69858 17.2201 4.98966 16.8475 5.28657C16.4808 5.58347 16.0441 5.90948 15.5376 6.26461L14.5509 6.98067H17.5112L17.354 7.86266H13.7737Z" fill="#081E20"/>
          </svg>
          <span class="kiwi-fs-14 font-weight-medium">Reference</span>
        </button>
        <MathDialog v-if="showReference" title="Reference:" location="right" @expansionWidth="toggleWidth">
          <template v-slot:closeModal>
            <button @click="showReference = false"><v-icon class="mdi mdi-close" /></button>
          </template>
          <div class="kiwi-paragraphs-bundle">
            <template v-if="expansionWidth">
              <img src="/assets/reference-2.png" class="w-100" alt="">
            </template>
            <template v-else>
              <img src="/assets/reference-1.png" class="w-100" alt="">
            </template>
            <ul style="padding-left: 30px">
              <li>The number of degrees of arc in a circle is 360 degrees.</li>
              <li>The number of radians of arc in a circle is 2π.</li>
              <li>The sum of measures in degrees of the angles of the triangle is 180.</li>
            </ul>
          </div>
        </MathDialog>
      </template>
      <v-menu>
        <template v-slot:activator="{ props }">
          <button v-bind="props" class="d-flex flex-column text-center align-center justify-center" style="border: none; outline: none;">
            <img src="/assets/more-vertical.png" height="20" alt="">
            <span class="kiwi-fs-14 font-weight-medium">More</span>
          </button>
        </template>
        <v-list>
          <v-list-item @click="openHelpDialog">
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item>
          <v-list-item @click="shortcutPromt = true">
            <v-list-item-title>Keyboard Shortcuts</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showAssistentModal">
            <v-list-item-title>Assisted Technology</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-sheet
      class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent fill-height"
      variant="flat"
    >
      <div v-if="fetching" class="d-flex align-center justify-center h-100 w-100">
        <InitialTest :content-view="0"/>
      </div>
      <div class="d-flex align-center justify-center fill-height w-100 flex-wrap flex-column" v-else-if="['break'].includes(examStore.loader || '')">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else-if="needUpgrade">
        <UpgradePrompt />
      </div>
      <div v-else-if="startExam" class="fill-height">
        <QuestionStack ref="questionStack"
                       :examDetailData="examDetail"
                       :questionList="questions"
                       :examId="examIdParam"
                       :lastAttemptedQuestionId="examDetail?.last_attempted_question_id"
                       :take-from-props="true"
                       :exam-type="'test'"
        />
      </div>
    </v-sheet>
  </Wrapper>
  <PromptDialog v-bind="prompt" v-if="prompt" />
  <HelpPrompt v-if="dialog" :dialog="dialog" :persistent="persistent" @close-dialog="closeDialog" />
  <Shortcut v-if="shortcutPromt" :dialog="shortcutPromt" :persistent="persistent" @close-dialog="closeDialog" />
  <AssistentPrompt v-if="assistentPrompt" :dialog="assistentPrompt" :persistent="persistent" @close-dialog="closeDialog" />
</template>

<script setup>
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { ref, computed, watch, onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import Wrapper from "../ExamWrapper.vue";
import QuestionStack from "@/components/SAT/Question/QuestionStack.vue";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import { onBeforeUnmount } from "vue";
import UpgradePrompt from "@/components/Prompt/UpgradePrompt.vue";
import HelpPrompt from '@/components/SAT/Prompt/ActionModal.vue'
import Shortcut from '@/components/SAT/Prompt/keyboard_shortcuts.vue'
import AssistentPrompt from '@/components/SAT/Prompt/AssistiveTechnology.vue'
import Timer from "@/components/MockTest/Timer.vue";
import {useModuleStore} from "@/stores/Sat/module-store";
import {storeToRefs} from "pinia";
import InitialTest from "@/components/SAT/Question/InitialTest.vue";
import MathDialog from '@/components/SAT/Prompt/ExpandShrinkWrapper.vue'
import Desmos from "@/components/Calculators/DesmosMT.vue";
import {useQuestionBasedTestStore} from "@/stores/questionbased-test-store";


const moduleStore = useModuleStore();
const questionStack = ref("");
const prompt = ref(null);
const micPrompt = ref(null);
const needUpgrade = ref(false);
const startExam = ref(false);
const route = useRoute();
const router = useRouter();
const examIdParam = parseInt(route.params.id);
const examStore = useExamInstanceStore();
const dialog = ref(false);
const shortcutPromt = ref(false);
const assistentPrompt = ref(false);
const persistent = ref(true);
const closeDialog = (status) => {
  dialog.value = status;
  shortcutPromt.value = status;
}
const toggleTimer = ref(false);

const questionBasedTestStore = useQuestionBasedTestStore();
questionBasedTestStore.reset()
questionBasedTestStore.initTestInstance(examIdParam);

const {highLightMode} = storeToRefs(examStore)

const highlightModeModule = () => {
  highLightMode.value = !highLightMode.value;
}
const fetching = computed(() => {
  return questionBasedTestStore.fetching;
});
const openHelpDialog = () => {
  dialog.value = true;
}
const showDesmos = ref(false);
const showReference = ref(false);
const expansionWidth = ref(false);
const toggleWidth = (status) => {
  expansionWidth.value = status
}
const showAssistentModal = () => {
  assistentPrompt.value = true;
}
const examDetail = computed(() => {
  return questionBasedTestStore.testDetail;
});

watch(
  examDetail,
  (newVal, oldVal) => {
    if (newVal && newVal.id) {
      if (startExam.value) {
        return false;
      }
      if (!examDetail.value.is_accessible) {
        needUpgrade.value = true;
      } else if (examDetail.value.is_completed) {
        prompt.value = {
          title: "You have already completed the test!",
          actions: [
            {
              text: "View Results",
              type: "fill",
              onClick: goToEvaluation,
            },
          ],
        };
      } else if (
        examDetail.value.is_attempted &&
        !examDetail.value.is_completed
      ) {
        prompt.value = {
          title: "Test Resumed",
          content: "Click <strong>“Continue”</strong> to start the test",
          actions: [
            {
              text: "Continue",
              type: "fill",
              onClick: () => {
                prompt.value = null;
                startExam.value = true;
              },
            },
          ],
        };
      } else {
        startExam.value = true;
      }
    }
  },
  { immediate: true, deep: true }
);

onBeforeUnmount(() => {
  examStore.reset();
});

function goToEvaluation() {
  return router.push({
    name: "mockTestEvaluation",
    params: { exam: examId},
  });
}

const isCurrentModuleMath = computed(() => {
  const currentModule = examStore?.examDetail?.exam_attempt_data?.exam_state?.current_section_module;
  return currentModule === 22 || currentModule === 21;
})

const activeStack = computed(() => {
  return questionStack.value?.activeStack?.type;
});

const questions = computed(() => {
  return questionBasedTestStore.questions;
})

</script>

<style></style>
