<template>
<section class="kiwi-tutorial outer-layer position-wrapper position-fixed w-100 h-100" style="top: 0; left: 0;">
    <!-- <div class="overlay-layer overlay-bg position-absolute z-1 w-100 h-100" style="top: 0; left: 0;"></div> -->
    <div class="circle" id="exam-circle" :style="{top: `${circlePosition.x}px`, right: `${circlePosition.y}px`}">
    </div>
    <div class="popup-layer position-absolute kiwi-radius-8" :style="{right: `calc(${circlePosition.y}px - 40px)`}">
        <div class="px-4">
            <div class="header d-flex align-center py-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none" class="mr-2">
                    <g filter="url(#filter0_d_12399_8235)">
                    <g filter="url(#filter1_i_12399_8235)">
                    <path d="M26.7597 18.4998C26.7597 13.8975 23.0287 10.1665 18.4264 10.1665C13.824 10.1665 10.093 13.8975 10.093 18.4998C10.093 23.1022 13.824 26.8332 18.4264 26.8332C23.0287 26.8332 26.7597 23.1022 26.7597 18.4998Z" fill="#16885B"/>
                    </g>
                    <path d="M26.7597 18.4998C26.7597 13.8975 23.0287 10.1665 18.4264 10.1665C13.824 10.1665 10.093 13.8975 10.093 18.4998C10.093 23.1022 13.824 26.8332 18.4264 26.8332C23.0287 26.8332 26.7597 23.1022 26.7597 18.4998Z" stroke="#ADE6AD" stroke-width="1.5"/>
                    <path d="M18.4264 15.1665V21.8332M21.7597 18.4998H15.093" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <filter id="filter0_d_12399_8235" x="-0.57373" y="-0.5" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_12399_8235"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="4"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.109804 0 0 0 0 0.662745 0 0 0 0 0.447059 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12399_8235"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12399_8235" result="shape"/>
                    </filter>
                    <filter id="filter1_i_12399_8235" x="9.34302" y="9.4165" width="18.1667" height="22.1665" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.125 0 0 0 0 0.6 0 0 0 0 0.413393 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12399_8235"/>
                    </filter>
                    </defs>
                </svg>
                <span class="kiwi-fs-18 font-weight-bold kiwi-text-black-3">Change the Exam</span>
            </div>
            <v-divider />
            <p class="kiwi-fs-14 kiwi-text-light-black-3 py-3">Click on the Select Exam and choose your most preferred type of exam you would like to practice. You also can change it later.</p>
            <v-divider />
            <img src="/assets/changeExamTypeContent.png" class="w-100">

        </div>
        <v-divider />
        <v-btn block class="w-100 kiwi-button kiwi-bg-white kiwi-black-3 text-center align-center justify-center text-none py-2\4 px-3" size="large" variant="flat" @click="$emit('dismissIntro')">
            <v-icon class="mdi mdi-close kiwi-bg-black-3 kiwi-text-white mr-2 " style="width: 20px; height: 20px; border-radius: 100%; font-size: 12px;" />
            Dismiss
        </v-btn>
    </div>
</section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const circlePosition = ref({
    x: 0, y: 0
})
const calculatePos = () => {
    const el = document.getElementById("exam-type-btn");
    if(!el) {
        return false;
    }
    const circle = document.getElementById('exam-circle');
    const circleWidth = circle.offsetWidth
    const rect = el.getBoundingClientRect();
    const topPosition = 0;
    const buttonWidth = rect.right - rect.left
    const rightPosition = document.documentElement.clientWidth - rect.right + window.scrollX;
    const centerPosition = rightPosition + (buttonWidth / 2)
    circlePosition.value = {
        x: topPosition - (circleWidth / 2.2),
        y: centerPosition - (circleWidth / 2)
    }
}
const body = document.body;
onMounted(() => {
    calculatePos()
    body.style.height = '100vh';
    body.style.overflowY = 'hidden';
})
onBeforeUnmount(() => {
    body.style = '';
})
</script>

<style lang="scss" scoped>
.kiwi-tutorial.outer-layer {
    z-index: 9999;
    .circle {
        width: 220px;
        height: 220px;
        position: absolute;
        border: 1px solid var(--color-primary);
        top: 0;
        right: 15vw;
        border-radius: 100%;
        box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 1px 2px, rgba(0, 0, 0, 0.5) 0px 0px 0px 50000px;
    }
    .popup-layer {
        top: 140px;
        width: 328px;
        height: 348px;
        background-color: white;
        box-shadow: 0px 4px 6px -2px #10182808;
        box-shadow: 0px 12px 16px -4px #10182814;
        
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            top: -10px;
            right: 140px;
            width: 0;
            height: 0;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-bottom: 12px solid var(--color-white);
            // box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
        }
    }
    .img-layer {
        top: 0;
        right: 15vw;
    }
}
</style>