<template>
    <Alert :max-width="460" :persistent="!closable">
        <article class="text-center pa-4 pa-md-6">
            <v-btn icon flat class="text-center justify-center align-center" style="background: linear-gradient(95.8deg, rgba(233, 175, 89, 0.1) -47.68%, rgba(232, 67, 86, 0.1) 125.55%);"><v-icon class="la la-crown"></v-icon></v-btn>
            <h4 class="kiwi-fs-24 font-weight-bold mt-3">Upgrade Now To Access The Contents</h4>
            <div class="font-weight-medium prmt-content mt-2"> Upgrade your plan today to unlock premium features and contents.</div>
        </article>
        <v-divider />
        <v-row class="text-center pa-4 pa-md-6">
            <v-col cols="12" md="12">
                <v-btn 
                    block
                    size="large"
                    variant="flat"
                    class="kiwi-bg-upgrade kiwi-text-white kiwi-radius-4 text-none"
                    @click.prevent="upgrade"
                > <v-icon class="la la-crown mr-2"></v-icon> Upgrade </v-btn>
            </v-col>
        </v-row>
    </Alert>
</template>

<script setup>
import {ref, defineProps} from 'vue'
import Alert from '@/components/Prompt/ConfirmAlert.vue'
import { useTestAction } from '@/composables/useTestAction';
const dialog = ref(true)
const testAction = useTestAction()
function upgrade () {
    testAction.upgrade()
}
</script>
<style>
.prmt-content strong {
    font-weight: bold !important;
}
</style>