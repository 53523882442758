<template>
  <div class="steps mt-3">
    <div
      v-for="(step, index) in totalStep"
      :key="index"
      :class="['step', { 'step-color': step <= activeStep }]"
    ></div>
  </div>
</template>

<script setup>
import { ref, defineExpose, defineProps, watch } from "vue";
const props = defineProps(["recording", "playing"]);
const data = ref(0);
const activeStep = ref(0);
const totalStep = ref(10);

let intervalId = null;
const updateSteps = () => {
  if (activeStep.value > totalStep.value) {
    activeStep.value = 1;
  } else {
    activeStep.value += 1;
  }
};
const clear = (clearField) => {
  intervalId && clearInterval(intervalId);
  if(clearField){
    activeStep.value = 0;
  }
};

const startInterval = () => {
  activeStep.value = 0;
  intervalId = setInterval(() => {
    updateSteps();
  }, 500);
};
watch(
  () => props.recording,
  (val, old) => {
    if (val) {
      startInterval();
    } else {
      clear(false);
    }
  }
);
watch(
  () => props.playing,
  (val, old) => {
    if (val) {
      startInterval();
    } else {
      clear(true);
    }
  }
);

defineExpose({ startInterval });
</script>

<style>
.steps {
  display: flex;
  flex-flow: wrap row;
  grid-column-gap: 5px;
  height: 5px;
  overflow: hidden;
}

.step {
  border-radius: 4px;
  background-color: #e6e7e7;
  height: 5px;
  width: 20px;
  transition: background 0.2s fade-in-out;
}

.step.step-color {
  background-color: var(--color-primary);
  animation: cambioColor 1s linear;
}

@keyframes cambioColor {
  0% {
    background-position-x: 99%;
  }
  100% {
    background-position-x: 100%;
  }
}
</style>
