<template>
    <prompt max-width="500" @on-close="closeDialog" :persistent="requestSuccess">
        <template v-if="!requestSuccess ">
            <div class="pa-4 pt-8 text-center">
                <img src="/assets/dustbin.svg" alt="">
                <h2 class="font-weight-bold kiwi-fs-24">Do you want to proceed?</h2>
                <p style="font-size: 16px; text-align: center; margin-top: 10px;" class="kiwi-text-light-black-2">Deleting your account will remove all your information from the database.</p>
            </div>
            <v-divider />
            <div class="pa-4 d-flex flex-wrap" style="grid-gap: 20px">
                <v-btn
                    variant="outlined"
                    class="flex-md-grow-1 text-center kiwi-bg-white kiwi-text-black text-none kiwi-fs-16"
                    size="large"
                    @click.prevent="closeDialog"
                    style="border-color: #DBE1E0;"
                >Cancel</v-btn>
                <v-btn
                    variant="flat"
                    class="flex-md-grow-1 text-center text-none kiwi-fs-16"
                    size="large"
                    @click.prevent="reqeustDelete"
                    :class="'kiwi-bg-error kiwi-text-white'"
                    v-html="deleteBtn"
                ></v-btn>
            </div>
        </template>
        <template v-else>
            <div class="pa-4 pt-8 text-center">
                <img src="/assets/check.svg" alt="">
                <h2 class="font-weight-bold kiwi-fs-24">Your request has been submitted. It may take 24 hours or more to process your request.</h2>
            </div>
            <v-divider />
            <div class="pa-4 text-center">
                <v-btn
                variant="flat"
                block
                class="flex-md-grow-1 kiwi-bg-primary kiwi-text-white text-center text-none kiwi-fs-16"
                size="large"
                :to="{name:'dashboard'}"
            >Go to Dashboard</v-btn>
            </div>
        </template>
    </prompt>
</template>

<script setup>
import Prompt from '@/components/Prompt/ConfirmAlert.vue'
import { api } from '@/plugins/axios';
import {ref} from 'vue'

const emit = defineEmits(['closeModal'])

const requestSuccess = ref(false)
const deleteBtn = ref('Delete')

const closeDialog = () => {
    emit('closeModal')
}

const reqeustDelete = async () => {
    deleteBtn.value = 'Processing...'
    setTimeout(() => {     
        api.post('user/delete-account-request/')
        requestSuccess.value = true
        deleteBtn.value = 'Delete'
    }, 1000);
}
</script>

<style>

</style>