3<template>
  <Wrapper>
    <template v-slot:headerAction>
      <v-chip
        label
        class="kiwi-radius-4 kiwi-bg-white border kiwi-border-light-grey-3 kiwi-text-black font-weight-bold my-2 mr-2"
        variant="flat"
        v-if="questionStack && questionStack.currentQuestionStatus"
      >
        <img
          src="/assets/Question Based Tests/Reading/layers-01.svg"
          class="mr-2"
          alt=""
        />
        <span
          >{{ questionStack.currentQuestionStatus.current }} of
          {{ questionStack.currentQuestionStatus.total }}</span
        >
      </v-chip>
      <Timer :examType="'test'" />
    </template>
    <v-sheet
      class="kiwi-my-practice-page kiwi-test-black w-100 pa-0 kiwi-radius-8 overflow-hidden kiwi-bg-transparent"
      variant="flat"
    >
      <div v-if="fetching">
        <PromptDialog
          v-bind="{ content: 'Please Wait..', progress: true, width: 300 }"
        />
      </div>
      <div v-else-if="needUpgrade">
        <UpgradePrompt />
      </div>
      <div v-else-if="startExam">
        <QuestionStack
          :examType="'test'"
          ref="questionStack"
          :examInstance="instanceData"
        />
      </div>
    </v-sheet>
  </Wrapper>
  <PromptDialog v-bind="micPrompt" v-if="micPrompt" />
  <PromptDialog v-bind="prompt" v-if="prompt" />
</template>

<script setup>
import { useQuestionBasedTestStore } from "@/stores/questionbased-test-store";
import { ref, computed, watch, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import Wrapper from "../ExamWrapper.vue";
import QuestionStack from "@/components/Question/QuestionStack.vue";
import PromptDialog from "@/components/Prompt/PromptDialog.vue";
import UpgradePrompt from "@/components/Prompt/UpgradePrompt.vue";
import { useMicrophone } from "@/composables/useMicrophone";

const { microphoneAccess, requestMicrophonePermission } = useMicrophone();
const micPrompt = ref(null);
const questionStack = ref("");
const startExam = ref(false);
const prompt = ref(null);
const needUpgrade = ref(false);
const route = useRoute();
const router = useRouter();
const examId = parseInt(route.params.id);
const questionBasedTestStore = useQuestionBasedTestStore();
questionBasedTestStore.reset()
questionBasedTestStore.initTestInstance(examId);
const contentView = ref(0);
const instanceData = computed(() => {
  return {
    questions: questionBasedTestStore.questions,
    id: questionBasedTestStore.testId,
    introContent: {},
    lastAttemptedQuestionId:
      questionBasedTestStore.testDetail?.last_attempted_question_id,
    examDetail: questionBasedTestStore.testDetail,
  };
});
const examDetail = computed(() => {
  return questionBasedTestStore.testDetail;
});
watch(
  examDetail,
  (newVal, oldVal) => {
    if (newVal && newVal.id) {
      if (startExam.value) {
        return false;
      }
      if (!examDetail.value.is_accessible) {
        needUpgrade.value = true;
      } else if (examDetail.value.is_completed) {
        prompt.value = {
          title: "You have already completed the test!",
          actions: [
            {
              text: "View Results",
              type: "fill",
              onClick: goToEvaluation,
            },
          ],
        };
      } else if (
        examDetail.value.is_attempted &&
        !examDetail.value.is_completed
      ) {
        prompt.value = {
          title: "Test Resumed",
          content: "Click <strong>“Continue”</strong> to start the test",
          actions: [
            {
              text: "Continue",
              type: "fill",
              onClick: () => {
                prompt.value = null;
                startExam.value = true;
              },
            },
          ],
        };
      } else {
        startExam.value = true;
      }
    }
  },
  { immediate: true, deep: true }
);
const fetching = computed(() => {
  return questionBasedTestStore.fetching;
});
onBeforeUnmount(() => {
  questionBasedTestStore.reset();
});
function goToEvaluation() {
  return router.push({
    name: "QBTEvaluation",
    params: { test: examDetail.value.id },
  });
}
watch(
  microphoneAccess,
  (newVal, oldVal) => {
    if (newVal && newVal === "denied") {
      startExam.value = false;
      micPrompt.value = {
        title: "Opps!",
        content:
          "Looks like we can't detect a microphone. Please check your settings and ensure your microphone is connected and allowed!",
        actions: [
          {
            text: "Ok",
            type: "fill",
            onClick: () => {
              startExam.value = true;
              micPrompt.value = null;
            },
          },
        ],
      };
    }
  },
  { immediate: true, deep: true }
);
</script>

<style></style>
