<template>
  <div>
    <div v-if="!listViewWithSelect">
      <AHeadline :text="'Options:'" />
      <div class="kiwi-paragraphs-bundle mb-2">
          <ol class="ans-ol">
              <li v-for="(option, i) in optionList" :key="i">
                  <span v-html="option.text"
                  class="opt-text"
                  :class="{
                      'err-mark': !option.correct && option.selected,
                      'right-mark': option.correct
                  }"
                  ></span>
                  <span v-if="option.selected" class="your-ans"> (Your Answer)</span>
              </li>
          </ol>
      </div>
    </div>
    <div v-else>
      <v-radio-group v-model="selectedOptions[0]" >
        <v-radio
          v-for="(option, i) in optionList"
          :key="i"
          class="v-radio-focus-white mb-3"
          :value="option.id"
          :disabled="true"
          >
          <template v-slot:label>
            
            <template v-if="dictionaryCondition">
              <span class="d-flex flex-wrap text-black " :class="{ 
                'err-mark': !option.correct && option.selected,
                        'right-mark': option.correct,
              }">
                    <span 
                      v-for="(word, index) in option.words"
                      :key="index"
                      class="cursor-pointer iqt-fib-words"  :class="{
                        
                        'kiwi-text-primary': (selectedWords === word && selectedIndex === `${i}-${index}`)
                    }" 
                    @click="handleClick(word, index, i)"
                    style="font-weight: 400;">
                    {{word}}
                  </span>
              </span>
            </template>
            <template v-else>
              <span class="d-flex flex-wrap text-black"  :class="{
                      'err-mark': !option.correct && option.selected,
                      'right-mark': option.correct
                  }" style="font-weight: 400;" v-html="`${removeTags(option.text)}`"></span>
            </template>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
    <DictionaryDialog v-if="dialog && !['swt' , 'we' , 'sst', 'mcm-l', 'mcq', 'mcm-r', 'mcs', 'smw'].includes(slug)" @close-dialog="cloeDialogModal" :word="word" :returnData="dictionaryStore?.dictonaryData" />
  </div>
</template>
<script setup>
import {computed, ref} from "vue";
import AHeadline from "@/components/Evaluation/MockTests/Answers/AHeadline.vue";
import { useIndividualQustionsDictonaryStore } from "@/stores/individual-question-dictionary";
import DictionaryDialog from "@/components/IndividualQuestion/DIctionaryDialog.vue";
import { storeToRefs } from "pinia";
import { removeTags } from '@/utils/utils';
const props = defineProps({
    options: {
        required: true,
        type: Array
    },
    obtained: {
        required: true,
        type: Array
    },
    listViewWithSelect: {
      required: false,
      type: Boolean,
      default: false
    },
    dictionaryCondition: {
      type: Boolean,
      default: false,
    }
})
const dialog = ref(false);
const word = ref('');
const selectedWords = ref('');
const selectedIndex = ref('');
const dictionaryStore = useIndividualQustionsDictonaryStore();
const { getDictonaryData, dictonaryData } = storeToRefs(dictionaryStore)

const selectedOptions = ref(props.obtained.filter(obt => obt.was_selected).map(obt => obt.id));

const optionList = computed(() => {
    return props.options.map(o => {
        const selected = props.obtained.find(obt => obt.id === o.id)
        return {
            id: o.id,
            text: removeTags(o.option),
            words: removeTags(o.option).split(/(?:\s|&nbsp;| )+/),
            correct: selected?.is_correct,
            selected: selected?.was_selected,
        }
    })
})
const handleClick = (word, index, i) => {
    if(props.dictionaryCondition) {
        let text = word;
        
        if(word.split('.')) {
            text = word.split(/[^a-zA-Z]+/);
            text = text.filter(word => word);
        } else {
            text = word;
        }

        selectedWords.value = word
        selectedIndex.value = `${i}-${index}`

        dictionaryStore.getDictonaryData(text).then(res => {
            dialog.value = true;
            word.value = text;
        });
    }
}

const cloeDialogModal = () => {
  dialog.value = false
  word.value = ''
  selectedWords.value = ''
}
</script>
<style lang="scss" scoped>
.ans-ol {
    padding-left: 15px;
}
.opt-text {
    padding: 2px 2px;
}
.err-mark {
    color: rgba(217, 45, 32, 1);
    background-color: rgba(254, 228, 226, 1);
}
.right-mark {
    color: rgba(3, 152, 85, 1);
    background-color: rgba(202, 246, 229, 1);
}
.your-ans {
    color: rgba(228, 168, 59, 1);
}

.iqt-fib-words {
  &:hover {
    color: var(--color-primary);
  }
  & + .iqt-fib-words {
    margin-left: 10px;
  }
}

.v-selection-control {
  opacity: 1;
  pointer-events: auto;
}
</style>
