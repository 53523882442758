import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useDashboardStore = defineStore('scoreTarget' , () =>{
    const streams  = ref([]);
    const isFirstLogin = ref(false);
    const dashboardData  = ref(null);
    async function setTargetScore(paylaod){
      const res  = await  api.post('answer/set-target-score/', paylaod) 
      return res;
    }
    async function updateTargetScore(payload){
        const res =  await api.put('answer/target-score/', {target_score: payload.target_score});
        return res
    }
    async function getTargetScoreList(){
        const res = await api.get('answer/all-target_score/')
        return res
    }
    function getStreams(){
        api.get('user/streams/').then(res=>{
            streams.value = res.data;
        }).catch(error =>{

        })
    }
    function changeStream(streamId){
        try {
            const res = api.post('user/change-stream/', {stream_id: streamId})
            return res
        } catch (error) {
            throw error
        }
    }
    async function getDashboardData() {
        try {
            const response = await api.get('home', {
                params: {
                    fields: 'children',
                },
            });
            dashboardData.value = response.data
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    }
    return {streams , getStreams , setTargetScore , updateTargetScore , getDashboardData , dashboardData, getTargetScoreList, changeStream, isFirstLogin}
})