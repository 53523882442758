<template>
  <v-dialog width="500px" v-model="props.dialog" persistent
    >
    <v-sheet
      class="kiwi-bg-white kiwi-radius-8 position-relative"
      style="box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1)"
    >
      <v-icon size="24" class="mdi mdi-close-circle-outline position-absolute cursor-pointer kiwi-text-primary"
        @click.prevent="closeDialog"
        v-if="isUpdate"
        style="right: 10px; top: 10px;"
      ></v-icon>
      <div class="pa-4 pa-md-8 text-center">
        <div class="kiwi-fs-28 font-weight-bold">
          {{ !(isUpdate || !initialView) ? "Select your exam type" : "Set target score" }}
        </div>
        <v-form class="mt-4">
          <div v-if="!isUpdate && initialView ">
            <div class="kiwi-form-group form-gorup">
              <v-select
                class="kiwi-form-input kiwi-radius-4 kiwi-text-black"
                variant="outlined"
                hide-details
                v-model="formData.stream"
                :items="streams"
                placeholder="Select One"
                item-title="name"
                item-value="id"
                :rules="[rules.required]"
              >
              </v-select>
            </div>
            <div class="kiwi-button-wrapper mt-4">
              <v-btn
                block
                class="kiwi-bg-primary text-none kiwi-text-white kiwi-button kiwi-radius-8 kiwi-fs-16 font-weight-semi-bold"
                size="x-large"
                :disabled="!formData.stream"
                :class="
                  !formData.stream ? 'kiwi-button-disable kiwi-bg-disabled' : ''
                "
                @click.prevent="showNextView"
                >Next</v-btn
              >
            </div>
          </div>
          <div v-else>
            <v-item-group selected-class="bg-primary">
              <v-row>
                <v-col
                  v-for="(score, i) in targetScores"
                  :key="i"
                  cols="12"
                  md="6"
                >
                  <v-item>
                    <v-card
                      class="kiwi-bg-light-grey-5 d-flex align-center justify-center position-relative"
                      variant="flat"
                      height="60"
                      dark
                      @click="toggle(score)"
                      style="border: 1px solid #e2e6e4"
                    >
                      <img
                        v-if="score == formData.target_score"
                        class="position-absolute z-1"
                        src="/assets/targetScoreSelcted.svg"
                        alt=""
                        style="top: 10px; right: 10px"
                      />
                      <div
                        class="kiwi-fs-18 font-weight-semi-bold flex-grow-1 text-center"
                      >
                        {{ score }} {{ satScoreIcon }}
                      </div>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
            <div class="kiwi-button-wrapper mt-4">
              <v-row>
                <!-- <v-col cols="12" md="6">
                  <v-btn
                    v-if="!props.isUpdate"
                    block
                    class="kiwi-bg-white  text-none kiwi-text-black kiwi-button kiwi-radius-8 kiwi-fs-16 font-weight-semi-bold"
                    size="large"
                    variant="flat"
                    style="border: 1px solid #dbe1e0"
                    @click.prevent="backToInitialView"
                    >Back</v-btn
                  >
                </v-col> -->
                <v-col cols="12" md="12">
                  <v-btn
                    v-if="!props.isUpdate"
                    variant="flat"
                    block
                    class="kiwi-bg-primary text-none kiwi-text-white kiwi-button kiwi-radius-8 kiwi-fs-16 font-weight-semi-bold"
                    size="large"
                    :disabled="
                      !formData.target_score || formData.target_score === ''
                    "
                    :class="
                      !formData.target_score || formData.target_score === ''
                        ? 'kiwi-button-disable kiwi-bg-disabled'
                        : ''
                    "
                    @click.prevent="setTargetScoreForStream"
                    :loading="submiting"
                    >Next</v-btn
                  >
                </v-col>
                <v-btn
                    v-if="props.isUpdate"
                    block
                    class="kiwi-bg-primary text-none kiwi-text-white kiwi-button kiwi-radius-8 kiwi-fs-16 font-weight-semi-bold"
                    size="large"
                    variant="flat"
                    style="border: 1px solid #dbe1e0"
                    @click="updateTarget"
                    >Update</v-btn
                  >
              </v-row>
            </div>
          </div>
        </v-form>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script setup>
import { ref, onMounted, reactive , watchEffect} from "vue";
import { useDashboardStore } from "@/stores/dashboard-store";
import { storeToRefs } from "pinia";
import { useValidation } from "@/composables/useValidation";
import { useConfirmer } from "@/stores/confirmer";
import { useAuthStore } from "@/stores/auth/authStore";
const dashboardStore = useDashboardStore()
const {updateTargetScore, getTargetScoreList} = dashboardStore;
const props = defineProps({
    dialog: {
        type: Boolean,
        default: false,
    },
    isUpdate:{
      type:Boolean,
      default:false
    },
    targetScore:{
      default:null
    },
});
const emit = defineEmits(['update:dialog']);
const confirmer = useConfirmer();
const { rules } = useValidation();
const store = useDashboardStore();
const { streams } = storeToRefs(store);
const { getStreams, setTargetScore ,getDashboardData } = store;
const authStore = useAuthStore();
const {user, satScoreIcon} = storeToRefs(authStore)

const initialView = ref(false);
const submiting = ref(false);

const formData = reactive({});

const showNextView = () => {
  initialView.value = false;
};

const targetScores = ref([]);

const toggle = (score) => { 
  formData.target_score = score;
};

const backToInitialView = () => {
  initialView.value = true;
};

const getTargetScores = async () => {
  const response = await getTargetScoreList()
  targetScores.value = response.data.target_scores
}

const setTargetScoreForStream = async () => {
  try {
    submiting.value = true;
    const res =await setTargetScore(formData);
    formData.value = {};
    submiting.value = false;
    getDashboardData()
    emit('update:dialog', false);
    confirmer.openSnackBar({ type: "success",msg:'Target Score Updated Successfully' });
  } catch (err) {
    submiting.value = false;
    confirmer.openSnackBar({ type: "error", msg: err.response.dasetTargetScoreForStreamta.data[0] });
  }
};
const closeDialog = () => {
  emit('update:dialog', false);
}
const updateTarget = async () =>{
  try{
    submiting.value = true;
    const res = await updateTargetScore(formData)
    submiting.value = false
    getDashboardData()
    confirmer.openSnackBar({ type: "success",msg:'Target Score Updated Successfully' });
    emit('update:dialog', false);
  }catch(err){
    submiting.value = false
    confirmer.openSnackBar({ type: "error", msg: 'Something Went Wrong Please Try Again' });
  }
}
onMounted(() => {
  if(!props.isUpdate){
    getStreams(); 
  }
  formData.stream = user.value.stream.id
  getTargetScores()
});
watchEffect(() => {
  if (props.targetScore && props.targetScore.value !== null) {
    toggle(props.targetScore)
  }
});
</script>

<style></style>
