<template>
  <div class="fill-height">
    <v-app-bar
      class="kiwi-bg-light-grey-5 kiwi-text-black px-3 kiwi-border-b-1 kiwi-border-light-grey-3 position-fixed w-100 top-0"
      elevation="0"
      density="comfortable"
      height="68"
    >
      <div class="d-flex align-center">
        <img
          :src="institute.logo"
          class="mr-2 d-none d-lg-block"
          alt="kiwi logo"
          style="height: 40px; width: auto; object-fit: contain;"
        />
        <img
          :src="institute.favicon"
          class="mr-2 d-lg-none"
          alt="kiwi logo"
          style="height: 40px; width: auto; object-fit: contain;"
        />
        <span class="slash mr-2">|</span>
        <span class="font-weight-semi-bold">{{ examName }}</span>
      </div>
      <v-spacer></v-spacer>
      <slot name="headerAction" />
    </v-app-bar>
    <div style="padding-bottom: 85px; height: 100%">
      <div
        class="px-5 py-3 w-100 fill-height"
        style="min-height: calc(100vh - 170px) !important"
      >
        <v-container
          class="kiwi-bg-light-grey-6 fill-height align-start kiwi-radius-6 py-5 py-md-6 px-8 px-md-8"
        >
          <slot />
        </v-container>
      </div>
      <!-- <v-divider></v-divider>
        <v-footer>
            <v-container class="d-flex px-0" variant="flat">
                <slot name="actionButtons" />
            </v-container>
        </v-footer> -->
    </div>
  </div>
</template>

<script setup>
import { useExamInstanceStore } from "@/stores/examInstance-store";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useInstituteStore } from '@/stores/institute/institute-store';
const institute = useInstituteStore()
const route = useRoute();
const examInstanceStore = useExamInstanceStore();
const questionBasedStore = useQuestionBasedTestStore();
import { useExamTimerInstanceStore } from "@/stores/examTimerInstance-store";
import { useRoute } from "vue-router";
import { useQuestionBasedTestStore } from "@/stores/questionbased-test-store";
const timer = useExamTimerInstanceStore();
timer.clearTimer()
const examName = computed(() => {
  if(route.name === 'questionbasedtest') {
    return questionBasedStore.testDetail
    ? questionBasedStore.testDetail.title
    : "";
  }
  return examInstanceStore.examDetail
    ? examInstanceStore.examDetail.description
    : "";
});
</script>
