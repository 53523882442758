<template>
    <WebLayout page="privacy" :isGradientBgTop="false">
        <Banner mainClass="kiwi-blog-inner-banner py-12" :content="bannerContent" />
        <section class="">
            <v-container>
                <div class="kiwi-paragraphs-bundle" v-html="privacyContent"></div>

            </v-container>
        </section>
        
    </WebLayout>
</template>

<script setup>
import WebLayout from '../WebLayout.vue'
import Banner from '../Addon/InnerBanner.vue'
import {api, apiWithoutAuth} from '@/plugins/axios'
import {ref} from 'vue'

const bannerContent = ref({})
bannerContent.value = {
    title: 'Privacy Policy',
}
const privacyContent = ref('')
try {
    const response = apiWithoutAuth.get('common/project-config/privacy-policy/only');
    response.then(res => {
        privacyContent.value = res.data['privacy-policy']
    })
} catch(err) {
    console.log(err)
}
</script>