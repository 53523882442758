<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card
        elevation="0"
        variant="flat"
        v-bind="props"
        class="overflow-visible"
      >
        <div
          ref="sliderContainer"
          id="sound-slider__container"
          class="justify-end position-relative overflow-visible"
        >
          <div
            class="vertical-slider position-absolute"
            style="top: -100px; z-index: 1"
          >
            <input
              v-if="isHovering"
              type="range"
              v-model="volume"
              class="vertical answer-audio-recorder-slider slider"
              min="0"
              max="100"
              @input="handleRangeUpdate"
              :style="
                volume
                  ? {
                      background: `linear-gradient(to right, rgb(104, 113, 109) ${volume}%, rgb(212, 215, 214) ${volume}%)`,
                    }
                  : ''
              "
            />
          </div>
          <v-icon @click="toggleMute" class="sound-vol-icon">{{ soundIcon }}</v-icon>
        </div>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import * as Tone from "tone";

const volume = ref(80);
const lastVolume = ref(80);
const sliderContainer = ref(null);
const soundIcon = ref("mdi mdi-volume-high");

const updateAudioVolume = () => {
  const vol = volume.value / 100;
  Tone.Destination.volume.value = Tone.gainToDb(vol);
};

const handleRangeUpdate = () => {
  soundIcon.value =
    volume.value > 0 ? "mdi mdi-volume-high" : "mdi mdi-volume-off";
  updateAudioVolume();
};

const toggleMute = () => {
  if (volume.value > 0) {
    lastVolume.value = volume.value;
    volume.value = 0;
  } else {
    volume.value = lastVolume.value;
  }
  handleRangeUpdate();
};

const updateMousePosition = (event) => {};

onMounted(() => {
  handleRangeUpdate();
});
</script>

<style lang="scss">
#sound-slider__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 40px;
  border-radius: 1in;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .vertical-slider {
    height: 100px;
    width: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  input {
    height: 6px;
    width: 10px;
    flex: 1;
    transform: rotate(-90deg);
  }
}
.sound-vol-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(104, 113, 109);
  font-size: 16px;
  color: var(--color-white) !important;
  border-radius: 100%;
}
</style>
