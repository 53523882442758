<script setup>
import {ref, watch , computed} from 'vue'
import {storeToRefs} from "pinia";
import {useDictionaryStore} from "@/stores/dictionary-store";


const dictionaryStore = useDictionaryStore();
const {getDictionary } = dictionaryStore;
const {dictionaryData , meanings} = storeToRefs(dictionaryStore);
const props = defineProps({
  word:{
    type: String,
    default:'hello'
  },
  color:{
    type: String,
    default:'green'
  }
})
  getDictionary(props.word);
  const emit = defineEmits(['closeDialog'])
  const dialog = ref(true)
  const tab = ref(0)
  const tabs = ['Pronunciation' , 'Vocabulary']

  const close = () =>{
    emit('closeDialog');
  }
watch(dialog, (newVal, oldVal) => {
  if(!newVal && oldVal) {
    emit('closeDialog')
  }
})
  const ucfirst = (str) => {
    str = str.replace(/\./g, '');
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const pronounceWord = (word , langCode) =>{
    getVoice(langCode).then(voice => {
      if (voice) {
        const utterance = new SpeechSynthesisUtterance(word);
        utterance.voice = voice;
        utterance.pitch = 1;
        utterance.rate = 1;
        window.speechSynthesis.speak(utterance);
      } else {
        console.log('Voice not found for language code:', langCode);
      }
  });
  function getVoice(langCode) {
    return new Promise((resolve) => {
      const voices = window.speechSynthesis.getVoices();
      const voice = voices.find(v => v.lang.startsWith(langCode));
      resolve(voice);
    });
  }
}
const getButtonText = computed(() => {
  if (props.color === 'green') {
    return 'Good Pronunciation';
  } else if (props.color === 'orange') {
    return 'Average Pronunciation';
  } else if (props.color == 'red') {
    return 'Bad Pronunciation';
  } else {
    return 'Good Pronunciation';
  }
});
const suggession = computed(() => {
  if(props.color === 'orange') {
    return 'Try to improve it'
  }else if(props.color === 'red') {
    return 'Correction Required'
  }
})
const phonetics = computed(()=>{
  return dictionaryData.value[0]?.phonetics;
})
</script>

<template>
  <v-dialog v-model="dialog" max-width="450px" :persistent="false" >
    <v-card>
      <v-tabs v-model="tab" style="background: #F5F6F7;" dark>
        <v-tab v-for="tab in tabs" :key="tab" class="text-none">{{ tab }}</v-tab>
      </v-tabs>
      <v-card-text class="px-4">
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="Pronunciation">
            <div class="d-flex justify-space-between">
              <v-btn
                class="kiwi-fs-16 text-capitalize mb-2"
                size="small"
                block-sm
                variant="tonal"
                style="font-size: 14px"
                prepend-icon="mdi mdi-circle-small"
                :style="{ color: color }"
              >
                {{ getButtonText }}
              </v-btn>
              <span class="kiwi-fs-14" style="font-style: italic; margin-right: 5px;">{{suggession}}</span>

            </div>
            <v-divider />
            <div class="mt-1"><strong>{{ ucfirst(word) }}</strong></div>
            <div class="mt-2"><strong>[ Uk ]</strong>{{ phonetics[0]?.text }}<v-icon class="mdi mdi-headphones kiwi-text-primary" @click.native="pronounceWord(word ,'en-GB')"/></div>
            <div class="mt-2"><strong>[ USA ]</strong>{{ phonetics[1]?.text }}<v-icon class="mdi mdi-headphones kiwi-text-primary" @click.native="pronounceWord(word ,'en-US')"/></div>
          </v-tabs-window-item>
          <v-tabs-window-item value="Vocabulary">
            <div><strong>{{ ucfirst(word) }}</strong></div>
            <template v-if="meanings && meanings.length > 0">
              <div v-if="meanings[0]">
                <div>{{ meanings[0].partOfSpeech }}</div>
                <ul style="padding-left: 30px">
                  <li v-if="meanings[0].definitions && meanings[0].definitions.length > 0">
                    {{ meanings[0].definitions[0].definition }}
                  </li>
                </ul>
              </div>

              <div v-if="meanings[1]">
                <div>{{ meanings[1].partOfSpeech }}</div>
                <ul style="padding-left: 30px">
                  <li v-if="meanings[1].definitions && meanings[1].definitions.length > 0">
                    {{ meanings[1].definitions[0].definition }}
                  </li>
                </ul>
              </div>

              <v-divider  class="mt-1 mb-1" />

              <div v-if="meanings[0].definitions[0].example">Example:</div>
              <div v-if="meanings[0] && meanings[0].definitions && meanings[0].definitions.length > 0 && meanings[0].definitions[0].example">
                <ul style="padding-left: 30px">
                  <li>{{ meanings[0].definitions[0].example }}</li>
                </ul>
              </div>
              <div v-if="meanings[1] && meanings[1].definitions && meanings[1].definitions.length > 0 && meanings[1].definitions[0].example">
                <ul style="padding-left: 30px">
                  <li>{{ meanings[1].definitions[0].example }}</li>
                </ul>
              </div>
            </template>
          </v-tabs-window-item>

        </v-tabs-window>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn  text @click="close">Close</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-tab--selected {
  color: var(--color-primary);
}
</style>
