<template>
    <div class="fill-height align-center justify-center d-flex"
        v-if="layout === 'MainLayout'"
    >
        <v-empty-state
            headline="Page not found"
            text="Sorry, It’s look like the link is broken or the page you are looking for doesn’t exist or has been removed. Keep exploring out site."
            image="/assets/404.svg"
            min-height="auto"
            max-width="700"
            action-text="Go To Dashboard"
            class="kiwi-paragraphs-bundle kiwi-text-black-2 w-100"
        >
                <template v-slot:actions>
                <v-btn
                    class="kiwi-bg-primary kiwi-text-white kiwi-fs-16 text-none"
                    rounded="xl"
                    size="large"
                    :to="{name: 'dashboard'}"
                ></v-btn>
                </template>
        </v-empty-state>
    </div>
    <WebLayout page="page-not-found" :isGradientBgTop="false" v-else>
        <v-empty-state
            title="Page not found"
            text="Sorry, It’s look like the link is broken or the page you are looking for doesn’t exist or has been removed. Keep exploring out site."
            image="/assets/404.svg"
            max-height="400px"
            min-height="auto"
            action-text="Go To Homepage"
            class="kiwi-paragraphs-bundle kiwi-text-black-2"
        >
             <template v-slot:actions>
                <v-btn
                    class="kiwi-bg-primary kiwi-text-white kiwi-fs-16 text-none"
                    rounded="xl"
                    size="large"
                    :to="{name: 'home'}"
                ></v-btn>
             </template>
        </v-empty-state>
    </WebLayout>
</template>

<script setup>
import WebLayout from '../WebLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import {ref, computed} from 'vue'
import {useRoute} from 'vue-router'
const route = useRoute();
const layout = computed(() => {
   const meta =  route.meta || {};
   if(meta.requiresAuth) {
      return meta.layout || 'MainLayout'
   }else {
      return null
   }
})
</script>

<style lang="scss">

</style>