import { api } from "@/plugins/axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useNotificationStore = defineStore("notification", () => {
  const notificationData = ref({});
  const notificationList = ref([]);
  const unseenCounter = ref(null);
  function uniqueArray(array, newArray) {
    newArray.forEach((newAr) => {
      array.findIndex((ar) => ar.id === newAr.id) > 0
        ? array.splice(array.indexOf(newAr), 1)
        : array.push(newAr);
    });
  }

  async function fetchNotificationData(nextUrl = null) {
    try {
      const url =
        nextUrl === null ? `others/notification/?page=1&page_size=4` : nextUrl;
      await api.get(url).then((res) => {
        notificationData.value = res.data;
        uniqueArray(notificationList.value, res.data.results);
      });
    } catch (error) {}
  }

  function fetchUnseenCount() {
    try {
      api.get("others/notification/unseen_count/").then((res) => {
        unseenCounter.value = res.data.unseen_count;
      });
    } catch (error) {}
  }

  function markAsSeen(id) {
    try {
      api.post(`others/notification/${id}/mark-as-seen/`).then((res) => {});
    } catch (err) {}
  }

  return {
    notificationData,
    notificationList,
    fetchNotificationData,
    unseenCounter,
    fetchUnseenCount,
    markAsSeen,
  };
});
